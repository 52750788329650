/* eslint-disable no-case-declarations */
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { Row } from 'antd';
import {
  setBrandViewFiltersSelected,
  setIsFromPortfolioTab,
} from '@store/brand/brandViewSlice';
import FilterOptions from './FilterOptions';
import classes from './Filters.module.scss';
import { Ioptions } from '@constant';

const Filters = ({ handleFilterChange, currentTab }) => {
  const versionTypeData = useSelector(
    (state) => state?.brandPerformance?.versionType,
  );
  const isFromPortfolioTab = useSelector(
    (state) => state?.brandPerformance.isFromPortfolioTab,
  );
  const filterData = useSelector((state) => state?.brandPerformance?.filters);

  const incrementPortfolioTab = useSelector(
    (state) => state?.brandPerformance.increment,
  );
  const defaultFilter = filterData?.filter(
    (item) => item?.DefaultBrandView === 'Y',
  )[0];

  const userSquadMap = useSelector(
    (state) => state?.brandPerformance?.userSquadMap,
  );

  const brandFilterPortfolio = useSelector(
    (state) => state?.brandPerformance?.brandViewFiltersSelected,
  );
  const [showFloatingFilter, setShowFloatingFilter] = useState(false);
  const [options, setOptions] = useState({
    Brand: [],
    Business_Unit: [],
    Franchise: [],
    VersionType: [...versionTypeData],
    SKU: [], // Added SKU in options
  });
  const [searchInputs, setSearchInputs] = useState({
    Franchise: '',
    Brand: '',
    SKU: '',
  });
  const [floatingFilterOpen, setFloatingFilterOpen] = useState(false);
  const [loading, setLoading] = useState({
    Brand: false,
    Business_Unit: false,
    Franchise: false,
    VersionType: false,
    SKU: false, // Added SKU in loading state
  });
  const [count, setCount] = useState(0);
  const [selections, setSelections] = useState({
    Brand: [],
    Business_Unit: '',
    Franchise: [],
    VersionType: '',
    SKU: [], // Added SKU in selections
  });

  const containerRef = useRef(null);
  const filterModalRef = useRef(null);
  const selectModalRef = useRef(null);
  const intersectionCb = (entries) => {
    const [entry] = entries;
    setShowFloatingFilter(!entry.isIntersecting);
  };

  const dispatch = useDispatch();

  // First useEffect for updating selections when defaultFilter changes
  useEffect(() => {
    if (
      currentTab === 'brand' &&
      isFromPortfolioTab &&
      incrementPortfolioTab > 0
    ) {
      setSelections({
        Brand:
          brandFilterPortfolio?.Brand?.length > 0
            ? brandFilterPortfolio?.Brand
            : currentTab === 'brand' && defaultFilter
            ? [defaultFilter?.BrandName]
            : [],
        Business_Unit:
          brandFilterPortfolio?.Brand?.length > 0
            ? brandFilterPortfolio?.Business_Unit
            : defaultFilter?.BUName,
        Franchise:
          brandFilterPortfolio?.Brand?.length > 0
            ? brandFilterPortfolio?.Franchise
            : [],
        VersionType:
          brandFilterPortfolio?.Brand?.length > 0
            ? brandFilterPortfolio?.VersionType
            : versionTypeData[0],
        SKU: [], // Added SKU in selections
      });
    } else {
      setSelections({
        Brand: [defaultFilter?.BrandName],
        Business_Unit: defaultFilter?.BUName,
        Franchise: [],
        VersionType: versionTypeData[0],
        SKU: [], // Added SKU in selections
      });
    }
  }, [defaultFilter, isFromPortfolioTab, incrementPortfolioTab]);

  useEffect(() => {
    if (currentTab === 'portfolio' && count >= 1) {
      // Call handleFilterChange if both selections are made or if Franchise is deselected
      if (selections?.Franchise?.length > 0 && selections?.VersionType) {
        handleFilterChange(selections, options);
      } else if (selections?.Franchise?.length === 0) {
        // If no Franchise is selected, still call the filter change
        handleFilterChange(selections, options);
      }
    } else if (currentTab === 'brand' && count >= 3) {
      if (selections) {
        handleFilterChange(selections, options);
      }
    } else if (currentTab === 'sop' && count >= 4) {
      if (selections?.Brand) {
        handleFilterChange(selections, options);
      }
    } else if (currentTab === 'businessplan' && count >= 2) {
      if (selections?.Brand) {
        handleFilterChange(selections, options);
      }
    }
  }, [selections]);

  useEffect(() => {
    const observer = new IntersectionObserver(intersectionCb, Ioptions);
    if (containerRef.current) observer.observe(containerRef.current);
    document.addEventListener('click', (el) => {
      if (
        filterModalRef.current &&
        !filterModalRef.current.contains(el.target)
      ) {
        if (
          el.target.id !== 'menu-item' &&
          el.target.tagName !== 'path' &&
          el.target.tagName !== 'svg' &&
          el.target.id !== 'menu-item-checkbox'
        ) {
          setFloatingFilterOpen(false);
        }
      }
    });
    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
      document.removeEventListener('click', () => {});
    };
  }, [containerRef]);

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (
        e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  const getUniqueFieldValues = (data, fieldName) => {
    if (!data?.length || !data[0].hasOwnProperty(fieldName)) {
      return [];
    }
    return [...new Set(data?.map((item) => item[fieldName]))];
  };

  const fetchDataForDropdown = async (field, from = 'selection') => {
    setLoading((prev) => ({ ...prev, [field]: true }));

    const { Business_Unit, Franchise, Brand } = selections;

    const getSortedOptions = (options, field) => {
      return field === 'Business_Unit'
        ? options.sort((a, b) => b?.localeCompare(a))
        : options.sort((a, b) => a?.localeCompare(b));
    };

    switch (field) {
      case 'Business_Unit':
        const businessUnitOptions = getUniqueFieldValues(filterData, 'BUName');
        setOptions((prev) => ({
          ...prev,
          Business_Unit: getSortedOptions(businessUnitOptions, field),
        }));
        break;

      case 'Franchise':
        if (Business_Unit) {
          const franchiseOptions = getUniqueFieldValues(
            filterData?.filter((item) => item?.BUName === Business_Unit),
            'SquadName',
          );
          setOptions((prev) => ({
            ...prev,
            Franchise: getSortedOptions(franchiseOptions, field),
          }));
        }
        break;

      case 'Brand':
        if (Business_Unit) {
          const brandOptions = getUniqueFieldValues(
            filterData
              ?.filter((item) =>
                Franchise?.length > 0
                  ? item?.BUName === Business_Unit &&
                    Franchise?.includes(item.SquadName)
                  : item?.BUName === Business_Unit,
              )
              ?.sort((a, b) => {
                if (a?.DefaultBrandView === 'Y' && b?.DefaultBrandView !== 'Y')
                  return -1;
                if (a?.DefaultBrandView !== 'Y' && b?.DefaultBrandView === 'Y')
                  return 1;

                return 0;
              }),
            'BrandName',
          );

          setOptions((prev) => ({
            ...prev,
            Brand:
              currentTab === 'brand'
                ? brandOptions
                : getSortedOptions(brandOptions, field),
          }));
          if (from !== 'ddn' && from !== 'portfolio') {
            if (currentTab === 'brand') {
              if (userSquadMap?.length === 1 && userSquadMap[0] === 'ALL') {
                setSelections((prev) => ({
                  ...prev,
                  Brand: [brandOptions[0]],
                }));
              } else {
                setSelections((prev) => ({
                  ...prev,
                  Brand: [brandOptions[0]],
                }));
              }
            }
          }
          if (from === 'portfolio') {
            handleFilterChange(brandFilterPortfolio, options);
          }
        }
        break;

      case 'SKU':
        // Filter SKU based on selected Business Unit, Franchise, and Brand dynamically
        const skuOptions = getUniqueFieldValues(
          filterData?.filter((item) => {
            return (
              (!Business_Unit || item.BUName === Business_Unit) &&
              (Franchise?.length === 0 ||
                Franchise?.includes(item.SquadName)) &&
              (Brand?.length === 0 || Brand?.includes(item.BrandName))
            );
          }),
          'SkuName',
        );

        setOptions((prev) => ({
          ...prev,
          SKU: getSortedOptions(skuOptions, field),
        }));
        break;

      default:
        break;
    }

    setLoading((prev) => ({ ...prev, [field]: false }));
  };

  const handleChange = (field, value, isMulti = false) => {
    if (field === 'Business_Unit') {
      setCount(0);
    }
    if (brandFilterPortfolio?.Brand?.length > 0) {
      dispatch(
        setBrandViewFiltersSelected({
          Brand: [],
          Business_Unit: '',
          Franchise: [],
          VersionType: '',
          SKU: [],
        }),
      );
      dispatch(setIsFromPortfolioTab(false));
    }
    setSelections((prevSelections) => {
      if (isMulti) {
        // Multi-select logic: Add or remove value from the array
        if (value === 'Multiple Selected') {
          return {
            ...prevSelections,
            [field]: [],
          };
        } else if (prevSelections[field].includes(value)) {
          // If the value exists, remove it (deselect)
          return {
            ...prevSelections,
            [field]: prevSelections[field].filter((item) => item !== value),
          };
        } else {
          // If the value doesn't exist, add it (select)
          return {
            ...prevSelections,
            [field]: [...prevSelections[field], value],
          };
        }
      } else {
        if (field === 'Brand') {
          // Single-select logic: Directly update with the selected value
          return {
            ...prevSelections,
            [field]: [value],
          };
        } else {
          // Single-select logic: Directly update with the selected value
          return {
            ...prevSelections,
            [field]: value,
          };
        }
      }
    });
  };

  useEffect(() => {
    setCount(0);
  }, [currentTab]);

  useEffect(() => {
    if (selections.Business_Unit) {
      setCount((prevCount) => {
        return prevCount + 1;
      });
      setSelections((prev) => ({ ...prev, Franchise: [] }));
      fetchDataForDropdown('Franchise');
    }
  }, [selections.Business_Unit]);

  useEffect(() => {
    // if (brandFilterPortfolio?.Brand?.length == 0){

    if (currentTab === 'brand' && selections?.Franchise) {
      if (brandFilterPortfolio?.Brand?.length > 0) {
        fetchDataForDropdown('Brand', 'portfolio');
        setCount((prevCount) => {
          return prevCount + 1;
        });
      } else {
        fetchDataForDropdown('Brand');
        setCount((prevCount) => {
          return prevCount + 1;
        });
      }
    } else if (currentTab === 'sop' && selections?.Franchise) {
      setSelections((prev) => ({ ...prev, Brand: [], SKU: [] }));
      setCount((prevCount) => {
        return prevCount + 1;
      });
    } else if (currentTab === 'businessplan' && selections?.Franchise) {
      setSelections((prev) => ({ ...prev, Brand: [] }));
      setCount((prevCount) => {
        return prevCount + 1;
      });
    }
    // }
  }, [selections.Franchise]);

  useEffect(() => {
    if (currentTab === 'brand' && selections?.Brand) {
      setSelections((prev) => ({ ...prev, SKU: [] }));
      setCount((prevCount) => {
        return prevCount + 1;
      });
    }
    if (currentTab === 'sop' && selections?.Brand) {
      setSelections((prev) => ({ ...prev, SKU: [] }));
      fetchDataForDropdown('SKU');
      setCount((prevCount) => {
        return prevCount + 1;
      });
    }
  }, [selections.Brand]);

  useEffect(() => {
    if (currentTab === 'sop' && selections?.Brand) {
      setCount((prevCount) => {
        return prevCount + 1;
      });
    }
  }, [selections.SKU]);

  const handleSearchChange = (field, value) => {
    setSearchInputs((prev) => ({ ...prev, [field]: value }));
  };

  const handleDropdownVisibleChange = (field, isOpen) => {
    if (!isOpen) {
      // Clear search input for the dropdown when it's closed
      setSearchInputs((prev) => ({ ...prev, [field]: '' }));
    } else {
      fetchDataForDropdown(field, 'ddn'); // Trigger data fetch on open
    }
  };

  return (
    <div className={classes['filter-container']}>
      <Row ref={containerRef} gutter={[4, 4]} style={{ width: '100%' }}>
        <FilterOptions
          currentTab={currentTab}
          fetchDataForDropdown={fetchDataForDropdown}
          handleChange={handleChange}
          handleDropdownVisibleChange={handleDropdownVisibleChange}
          handleSearchChange={handleSearchChange}
          loading={loading}
          options={options}
          searchInputs={searchInputs}
          selections={selections}
          filterModalRef={filterModalRef}
          floatingFilterOpen={floatingFilterOpen}
          setFloatingFilterOpen={setFloatingFilterOpen}
          showFloatingFilter={showFloatingFilter}
          selectModalRef={selectModalRef}
        />
      </Row>
    </div>
  );
};

export default Filters;
