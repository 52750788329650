import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  menuClasses,
} from 'react-pro-sidebar';
import NavSubMenu from './NavSubMenu';
import { APP_URL, REPORT_CATEGORY } from '../../../constants';
import { useSelector } from 'react-redux';
import classes from './SideBar.module.scss';
import { getUserName } from '@utils/getUser';

const SideBar = ({ items }) => {
  const location = useLocation();
  const userName = getUserName();
  const [collapsed, setCollapsed] = useState(true);
  const categoryData = [...items];
  const [activeItem, setActiveItem] = useState('/portfolio');

  const UserAccess = useSelector((state) => state?.brandPerformance?.userAcces);

  const viewFlags = UserAccess.reduce((acc, item) => {
    acc[item.ViewName] = true;
    return acc;
  }, {});

  const showPortfolio = viewFlags['Portfolio Performance'] || false;
  const showBrand = viewFlags['Brand Performance'] || false;
  const showSop = viewFlags['S&OP'] || false;

  useEffect(() => {
    if (!showPortfolio) {
      if (showBrand) {
        setActiveItem('/brand');
      } else if (showSop) {
        setActiveItem('/sop');
      } else {
        setActiveItem(null);
      }
    } else {
      setActiveItem('/portfolio');
    }
  }, [showPortfolio, showBrand, showSop]);

  const collapseSidebar = () => {
    setCollapsed(!collapsed);
  };

  const handleMenuItemClick = (link) => {
    setActiveItem(link);
    setCollapsed(true);
  };
  const isActive = (link) => {
    const isActivePath =
      location.pathname === `${APP_URL}${link}` ||
      (link === '/' && location.pathname === APP_URL);
    const defaultSelection =
      location.pathname === `${APP_URL}` && link === activeItem;
    return isActivePath || defaultSelection;
  };
  const getParentActiveStatus = (submenu) => {
    return submenu.some((item) => isActive(item.link)) || isActive('/');
  };

  return (
    <Sidebar
      backgroundColor={'var(--color-secondary-blue)'}
      collapsed={collapsed}
      collapsedWidth="60px"
      height={'400px'}
      width={260}
      className={classes['sidebarNav']}
    >
      <Menu
        closeOnClick={true}
        renderExpandIcon={({ open }) => {
          return (
            !collapsed && (
              <>
                {open ? (
                  <>
                    <i className={`nav-link-icon icon icon__chevronUp`}></i>
                  </>
                ) : (
                  <i className={`nav-link-icon icon icon__chevronDown`}></i>
                )}
              </>
            )
          );
        }}
      >
        <MenuItem
          rootStyles={{
            backgroundColor: 'var(--color-secondary-blue)',
            ['.' + menuClasses.button]: {
              backgroundColor: 'var(--color-secondary-blue)',
              paddingRight: '10px !important',
              paddingLeft: '10px !important',
              margin: '10px 5px',
              '&:hover': {
                backgroundColor: 'var(--color-secondary-blue) !important',
              },
            },
            ['.' + menuClasses.label]: {
              display: 'flex',
              justifyContent: 'space-between',
            },
            ['.' + menuClasses.menuItemRoot]: {
              padding: '10px 5px',
            },
          }}
          key="menuitem1"
        >
          <button className={classes['menu-btn']} onClick={collapseSidebar}>
            {!collapsed ? (
              <i className={'icon icon__menu-collapse'}></i>
            ) : (
              <i className={'icon icon__menu-expand'}></i>
            )}
          </button>
          {!collapsed && (
            <div className={classes.userInfo}>
              <div className={classes.userName}>
                <i className="icon icon__user_avatar"></i>
                {userName || 'User'}
              </div>
            </div>
          )}
        </MenuItem>
        {categoryData.map((item, index) => {
          const isParentActive =
            item.submenu && getParentActiveStatus(item.submenu);
          const isActiveItem = isActive(item.link);
          return !item?.submenu?.length ? (
            <MenuItem
              key={index}
              component={<Link to={`${APP_URL}${item?.link}`} />}
              onClick={() => handleMenuItemClick(item?.link)}
              title={item.categoryName}
              rootStyles={{
                ['.' + menuClasses.button]: {
                  backgroundColor: isActiveItem
                    ? 'var(--color-tertiary-blue)'
                    : 'var(--color-secondary-blue)',
                  '&:hover': {
                    backgroundColor: 'var(--color-tertiary-blue) !important',
                  },
                },
              }}
            >
              <i
                title={item.categoryName}
                className={`icon icon__${item.categoryIcon} nav-link-icon ${classes['nav-icon']}`}
              ></i>
              {!collapsed && (
                <span
                  style={{
                    color: item?.disabled ? '#A2C1D9' : '',
                  }}
                >
                  {item.categoryName}
                  {item.categoryName === REPORT_CATEGORY && (
                    <i
                      className={`icon icon__externalLink ${classes['report-icon']}`}
                    ></i>
                  )}
                </span>
              )}
            </MenuItem>
          ) : (
            <SubMenu
              key={index}
              title={item.categoryName}
              label={<NavSubMenu item={item} collapsed={collapsed} />}
              rootStyles={{
                ['& > .' + menuClasses.subMenuContent]: {
                  inset: '52px auto auto 0px !important',
                },
                ['& > .' + menuClasses.button]: {
                  paddingRight: '10px !important',
                  backgroundColor: isParentActive
                    ? 'var(--color-tertiary-blue)'
                    : 'var(--color-secondary-blue)',
                  '&:hover': {
                    backgroundColor: 'var(--color-tertiary-blue) !important',
                  },
                },
                ['.' + menuClasses.label]: {
                  textOverflow: 'initial',
                },
                ['& > .' + menuClasses.icon]: {
                  backgroundImage:
                    "url('./icon_menu_engagement.png') !important",
                  width: '26px',
                  height: ' 26px',
                },
              }}
            >
              {item?.submenu &&
                item?.submenu?.map((menu, i) => {
                  const isSubMenuActive = isActive(menu?.link);
                  return (
                    <MenuItem
                      title={menu.categoryName}
                      component={<Link to={`${APP_URL}${menu?.link}`} />}
                      onClick={() => handleMenuItemClick(menu?.link)}
                      rootStyles={{
                        ['.' + menuClasses.button]: {
                          backgroundColor: isSubMenuActive
                            ? 'var(--color-tertiary-blue)'
                            : 'var( --color-dark-blue)',
                          '&:hover': {
                            backgroundColor:
                              'var(--color-tertiary-blue) !important',
                          },
                        },
                      }}
                      key={i}
                    >
                      {menu.categoryName}
                    </MenuItem>
                  );
                })}
            </SubMenu>
          );
        })}
      </Menu>
    </Sidebar>
  );
};

export default SideBar;
