export const portfolioInitialState = {
  performanceChartData: [],
  performanceTableData: [],
  performanceCommentData: [],
  portfolioSummaryCommentData: [],
  performanceWeek: '',
  performanceYear: '',
  portfolioExpression: [],
  portfolioTableExpression: [],
  portfolioExpressionVariance: [],
  selectedFilters: {},
  summaryTab: {
    ytdVarianceActual: {},
    ytdVariance: {},
    ytdPyActual: {},
    ytdPyVariance: {},
    ytdAvgLabel: '',
    ytd12weekLabel: '',
    ytdTrendLabel: '',
    ytdTOLabel: '',
    fyProjection: {},
  },

  portfolioTab: {
    QtdYear: '',
    Week: '',
    portfolioDataQtdLabel: [],
    portfolioDataQtdTotal: [],
    portfolioDataQtd: [],
    portfolioDataYtdTotal: [],
    portfolioDataYtd: [],
  },
  eqoutsTab: {
    eqoutTable: [],
  },
  portfolioFilter: {
    businessUnit: [],
    forecastType: [],
    squad: [],
  },
  portfolioCommentUpdate: 0,
};
