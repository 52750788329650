import { useSelector } from 'react-redux';
import { List, Tooltip } from 'antd';
import { GETCOMMENTCREATEDDATE } from '@utils/utils';
import classes from './Catalogue.module.scss';
import { ACTION_ADD, ACTION_DELETE } from '@constant';

const Catalogue = ({
  data,
  onConfigureComment,
  currentUser,
  cardNumber,
  onConfigurePinnedComment,
  cardId,
  selectedFilters,
}) => {
  const UserAccess = useSelector((state) => state?.brandPerformance?.userAcces);
  // const isPinned = data?.[0]?.isPinned ? true : false;
  let isPinned = data?.find((x) => x?.isPinned);
  isPinned = isPinned?.isPinned ? true : false;
  const distinctCardIds = Array?.from(
    new Set(
      UserAccess?.filter((item) => item?.CommentEditAccess === 'Y')?.map(
        (item) => item?.CardId,
      ),
    ),
  );

  const cardNumberExists = distinctCardIds?.includes(cardNumber);

  return (
    <List
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            title={
              <div className={classes['catalogue-title']}>
                <div>
                  {item?.UserName}
                  <span className={classes['comment-info']}>
                    {GETCOMMENTCREATEDDATE(item?.CreatedDate)}
                  </span>
                </div>
                <div style={{ display: 'flex' }}>
                  {item?.isPinned && (
                    <Tooltip title="Unpin">
                      <i
                        className={'icon icon__unpinned'}
                        onClick={() =>
                          onConfigurePinnedComment(
                            ACTION_DELETE,
                            item?.ID,
                            item?.ChartId,
                            cardId,
                          )
                        }
                      />
                    </Tooltip>
                  )}
                  {((cardId === 'short-termforecast' &&
                    selectedFilters?.SKU?.length === 1) ||
                    (cardId !== 'short-termforecast' &&
                      selectedFilters?.Brand?.length === 1)) &&
                    !isPinned &&
                    !item?.isPinned && (
                      <Tooltip title="Pin">
                        <i
                          className={'icon icon__pinned'}
                          style={{
                            paddingRight: '5px',
                            fontSize: '14px',
                            color: '#13294b',
                          }}
                          onClick={() =>
                            onConfigurePinnedComment(
                              ACTION_ADD,
                              item?.ID,
                              item?.ChartId,
                              cardId,
                            )
                          }
                        />
                      </Tooltip>
                    )}
                  {currentUser?.toLowerCase() === item?.UnixId?.toLowerCase() &&
                    cardNumberExists && (
                      <>
                        <Tooltip title="Edit">
                          <i
                            className={'icon icon__edit'}
                            onClick={() => onConfigureComment('edit', item?.ID)}
                          ></i>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <i
                            className={'icon icon__delete_blue'}
                            onClick={() =>
                              onConfigureComment('delete', item?.ID)
                            }
                          ></i>
                        </Tooltip>
                      </>
                    )}
                </div>
              </div>
            }
            description={
              <div className={classes['catalogue-desc']}>{item?.Comment}</div>
            }
          />
        </List.Item>
      )}
    />
  );
};
export default Catalogue;
