/* eslint-disable no-prototype-builtins */
import { useEffect, useState } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { Modal, Button, Row, Col, Tooltip, Input, Spin } from 'antd';
import classes from './ConfigureMyView.module.scss';
import {
  demandChartGridCols,
  fyProjectionGridCols,
  yoyGridCols,
  ytdChartGridCols,
  ytdSalesVsForecastChartGridCols,
} from '@mockdata/brandChartData';
import { useSelector } from 'react-redux';
import { getQlikSenseUnformattedData } from '@service/qlik.service';
import ViewTile from './ViewTile';
import {
  DEFAULT_FORECASTVIEW,
  MY_VIEW_CHART_With_GRID,
  MY_VIEW_FULLWIDTH,
  MY_VIEW_SINGLECHART,
  SHORT_TERM_VIEW_TYPE_WEEK,
  CARDNAMECCAPS,
  CARDNAME,
  CONFIGWARNINGMSG,
  CHART_GRID_COLUMNS,
  SINGLE,
  CATEGORYUPPERCASE,
  VREPLACESTRING,
  GROWTH,
  T0,
  PRIMARY_CONDITION_FILTER,
  FILTERS,
  BRANDUPPERCASE,
  BRAND_SECTION_EXPRESSION_NAME,
  ACTUAL_SALES,
  ACTUAL,
  VIEW_TYPE_PREVIOUS,
  COMMENTEDCHARTS,
  PREVIOUSMONTHFORECASTKPICARDID,
  YEARFORECASTFINANCIALFORECASTKPICARDID,
  STATE_BRAND_SUMMARY,
} from '@constant';
import {
  EXTRACTCATEGORYARRAY,
  FINDMINMAX,
  shiftElementPlacement,
  SORTYEARS,
  TRANSFORM_AGGFORECAST_CHARTDATA,
  TRANSFORM_AGGFORECAST_GRIDDATA,
  TRANSFORM_BPCHART_SCATTER,
  TRANSFORM_BPLAN_CHARTDATA,
  TRANSFORM_FORECASTTREND_CHARTDATA,
  TRANSFORM_FORECASTTREND_GRIDDATA,
  TRANSFORM_FYPROJECTION_CHARTDATA,
  TRANSFORM_FYPROJECTION_GRIDDATA,
  TRANSFORM_SD_CHARTDATA,
  TRANSFORM_SD_GRIDDATA,
  TRANSFORM_SHORTBIAS_GRIDDATA,
  TRANSFORM_SHORTTERMBIAS_CHARTDATA,
  TRANSFORM_VARIANCE_CHARTDATA,
  TRANSFORM_YOYOUTS_CHARTDATA,
  TRANSFORM_YOYOUTS_GRIDATA,
  TRANSFORM_YOYSALES_CHARTDATA,
  TRANSFORM_YOYSALES_GRIDDATA,
  TRANSFORM_YTD_CHARTDATA,
  TRANSFORM_YTD_GRIDDATA,
  TRANSFORM_YTDFORECASTGRIDDATA,
  TRANSFORM_YTDFORECASTPY_GRIDDATA,
} from '@utils/dataFormatter';
import {
  AccuracyForecastGridCols,
  AggForecastGridCols,
  ShortWeekChartGridCols,
  YearForecastGridCols,
} from '@mockdata/SopChartData';
import { CheckOutlined } from '@ant-design/icons';
import {
  deepEqualIgnoreArrayOrder,
  extractMeasureSOP,
  extractMeasureEventType,
  extractBPBusinessUnit,
  GETSECTIONSEXPRESSIONGRID,
  GETLABELEXPRESSION,
  GETSECTIONSEXPRESSION,
} from '@utils/utils';
import MyViewKpi from '@components/layout/myviewsearchbar/MyViewKpi';
import MyViewFilters from '@components/myviewFilter/myviewFilters';
import { CARD_CONFIG } from '@utils/cardConfig';
import InfoDialog from '@components/elements/Modal/InfoDialog';
import _ from 'lodash';

const ConfigureMyView = ({
  handleAddToView,
  showModalContainer,
  editData,
  setShowModalContainer,
  page,
  setCurrentPage,
  handleUdpateMyView,
  editMode,
  setEditMode,
  eventFilterOptions,
  dataFilterOptions,
  setEditData,
  metricOptions,
  addHandleLoader,
  allComments,
}) => {
  const { promiseInProgress } = usePromiseTracker();
  const brandExpressions = useSelector(
    (state) => state?.brandPerformance?.brandExpressions,
  );
  const [editmodeCon, setEditModeCon] = useState(false);

  useEffect(() => {
    setEditModeCon(editMode === true ? true : false);
  }, [editMode]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedKpi, setKpi] = useState(null); // Holds the KPI object
  const [selectedDimension, setSelectedDimension] = useState(null); // Holds the dimension object
  const [selectedKpiName, setSelectedKpiName] = useState('Portfolio'); // Holds the KPI name
  const [expression, setExpression] = useState();
  const [expressionVariance, setExpressionVariance] = useState();
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState();
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [gridColumns, setGridColumns] = useState([]);
  const [formattedGridData, setFormattedGridData] = useState([]);
  const [netSalesTable, setNetSalesTable] = useState([]);
  const [chartIdLabel, setChartIdLabel] = useState('');
  const [selectedFilter, setSelectedFilter] = useState([]);

  const [varianceChartData, setVarianceChartData] = useState([]);
  const [varianceGridData, setVarianceGridData] = useState([]);
  const [editableText, setEditableText] = useState('');
  const [biasInputData, setBiasInputData] = useState([]);
  const [biasWeeksInputData, setBiasWeeksInputData] = useState([]);
  const [selectedView, setSelectedView] = useState(DEFAULT_FORECASTVIEW);
  const [bpExpression, setBpExpression] = useState('');
  const [selectedViewShortTerm, setSelectedViewShortTerm] = useState(
    SHORT_TERM_VIEW_TYPE_WEEK,
  );
  const [bpData, setBpData] = useState([]);
  const [category, setCategory] = useState([]);
  const [scatterEvents, setScatterEvents] = useState([]);

  const filterData = useSelector((state) => state?.brandPerformance?.filters);
  const defaultFilter = filterData?.filter(
    (item) => item?.DefaultBrandView === 'Y',
  )[0];

  const [checkboxValues, setCheckBoxValues] = useState([]);
  const [checknetsales, setchecknetsales] = useState(false);
  const [currentNetSales, setCurrentNetSales] = useState();
  const [filteredBPData, SetFilteredBPData] = useState();
  const [summaryData, setSummaryData] = useState({
    ytdAvgLabel: '',
    ytd12weekLabel: '',
    ytdTrendLabel: '',
    ytdTOLabel: '',
    ytdRoyDemandText: '',
    yearForecastLabel: [],
    aggForecastLabel: '',
    ytdRoyDemandLabel: '',
    forecastTrendMonth: '',
  });
  const [checkboxValuesEvent, setCheckBoxValuesEvent] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [options, setOptions] = useState([]);
  const [isApplyDisabled, setIsApplyDisabled] = useState(true);
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);
  const [isAddDisabled, setIsAddDisabled] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [initialFilters, setInitialFilters] = useState(selectedFilter);
  const [isDimensionChangedinEditmode, setIsDimensionChangedInEditmode] =
    useState(false);
  const [viewType, setViewType] = useState(VIEW_TYPE_PREVIOUS);
  const [showComment, setShowComment] = useState(false);
  const [commentDetails, setCommentDetails] = useState(null);
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [sopPreviousOrFinanceHasComment, setSopPreviousOrFinanceHasComment] =
    useState(false);

  const versionTypeData = useSelector(
    (state) => state?.brandPerformance?.versionType,
  );

  const myViewKpi = useSelector(
    (state) => state?.brandPerformance?.myViewKpiDetails,
  );

  let selectedDimensionName = getDimensionName(selectedDimension, myViewKpi);

  function getDimensionName(selectedDimension, myViewKpi) {
    for (let kpi of myViewKpi) {
      for (let dimension of kpi?.dimensions) {
        if (dimension?.ChartLabel === selectedDimension?.ChartLabel) {
          return dimension?.DimensionName;
        }
      }
    }
    return null;
  }

  const metricOptionsList = metricOptions?.map((item) => ({
    value: item,
    label: item,
  }));

  const card_config_db = useSelector(
    (state) => state?.brandPerformance.cardConfig,
  );

  const handleInfoModalClose = () => {
    setIsInfoModalVisible(false);
  };

  const getLeastConfigureMyViewFilter = () => {
    const KPIIdWithSKUFilter = myViewKpi
      ?.filter((item) => item.KpiName === 'S&OP')
      .map((item) => item.KpiId);

    const KPIIdWithBrandFilter = myViewKpi
      ?.filter((item) => ['S&OP', 'Brand', 'Business'].includes(item.KpiName))
      .map((item) => item.KpiId);

    let brand = selectedFilter?.Brand;
    let businessUnit = selectedFilter?.Business_Unit;
    let franchise = selectedFilter?.Franchise;
    let sku = selectedFilter?.SKU;
    let priorityFilter = '';

    if (
      sku?.length > 0 &&
      KPIIdWithSKUFilter.includes(
        selectedDimension?.KpiId
          ? selectedDimension?.KpiId
          : selectedDimension?.KPIId,
      )
    ) {
      priorityFilter = sku;
    } else if (
      brand?.length > 0 &&
      KPIIdWithBrandFilter.includes(
        selectedDimension?.KpiId
          ? selectedDimension?.KpiId
          : selectedDimension?.KPIId,
      )
    ) {
      priorityFilter = brand;
    } else if (franchise?.length > 0) {
      priorityFilter = franchise;
    } else {
      priorityFilter = businessUnit;
    }
    if (_.isArray(priorityFilter)) {
      let priorityMultiFilter = '';
      priorityFilter?.forEach((item) => (priorityMultiFilter += item + ', '));
      return priorityMultiFilter.replace(/,\s*$/, '');
    } else {
      return priorityFilter;
    }
  };
  let selectedDimensionNameWithLeastFilter = `${selectedDimensionName} for ${getLeastConfigureMyViewFilter()}`;

  useEffect(() => {
    if (editMode) {
      setEditableText(editData?.CardName || editData?.cardName);
      const filter = JSON.parse(editData?.Filter || editData?.filter);
      // handleCheckFilterSame(filter);
      setSelectedFilter(filter);
      // handleSelection(editData?.ViewName?.split(" ")[0], editData);
      setIsApplyDisabled(true);
      // setIsUpdateDisabled(true);
      if (extractMeasureEventType(editData?.Expression)?.length > 0) {
        let value = extractMeasureEventType(editData?.Expression)
          ?.replace(/'/g, '')
          ?.split(',');
        // setCheckBoxValuesEvent(value)
        onSelectChangesEvent(value);
      }

      if (
        editData?.ChartLabel == CARD_CONFIG?.SOP_YEAR_FORECAST_CHART?.ChartLabel
      ) {
        setCurrentNetSales(extractMeasureSOP(editData?.Expression));
      }

      const data = formatOutput(
        filter,
        editData?.ViewName?.split(' ')[0]?.toLowerCase(),
      );
      const varianceData = formatOutputVarience(
        selectedFilter,
        selectedKpiName?.toLowerCase(),
      );
      setExpression(data);
      setExpressionVariance(varianceData);

      handleSelection(editData?.ViewName?.split(' ')[0], editData);
    } else {
      if (defaultFilter) {
        setIsApplyDisabled(true);
        setIsAddDisabled(false);
        setInitialFilters({
          Brand:
            selectedKpiName === BRANDUPPERCASE
              ? [defaultFilter?.BrandName]
              : [],
          Business_Unit: defaultFilter?.BUName,
          Franchise: [],
          VersionType: versionTypeData[0],
          SKU: [], // Added SKU in selections
        });
        setSelectedFilter({
          Brand:
            selectedKpiName === BRANDUPPERCASE
              ? [defaultFilter?.BrandName]
              : [],
          Business_Unit: defaultFilter?.BUName,
          Franchise: [],
          VersionType: versionTypeData[0],
          SKU: [], // Added SKU in selections
        });
      }
    }
  }, [defaultFilter, editMode]);

  useEffect(() => {
    if (showModalContainer) {
      showModal();
    }
  }, [showModalContainer]);

  const showModal = () => {
    setIsModalVisible(true);
    let names = isDimensionChangedinEditmode
      ? selectedDimension?.DimensionName
      : editData?.CardName || editData?.cardName;

    setEditableText(
      page
        ? names
        : `${
            selectedDimension?.DimensionName
          } for ${getLeastConfigureMyViewFilter()}`,
    );
    setInitialFilters(selectedFilter);
  };

  useEffect(() => {
    let names = isDimensionChangedinEditmode
      ? selectedDimension?.DimensionName ?? selectedDimension?.CardName
      : editData?.CardName || editData?.cardName;
    setEditableText(
      editMode
        ? names
        : `${
            selectedDimension?.DimensionName
          } for ${getLeastConfigureMyViewFilter()}`,
    );
  }, [selectedDimension, selectedFilter]);

  useEffect(() => {
    setInitialFilters({
      Brand:
        selectedKpiName === BRANDUPPERCASE ? [defaultFilter?.BrandName] : [],
      Business_Unit: defaultFilter?.BUName,
      Franchise: [],
      VersionType: versionTypeData[0],
      SKU: [], // Added SKU in selections
    });
    setSelectedFilter({
      Brand:
        selectedKpiName === BRANDUPPERCASE ? [defaultFilter?.BrandName] : [],
      Business_Unit: defaultFilter?.BUName,
      Franchise: [],
      VersionType: versionTypeData[0],
      SKU: [], // Added SKU in selections
    });
  }, [selectedKpi]);

  const handleCancel = () => {
    setEditMode(false);
    // setIsUpdateDisabled(true);
    setSelectedFilter({
      Brand:
        selectedKpiName === BRANDUPPERCASE ? [defaultFilter?.BrandName] : [],
      Business_Unit: defaultFilter?.BUName,
      Franchise: [],
      VersionType: versionTypeData[0],
      SKU: [], // Added SKU in selections
    });

    handleSelection('Portfolio', null);
    setCurrentPage(false);
    setCheckBoxValuesEvent(eventFilterOptions);

    setIsDimensionChangedInEditmode(false);
    SetFilteredBPData();
    setShowModalContainer(false);
    setIsModalVisible(false);
  };

  // Function to handle the selection of both KPI name and dimension
  const handleSelection = (kpiName, dimension, isDimensionupdated = false) => {
    setSelectedKpiName(kpiName);
    setSelectedDimension(dimension);
    setChartIdLabel(dimension?.ChartLabel);

    if (kpiName && dimension && expression) {
      trackPromise(fetchData(kpiName, dimension));
    }
    if (isDimensionupdated && editMode) {
      setIsDimensionChangedInEditmode(true);
    }
  };

  const formatOutput = (data, view) => {
    if (view === 'portfolio') {
      const franchiseCondition =
        data?.Franchise?.length === 0
          ? PRIMARY_CONDITION_FILTER.FRANCHISE
          : `${FILTERS.FRANCHISE}={'${data?.Franchise?.join("','")}'}`;

      const formattedOutput = `${FILTERS.BU_FILTER}= {"${data?.Business_Unit}"} , ${FILTERS.VERSION_TYPE} ={"${data?.VersionType}"} , ${franchiseCondition} `;
      return formattedOutput;
    } else if (view === 'brand') {
      const franchiseCondition =
        data?.Franchise?.length === 0
          ? PRIMARY_CONDITION_FILTER.FRANCHISE
          : `${FILTERS.FRANCHISE}={'${data?.Franchise?.join("','")}'}`;

      const brandCondition =
        data?.Brand?.length === 0
          ? PRIMARY_CONDITION_FILTER.BRAND
          : `${FILTERS.BRAND}={"${data?.Brand}"}`;

      const formattedOutput = `${FILTERS.BU_FILTER}= {"${data?.Business_Unit}"} , ${FILTERS.VERSION_TYPE} ={"${data?.VersionType}"} , ${franchiseCondition}  , ${brandCondition}`;

      return formattedOutput;
    } else if (view === 's&op') {
      const franchiseCondition =
        data?.Franchise?.length === 0
          ? PRIMARY_CONDITION_FILTER.FRANCHISE
          : `${FILTERS.FRANCHISE}={'${data?.Franchise?.join("','")}'}`;
      const SKUCondition =
        data?.SKU?.length === 0
          ? PRIMARY_CONDITION_FILTER.SKU
          : `${FILTERS.SKU}={'${data?.SKU?.join("','")}'}`;

      const BrandCondition =
        data?.Brand?.length === 0
          ? PRIMARY_CONDITION_FILTER.BRAND
          : `${FILTERS.BRAND}={'${data?.Brand?.join("','")}'}`;
      const formattedOutput = `${FILTERS.BU_FILTER}= {'${data?.Business_Unit}'} , ${FILTERS.VERSION_TYPE} ={'${data?.VersionType}'} , ${franchiseCondition} , ${BrandCondition} ,${SKUCondition} `;

      return formattedOutput;
    } else {
      const franchiseCondition =
        data?.Franchise?.length === 0
          ? PRIMARY_CONDITION_FILTER.FRANCHISE
          : `${FILTERS.FRANCHISE}={'${data?.Franchise.join("','")}'}`;
      const BrandCondition =
        data?.Brand?.length === 0
          ? `${FILTERS.BP_BRAND}=`
          : `${FILTERS.BP_BRAND}={'${data?.Brand?.join("','")}'}`;
      const formattedOutput = ` ${FILTERS.BP_BUSINESS_UNIT}= {'${data?.Business_Unit}'} , ${franchiseCondition}, ${BrandCondition}`;
      return formattedOutput;
    }
  };

  const formatOutputVarience = (data, view) => {
    if (view === 'portfolio') {
      // const metric = factFlagType(forecastMetric || currentMetric);
      const franchiseCondition =
        data?.Franchise?.length === 0
          ? PRIMARY_CONDITION_FILTER.FRANCHISE
          : `${FILTERS.FRANCHISE}={'${data?.Franchise?.join("','")}'}`;
      const formattedOutput = `${FILTERS.BU_FILTER}= {"${data?.Business_Unit}"} , ${FILTERS.VARIANCE_VERSION_TYPE}  ={"${data?.VersionType}"} , ${franchiseCondition}`;

      return formattedOutput;
    } else if (view === 'brand') {
      const franchiseCondition =
        data?.Franchise?.length === 0
          ? PRIMARY_CONDITION_FILTER.FRANCHISE
          : `${FILTERS.FRANCHISE}={'${data?.Franchise?.join("','")}'}`;
      const brandCondition =
        data?.Brand?.length === 0
          ? PRIMARY_CONDITION_FILTER.BRAND
          : `${FILTERS.BRAND}={"${data?.Brand}"}`;
      const formattedOutput = `${FILTERS.BU_FILTER}= {"${data?.Business_Unit}"} , ${FILTERS.VARIANCE_VERSION_TYPE}  ={"${data?.VersionType}"} , ${franchiseCondition} , ${brandCondition}`;

      return formattedOutput;
    } else if (view === 's&op') {
      // const metric = factFlagType(forecastMetric || currentMetric);
      const franchiseCondition =
        data?.Franchise?.length === 0
          ? PRIMARY_CONDITION_FILTER.FRANCHISE
          : `${FILTERS.FRANCHISE}={'${data?.Franchise?.join("','")}'}`;

      const SKUCondition =
        data?.SKU?.length === 0
          ? PRIMARY_CONDITION_FILTER.SKU
          : `${FILTERS.SKU}={'${data?.SKU.join("','")}'}`;
      const BrandCondition =
        data?.Brand?.length === 0
          ? PRIMARY_CONDITION_FILTER.BRAND
          : `${FILTERS.BRAND}={'${data?.Brand.join("','")}'}`;
      const formattedOutput = `${FILTERS.BU_FILTER}= {"${
        data?.Business_Unit
      }"} ,${FILTERS.VERSION_TYPE}  ={"${
        data?.VersionType
      }"} , ${franchiseCondition} , ${BrandCondition} ,${SKUCondition} , ${
        FILTERS.MEASURE_SOP
      }={'${currentNetSales ? currentNetSales : metricOptionsList[0]?.value}'}`;

      return formattedOutput;
    }
  };

  useEffect(() => {
    const data = formatOutput(selectedFilter, selectedKpiName?.toLowerCase());
    const varianceData = formatOutputVarience(
      selectedFilter,
      selectedKpiName?.toLowerCase(),
    );
    setExpression(data);
    setExpressionVariance(varianceData);
    //handleSelection(selectedKpiName, selectedDimension);
    getPriorityComment();
  }, [selectedFilter, selectedDimension]);

  useEffect(() => {
    const data = formatOutput(selectedFilter, selectedKpiName?.toLowerCase());
    const varianceData = formatOutputVarience(
      selectedFilter,
      selectedKpiName?.toLowerCase(),
    );
    setExpression(data);
    setExpressionVariance(varianceData);
    // fetchSelectedAPI()
  }, [currentNetSales]);

  const onFilterSelection = (filters, soptions) => {
    setSelectedFilter(filters);
    setOptions(soptions);
    setIsUpdateDisabled(false);
    setIsAddDisabled(false);
    setIsApplyDisabled(true);
    getPriorityComment();
  };

  const areFiltersEqualTest = (filter1, filter2) => {
    const normalizeFilter = (filter) => {
      if (typeof filter === 'string') {
        try {
          filter = JSON.parse(filter);
        } catch (e) {
          return false; // Invalid JSON string
        }
      }
      return {
        Brand: filter?.Brand || [],
        Business_Unit: filter?.Business_Unit || '',
        Franchise: filter?.Franchise || [],
        VersionType: filter?.VersionType || '',
        SKU: filter?.SKU || [],
      };
    };

    const f1 = normalizeFilter(filter1);
    const f2 = normalizeFilter(filter2);

    if (!f1 || !f2) return false;

    return (
      f1.Business_Unit === f2.Business_Unit &&
      f1.VersionType === f2.VersionType &&
      JSON.stringify(f1.Brand.sort()) === JSON.stringify(f2.Brand.sort()) &&
      JSON.stringify(f1.Franchise.sort()) ===
        JSON.stringify(f2.Franchise.sort()) &&
      JSON.stringify(f1.SKU.sort()) === JSON.stringify(f2.SKU.sort())
    );
  };

  const handleCheckFilterSame = (fitlerselection) => {
    const areFiltersEqual = deepEqualIgnoreArrayOrder(
      fitlerselection,
      selectedFilter,
    );
    const areFiltersEqualForUpdate = deepEqualIgnoreArrayOrder(
      fitlerselection,
      selectedFilter,
    );
    setIsApplyDisabled(!areFiltersEqual ? false : true);
    setIsAddDisabled(!areFiltersEqual ? true : false);
    if (
      selectedDimension?.DimensionId === editData?.DimensionId &&
      areFiltersEqualForUpdate
    ) {
      setIsUpdateDisabled(true);
    }
    if (
      selectedDimension?.DimensionId === editData?.DimensionId &&
      !areFiltersEqualTest(selectedFilter, editData?.Filter)
    ) {
      setIsUpdateDisabled(false);
    }
  };

  useEffect(() => {
    if (selectedDimension?.DimensionId !== editData?.DimensionId) {
      setIsUpdateDisabled(false);
    }
  }, [selectedDimension, selectedKpi]);

  const getChartText = (chartId) => {
    let chartText;
    if (chartId === CARD_CONFIG?.PORTFOLIO_FY_PROJECTION_CHART?.ChartLabel) {
      chartText = {
        ytdAvg: summaryData?.ytdAvgLabel || '-',
        twelveWeek: summaryData?.ytd12weekLabel || '-',
        trend: summaryData?.ytdTrendLabel || '-',
        to: summaryData?.ytdTOLabel || '-',
      };
    } else if (chartId === CARD_CONFIG?.BRAND_YTD_ROY_CHART?.ChartLabel) {
      chartText = { ytd: summaryData?.ytdRoyDemandText || '-' };
    } else if (chartId === CARD_CONFIG?.SOP_YEAR_FORECAST_CHART?.ChartLabel) {
      chartText = {
        kpis: summaryData?.yearForecastLabel?.values,
        forecastTrendMonth: summaryData?.forecastTrendMonth,
      };
    } else if (chartId === CARD_CONFIG.SOP_AGGFORECAST_CHART.ChartLabel) {
      chartText = summaryData?.aggForecastLabel;
    }
    return chartText;
  };

  useEffect(() => {
    // Execute dependent logic after state has been updated
    if (selectedKpiName && selectedDimension) {
      handleSelection(selectedKpiName, selectedDimension);
    }
    getPriorityComment();
  }, [expression, expressionVariance, dataFilterOptions, checkboxValuesEvent]);

  const fetchData = async (_kpiName, dimension) => {
    setIsLoading(true);
    setSummaryData({
      ytdAvgLabel: '',
      ytd12weekLabel: '',
      ytdTrendLabel: '',
      ytdTOLabel: '',
      ytdRoyDemandText: '',
      yearForecastLabel: [],
      aggForecastLabel: '',
      ytdRoyDemandLabel: '',
      forecastTrendMonth: '',
    });

    if (!dimension) return;
    try {
      // Use CardId and ChartId from the selected dimension
      const { CardId, ChartId, ChartLabel, ChartDataType } = dimension;

      if (
        ChartLabel === CARD_CONFIG?.PORTFOLIO_YTD_VARIANCE_CHART?.ChartLabel ||
        ChartLabel ===
          CARD_CONFIG?.PORTFOLIO_YTD_PY_FORECAST_ACTUAL_CHART?.ChartLabel
      ) {
        const getPortfolioActualTO = getSectionsExpression(
          brandExpressions,
          CardId,
          card_config_db?.PORTFOLIO_YTD_FORECAST_ACTUAL_CHART?.ChartId,
        );
        const getPortfolioYtd = getSectionsExpressionVariance(
          brandExpressions,
          CardId,
          card_config_db?.PORTFOLIO_YTD_VARIANCE_CHART?.ChartId,
        );
        await getQlikData(
          getPortfolioActualTO.expression,
          dimension,
          getPortfolioActualTO.dimension,
          ChartDataType,
        );
        await getQlikDataVariance(
          getPortfolioYtd.expression,
          dimension,
          getPortfolioYtd.dimension,
          ChartDataType,
        );
      } else if (
        ChartLabel === CARD_CONFIG?.PORTFOLIO_FY_PROJECTION_CHART?.ChartLabel
      ) {
        const getYtdAvgLabel = GETSECTIONSEXPRESSION(
          brandExpressions,
          selectedFilter?.Franchise?.length === 1
            ? card_config_db?.PORTFOLIO_FY_PROJECTION_CHART_YTDAVG_LABEL_SQUAD
                ?.CardId
            : card_config_db?.PORTFOLIO_FY_PROJECTION_CHART_YTDAVG_LABEL
                ?.CardId,
          selectedFilter?.Franchise?.length === 1
            ? card_config_db?.PORTFOLIO_FY_PROJECTION_CHART_YTDAVG_LABEL_SQUAD
                ?.ChartId
            : card_config_db?.PORTFOLIO_FY_PROJECTION_CHART_YTDAVG_LABEL
                ?.ChartId,
          expression,
        );
        const get12weekLabel = GETSECTIONSEXPRESSION(
          brandExpressions,
          selectedFilter?.Franchise?.length === 1
            ? card_config_db?.PORTFOLIO_FY_PROJECTION_CHART_12WEEK_LABEL_SQUAD
                ?.CardId
            : card_config_db?.PORTFOLIO_FY_PROJECTION_CHART_12WEEK_LABEL
                ?.CardId,
          selectedFilter?.Franchise?.length === 1
            ? card_config_db?.PORTFOLIO_FY_PROJECTION_CHART_12WEEK_LABEL_SQUAD
                ?.ChartId
            : card_config_db?.PORTFOLIO_FY_PROJECTION_CHART_12WEEK_LABEL
                ?.ChartId,
          expression,
        );
        const getTrendLabel = GETSECTIONSEXPRESSION(
          brandExpressions,
          selectedFilter?.Franchise?.length === 1
            ? card_config_db?.PORTFOLIO_FY_PROJECTION_CHART_TREND_LABEL_SQUAD
                ?.CardId
            : card_config_db?.PORTFOLIO_FY_PROJECTION_CHART_TREND_LABEL?.CardId,
          selectedFilter?.Franchise?.length === 1
            ? card_config_db?.PORTFOLIO_FY_PROJECTION_CHART_TREND_LABEL_SQUAD
                ?.ChartId
            : card_config_db?.PORTFOLIO_FY_PROJECTION_CHART_TREND_LABEL
                ?.ChartId,
          expression,
        );
        const getTOLabel = GETSECTIONSEXPRESSION(
          brandExpressions,
          selectedFilter?.Franchise?.length === 1
            ? card_config_db?.PORTFOLIO_FY_PROJECTION_CHART_TO_LABEL_SQUAD
                ?.CardId
            : card_config_db?.PORTFOLIO_FY_PROJECTION_CHART_TO_LABEL?.CardId,
          selectedFilter?.Franchise?.length === 1
            ? card_config_db?.PORTFOLIO_FY_PROJECTION_CHART_TO_LABEL_SQUAD
                ?.ChartId
            : card_config_db?.PORTFOLIO_FY_PROJECTION_CHART_TO_LABEL?.ChartId,
          expression,
        );
        const sectionExpression = GETSECTIONSEXPRESSION(
          brandExpressions,
          selectedFilter?.Franchise?.length === 1
            ? card_config_db?.PORTFOLIO_FY_PROJECTION_CHART_SQUAD?.CardId
            : card_config_db?.PORTFOLIO_FY_PROJECTION_CHART?.CardId,
          selectedFilter?.Franchise?.length === 1
            ? card_config_db?.PORTFOLIO_FY_PROJECTION_CHART_SQUAD?.ChartId
            : card_config_db?.PORTFOLIO_FY_PROJECTION_CHART?.ChartId,
          expression,
        );
        await getQlikDataChartText(
          getYtdAvgLabel.expression,
          'ytdAvgLabel',
          [],
          SINGLE,
        ),
          await getQlikDataChartText(
            get12weekLabel.expression,
            'ytd12weekLabel',
            [],
            SINGLE,
          ),
          await getQlikDataChartText(
            getTrendLabel.expression,
            'ytdTrendLabel',
            [],
            SINGLE,
          ),
          await getQlikDataChartText(
            getTOLabel.expression,
            'ytdTOLabel',
            [],
            SINGLE,
          );

        await getQlikData(
          sectionExpression.expression,
          dimension,
          sectionExpression.dimension,
          ChartDataType,
        );
      } else if (ChartLabel === CARD_CONFIG?.BRAND_YTD_ROY_CHART?.ChartLabel) {
        const sectionExpression = getSectionsExpression(
          brandExpressions,
          CardId,
          ChartId,
        );
        await getQlikData(
          sectionExpression.expression,
          dimension,
          sectionExpression.dimension,
          ChartDataType,
        );

        const chartdata3DemandText = getSectionsExpression(
          brandExpressions,
          card_config_db?.BRAND_CHARTLABEL_YTD?.CardId,
          card_config_db?.BRAND_CHARTLABEL_YTD?.ChartId,
          BRAND_SECTION_EXPRESSION_NAME.CHARTDATA3DEMANDTEXT,
        );
        const chartdata3ytdLabel = getSectionsExpression(
          brandExpressions,
          card_config_db?.BRAND_YTD_ROY_CHART_LABEL?.CardId,
          card_config_db?.BRAND_YTD_ROY_CHART_LABEL?.ChartId,
          BRAND_SECTION_EXPRESSION_NAME.CHARTDATA3DEMANDTEXT,
        );
        await getQlikDataChartText(
          chartdata3ytdLabel.expression,
          'ytdRoyDemandLabel',
          [],
          SINGLE,
          true,
        );
        await getQlikDataChartText(
          chartdata3DemandText.expression,
          'ytdRoyDemandText',
          chartdata3DemandText.dimension,
          SINGLE,
          true,
        );
      } else if (
        ChartLabel === CARD_CONFIG?.BRAND_YTD_VARIANCE_CHART?.ChartLabel ||
        ChartLabel === CARD_CONFIG?.BRAND_YTD_YOY_VARIANCE_CHART?.ChartLabel
      ) {
        const sectionExpression = getSectionsExpressionVariance(
          brandExpressions,
          CardId,
          ChartId,
        );
        await getQlikData(
          sectionExpression.expression,
          dimension,
          sectionExpression.dimension,
          ChartDataType,
        );
      } else if (
        ChartLabel === CARD_CONFIG?.SOP_YEAR_FORECAST_CHART?.ChartLabel
      ) {
        const sectionExpression = getSectionsExpressionVariance(
          brandExpressions,
          CardId,
          ChartId,
        );
        await getQlikData(
          sectionExpression.expression,
          dimension,
          sectionExpression.dimension,
          ChartDataType,
        );
        const yearForecastLabelText = getSectionsExpressionVariance(
          brandExpressions,
          CardId,
          card_config_db?.SOP_YEAR_FORECAST_CHART_LABEL?.ChartId,
          BRAND_SECTION_EXPRESSION_NAME.CHARTDATA3DEMANDTEXT,
        );
        const getSopForecastMonth = getSectionsExpressionVariance(
          brandExpressions,
          CardId,
          card_config_db?.SOP_YEAR_FORECAST_CHART_MONTHLABEL?.ChartId,
        );
        await getQlikDataChartText(
          getSopForecastMonth.expression,
          'forecastTrendMonth',
          getSopForecastMonth.dimension,
          SINGLE,
          true,
        );
        await getQlikDataChartText(
          yearForecastLabelText.expression,
          'yearForecastLabel',
          yearForecastLabelText.dimension,
          SINGLE,
          true,
        );
      } else if (
        ChartLabel === CARD_CONFIG?.SOP_AGGFORECAST_CHART?.ChartLabel
      ) {
        const getSopAggreagte = getSectionsExpression(
          brandExpressions,
          CardId,
          ChartId,
        );
        await getQlikData(
          getSopAggreagte.expression,
          dimension,
          getSopAggreagte.dimension,
          ChartDataType,
        );
        const getSopAccuracy = getSectionsExpression(
          brandExpressions,
          card_config_db?.SOP_AGG_ACCURACY_FORECAST_CHART?.CardId,
          card_config_db?.SOP_AGG_ACCURACY_FORECAST_CHART?.ChartId,
        );
        await getQlikDataVariance(
          getSopAccuracy.expression,
          dimension,
          getSopAccuracy.dimension,
          ChartDataType,
        );
        const getAggForecastabel = getSectionsExpression(
          brandExpressions,
          card_config_db?.SOP_AGGFORECAST_CHART_LABEL?.CardId,
          card_config_db?.SOP_AGGFORECAST_CHART_LABEL?.ChartId,
        );
        await getQlikDataChartText(
          getAggForecastabel.expression,
          'aggForecastLabel',
          getAggForecastabel.dimension,
          SINGLE,
          true,
        );
      } else if (
        ChartLabel === CARD_CONFIG?.SOP_SHORTTERM_BIAS_4WKS_CHART?.ChartLabel
      ) {
        const getSopBiasForecast4Weeks = getSectionsExpression(
          brandExpressions,
          CardId,
          ChartId,
        );
        await getQlikData(
          getSopBiasForecast4Weeks.expression,
          dimension,
          getSopBiasForecast4Weeks.dimension,
          ChartDataType,
        );
        const getSopBiasForecast26Weeks = getSectionsExpression(
          brandExpressions,
          card_config_db?.SOP_SHORTTERM_BIAS_26WKS_CHART?.CardId,
          card_config_db?.SOP_SHORTTERM_BIAS_26WKS_CHART?.ChartId,
        );
        await getQlikDataVariance(
          // used another qlik function to distinguish between
          getSopBiasForecast26Weeks.expression,
          dimension,
          getSopBiasForecast26Weeks.dimension,
          ChartDataType,
        );
      } else if (
        ChartLabel === CARD_CONFIG?.BRAND_YOY_SALES_CHART?.ChartLabel
      ) {
        const getYoySalesChart = getSectionsExpression(
          brandExpressions,
          CardId,
          ChartId,
          expression,
        );
        await getQlikData(
          getYoySalesChart.expression,
          dimension,
          getYoySalesChart.dimension,
          ChartDataType,
        );
        const getYoySalesGrid = getSectionsExpression(
          brandExpressions,
          card_config_db?.BRAND_YOY_SALES_GRID?.CardId,
          card_config_db?.BRAND_YOY_SALES_GRID?.ChartId,
          expression,
        );
        await getQlikDataGrid(
          getYoySalesGrid.expression,
          dimension,
          getYoySalesGrid.dimension,
          ChartDataType,
          true,
          ChartLabel,
        );
      } else if (
        ChartLabel === CARD_CONFIG?.BRAND_YOY_OUTS_GROWTH_CHART?.ChartLabel
      ) {
        const chartdatayoyOutsLabel = GETLABELEXPRESSION(
          brandExpressions,
          card_config_db?.BRAND_YOY_OUTS_GROWTH_CHART?.CardId,
          card_config_db?.BRAND_YOY_OUTS_GROWTH_CHART?.ChartId,
          expression,
        );

        const labelResponse = await getQlikDataLabel(
          chartdatayoyOutsLabel.expression,
          STATE_BRAND_SUMMARY.YOYOUTSCHARTLABEL,
          chartdatayoyOutsLabel.dimension,
        );

        const getYoyGrowthChart = GETSECTIONSEXPRESSIONGRID(
          brandExpressions,
          CardId,
          ChartId,
          expression,
          labelResponse?.values,
        );
        await getQlikData(
          getYoyGrowthChart.expression,
          dimension,
          getYoyGrowthChart.dimension,
          ChartDataType,
        );
        const getYoyGrowthGrid = getSectionsExpression(
          brandExpressions,
          card_config_db?.BRAND_YOY_OUTS_GROWTH_GRID?.CardId,
          card_config_db?.BRAND_YOY_OUTS_GROWTH_GRID?.ChartId,
          expression,
        );
        await getQlikDataGrid(
          getYoyGrowthGrid.expression,
          dimension,
          getYoyGrowthGrid.dimension,
          ChartDataType,
          true,
          ChartLabel,
        );
      } else if (ChartLabel === CARD_CONFIG?.BP_NETSALES_CHART?.ChartLabel) {
        setBpData([]);
        if (
          dataFilterOptions &&
          dataFilterOptions.length > 0 &&
          extractBPBusinessUnit(expression)?.length > 0
        ) {
          await Promise.all(
            dataFilterOptions?.map(async (item) => {
              const sectionExpression = getSectionsExpressionBP(
                brandExpressions,
                CardId,
                ChartId,
                item,
              );
              await getQlikData(
                sectionExpression.expression,
                dimension,
                sectionExpression.dimension,
                ChartDataType,
                true,
                item,
              );
            }),
          );
        }
      } else {
        const sectionExpression = getSectionsExpression(
          brandExpressions,
          CardId,
          ChartId,
        );
        await getQlikData(
          sectionExpression.expression,
          dimension,
          sectionExpression.dimension,
          ChartDataType,
        );
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getQlikDataChartText = async (
    data,
    cardId,
    dim = [],
    type = undefined,
    isSortDate = false,
  ) => {
    const payload = {
      connection_details: [
        {
          dimension: dim,
          appId:
            selectedKpiName === 'S&OP'
              ? process.env.APP_ID_SOP
              : process.env.APP_ID,
          expression: data,
        },
      ],
      dataType: type,
      isSortDate: isSortDate,
    };

    const postResponse = await getQlikSenseUnformattedData(payload);

    if (postResponse) {
      const dataFromResponse = postResponse[0];

      if (dataFromResponse) {
        setSummaryData((prev) => {
          const newState = {
            ...prev,
            [cardId]: dataFromResponse, // Store the entire dataFromResponse object
          };
          return newState;
        });
      }
    }

    setError(null);
  };

  const getSectionsExpression = (data, CardId, ChartId) => {
    const expressionData = data
      .filter((e) => e.CardId === CardId && e.ChartId === ChartId)
      .map((obj) =>
        JSON.stringify({
          CardName: obj.CardName,
          id: obj.KpiId,
          name: obj.KpiName,
          expression: obj.Expression.replaceAll(VREPLACESTRING, expression),
        }),
      );

    let dimension = [];
    const dimensionNames = new Set();

    data
      .filter((e) => e.CardId === CardId && e.ChartId === ChartId)
      .forEach((obj) => {
        if (obj.DimensionName && !dimensionNames.has(obj.DimensionName)) {
          dimensionNames.add(obj.DimensionName);
          dimension.push(
            JSON.stringify({
              dimensionType: CATEGORYUPPERCASE,
              dimensionName: obj.DimensionName,
            }),
          );
        }
      });
    return {
      expression: expressionData,
      dimension: dimension,
    };
  };

  const getSectionsExpressionVariance = (data, CardId, ChartId) => {
    const expressionData = data
      .filter((e) => e.CardId === CardId && e.ChartId === ChartId)
      .sort((a, b) => a?.KpiId - b?.KpiId)
      .map((obj) =>
        JSON.stringify({
          CardName: obj.CardName,
          id: obj.KpiId,
          name: obj.KpiName,
          expression: obj.Expression.replaceAll(
            VREPLACESTRING,
            expressionVariance,
          ),
        }),
      );

    let dimension = [];
    const dimensionNames = new Set();

    data
      .filter((e) => e.CardId === CardId && e.ChartId === ChartId)
      .forEach((obj) => {
        if (obj.DimensionName && !dimensionNames.has(obj.DimensionName)) {
          dimensionNames.add(obj.DimensionName);
          dimension.push(
            JSON.stringify({
              dimensionType: CATEGORYUPPERCASE,
              dimensionName: obj.DimensionName,
            }),
          );
        }
      });
    return {
      expression: expressionData,
      dimension: dimension,
    };
  };

  const getSectionsExpressionBP = (data, CardId, ChartId, item) => {
    const EventCondiiton =
      checkboxValuesEvent?.length === eventFilterOptions?.length
        ? `${FILTERS.EVENT_TYPE}=`
        : `${FILTERS.EVENT_TYPE}={'${checkboxValuesEvent?.join("','")}'}`;
    const expressionData = data
      .filter((e) => e?.CardId === CardId && e?.ChartId === ChartId)
      .map((obj) =>
        JSON.stringify({
          CardName: obj.CardName,
          id: obj.KpiId,
          name: obj.KpiName,
          expression: obj.Expression.replaceAll(
            VREPLACESTRING,
            expression.concat(
              `, ${FILTERS.VERSION_NAME} ={'${item}'}, ${EventCondiiton}`,
            ),
          ),
        }),
      );

    setBpExpression(
      expression.concat(
        `,  ${FILTERS.VERSION_NAME} ={'${item}'}, ${EventCondiiton}`,
      ),
    );

    let dimension = [];
    const dimensionNames = new Set();

    data
      .filter((e) => e.CardId === CardId && e.ChartId === ChartId)
      .forEach((obj) => {
        if (obj.DimensionName && !dimensionNames.has(obj.DimensionName)) {
          dimensionNames.add(obj.DimensionName);
          dimension.push(
            JSON.stringify({
              dimensionType: CATEGORYUPPERCASE,
              dimensionName: obj.DimensionName,
            }),
          );
        }
      });

    return {
      expression: expressionData,
      dimension: dimension,
    };
  };

  const ytdVarianceActualChartData = (inputData) => {
    return inputData?.values
      ?.filter((item) => {
        return item?.name !== GROWTH;
      })
      ?.map((item) => {
        return {
          name: item?.name === T0 ? selectedFilter?.VersionType : item?.name,
          value: Number(item?.data),
        };
      })
      ?.reverse();
  };

  const ytdPyActualChartData = (inputData) => {
    return inputData?.values
      ?.filter((item) => {
        return item?.name !== GROWTH;
      })
      ?.map((item) => {
        return {
          name: item?.name,
          value: Number(item?.data),
        };
      })
      ?.reverse();
  };

  const MYVIEW_MAPPING = {
    'ytd-sales-v-forec': {
      chartData: (inputData) => ytdVarianceActualChartData(inputData),
      gridColumns: () => ytdSalesVsForecastChartGridCols,
      gridData: (inputData) =>
        TRANSFORM_YTDFORECASTGRIDDATA(
          inputData?.values,
          selectedFilter?.VersionType,
        ),
      category: () => {
        return [];
      },
      varianceChartData: (inputData) => TRANSFORM_VARIANCE_CHARTDATA(inputData),
      varianceGridData: (inputData) => inputData?.values,
    },
    'ytd-sales-v-py': {
      chartData: (inputData) => ytdPyActualChartData(inputData),
      gridColumns: () => ytdSalesVsForecastChartGridCols,
      gridData: (inputData) =>
        TRANSFORM_YTDFORECASTPY_GRIDDATA(inputData?.values),
      category: () => {
        return [];
      },
      varianceChartData: (inputData) => TRANSFORM_VARIANCE_CHARTDATA(inputData),
      varianceGridData: (inputData) => inputData?.values,
    },
    'fy-projection': {
      chartData: (inputData) =>
        TRANSFORM_FYPROJECTION_CHARTDATA(
          inputData,
          selectedFilter?.VersionType,
        ),
      gridColumns: () => fyProjectionGridCols(selectedFilter?.VersionType),
      gridData: (inputData) => TRANSFORM_FYPROJECTION_GRIDDATA(inputData),
      category: (inputData) => {
        return inputData;
      },
    },
    'ytd-variance': {
      chartData: (inputData) => TRANSFORM_VARIANCE_CHARTDATA(inputData),
      gridColumns: () => ytdChartGridCols,
      gridData: (inputData) => inputData?.values,
      category: () => {
        return [];
      },
    },
    'ytd-py': {
      chartData: (inputData) => TRANSFORM_VARIANCE_CHARTDATA(inputData),
      gridColumns: () => ytdChartGridCols,
      gridData: (inputData) => inputData?.values,
      category: () => {
        return [];
      },
    },
    'ytd-roy': {
      chartData: (inputData) =>
        TRANSFORM_YTD_CHARTDATA(inputData, selectedFilter?.VersionType),
      gridColumns: () => yoyGridCols(selectedFilter?.VersionType),
      gridData: (inputData) => TRANSFORM_YTD_GRIDDATA(inputData),
      category: (inputData) => {
        return inputData;
      },
    },
    'sales-demand': {
      chartData: (inputData) => TRANSFORM_SD_CHARTDATA(inputData),
      gridColumns: () => demandChartGridCols,
      gridData: (inputData) => TRANSFORM_SD_GRIDDATA(inputData),
      category: (inputData) => {
        return inputData;
      },
    },
    'yoy-sales': {
      chartData: (inputData) => TRANSFORM_YOYSALES_CHARTDATA(inputData),
      gridColumns: (inputData) =>
        inputData &&
        Object?.keys(inputData)?.length > 0 &&
        inputData?.category[0]?.length > 0 &&
        CHART_GRID_COLUMNS(inputData?.category[0]),
      gridData: (inputData) => TRANSFORM_YOYSALES_GRIDDATA(inputData),
      category: (inputData) => SORTYEARS(inputData?.category),
    },
    'yoy-growth': {
      chartData: (inputData) => TRANSFORM_YOYOUTS_CHARTDATA(inputData),
      gridColumns: (inputData) =>
        inputData && inputData?.length > 0 && CHART_GRID_COLUMNS(inputData),
      gridData: (inputData) => TRANSFORM_YOYOUTS_GRIDATA(inputData),
      category: (inputData) => inputData?.category[0],
    },
    'year-forecast': {
      chartData: (inputData) =>
        TRANSFORM_FORECASTTREND_CHARTDATA(
          inputData,
          selectedFilter?.VersionType,
        ),
      gridColumns: () => YearForecastGridCols(selectedFilter?.VersionType),
      gridData: (inputData) => TRANSFORM_FORECASTTREND_GRIDDATA(inputData),
      category: (inputData) => inputData,
    },
    'agg-forecast': {
      chartData: (inputData) => TRANSFORM_AGGFORECAST_CHARTDATA(inputData),
      gridColumns: () => AggForecastGridCols,
      gridData: (inputData) => TRANSFORM_AGGFORECAST_GRIDDATA(inputData),
      category: (inputData) => inputData,
      varianceChartData: (inputData) =>
        TRANSFORM_AGGFORECAST_CHARTDATA(inputData),
      varianceGridData: (inputData) =>
        TRANSFORM_AGGFORECAST_GRIDDATA(inputData),
    },
    'short-termforecast': {
      chartData: (inputData) => TRANSFORM_SHORTTERMBIAS_CHARTDATA(inputData),
      gridColumns: () => ShortWeekChartGridCols,
      gridData: (inputData) => TRANSFORM_SHORTBIAS_GRIDDATA(inputData),
      category: (inputData) => inputData?.category,
      varianceChartData: (inputData) =>
        TRANSFORM_SHORTTERMBIAS_CHARTDATA(inputData),
      varianceGridData: (inputData) => TRANSFORM_SHORTBIAS_GRIDDATA(inputData),
    },
    'net-sales': {
      chartData: (inputData) => TRANSFORM_BPLAN_CHARTDATA(inputData),
      gridColumns: () => ShortWeekChartGridCols,
      gridData: () => [],
      category: (inputData) => inputData?.category,
    },
  };

  const getChartInfo = (kpi, inputData) => {
    setChartData(MYVIEW_MAPPING[kpi]?.chartData(inputData));
    if (kpi !== CARD_CONFIG?.BRAND_YOY_OUTS_GROWTH_CHART?.ChartLabel) {
      setGridColumns(MYVIEW_MAPPING[kpi]?.gridColumns(inputData));
    }

    if (kpi === CARD_CONFIG.BRAND_YOY_SALES_CHART.ChartLabel) {
      setNetSalesTable(MYVIEW_MAPPING[kpi]?.gridData(inputData));
    } else {
      setFormattedGridData(MYVIEW_MAPPING[kpi]?.gridData(inputData));
    }

    setCategory(MYVIEW_MAPPING[kpi]?.category(inputData));
    setBiasInputData(
      kpi === CARD_CONFIG?.SOP_SHORTTERM_BIAS_4WKS_CHART?.ChartLabel
        ? inputData
        : [],
    );
  };

  const getVarianceChart = (kpi, inputData) => {
    setVarianceChartData(MYVIEW_MAPPING[kpi]?.varianceChartData(inputData));
    setVarianceGridData(MYVIEW_MAPPING[kpi]?.varianceGridData(inputData));
    setBiasWeeksInputData(
      kpi === CARD_CONFIG?.SOP_SHORTTERM_BIAS_4WKS_CHART?.ChartLabel
        ? inputData
        : [],
    );
  };

  const getQlikDataGrid = async (
    data,
    cardId,
    dim = [],
    // eslint-disable-next-line no-unused-vars
    _type = undefined,
    isSortDate = false,
    ChartLabel,
  ) => {
    const payload = {
      connection_details: [
        {
          dimension: dim,
          appId:
            selectedKpiName === 'S&OP'
              ? process.env.APP_ID_SOP
              : process.env.APP_ID,
          expression: data,
        },
      ],
      dataType: 'kpi',
      isSortDate: isSortDate,
    };
    const postResponse = await getQlikSenseUnformattedData(payload);
    if (postResponse) {
      const dataFromResponse = postResponse[0];
      if (dataFromResponse) {
        if (ChartLabel === CARD_CONFIG?.BRAND_YOY_SALES_CHART?.ChartLabel) {
          const additionalData = TRANSFORM_YOYSALES_GRIDDATA(dataFromResponse);

          setFormattedGridData(() => [...additionalData]);
        } else {
          setGridColumns(
            MYVIEW_MAPPING[cardId?.ChartLabel]?.gridColumns(
              dataFromResponse?.category[0],
            ),
          );
          setFormattedGridData(
            MYVIEW_MAPPING[cardId?.ChartLabel]?.gridData(dataFromResponse),
          );
        }
      }
    }
    setError(null);
  };

  const getQlikData = async (
    data,
    cardId,
    dim = [],
    type = undefined,
    isSortDate = false,
    item, // for bplan
  ) => {
    setChartData([]);
    const payload = {
      connection_details: [
        {
          dimension: dim,
          appId:
            selectedKpiName === 'S&OP'
              ? process.env.APP_ID_SOP
              : process.env.APP_ID,
          expression: data,
        },
      ],
      dataType: type,
      isSortDate: isSortDate,
    };
    const postResponse = await getQlikSenseUnformattedData(payload);
    if (postResponse) {
      const dataFromResponse = postResponse[0];
      if (dataFromResponse) {
        if (cardId?.ChartLabel === CARD_CONFIG?.BP_NETSALES_CHART?.ChartLabel) {
          setBpData((prev) => [
            ...prev,
            {
              name: item,
              value: TRANSFORM_BPLAN_CHARTDATA(dataFromResponse, item),
            },
          ]);
          const years = dataFromResponse?.category[0];
          const uniqueYears = [...new Set(years)];
          setCategory(uniqueYears);
          setScatterEvents(TRANSFORM_BPCHART_SCATTER(dataFromResponse));
        } else {
          getChartInfo(cardId?.ChartLabel, dataFromResponse);
        }
      }
    }
    setError(null);
  };

  const getQlikDataLabel = async (
    data, // expression
    cardName, // card name
    dim = [], // dimension
    type = undefined, // chart data type = line, bar
    isSortDate = false,
    currentCard,
  ) => {
    const payload = {
      connection_details: [
        {
          dimension: dim,
          appId:
            currentCard?.ViewName?.split(' ')[0] === 'S&OP'
              ? process.env.APP_ID_SOP
              : process.env.APP_ID,
          expression: data,
        },
      ],
      dataType: type,
      isSortDate: isSortDate,
    };

    try {
      const postResponse = await getQlikSenseUnformattedData(payload); // Wait for API response

      if (postResponse && postResponse.length > 0) {
        const dataFromResponse = postResponse[0]; // Extract the first result

        return dataFromResponse; // Return the actual data synchronously
      }
      return null; // Return null if no valid response
    } catch (error) {
      // eslint-disable-next-line no-undef
      console.error('Error fetching Qlik data:', error);
      return null; // Return null in case of an error
    }
  };

  const getQlikDataVariance = async (
    data,
    cardId,
    dim = [],
    type = undefined,
    isSortDate = false,
  ) => {
    getVarianceChart([]);
    const payload = {
      connection_details: [
        {
          dimension: dim,
          appId:
            selectedKpiName === 'S&OP'
              ? process.env.APP_ID_SOP
              : process.env.APP_ID,
          expression: data,
        },
      ],
      dataType: type,
      isSortDate: isSortDate,
    };
    const postResponse = await getQlikSenseUnformattedData(payload);
    if (postResponse) {
      const dataFromResponse = postResponse[0];
      if (dataFromResponse) {
        getVarianceChart(cardId?.ChartLabel, dataFromResponse);
      }
    }
    setError(null);
  };

  const onEditCard = () => {
    setEditMode(true);
  };

  const onChangeEditMode = () => {
    // handleEditCardTitle(cardId, cardName);
    let dataToUpdate = { ...editData };
    if (dataToUpdate?.hasOwnProperty(CARDNAME)) {
      dataToUpdate.cardName = editableText;
    } else if (dataToUpdate?.hasOwnProperty(CARDNAMECCAPS)) {
      dataToUpdate.CardName = editableText;
    }
    setEditData(dataToUpdate);
    setIsEditing(false);
    setIsUpdateDisabled(true)
  };
  const onSelectChange = (key, e) => {
    const updatedSelection = e.checked
      ? [...checkboxValues, key]
      : checkboxValues?.filter((item) => item !== key);

    setCheckBoxValues(updatedSelection);
  };

  const handleForecastType = (e) => {
    setSelectedView(e?.target?.value);
  };

  const handleForecastWeekType = (e) => {
    setSelectedViewShortTerm(e.target.value);
  };

  const onSelectChangesNetSales = (updatedSelection) => {
    setchecknetsales(!checknetsales);
    setCheckBoxValues(updatedSelection);
    const resultMap = {};

    bpData?.forEach((item) => {
      if (resultMap[item.name]) {
        resultMap[item.name].value = item?.value;
      } else {
        resultMap[item.name] = { ...item };
      }
    });
    const filteredData = Object.values(resultMap)?.flatMap((item) =>
      item?.value?.map((v) => v),
    );
    const resultData = filteredData?.filter((item) =>
      updatedSelection?.some((option) => item?.name?.includes(option)),
    );
    SetFilteredBPData(resultData);
    getChartData(CARD_CONFIG?.BP_NETSALES_CHART?.ChartLabel, resultData);
  };

  const onSelectChangesEvent = (updatedSelection) => {
    setCheckBoxValuesEvent(updatedSelection);
    setIsApplyDisabled(false);
  };

  useEffect(() => {
    if (editMode) {
      if (extractMeasureEventType(editData?.Expression)?.length > 0) {
        let value = extractMeasureEventType(editData?.Expression)
          ?.replace(/'/g, '')
          ?.split(',');
        // SetFilteredBPData()

        onSelectChangesEvent(value);
      }
    } else {
      setCheckBoxValuesEvent(eventFilterOptions);
    }
  }, [eventFilterOptions]);

  const getChartData = (chartId, bpfilteredData) => {
    if (chartId === CARD_CONFIG?.BP_NETSALES_CHART?.ChartLabel) {
      if (bpfilteredData !== undefined) {
        return bpfilteredData;
      } else {
        const resultMap = {};
        bpData?.forEach((item) => {
          if (resultMap[item.name]) {
            resultMap[item.name].value = item?.value;
          } else {
            resultMap[item.name] = { ...item };
          }
        });
        const filteredData = Object.values(resultMap)?.flatMap((item) =>
          item?.value?.map((v) => v),
        );
        return filteredData;
      }
    } else if (chartId === CARD_CONFIG?.SOP_AGGFORECAST_CHART?.ChartLabel) {
      return selectedView !== DEFAULT_FORECASTVIEW
        ? varianceChartData
        : chartData;
    } else if (
      chartId === CARD_CONFIG?.SOP_SHORTTERM_BIAS_4WKS_CHART?.ChartLabel
    ) {
      return selectedViewShortTerm !== SHORT_TERM_VIEW_TYPE_WEEK
        ? varianceChartData
        : chartData;
    } else {
      return chartData;
    }
  };

  const inputString = summaryData?.ytdRoyDemandLabel;
  const transformLabel = (inputString) => {
    const lineStyle = {
      Actual: '#13294B',
      '12 wk MA': '#DB930B',
      Trend: '#56A944',
      T0: '#af1685',
      High: '#919698',
      Low: '#CBCBCB',
    };
    const result = [];

    if (inputString && typeof inputString === 'string') {
      const splitData = inputString.split('|');
      result.push(...splitData);
    }

    const transformed = result.map((item, index) => {
      const parts = item.split(':');
      let name = parts[0].trim();

      if (name === ACTUAL_SALES) {
        name = ACTUAL;
      }

      return {
        key: index + 1,
        name: name,
        data: item.trim(),
        linsStyle: lineStyle[name],
      };
    });

    return transformed; // Return the transformed array
  };

  const labelytd = transformLabel(inputString);

  const getChartType = (chartlabel, chartType) => {
    let resultData = [];
    if (chartlabel === CARD_CONFIG?.SOP_AGGFORECAST_CHART?.ChartLabel) {
      resultData =
        selectedView !== DEFAULT_FORECASTVIEW
          ? CARD_CONFIG?.SOP_AGG_ACCURACY_FORECAST_CHART?.ChartType
          : chartType;
    } else {
      resultData = chartType;
    }

    return resultData;
  };

  // const editableText () => {
  //   if (editableText?.length > 35) {
  //     return editableText?.length > 35 ? (
  //       <Tooltip title={editableText}>
  //         {`${editableText.substring(0, 35)}...`}
  //       </Tooltip>
  //     ) : (
  //       { editableText }
  //     );
  //   } else return editableText;
  // };

  useEffect(() => {
    getPriorityComment();
  }, [viewType]);

  const onSelectViewType = (e) => {
    setViewType(e?.target?.value);
  };

  const getPriorityComment = () => {
    const { Brand, SKU } = selectedFilter || {};
    let brand = Brand?.[0];
    if (Brand == undefined || Brand?.length > 1 || Brand?.length == 0)
      brand = null;

    let sku = SKU?.[0];
    if (SKU == undefined || SKU?.length > 1 || SKU?.length == 0) sku = null;

    const { CardId: ChartId, ChartLabel } = selectedDimension || {};
    let latestComments;
    let sopPreviousOrFinanceHasComment = false;
    if (ChartLabel === CARD_CONFIG?.SOP_YEAR_FORECAST_CHART?.ChartLabel) {
      latestComments = allComments?.filter((item) =>
        viewType === VIEW_TYPE_PREVIOUS
          ? item?.ChartId === PREVIOUSMONTHFORECASTKPICARDID &&
            item?.BrandName === brand
          : item?.ChartId === YEARFORECASTFINANCIALFORECASTKPICARDID &&
            item?.BrandName === brand,
      );
      const previousComments = allComments?.filter(
        (item) =>
          item?.ChartId === PREVIOUSMONTHFORECASTKPICARDID &&
          item?.BrandName === brand,
      );
      const financeComments = allComments?.filter(
        (item) =>
          item?.ChartId === YEARFORECASTFINANCIALFORECASTKPICARDID &&
          item?.BrandName === brand,
      );
      sopPreviousOrFinanceHasComment =
        previousComments.length > 0 || financeComments.length > 0;
    } else if (
      ChartLabel === CARD_CONFIG?.SOP_SHORTTERM_BIAS_4WKS_CHART?.ChartLabel
    ) {
      latestComments = allComments?.filter((item) => {
        return item?.ChartId === ChartId && item?.Sku === sku;
      });
    } else {
      latestComments = allComments?.filter(
        (item) => item?.ChartId === ChartId && item?.BrandName === brand,
      );
    }

    const shouldShowComment =
      latestComments?.length > 0 &&
      COMMENTEDCHARTS.includes(ChartLabel) &&
      !(
        ChartLabel === CARD_CONFIG?.BRAND_YTD_VARIANCE_CHART?.ChartLabel &&
        !brand
      ) &&
      !(
        ChartLabel === CARD_CONFIG?.BRAND_YTD_YOY_VARIANCE_CHART?.ChartLabel &&
        !brand
      ) &&
      !(
        ChartLabel === CARD_CONFIG?.SOP_YEAR_FORECAST_CHART?.ChartLabel &&
        !brand
      ) &&
      !(
        ChartLabel === CARD_CONFIG?.SOP_SHORTTERM_BIAS_4WKS_CHART?.ChartLabel &&
        !sku
      ) &&
      !(ChartLabel === CARD_CONFIG?.BP_NETSALES_CHART?.ChartLabel && !brand);

    const pinnedIndex = latestComments?.findIndex((x) => x?.isPinned);
    if (pinnedIndex > -1) {
      latestComments = shiftElementPlacement(latestComments, pinnedIndex, 0);
    }

    setShowComment(shouldShowComment);
    setCommentDetails(shouldShowComment ? latestComments[0] : null);
    setSopPreviousOrFinanceHasComment(sopPreviousOrFinanceHasComment);
  };

  const [infoMessageTitle, setInfoMessageTitle] = useState('');

  const handleInfoModalOpen = () => {
    const info = getUniqueInfoTextString(
      brandExpressions,
      selectedDimension?.CardId,
    );
    setInfoMessageTitle(info);
    setIsInfoModalVisible(true);
  };

  const getUniqueInfoTextString = (objects, cardId) => {
    const uniqueInfoText = new Set();

    objects?.forEach((obj) => {
      if (obj.CardId === cardId) {
        uniqueInfoText.add(obj?.Info_Text);
      }
    });
    return [...uniqueInfoText].join(', '); // Convert Set to a string
  };

  useEffect(() => {
    if (!isUpdateDisabled) {
      setEditableText(selectedDimensionNameWithLeastFilter);
    }
  }, [selectedFilter]);

  return (
    <>
      <div className={classes['config-header']} onClick={showModal}>
        <i className={`icon icon__setting_blue`}></i>
        Configure My View
      </div>
      <Modal
        title={
          <div className={classes['modal-header']}>
            <i className={`icon icon__setting ${classes['icon-align']}`}></i>
            Configure My View
          </div>
        }
        open={isModalVisible}
        onCancel={() => handleCancel()}
        destroyOnClose={true}
        footer={[
          <Button key="cancel" onClick={() => handleCancel()} type="link">
            Close
          </Button>,
        ]}
        zIndex={1000}
        width="85%"
        height="50%"
      >
        <div className={classes['sub-header']}>
          <div>You can add up to 20 tiles to display.</div>

          {((isDimensionChangedinEditmode &&
            selectedDimension?.DimensionId != editData?.DimensionId) ||
            (isDimensionChangedinEditmode &&
              selectedDimension?.DimensionId == editData?.DimensionId &&
              editableText != editData?.CardName)) &&
            editMode && (
              <div className={classes['centered-div-onfigureMyView']}>
                <span>{CONFIGWARNINGMSG}</span>
              </div>
            )}
          {editMode ? (
            <Button
              className={classes['config-add-view']}
              onClick={() => {
                handleUdpateMyView(
                  selectedKpiName,
                  selectedFilter,
                  selectedDimension?.ChartLabel ===
                    CARD_CONFIG?.BRAND_YTD_VARIANCE_CHART?.ChartLabel ||
                    selectedDimension?.ChartLabel ===
                      CARD_CONFIG?.BRAND_YTD_YOY_VARIANCE_CHART?.ChartLabel ||
                    selectedDimension?.ChartLabel ===
                      CARD_CONFIG?.SOP_YEAR_FORECAST_CHART?.ChartLabel
                    ? expressionVariance
                    : selectedDimension?.ChartLabel ===
                      CARD_CONFIG?.BP_NETSALES_CHART?.ChartLabel
                    ? bpExpression
                    : expression,
                  selectedDimension?.CardId,
                  selectedDimensionNameWithLeastFilter ||
                    selectedDimension?.CardName ||
                    selectedDimension?.DimensionName,
                  selectedDimension?.ChartId,
                  selectedDimension?.ChartType,
                  selectedDimension?.ChartLabel,
                  selectedDimension?.DimensionId,
                  editData?.ID,
                  selectedDimension?.ChartDataType,
                  selectedDimension?.ChartLabel ===
                    CARD_CONFIG?.BP_NETSALES_CHART?.ChartLabel
                    ? getChartData(selectedDimension?.ChartLabel, undefined)
                    : chartData,
                  selectedDimension?.ChartLabel ===
                    CARD_CONFIG?.SOP_SHORTTERM_BIAS_4WKS_CHART?.ChartLabel ||
                    selectedDimension?.ChartLabel ===
                      CARD_CONFIG?.BRAND_YOY_SALES_CHART?.ChartLabel ||
                    selectedDimension?.ChartLabel ===
                      CARD_CONFIG?.BP_NETSALES_CHART?.ChartLabel ||
                    selectedDimension?.ChartLabel ===
                      CARD_CONFIG?.BRAND_YOY_OUTS_GROWTH_CHART?.ChartLabel
                    ? category
                    : EXTRACTCATEGORYARRAY(category),
                  formattedGridData,
                  selectedDimension?.ChartLabel ===
                    CARD_CONFIG?.BRAND_YOY_OUTS_GROWTH_CHART?.ChartLabel
                    ? gridColumns
                    : [],
                  varianceChartData,
                  varianceGridData,
                  getChartText(selectedDimension?.ChartLabel),
                  '',
                  labelytd,
                  currentNetSales,
                  selectedDimension?.ChartLabel ===
                    CARD_CONFIG?.BP_NETSALES_CHART?.ChartLabel
                    ? bpData
                    : [],
                  selectedDimension?.ChartLabel ===
                    CARD_CONFIG?.BP_NETSALES_CHART?.ChartLabel
                    ? scatterEvents
                    : [],
                );
                setSelectedFilter({
                  Brand:
                    selectedKpiName === BRANDUPPERCASE
                      ? [defaultFilter?.BrandName]
                      : [],
                  Business_Unit: defaultFilter?.BUName,
                  Franchise: [],
                  VersionType: versionTypeData[0],
                  SKU: [], // Added SKU in selections
                });
                handleSelection('portfolio', null);
                setEditMode(false);
                setIsModalVisible(false);
                SetFilteredBPData();
                setIsDimensionChangedInEditmode(false);
                setShowModalContainer(false);
                setEditableText('');
              }}
              disabled={
                promiseInProgress || isUpdateDisabled || !isApplyDisabled
              }
            >
              <i className={`icon icon__add_blue ${classes['icon-add']}`}></i>
              <span>Update My View</span>
            </Button>
          ) : (
            <Spin spinning={addHandleLoader}>
              <Button
                className={classes['config-add-view']}
                onClick={() => {
                  setEditMode(false);
                  handleAddToView(
                    selectedKpiName,
                    selectedFilter,
                    selectedDimension?.ChartLabel ===
                      CARD_CONFIG?.BRAND_YTD_VARIANCE_CHART?.ChartLabel ||
                      selectedDimension?.ChartLabel ===
                        CARD_CONFIG?.BRAND_YTD_YOY_VARIANCE_CHART?.ChartLabel ||
                      selectedDimension?.ChartLabel ===
                        CARD_CONFIG?.SOP_YEAR_FORECAST_CHART?.ChartLabel
                      ? expressionVariance
                      : selectedDimension?.ChartLabel ===
                        CARD_CONFIG?.BP_NETSALES_CHART?.ChartLabel
                      ? bpExpression
                      : expression,
                    selectedDimension?.CardId,
                    editableText || selectedDimension?.DimensionName,
                    selectedDimension?.ChartId,
                    selectedDimension?.ChartType,
                    selectedDimension?.ChartLabel,
                    selectedDimension?.DimensionId,
                    selectedDimension?.KPIId,
                    selectedDimension?.ChartDataType,
                    getChartData(selectedDimension?.ChartLabel, undefined),
                    selectedDimension?.ChartLabel ===
                      CARD_CONFIG?.SOP_SHORTTERM_BIAS_4WKS_CHART?.ChartLabel ||
                      selectedDimension?.ChartLabel ===
                        CARD_CONFIG?.BRAND_YOY_SALES_CHART?.ChartLabel ||
                      selectedDimension?.ChartLabel ===
                        CARD_CONFIG?.BP_NETSALES_CHART?.ChartLabel ||
                      selectedDimension?.ChartLabel ===
                        CARD_CONFIG?.BRAND_YOY_OUTS_GROWTH_CHART?.ChartLabel
                      ? category
                      : EXTRACTCATEGORYARRAY(category),
                    formattedGridData,
                    selectedDimension?.ChartLabel ===
                      CARD_CONFIG?.BRAND_YOY_OUTS_GROWTH_CHART?.ChartLabel
                      ? gridColumns
                      : [],
                    varianceChartData,
                    varianceGridData,
                    getChartText(selectedDimension?.ChartLabel),
                    '',
                    labelytd,
                    currentNetSales,
                    selectedDimension?.ChartLabel ===
                      CARD_CONFIG?.BP_NETSALES_CHART?.ChartLabel
                      ? bpData
                      : [],
                    selectedDimension?.ChartLabel ===
                      CARD_CONFIG?.BP_NETSALES_CHART?.ChartLabel
                      ? scatterEvents
                      : [],
                  );
                }}
                disabled={promiseInProgress || isAddDisabled || addHandleLoader}
              >
                <>
                  <i
                    className={`icon icon__add_blue ${classes['icon-add']}`}
                  ></i>
                  <span>Add to My View</span>
                </>
              </Button>
            </Spin>
          )}
        </div>
        <Row>
          <Col md={4}>
            {/* Pass the handleSelection function to MyViewKpi */}

            <MyViewKpi
              setKpi={setKpi}
              handleSelection={handleSelection}
              page={page}
              editData={editData}
              editMode={editmodeCon}
              setShowComment={setShowComment}
              setCommentDetails={setCommentDetails}
              getPriorityComment={getPriorityComment}
            />
          </Col>
          <Col md={20}>
            <div className={classes['input-container']}>
              <div className={classes['input-text']}>
                <div className={classes['input-card']}>
                  {isEditing ? (
                    <>
                      <Input
                        type="text"
                        value={editableText}
                        onChange={(e) => setEditableText(e?.target?.value)}
                        style={{ marginRight: '5px' }}
                        addonAfter={
                          <span
                            style={
                              editableText?.trim() ? {} : { cursor: 'not-allowed' }
                            }
                          >
                            <CheckOutlined
                              style={
                                editableText?.trim()
                                  ? {}
                                  : { pointerEvents: 'none', opacity: '0.4' }
                              }
                              onClick={onChangeEditMode}
                            />
                          </span>
                        }
                      />
                    </>
                  ) : (
                    <>
                      <div className={classes['input-card-title']}>
                        <Tooltip title={editableText}>
                          <span className={classes['input-card-title-text']}>
                            {isUpdateDisabled
                              ? editableText
                              : selectedDimensionNameWithLeastFilter}
                          </span>
                        </Tooltip>
                      </div>
                      <i
                        className={`icon icon__edit ${classes['icon-edit']}`}
                        onClick={() => {
                          setIsEditing(true);
                        }}
                      ></i>
                    </>
                  )}

                  <i
                    className={`icon icon__info ${classes['icon-info']}`}
                    onClick={handleInfoModalOpen}
                  ></i>
                </div>
              </div>
              <div>
                <MyViewFilters
                  handleFilterChange={onFilterSelection}
                  currentTab={selectedKpiName?.toLowerCase()}
                  editMode={editMode}
                  selectedFilter={selectedFilter}
                  editfilters={
                    editData?.Filter?.length > 0 && JSON.parse(editData?.Filter)
                  }
                  isApplyDisabled={isApplyDisabled}
                  handleCheckFilterSame={handleCheckFilterSame}
                />
              </div>
              <Spin spinning={promiseInProgress || isLoading}>
                <ViewTile
                  cardTitle={selectedDimension?.DimensionName}
                  chartData={
                    selectedDimension?.ChartLabel ==
                      CARD_CONFIG?.BP_NETSALES_CHART?.ChartLabel &&
                    filteredBPData
                      ? filteredBPData
                      : getChartData(selectedDimension?.ChartLabel)
                  }
                  varianceChartData={varianceChartData}
                  varianceGridData={varianceGridData}
                  chartText={getChartText(selectedDimension?.ChartLabel)}
                  griCols={
                    selectedView !== DEFAULT_FORECASTVIEW
                      ? AccuracyForecastGridCols
                      : gridColumns
                  }
                  cardId={chartIdLabel}
                  gridData={
                    selectedView !== DEFAULT_FORECASTVIEW ||
                    selectedViewShortTerm !== SHORT_TERM_VIEW_TYPE_WEEK
                      ? varianceGridData
                      : formattedGridData && [
                          ...(chartIdLabel ===
                            CARD_CONFIG.BRAND_YOY_SALES_CHART.ChartLabel ||
                          chartIdLabel ===
                            CARD_CONFIG.BRAND_YTD_SD_CHART.ChartLabel
                            ? netSalesTable
                            : []),
                          ...formattedGridData,
                        ]
                  }
                  cardWidth={'100%'}
                  chartWidth={
                    MY_VIEW_FULLWIDTH.includes(
                      CARD_CONFIG?.BRAND_YTD_ROY_CHART?.ChartLabel,
                    )
                      ? '90%'
                      : '100%'
                  }
                  chartHeight={
                    MY_VIEW_FULLWIDTH.includes(selectedDimension?.ChartLabel) ||
                    MY_VIEW_SINGLECHART.includes(selectedDimension?.ChartLabel)
                      ? 402
                      : MY_VIEW_CHART_With_GRID?.includes(
                          selectedDimension?.ChartLabel,
                        )
                      ? 402
                      : 190
                  }
                  cardHeight={
                    MY_VIEW_FULLWIDTH.includes(selectedDimension?.ChartLabel) ||
                    MY_VIEW_SINGLECHART.includes(selectedDimension?.ChartLabel)
                      ? 585
                      : 585
                  }
                  chartType={getChartType(
                    selectedDimension?.ChartLabel,
                    selectedDimension?.ChartType,
                  )}
                  onSelectChange={onSelectChange}
                  checkboxValues={checkboxValues}
                  infoMessage={getUniqueInfoTextString(
                    brandExpressions,
                    selectedDimension?.CardId,
                  )}
                  page={selectedKpiName?.toLowerCase()}
                  category={
                    chartIdLabel ===
                      CARD_CONFIG?.SOP_SHORTTERM_BIAS_4WKS_CHART?.ChartLabel ||
                    chartIdLabel ===
                      CARD_CONFIG?.BRAND_YOY_SALES_CHART?.ChartLabel ||
                    chartIdLabel ===
                      CARD_CONFIG?.BP_NETSALES_CHART?.ChartLabel ||
                    chartIdLabel ===
                      CARD_CONFIG?.BRAND_YOY_OUTS_GROWTH_CHART?.ChartLabel
                      ? category
                      : EXTRACTCATEGORYARRAY(category)
                  }
                  selectedDimension={selectedDimension}
                  tab={'configure'}
                  onEditCard={onEditCard}
                  versionType={selectedFilter?.VersionType || 'T0'}
                  forecastLabel={getChartText(selectedDimension?.ChartLabel)}
                  minMax={
                    selectedViewShortTerm === SHORT_TERM_VIEW_TYPE_WEEK
                      ? FINDMINMAX(biasInputData)
                      : FINDMINMAX(biasWeeksInputData)
                  }
                  handleForecastType={handleForecastType}
                  handleChange={handleForecastWeekType}
                  selectedViewType={
                    selectedDimension?.ChartLabel ===
                    CARD_CONFIG?.SOP_AGGFORECAST_CHART?.ChartLabel
                      ? selectedView
                      : selectedViewShortTerm
                  }
                  eventFilterOptions={eventFilterOptions}
                  dataFilterOptions={dataFilterOptions}
                  onSelectChangesNetSales={onSelectChangesNetSales}
                  scatterPlotDatatoPlot={scatterEvents}
                  onSelectChangesEvent={onSelectChangesEvent}
                  labelytd={labelytd}
                  currentNetSales={
                    selectedDimension?.ChartLabel ==
                    CARD_CONFIG?.SOP_YEAR_FORECAST_CHART?.ChartLabel
                      ? extractMeasureSOP(expressionVariance)
                      : ''
                  }
                  onSelectOptionChange={(e) => {
                    setCurrentNetSales(e);
                    setIsApplyDisabled(false);
                  }}
                  checkboxValuesEvent={checkboxValuesEvent}
                  metricOptionsList={metricOptionsList}
                  showComment={showComment}
                  comment={commentDetails}
                  viewType={viewType}
                  onSelectViewType={onSelectViewType}
                  sopPreviousOrFinanceHasComment={
                    sopPreviousOrFinanceHasComment
                  }
                />
              </Spin>
            </div>
          </Col>
        </Row>
      </Modal>

      <InfoDialog
        isVisible={isInfoModalVisible}
        onClose={handleInfoModalClose}
        message={infoMessageTitle}
      />
    </>
  );
};

export default ConfigureMyView;
