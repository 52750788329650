import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ViewTile from './ViewTile';
import {
  demandChartGridCols,
  fyProjectionGridCols,
  yoyGridCols,
  ytdChartGridCols,
  ytdSalesVsForecastChartGridCols,
} from '@mockdata/brandChartData';
import {
  FINDMINMAXTILE,
  shiftElementPlacement,
  SORTYEARS,
  TRANSFORM_AGGFORECAST_CHARTDATA,
  TRANSFORM_AGGFORECAST_GRIDDATA,
  TRANSFORM_BPLAN_CHARTDATA,
  TRANSFORM_FORECASTTREND_CHARTDATA,
  TRANSFORM_FORECASTTREND_GRIDDATA,
  TRANSFORM_FYPROJECTION_CHARTDATA,
  TRANSFORM_FYPROJECTION_GRIDDATA,
  TRANSFORM_SD_CHARTDATA,
  TRANSFORM_SD_GRIDDATA,
  TRANSFORM_SHORTBIAS_GRIDDATA,
  TRANSFORM_SHORTTERMBIAS_CHARTDATA,
  TRANSFORM_VARIANCE_CHARTDATA,
  TRANSFORM_YOYOUTS_CHARTDATA,
  TRANSFORM_YOYOUTS_GRIDATA,
  TRANSFORM_YOYSALES_CHARTDATA,
  TRANSFORM_YOYSALES_GRIDDATA,
  TRANSFORM_YTD_CHARTDATA,
  TRANSFORM_YTD_GRIDDATA,
  TRANSFORM_YTDFORECASTGRIDDATA,
  TRANSFORM_YTDFORECASTPY_GRIDDATA,
} from '@utils/dataFormatter';
import {
  MY_VIEW_FULLWIDTH,
  MY_VIEW_SINGLECHART,
  MY_VIEW_CHART_With_GRID,
  LEGAL_COMPLIANCE_TEXT,
  DEFAULT_FORECASTVIEW,
  SHORT_TERM_VIEW_TYPE_WEEK,
  CHART_GRID_COLUMNS,
  VIEW_MORE,
  VIEW_TYPE_PREVIOUS,
  GROWTH,
  T0,
  COMMENTEDCHARTS,
  PREVIOUSMONTHFORECASTKPICARDID,
  YEARFORECASTFINANCIALFORECASTKPICARDID,
} from '@constant';
import { editCardTitle, updateCardPositions } from '@service/myview.service';
import classes from './TileLayoutContainer.module.scss';
import DefaultMessage from '../defaultMessage/DefaultMessage';
import { Spin } from 'antd';
import {
  AccuracyForecastGridCols,
  AggForecastGridCols,
  ShortWeekChartGridCols,
  YearForecastGridCols,
} from '@mockdata/SopChartData';
import { setMyViewCardsList } from '@store/brand/brandViewSlice';
import { extractMeasureSOP, extractMeasureEventType } from '@utils/utils';
import { CARD_CONFIG } from '@utils/cardConfig';
import { getUserUnixId } from '@utils/getUser';

const TileLayoutContainer = ({
  onRemoveFromView,
  onDuplicateCard,
  onEditCard,
  onOpenConfigureModal,
  dataFilterOptions,
  eventFilterOptions,
  loader,
  allComments,
  isViewMoreLoading,
  onClickViewMore,
  setIsViewMoreClicked,
}) => {
  const dispatch = useDispatch();
  const unixId = getUserUnixId();
  // eslint-disable-next-line no-unused-vars
  const [cardsList, setCardsList] = useState([]);
  const myViewCards = useSelector(
    (state) => state?.brandPerformance?.myViewCardsList,
  );
  const pointerIndex = useSelector(
    (state) => state?.brandPerformance?.pointerIndex,
  );

  const hoveredIndexRef = useRef(null);
  const brandExpressions = useSelector(
    (state) => state?.brandPerformance?.brandExpressions,
  );

  const [selectedView, setSelectedView] = useState(DEFAULT_FORECASTVIEW);
  const [selectedViewShortTerm, setSelectedViewShortTerm] = useState(
    SHORT_TERM_VIEW_TYPE_WEEK,
  );
  const [showViewMoreDefault, setShowViewMoreDefault] = useState(false);
  const [viewType, setViewType] = useState(VIEW_TYPE_PREVIOUS);

  const getUniqueInfoTextString = (objects, cardId) => {
    const uniqueInfoText = new Set();

    objects?.forEach((obj) => {
      if (obj.CardId === cardId) {
        uniqueInfoText.add(obj?.Info_Text);
      }
    });
    return [...uniqueInfoText].join(', '); // Convert Set to a string
  };

  const ytdVarianceActualChartData = (inputData) => {
    return inputData?.values
      ?.filter((item) => {
        return item?.name !== GROWTH;
      })
      ?.map((item) => {
        return {
          name: item?.name === T0 ? T0 : item?.name,
          value: Number(item?.data),
        };
      })
      ?.reverse();
  };

  const ytdPyActualChartData = (inputData) => {
    return inputData?.values
      ?.filter((item) => {
        return item?.name !== GROWTH;
      })
      ?.map((item) => {
        return {
          name: item?.name,
          value: Number(item?.data),
        };
      })
      ?.reverse();
  };

  const MYVIEW_MAPPING = {
    'ytd-sales-v-forec': {
      chartData: (inputData) => ytdVarianceActualChartData(inputData),
      gridColumns: () => ytdSalesVsForecastChartGridCols,
      gridData: (inputData) => TRANSFORM_YTDFORECASTGRIDDATA(inputData?.values),
      category: () => {
        return [];
      },
      varianceChartData: (inputData) => TRANSFORM_VARIANCE_CHARTDATA(inputData),
      varianceGridData: (inputData) => inputData?.values,
    },
    'ytd-sales-v-py': {
      chartData: (inputData) => ytdPyActualChartData(inputData),
      gridColumns: () => ytdSalesVsForecastChartGridCols,
      gridData: (inputData) =>
        TRANSFORM_YTDFORECASTPY_GRIDDATA(inputData?.values),
      category: () => {
        return [];
      },
      varianceChartData: (inputData) => TRANSFORM_VARIANCE_CHARTDATA(inputData),
      varianceGridData: (inputData) => inputData?.values,
    },
    'fy-projection': {
      chartData: (inputData) => TRANSFORM_FYPROJECTION_CHARTDATA(inputData),
      gridColumns: (versionType) => fyProjectionGridCols(versionType),
      gridData: (inputData) => TRANSFORM_FYPROJECTION_GRIDDATA(inputData),
      category: (inputData) => {
        return inputData;
      },
    },
    'ytd-variance': {
      chartData: (inputData) => TRANSFORM_VARIANCE_CHARTDATA(inputData),
      gridColumns: () => ytdChartGridCols,
      gridData: (inputData) => inputData?.values,
      category: () => {
        return [];
      },
    },
    'ytd-py': {
      chartData: (inputData) => TRANSFORM_VARIANCE_CHARTDATA(inputData),
      gridColumns: () => ytdChartGridCols,
      gridData: (inputData) => inputData?.values,
      category: () => {
        return [];
      },
    },
    'ytd-roy': {
      chartData: (inputData) => TRANSFORM_YTD_CHARTDATA(inputData),
      gridColumns: (versionType) => yoyGridCols(versionType),
      gridData: (inputData) => TRANSFORM_YTD_GRIDDATA(inputData),
      category: (inputData) => {
        return inputData;
      },
    },
    'sales-demand': {
      chartData: (inputData) => TRANSFORM_SD_CHARTDATA(inputData),
      gridColumns: () => demandChartGridCols,
      gridData: (inputData) => TRANSFORM_SD_GRIDDATA(inputData),
      category: (inputData) => {
        return inputData;
      },
    },
    'yoy-sales': {
      chartData: (inputData) => TRANSFORM_YOYSALES_CHARTDATA(inputData),
      gridColumns: (inputData, category) =>
        category && category?.length > 0 && CHART_GRID_COLUMNS(category),
      gridData: (inputData) => TRANSFORM_YOYSALES_GRIDDATA(inputData),
      category: (inputData) => SORTYEARS(inputData?.category),
    },
    'yoy-growth': {
      chartData: (inputData) => TRANSFORM_YOYOUTS_CHARTDATA(inputData),
      gridColumns: (inputData, category) =>
        category && category?.length > 0 && CHART_GRID_COLUMNS(category),
      gridData: (inputData) => TRANSFORM_YOYOUTS_GRIDATA(inputData),
      category: (inputData) => inputData,
    },

    'year-forecast': {
      chartData: (inputData) => TRANSFORM_FORECASTTREND_CHARTDATA(inputData),
      gridColumns: (versionType) => YearForecastGridCols(versionType),
      gridData: (inputData) => TRANSFORM_FORECASTTREND_GRIDDATA(inputData),
      category: (inputData) => inputData,
    },
    'agg-forecast': {
      chartData: (inputData) => TRANSFORM_AGGFORECAST_CHARTDATA(inputData),
      gridColumns: () => AggForecastGridCols,
      gridData: (inputData) => TRANSFORM_AGGFORECAST_GRIDDATA(inputData),
      category: (inputData) => inputData,
      varianceChartData: (inputData) =>
        TRANSFORM_AGGFORECAST_CHARTDATA(inputData),
      varianceGridData: (inputData) =>
        TRANSFORM_AGGFORECAST_GRIDDATA(inputData),
    },
    'short-termforecast': {
      chartData: (inputData) => TRANSFORM_SHORTTERMBIAS_CHARTDATA(inputData),
      gridColumns: () => ShortWeekChartGridCols,
      gridData: (inputData) => TRANSFORM_SHORTBIAS_GRIDDATA(inputData),
      category: (inputData) => inputData?.category,
      varianceChartData: (inputData) =>
        TRANSFORM_SHORTTERMBIAS_CHARTDATA(inputData),
      varianceGridData: (inputData) => TRANSFORM_SHORTBIAS_GRIDDATA(inputData),
    },
    'net-sales': {
      chartData: (inputData) => TRANSFORM_BPLAN_CHARTDATA(inputData),
      gridColumns: () => ShortWeekChartGridCols,
      gridData: () => [],
      category: (inputData) => inputData?.category,
    },
  };

  const handleEditCardTitle = async (cardId, newCardName) => {
    const payload = {
      id: cardId,
      unixId: unixId,
      cardName: newCardName,
    };
    await editCardTitle(payload);
    const filteredCard = myViewCards?.map((item) => {
      if (item?.ID === cardId) {
        return { ...item, CardName: newCardName };
      }
      return item;
    });

    dispatch(setMyViewCardsList(filteredCard));
  };

  const sortList = async (list, payload) => {
    try {
      await updateCardPositions(payload);
      dispatch(setMyViewCardsList(list));
    } catch (err) {
      console.log(err, '....');
    }
  };

  const reorderList = (result) => {
    const { source, destination } = result;

    let destinationIndex = destination?.index
      ? destination?.index
      : hoveredIndexRef?.current;

    // If dropped outside the list
    if (destinationIndex == null || destinationIndex == undefined) return;

    // If the source and destination indices are the same, do nothing
    if (source?.index === destinationIndex) return; //1===0

    const updatedCards = [...myViewCards];

    // Create shallow copies of the source and destination card objects

    let sourceOrderId = updatedCards[source?.index]?.PositionIndex;
    let destinationOrderId = updatedCards[destinationIndex]?.PositionIndex;

    // Clone the objects to avoid mutating the original read-only objects
    let updatedSourceCard = { ...updatedCards[source.index] };
    let updatedDestinationCard = { ...updatedCards[destinationIndex] };

    // Now safely modify the cloned objects
    updatedSourceCard.PositionIndex = destinationOrderId;
    updatedDestinationCard.PositionIndex = sourceOrderId;

    // Update the array with the modified objects
    updatedCards[source.index] = updatedSourceCard;
    updatedCards[destinationIndex] = updatedDestinationCard;
    const [movedCard] = updatedCards?.splice(source?.index, 1);

    updatedCards?.splice(destinationIndex, 0, movedCard);

    // Create the payload for the backend update
    const payload = {
      cards: updatedCards?.map((card) => ({
        cardId: card?.ID,
        order: card?.PositionIndex,
        positionId: card?.PositionIndex,
      })),
    };

    // Update the state and send to backend

    setCardsList(updatedCards);
    sortList(updatedCards, payload);
    // setDraggingIndex(null);
  };

  const getRowClassAndWidth = (card) => {
    if (MY_VIEW_FULLWIDTH?.includes(card?.ChartLabel)) {
      return {
        className: classes['full-width-row'], // Custom class for 100% width
        width: '100%',
      };
    }
    return {
      className: classes['normal-row'], // Default class
      width: '48%',
    };
  };

  useEffect(() => {
    if (myViewCards?.length > 6 && !showViewMoreDefault) {
      setShowViewMoreDefault(true);
    } else {
      setShowViewMoreDefault(false);
    }
  }, [myViewCards]);

  const onDragStart = () => {
    // setDraggingIndex(start?.source?.index); // Set the dragging index
    // setHoveredIndex(null);
    // hoveredIndexRef.current = null;
  };

  const onDragEnter = (index) => {
    hoveredIndexRef.current = index; // Set hovered index
  };

  const handleForecastType = (e) => {
    setSelectedView(e?.target?.value);
  };

  const handleForecastWeekType = (e) => {
    setSelectedViewShortTerm(e.target.value);
  };

  const getMINMAX = (chartlabel, item) => {
    if (item) {
      if (
        chartlabel === CARD_CONFIG.SOP_SHORTTERM_BIAS_26WKS_CHART.ChartLabel
      ) {
        return selectedViewShortTerm === SHORT_TERM_VIEW_TYPE_WEEK
          ? FINDMINMAXTILE(item?.chartData)
          : FINDMINMAXTILE(item?.varianceChartData);
      }
    }
  };

  const getGridColumns = (chartlabel, versionType, item) => {
    let resultData = [];
    if (chartlabel === CARD_CONFIG.SOP_AGGFORECAST_CHART.ChartLabel) {
      resultData =
        selectedView !== DEFAULT_FORECASTVIEW
          ? AccuracyForecastGridCols
          : MYVIEW_MAPPING[chartlabel]?.gridColumns(versionType);
    } else {
      resultData =
        chartlabel === CARD_CONFIG.BRAND_YOY_OUTS_GROWTH_CHART.ChartLabel
          ? item?.gridColumns
          : MYVIEW_MAPPING[chartlabel]?.gridColumns(
              versionType,
              item?.category,
            );
    }

    return resultData;
  };

  const getGridData = (chartlabel, varianceGridData, gridData) => {
    let resultData = [];
    if (chartlabel === CARD_CONFIG.SOP_AGGFORECAST_CHART.ChartLabel) {
      resultData =
        selectedView !== DEFAULT_FORECASTVIEW ? varianceGridData : gridData;
    } else if (
      chartlabel === CARD_CONFIG.SOP_SHORTTERM_BIAS_26WKS_CHART.ChartLabel
    ) {
      resultData =
        selectedViewShortTerm !== SHORT_TERM_VIEW_TYPE_WEEK
          ? varianceGridData
          : gridData;
    } else {
      resultData = gridData;
    }
    return resultData;
  };

  const getChartData = (chartlabel, varianceChartData, chartData) => {
    let resultData = [];
    if (chartlabel === CARD_CONFIG.SOP_AGGFORECAST_CHART.ChartLabel) {
      resultData =
        selectedView !== DEFAULT_FORECASTVIEW ? varianceChartData : chartData;
    } else if (
      chartlabel === CARD_CONFIG.SOP_SHORTTERM_BIAS_26WKS_CHART.ChartLabel
    ) {
      resultData =
        selectedViewShortTerm !== SHORT_TERM_VIEW_TYPE_WEEK
          ? varianceChartData
          : chartData;
    } else {
      resultData = chartData;
    }

    return resultData;
  };

  const getChartType = (chartlabel, chartType) => {
    let resultData = [];
    if (chartlabel === CARD_CONFIG.SOP_AGGFORECAST_CHART.ChartLabel) {
      resultData =
        selectedView !== DEFAULT_FORECASTVIEW
          ? CARD_CONFIG.SOP_AGG_ACCURACY_FORECAST_CHART.ChartType
          : chartType;
    } else {
      resultData = chartType;
    }

    return resultData;
  };

  const onSelectChangesNetSales = (updatedSelection, data, index) => {
    // setchecknetsales(!checknetsales);
    // setCheckBoxValues(updatedSelection);
    let updatedItems = [...myViewCards];
    let obj = updatedItems[index];

    const resultMap = {};
    data?.bpData?.forEach((item) => {
      if (resultMap[item.name]) {
        resultMap[item.name].value = item?.value;
      } else {
        resultMap[item.name] = { ...item };
      }
    });
    const filteredData = Object.values(resultMap)?.flatMap((item) =>
      item?.value?.map((v) => v),
    );
    const resultData = filteredData?.filter((item) =>
      updatedSelection?.some((option) => item?.name?.includes(option)),
    );
    // let view
    let viewData = getChartData('net-sales', [], resultData);
    let newObj = { ...obj };
    newObj.chartData = viewData;
    updatedItems[index] = newObj;

    dispatch(setMyViewCardsList(updatedItems));
  };

  const newMyViewCards = myViewCards.map((item) => {
    const Brand = JSON.parse(item?.Filter)?.Brand;
    const SKU = JSON.parse(item?.Filter)?.SKU;

    let brand = Brand?.[0];
    if (Brand == undefined || Brand?.length > 1 || Brand?.length == 0)
      brand = null;

    let sku = SKU?.[0];
    if (SKU == undefined || SKU?.length > 1 || SKU?.length == 0) sku = null;

    const { CardId: ChartId, ChartLabel } = item || {};

    let latestComments;
    let sopPreviousOrFinanceHasComment = false;
    if (ChartLabel === CARD_CONFIG?.SOP_YEAR_FORECAST_CHART?.ChartLabel) {
      latestComments = allComments?.filter((item) =>
        viewType === VIEW_TYPE_PREVIOUS
          ? item?.ChartId === PREVIOUSMONTHFORECASTKPICARDID &&
            item?.BrandName === brand
          : item?.ChartId === YEARFORECASTFINANCIALFORECASTKPICARDID &&
            item?.BrandName === brand,
      );
      const previousComments = allComments?.filter(
        (item) =>
          item?.ChartId === PREVIOUSMONTHFORECASTKPICARDID &&
          item?.BrandName === brand,
      );
      const financeComments = allComments?.filter(
        (item) =>
          item?.ChartId === YEARFORECASTFINANCIALFORECASTKPICARDID &&
          item?.BrandName === brand,
      );
      sopPreviousOrFinanceHasComment =
        previousComments.length > 0 || financeComments.length > 0;
    } else if (
      ChartLabel === CARD_CONFIG?.SOP_SHORTTERM_BIAS_4WKS_CHART?.ChartLabel
    ) {
      latestComments = allComments?.filter((item) => {
        return item?.ChartId === ChartId && item?.Sku === sku;
      });
    } else {
      // added brand verification as comments are linked with brand.
      latestComments = allComments?.filter(
        (item) => item?.ChartId === ChartId && item?.BrandName == brand,
      );
    }

    const pinnedIndex = latestComments?.findIndex((x) => x?.isPinned);
    if (pinnedIndex > -1) {
      latestComments = shiftElementPlacement(latestComments, pinnedIndex, 0);
    }

    const newItem = {
      ...item,
      ...(latestComments.length > 0 &&
        COMMENTEDCHARTS.includes(ChartLabel) &&
        !(
          ChartLabel === CARD_CONFIG?.BRAND_YTD_VARIANCE_CHART?.ChartLabel &&
          !brand
        ) &&
        !(
          ChartLabel ===
            CARD_CONFIG?.BRAND_YTD_YOY_VARIANCE_CHART?.ChartLabel && !brand
        ) &&
        !(
          ChartLabel === CARD_CONFIG?.SOP_YEAR_FORECAST_CHART?.ChartLabel &&
          !brand
        ) &&
        !(
          ChartLabel ===
            CARD_CONFIG?.SOP_SHORTTERM_BIAS_4WKS_CHART?.ChartLabel && !sku
        ) &&
        !(
          ChartLabel === CARD_CONFIG?.BP_NETSALES_CHART?.ChartLabel && !brand
        ) && {
          showComment: true,
          comment: latestComments[0],
        }),
      ...{
        sopPreviousOrFinanceHasComment: sopPreviousOrFinanceHasComment,
      },
    };

    return newItem;
  });

  const onSelectViewType = (e) => {
    setViewType(e?.target?.value);
  };

  return (
    <div className={classes['tile-container']}>
      <Spin spinning={loader}>
        {newMyViewCards?.length > 0 ? (
          <DragDropContext
            onDragEnd={reorderList}
            onDragStart={onDragStart}
            disableInteractiveElementBlocking
          >
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={classes['tile-width']}
                >
                  {newMyViewCards
                    ?.slice(0, pointerIndex)
                    ?.filter((item) => Object.hasOwn(item, 'chartData'))
                    ?.map((item, index) => {
                      const { width } = getRowClassAndWidth(item);
                      // const isHovered = hoveredIndex === index;
                      return (
                        <React.Fragment key={item?.ID}>
                          <Draggable
                            key={item?.ID}
                            draggableId={item?.ID?.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                // {...provided.dragHandleProps}
                                onMouseEnter={() => onDragEnter(index)}
                                // onMouseLeave={() => setHoveredIndex(null)}
                                style={{
                                  ...provided.draggableProps.style,
                                  width,
                                  margin: '0px 8px 0px 8px',
                                }}
                              >
                                <div
                                  style={{ width: '20px' }}
                                  className={classes['dragsRestrictMyView']}
                                  {...provided.dragHandleProps}
                                >
                                  <i className={'icon icon__myView_Drag'} />
                                </div>
                                <ViewTile
                                  cardTitle={item?.CardName}
                                  chartData={getChartData(
                                    item?.ChartLabel,
                                    item?.varianceChartData,
                                    item?.chartData,
                                  )}
                                  chartText={item?.chartText}
                                  griCols={getGridColumns(
                                    item?.ChartLabel,
                                    JSON.parse(item?.Filter)?.VersionType,
                                    item,
                                  )}
                                  cardId={item?.ChartLabel}
                                  gridData={getGridData(
                                    item?.ChartLabel,
                                    item?.varianceGridData,
                                    item?.gridData,
                                    JSON.parse(item?.Filter)?.VersionType,
                                  )}
                                  varianceChartData={item?.varianceChartData}
                                  varianceGridData={item?.varianceGridData}
                                  cardWidth={'100%'}
                                  chartWidth={'100%'}
                                  chartHeight={
                                    MY_VIEW_FULLWIDTH.includes(
                                      item?.ChartLabel,
                                    ) ||
                                    MY_VIEW_SINGLECHART.includes(
                                      item?.ChartLabel,
                                    )
                                      ? 402
                                      : MY_VIEW_CHART_With_GRID?.includes(
                                          item?.ChartLabel,
                                        )
                                      ? 402
                                      : 190
                                  }
                                  cardHeight={
                                    MY_VIEW_FULLWIDTH.includes(
                                      item?.ChartLabel,
                                    ) ||
                                    MY_VIEW_SINGLECHART.includes(
                                      item?.ChartLabel,
                                    )
                                      ? 'auto'
                                      : 610
                                  }
                                  chartType={getChartType(
                                    item?.ChartLabel,
                                    item?.ChartType,
                                  )}
                                  // onSelectChange={onSelectChange}
                                  // checkboxValues={checkboxValues}
                                  infoMessage={getUniqueInfoTextString(
                                    brandExpressions,
                                    item?.CardId,
                                  )}
                                  // page={selectedKpiName?.toLowerCase()}
                                  category={item?.category}
                                  onRemoveFromView={() => {
                                    onRemoveFromView(item?.ID);
                                    setIsViewMoreClicked(true);
                                  }}
                                  onDuplicateCard={(item) =>
                                    onDuplicateCard(item)
                                  }
                                  onEditCard={(id) => onEditCard(id)}
                                  currentCardID={item?.ID}
                                  handleEditCardTitle={handleEditCardTitle}
                                  // selectedDimension={selectedDimension}
                                  versionType={
                                    JSON.parse(item?.Filter)?.VersionType
                                  }
                                  franchise={
                                    JSON.parse(item?.Filter)?.Franchise
                                  }
                                  businessUnit={
                                    JSON.parse(item?.Filter)?.Business_Unit
                                  }
                                  brand={JSON.parse(item?.Filter)?.Brand}
                                  sku={JSON.parse(item?.Filter)?.SKU}
                                  viewname={item?.ViewName}
                                  tab={'myview'}
                                  forecastLabel={item?.chartText}
                                  minMax={
                                    (item?.chartData?.length ||
                                      item?.varianceChartData?.length) &&
                                    getMINMAX(item?.ChartLabel, item)
                                  }
                                  handleForecastType={handleForecastType}
                                  handleChange={handleForecastWeekType}
                                  selectedViewType={
                                    item?.ChartLabel ===
                                    CARD_CONFIG.SOP_AGGFORECAST_CHART.ChartLabel
                                      ? selectedView
                                      : selectedViewShortTerm
                                  }
                                  labelytd={item?.labelytd}
                                  currentNetSales={
                                    item?.ChartLabel ===
                                    CARD_CONFIG.SOP_YEAR_FORECAST_CHART
                                      .ChartLabel
                                      ? item?.currentNetSales
                                        ? item?.currentNetSales
                                        : extractMeasureSOP(item?.Expression)
                                      : ''
                                  }
                                  dataFilterOptions={dataFilterOptions}
                                  eventFilterOptions={eventFilterOptions}
                                  scatterPlotDatatoPlot={item?.scatterEvents}
                                  onSelectChangesNetSales={(selected) =>
                                    onSelectChangesNetSales(
                                      selected,
                                      item,
                                      index,
                                    )
                                  }
                                  eventsSelected={
                                    extractMeasureEventType(item?.Expression)
                                      ?.length > 0
                                      ? extractMeasureEventType(
                                          item?.Expression,
                                        ).replace(/'/g, '')
                                      : 'All'
                                  }
                                  ChartLabel={item?.ChartLabel}
                                  showComment={item?.showComment}
                                  comment={item?.comment}
                                  viewType={viewType}
                                  onSelectViewType={onSelectViewType}
                                  sopPreviousOrFinanceHasComment={
                                    item?.sopPreviousOrFinanceHasComment
                                  }
                                />
                              </div>
                            )}
                          </Draggable>
                        </React.Fragment>
                      );
                    })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <DefaultMessage onOpenConfigureModal={onOpenConfigureModal} />
        )}

        {isViewMoreLoading && (
          <ViewTile isLoading={true} cardWidth={'100%'} cardHeight={'610px'} />
        )}

        {!loader &&
        !isViewMoreLoading &&
        pointerIndex &&
        myViewCards?.length > pointerIndex ? (
          <div className={classes['viewMoreButton']} onClick={onClickViewMore}>
            {VIEW_MORE} <i className="icon icon__chevronDown"></i>
          </div>
        ) : (
          <></>
        )}
        <div className={'disclaimer-text'}>
          RESTRICTED INSIDER INFORMATION: <em>{LEGAL_COMPLIANCE_TEXT}</em>
        </div>
      </Spin>
    </div>
  );
};

export default TileLayoutContainer;
