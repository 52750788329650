import { colorLabelSpan } from './colorLabelSpan';
export const barChartOptions = ({ chartType, chartData, expand }) => ({
  grid: expand ? { left: '5%', right: '5%' } : {},
  tooltip: {
    show: true,
    formatter: (params) => {
      let tooltipContent = ' ';
      tooltipContent +=
        colorLabelSpan({ color: params?.color }) +
        params?.name +
        '($)' +
        ' : ' +
        `${Number(params?.value)?.toLocaleString()}M`;
      return tooltipContent;
    },
  },
  xAxis: {
    type: 'category',
    data: ['Price', 'Reserve', 'Dist Inv', 'Demand'],
    axisLabel: {
      color: '#000000',
    },
    axisTick: {
      show: false,
    },
  },
  yAxis: {
    type: 'value',
    show: false,
    splitLine: { show: false },
  },
  series: [
    {
      data: chartData,
      type: chartType,
      label: {
        color: 'inherit',
        show: true,
        position: 'top',
        formatter: (params) => {
          return `${
            params?.data?.value > 0
              ? `+${Number(params?.data?.value)?.toLocaleString()}`
              : Number(params?.data?.value)?.toLocaleString()
          }`;
        },
      },
    },
  ],
});
