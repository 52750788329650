// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ETHs7f9P4qunTDqESLBZ{margin-right:25px}`, "",{"version":3,"sources":["webpack://./src/components/layout/sidebar/NavSubMenu.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA","sourcesContent":[".nav-icon {\n  margin-right: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav-icon": `ETHs7f9P4qunTDqESLBZ`
};
export default ___CSS_LOADER_EXPORT___;
