import { Select, Checkbox, Button } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { BUSSINESS_UNIT, FILTERS, FLOAT_FILTERS, SQUAD } from '@constant';
import classes from './Filters.module.scss';

const FilterOptions = ({
  selections,
  handleChange,
  fetchDataForDropdown,
  options,
  loading,
  currentTab,
  searchInputs,
  handleDropdownVisibleChange,
  handleSearchChange,
  showFloatingFilter,
  filterModalRef,
  floatingFilterOpen,
  setFloatingFilterOpen,
  selectModalRef,
}) => {
  const filterClickHandler = (e) => {
    e.stopPropagation();
    setFloatingFilterOpen(!floatingFilterOpen);
  };
  const handleKeyDown = (event) => {
    if (event.key == 'ArrowDown') {
      const listItems = document.querySelectorAll('.ant-select-item-option');
      const activeItems = document.querySelectorAll(
        '.ant-select-item-option-active',
      );
      if (
        listItems &&
        listItems.length > 0 &&
        activeItems &&
        activeItems.length > 0
      ) {
        if (
          activeItems[0].textContent ===
          listItems[listItems.length - 1].textContent
        ) {
          event.stopPropagation();
        }
      }
    } else if (event.key == 'ArrowUp') {
      const listItems = document.querySelectorAll('.ant-select-item-option');
      const activeItems = document.querySelectorAll(
        '.ant-select-item-option-active',
      );
      if (
        listItems &&
        listItems.length > 0 &&
        activeItems &&
        activeItems.length > 0
      ) {
        if (activeItems[0].textContent === listItems[0].textContent) {
          event.stopPropagation();
        }
      }
    }
  };
  return (
    <>
      <div className={classes['filter-wrap']}>
        <div className={classes['label-title']}>{BUSSINESS_UNIT}</div>
        <Select
          showSearch={true}
          value={selections?.Business_Unit || 'All'}
          style={{ width: '100%' }}
          onChange={(value) => handleChange('Business_Unit', value, false)}
          onDropdownVisibleChange={() => {
            fetchDataForDropdown('Business_Unit');
          }}
          options={options?.Business_Unit?.map((option) => ({
            label: option,
            value: option,
          }))}
          loading={loading.Business_Unit}
        />
      </div>
      {currentTab !== 'businessplan' && (
        <div className={classes['filter-wrap']}>
          <div className={classes['label-title']}> {'Forecast'}</div>
          <Select
            showSearch={true}
            value={selections?.VersionType || 'All'}
            style={{ width: '100%' }}
            onChange={(value) => handleChange('VersionType', value, false)}
            onDropdownVisibleChange={() =>
              fetchDataForDropdown('VersionType', 'ddn')
            }
            options={options?.VersionType?.map((option) => ({
              label: option,
              value: option,
            }))}
            loading={loading.VersionType}
            disabled={!selections?.Business_Unit}
          />
        </div>
      )}
      <div className={classes['filter-wrap']}>
        <div className={classes['label-title']}>{SQUAD}</div>
        <Select
          showSearch={true}
          mode="multiple"
          style={{ width: '100%' }}
          options={[
            // Display selected options at the top
            ...options?.Franchise?.filter(
              (option) =>
                selections?.Franchise?.includes(option) &&
                option
                  .toLowerCase()
                  .includes(searchInputs.Franchise.toLowerCase()),
            ).map((option) => ({ label: option, value: option })),
            // Display remaining options matching the search
            ...options?.Franchise?.filter(
              (option) =>
                !selections?.Franchise?.includes(option) &&
                option
                  .toLowerCase()
                  .includes(searchInputs.Franchise.toLowerCase()),
            ).map((option) => ({ label: option, value: option })),
          ]}
          value={
            selections?.Franchise?.length === options?.Franchise?.length
              ? undefined // Display as undefined if all options are selected
              : selections?.Franchise?.length > 1
              ? 'Multiple Selected' // Display "Multiple Selected" for more than one selection
              : selections?.Franchise?.length === 0
              ? undefined // Display as undefined if nothing is selected
              : selections?.Franchise // Display single selection if only one is selected
          }
          className="custom-select"
          placeholder={<span style={{ color: '#333' }}>All</span>}
          dropdownStyle={{ maxHeight: 200, overflowY: 'auto' }}
          dropdownRender={() => {
            if (!options?.Franchise || options?.Franchise.length === 0) {
              return (
                <div style={{ padding: '10px', textAlign: 'center' }}>
                  No data available
                </div>
              );
            }

            return (
              <div
                style={{
                  maxHeight: '200px',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  scrollbarWidth: 'thin',
                  scrollbarColor: '#888 #e0e0e0',
                }}
              >
                {[
                  ...options?.Franchise?.filter(
                    (option) =>
                      selections?.Franchise?.includes(option) &&
                      option
                        ?.toLowerCase()
                        ?.includes(searchInputs?.Franchise?.toLowerCase()),
                  )?.map((option) => ({ label: option, value: option })),
                  ...options?.Franchise?.filter(
                    (option) =>
                      !selections?.Franchise?.includes(option) &&
                      option
                        ?.toLowerCase()
                        ?.includes(searchInputs?.Franchise?.toLowerCase()),
                  )?.map((option) => ({ label: option, value: option })),
                ]?.map((item) => (
                  <div
                    id={'menu-item'}
                    key={item?.value}
                    className={classes['menu-content-filter']}
                    onClick={() => handleChange('Franchise', item?.value, true)}
                  >
                    <Checkbox
                      onClick={(e) => e.stopPropagation()}
                      id={'menu-item-checkbox'}
                      onChange={() =>
                        handleChange('Franchise', item?.value, true)
                      }
                      checked={selections?.Franchise?.includes(item?.value)}
                      style={{ marginRight: '10px' }}
                    />
                    {item.label}
                  </div>
                ))}
              </div>
            );
          }}
          onDropdownVisibleChange={(isOpen) =>
            handleDropdownVisibleChange('Franchise', isOpen)
          }
          loading={loading.Franchise}
          disabled={!selections?.VersionType}
          onDeselect={(value) => handleChange('Franchise', value, true)}
          onSearch={(value) => handleSearchChange('Franchise', value)}
          filterOption={false}
        />
      </div>
      {currentTab !== 'portfolio' && (
        <div className={classes['filter-wrap']}>
          <div className={classes['label-title']}>{FILTERS.BRAND}</div>
          {currentTab === 'brand' ? (
            <Select
              showSearch={true}
              value={
                selections?.Brand.length === 0 ? 'All' : selections?.Brand[0]
              }
              style={{ width: '100%' }}
              onChange={(value) => handleChange('Brand', value, false)}
              onDropdownVisibleChange={() => {
                fetchDataForDropdown('Brand', 'ddn');
              }}
              options={options?.Brand?.sort((a, b) => {
                return a.localeCompare(b, 'en', { sensitivity: 'base' });
              })?.map((option) => ({
                label: option,
                value: option,
              }))}
              loading={loading.Brand}
              disabled={!selections?.Franchise}
              onInputKeyDown={handleKeyDown}
            />
          ) : (
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              showSearch={true}
              options={[
                ...options?.Brand?.filter(
                  (option) =>
                    selections?.Brand?.includes(option) &&
                    option
                      ?.toLowerCase()
                      ?.includes(searchInputs?.Brand?.toLowerCase()),
                )?.map((option) => ({ label: option, value: option })),
                ...options?.Brand?.sort((a, b) => {
                  return a.localeCompare(b, 'en', { sensitivity: 'base' });
                })
                  ?.filter(
                    (option) =>
                      !selections?.Brand?.includes(option) &&
                      option
                        ?.toLowerCase()
                        ?.includes(searchInputs?.Brand?.toLowerCase()),
                  )
                  ?.map((option) => ({ label: option, value: option })),
              ]}
              value={
                selections?.Brand?.length === options?.Brand?.length
                  ? undefined // Display as undefined if all options are selected
                  : selections?.Brand?.length > 1
                  ? 'Multiple Selected' // Display "Multiple Selected" for more than one selection
                  : selections?.Brand?.length === 0
                  ? undefined // Display as undefined if nothing is selected
                  : selections?.Brand // Display single selection if only one is selected
              }
              className="custom-select"
              placeholder={<span style={{ color: '#333' }}>All</span>} // Styled placeholder
              dropdownStyle={{ maxHeight: 200, overflowY: 'auto' }} // Keep dropdown max height and overflow
              dropdownRender={() => (
                <div
                  style={{
                    maxHeight: '200px',
                    overflowY: 'auto', // Enable vertical scroll
                    overflowX: 'hidden', // Prevent horizontal scroll
                    scrollbarWidth: 'thin', // Firefox thin scrollbar
                    scrollbarColor: '#888 #e0e0e0', // Firefox scrollbar colors
                  }}
                >
                  {[
                    ...options?.Brand?.filter(
                      (option) =>
                        selections?.Brand?.includes(option) &&
                        option
                          ?.toLowerCase()
                          ?.includes(searchInputs?.Brand?.toLowerCase()),
                    )?.map((option) => ({ label: option, value: option })),
                    ...options?.Brand?.filter(
                      (option) =>
                        !selections?.Brand?.includes(option) &&
                        option
                          ?.toLowerCase()
                          ?.includes(searchInputs?.Brand?.toLowerCase()),
                    )?.map((option) => ({ label: option, value: option })),
                  ]?.map((item) => (
                    <div
                      id={'menu-item'}
                      key={item.value}
                      className={classes['menu-content-filter']}
                      onClick={() => handleChange('Brand', item?.value, true)}
                    >
                      <Checkbox
                        id={'menu-item-checkbox'}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() =>
                          handleChange('Brand', item?.value, true)
                        }
                        checked={selections?.Brand?.includes(item?.value)}
                        style={{ marginRight: '10px' }}
                      />
                      {item.label}
                    </div>
                  ))}
                </div>
              )}
              onDropdownVisibleChange={(isOpen) =>
                handleDropdownVisibleChange('Brand', isOpen)
              }
              loading={loading.Franchise}
              disabled={!selections?.VersionType}
              onDeselect={(value) => handleChange('Brand', value, true)}
              onSearch={(value) => handleSearchChange('Brand', value)}
              filterOption={false}
            />
          )}
        </div>
      )}
      {/* New SKU filter */}
      {currentTab === 'sop' && (
        <div className={classes['filter-wrap']}>
          <div className={classes['label-title']}>{FILTERS.SKU}</div>
          <Select
            mode="multiple"
            style={{ width: '100%', height: '32px' }}
            showSearch={true}
            options={[
              ...options?.SKU?.filter(
                (option) =>
                  selections?.SKU?.includes(option) &&
                  option
                    ?.toLowerCase()
                    ?.includes(searchInputs?.SKU?.toLowerCase()),
              )?.map((option) => ({ label: option, value: option })),
              ...options?.SKU?.filter(
                (option) =>
                  !selections?.SKU?.includes(option) &&
                  option
                    ?.toLowerCase()
                    ?.includes(searchInputs?.SKU?.toLowerCase()),
              )?.map((option) => ({ label: option, value: option })),
            ]}
            value={
              selections?.SKU?.length === options?.SKU?.length
                ? undefined // Display as undefined if all options are selected
                : selections?.SKU?.length > 1
                ? 'Multiple Selected' // Display "Multiple Selected" for more than one selection
                : selections?.SKU?.length === 0
                ? undefined // Display as undefined if nothing is selected
                : selections?.SKU // Display single selection if only one is selected
            }
            className="custom-select"
            placeholder={<span style={{ color: '#333' }}>All</span>} // Styled placeholder
            dropdownStyle={{ maxHeight: 200, overflowY: 'auto' }} // Keep dropdown max height and overflow
            dropdownRender={() => (
              <div
                style={{
                  maxHeight: '200px',
                  overflowY: 'auto', // Enable vertical scroll
                  overflowX: 'hidden', // Prevent horizontal scroll
                  scrollbarWidth: 'thin', // Firefox thin scrollbar
                  scrollbarColor: '#888 #e0e0e0', // Firefox scrollbar colors
                }}
              >
                {[
                  ...options?.SKU?.filter(
                    (option) =>
                      selections?.SKU?.includes(option) &&
                      option
                        ?.toLowerCase()
                        ?.includes(searchInputs?.SKU?.toLowerCase()),
                  )?.map((option) => ({ label: option, value: option })),
                  ...options?.SKU?.filter(
                    (option) =>
                      !selections?.SKU?.includes(option) &&
                      option
                        ?.toLowerCase()
                        ?.includes(searchInputs?.SKU?.toLowerCase()),
                  )?.map((option) => ({ label: option, value: option })),
                ]?.map((item) => (
                  <div
                    key={item.value}
                    className={classes['menu-content-filter']}
                    onClick={() => handleChange('SKU', item?.value, true)}
                  >
                    <Checkbox
                      onClick={(e) => e.stopPropagation()}
                      onChange={() => handleChange('SKU', item?.value, true)}
                      checked={selections?.SKU?.includes(item?.value)}
                      style={{ marginRight: '10px' }}
                    />
                    {item.label}
                  </div>
                ))}
              </div>
            )}
            onDropdownVisibleChange={(isOpen) =>
              handleDropdownVisibleChange('SKU', isOpen)
            }
            loading={loading.SKU}
            disabled={!selections?.Brand}
            onDeselect={(value) => handleChange('SKU', value, true)}
            onSearch={(value) => handleSearchChange('SKU', value)}
            filterOption={false}
          />
        </div>
      )}
      {showFloatingFilter && (
        <div
          ref={filterModalRef}
          className={
            floatingFilterOpen
              ? classes['floating-filter-open']
              : classes['floating-filter']
          }
        >
          <div
            className={classes['floating-filter-btn']}
            onClickCapture={filterClickHandler}
          >
            <Button
              type="primary"
              icon={
                !floatingFilterOpen ? (
                  <UpOutlined onClick={filterClickHandler} />
                ) : (
                  <DownOutlined onClick={filterClickHandler} />
                )
              }
              iconPosition={'end'}
            >
              <div>
                <i className={`icon icon__filter_white`}></i>
                <span>{FLOAT_FILTERS}</span>
              </div>
            </Button>
          </div>

          <div
            className={
              floatingFilterOpen
                ? classes['filter-folating-card']
                : classes['filter-folating-card-close']
            }
          >
            <div className={classes['floating-filter-wrap']}>
              <div className={classes['label-title']}>{BUSSINESS_UNIT}</div>
              <Select
                showSearch={true}
                value={selections?.Business_Unit || 'All'}
                style={{ width: '100%' }}
                onChange={(value) =>
                  handleChange('Business_Unit', value, false)
                }
                onDropdownVisibleChange={() => {
                  fetchDataForDropdown('Business_Unit');
                }}
                options={options?.Business_Unit?.map((option) => ({
                  label: option,
                  value: option,
                }))}
                loading={loading.Business_Unit}
              />
            </div>
            {currentTab !== 'businessplan' && (
              <div className={classes['floating-filter-wrap']}>
                <div className={classes['label-title']}> {'Forecast'}</div>
                <Select
                  showSearch={true}
                  value={selections?.VersionType || 'All'}
                  style={{ width: '100%' }}
                  onChange={(value) =>
                    handleChange('VersionType', value, false)
                  }
                  onDropdownVisibleChange={() =>
                    fetchDataForDropdown('VersionType', 'ddn')
                  }
                  options={options?.VersionType?.map((option) => ({
                    label: option,
                    value: option,
                  }))}
                  loading={loading.VersionType}
                  disabled={!selections?.Business_Unit}
                />
              </div>
            )}
            <div className={classes['floating-filter-wrap']}>
              <div className={classes['label-title']}>{SQUAD}</div>
              <Select
                showSearch={true}
                mode="multiple"
                style={{ width: '100%' }}
                ref={selectModalRef}
                options={[
                  // Display selected options at the top
                  ...options?.Franchise?.filter(
                    (option) =>
                      selections?.Franchise?.includes(option) &&
                      option
                        .toLowerCase()
                        .includes(searchInputs.Franchise.toLowerCase()),
                  ).map((option) => ({ label: option, value: option })),
                  // Display remaining options matching the search
                  ...options?.Franchise?.filter(
                    (option) =>
                      !selections?.Franchise?.includes(option) &&
                      option
                        .toLowerCase()
                        .includes(searchInputs.Franchise.toLowerCase()),
                  ).map((option) => ({ label: option, value: option })),
                ]}
                value={
                  selections?.Franchise?.length === options?.Franchise?.length
                    ? undefined // Display as undefined if all options are selected
                    : selections?.Franchise?.length > 1
                    ? 'Multiple Selected' // Display "Multiple Selected" for more than one selection
                    : selections?.Franchise?.length === 0
                    ? undefined // Display as undefined if nothing is selected
                    : selections?.Franchise // Display single selection if only one is selected
                }
                className="custom-select"
                placeholder={<span style={{ color: '#333' }}>All</span>}
                dropdownStyle={{ maxHeight: 200, overflowY: 'auto' }}
                dropdownRender={() => {
                  if (!options?.Franchise || options?.Franchise.length === 0) {
                    return (
                      <div style={{ padding: '10px', textAlign: 'center' }}>
                        No data available
                      </div>
                    );
                  }

                  return (
                    <div
                      style={{
                        maxHeight: '200px',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#888 #e0e0e0',
                      }}
                    >
                      {[
                        ...options?.Franchise?.filter(
                          (option) =>
                            selections?.Franchise?.includes(option) &&
                            option
                              ?.toLowerCase()
                              ?.includes(
                                searchInputs?.Franchise?.toLowerCase(),
                              ),
                        )?.map((option) => ({ label: option, value: option })),
                        ...options?.Franchise?.filter(
                          (option) =>
                            !selections?.Franchise?.includes(option) &&
                            option
                              ?.toLowerCase()
                              ?.includes(
                                searchInputs?.Franchise?.toLowerCase(),
                              ),
                        )?.map((option) => ({ label: option, value: option })),
                      ]?.map((item) => (
                        <div
                          key={item?.value}
                          id={'menu-item'}
                          className={classes['menu-content-filter']}
                          onClick={(e) => {
                            handleChange('Franchise', item?.value, true);
                            e.stopPropagation();
                          }}
                        >
                          <Checkbox
                            onClick={(e) => e.stopPropagation()}
                            id={'menu-item-checkbox'}
                            onChange={() =>
                              handleChange('Franchise', item?.value, true)
                            }
                            checked={selections?.Franchise?.includes(
                              item?.value,
                            )}
                            style={{ marginRight: '10px' }}
                          />
                          {item.label}
                        </div>
                      ))}
                    </div>
                  );
                }}
                onDropdownVisibleChange={(isOpen) =>
                  handleDropdownVisibleChange('Franchise', isOpen)
                }
                loading={loading.Franchise}
                disabled={!selections?.VersionType}
                onDeselect={(value) => handleChange('Franchise', value, true)}
                onSearch={(value) => handleSearchChange('Franchise', value)}
                filterOption={false}
              />
            </div>
            {currentTab !== 'portfolio' && (
              <div className={classes['floating-filter-wrap']}>
                <div className={classes['label-title']}>{FILTERS.BRAND}</div>
                {currentTab === 'brand' ? (
                  <Select
                    showSearch={true}
                    value={
                      selections?.Brand.length === 0
                        ? 'All'
                        : selections?.Brand[0]
                    }
                    style={{ width: '100%' }}
                    onChange={(value) => handleChange('Brand', value, false)}
                    onDropdownVisibleChange={() => {
                      fetchDataForDropdown('Brand', 'ddn');
                    }}
                    options={options?.Brand?.map((option) => ({
                      label: option,
                      value: option,
                    }))}
                    loading={loading.Brand}
                    disabled={!selections?.Franchise}
                    onInputKeyDown={handleKeyDown}
                  />
                ) : (
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    showSearch={true}
                    options={[
                      ...options?.Brand?.filter(
                        (option) =>
                          selections?.Brand?.includes(option) &&
                          option
                            ?.toLowerCase()
                            ?.includes(searchInputs?.Brand?.toLowerCase()),
                      )?.map((option) => ({ label: option, value: option })),
                      ...options?.Brand?.filter(
                        (option) =>
                          !selections?.Brand?.includes(option) &&
                          option
                            ?.toLowerCase()
                            ?.includes(searchInputs?.Brand?.toLowerCase()),
                      )?.map((option) => ({ label: option, value: option })),
                    ]}
                    value={
                      selections?.Brand?.length === options?.Brand?.length
                        ? undefined // Display as undefined if all options are selected
                        : selections?.Brand?.length > 1
                        ? 'Multiple Selected' // Display "Multiple Selected" for more than one selection
                        : selections?.Brand?.length === 0
                        ? undefined // Display as undefined if nothing is selected
                        : selections?.Brand // Display single selection if only one is selected
                    }
                    className="custom-select"
                    placeholder={<span style={{ color: '#333' }}>All</span>} // Styled placeholder
                    dropdownStyle={{ maxHeight: 200, overflowY: 'auto' }} // Keep dropdown max height and overflow
                    dropdownRender={() => (
                      <div
                        style={{
                          maxHeight: '200px',
                          overflowY: 'auto', // Enable vertical scroll
                          overflowX: 'hidden', // Prevent horizontal scroll
                          scrollbarWidth: 'thin', // Firefox thin scrollbar
                          scrollbarColor: '#888 #e0e0e0', // Firefox scrollbar colors
                        }}
                      >
                        {[
                          ...options?.Brand?.filter(
                            (option) =>
                              selections?.Brand?.includes(option) &&
                              option
                                ?.toLowerCase()
                                ?.includes(searchInputs?.Brand?.toLowerCase()),
                          )?.map((option) => ({
                            label: option,
                            value: option,
                          })),
                          ...options?.Brand?.filter(
                            (option) =>
                              !selections?.Brand?.includes(option) &&
                              option
                                ?.toLowerCase()
                                ?.includes(searchInputs?.Brand?.toLowerCase()),
                          )?.map((option) => ({
                            label: option,
                            value: option,
                          })),
                        ]?.map((item) => (
                          <div
                            id={'menu-item'}
                            key={item.value}
                            className={classes['menu-content-filter']}
                            onClick={() =>
                              handleChange('Brand', item?.value, true)
                            }
                          >
                            <Checkbox
                              onClick={(e) => e.stopPropagation()}
                              id={'menu-item-checkbox'}
                              onChange={() =>
                                handleChange('Brand', item?.value, true)
                              }
                              checked={selections?.Brand?.includes(item?.value)}
                              style={{ marginRight: '10px' }}
                            />
                            {item.label}
                          </div>
                        ))}
                      </div>
                    )}
                    onDropdownVisibleChange={(isOpen) =>
                      handleDropdownVisibleChange('Brand', isOpen)
                    }
                    loading={loading.Franchise}
                    disabled={!selections?.VersionType}
                    onDeselect={(value) => handleChange('Brand', value, true)}
                    onSearch={(value) => handleSearchChange('Brand', value)}
                    filterOption={false}
                  />
                )}
              </div>
            )}
            {/* New SKU filter */}
            {currentTab === 'sop' && (
              <div className={classes['floating-filter-wrap']}>
                <div className={classes['label-title']}>{FILTERS.SKU}</div>
                <Select
                  mode="multiple"
                  style={{ width: '100%', height: '32px' }}
                  showSearch={true}
                  options={[
                    ...options?.SKU?.filter(
                      (option) =>
                        selections?.SKU?.includes(option) &&
                        option
                          ?.toLowerCase()
                          ?.includes(searchInputs?.SKU?.toLowerCase()),
                    )?.map((option) => ({ label: option, value: option })),
                    ...options?.SKU?.filter(
                      (option) =>
                        !selections?.SKU?.includes(option) &&
                        option
                          ?.toLowerCase()
                          ?.includes(searchInputs?.SKU?.toLowerCase()),
                    )?.map((option) => ({ label: option, value: option })),
                  ]}
                  value={
                    selections?.SKU?.length === options?.SKU?.length
                      ? undefined // Display as undefined if all options are selected
                      : selections?.SKU?.length > 1
                      ? 'Multiple Selected' // Display "Multiple Selected" for more than one selection
                      : selections?.SKU?.length === 0
                      ? undefined // Display as undefined if nothing is selected
                      : selections?.SKU // Display single selection if only one is selected
                  }
                  className="custom-select"
                  placeholder={<span style={{ color: '#333' }}>All</span>} // Styled placeholder
                  dropdownStyle={{ maxHeight: 200, overflowY: 'auto' }} // Keep dropdown max height and overflow
                  dropdownRender={() => (
                    <div
                      style={{
                        maxHeight: '200px',
                        overflowY: 'auto', // Enable vertical scroll
                        overflowX: 'hidden', // Prevent horizontal scroll
                        scrollbarWidth: 'thin', // Firefox thin scrollbar
                        scrollbarColor: '#888 #e0e0e0', // Firefox scrollbar colors
                      }}
                    >
                      {[
                        ...options?.SKU?.filter(
                          (option) =>
                            selections?.SKU?.includes(option) &&
                            option
                              ?.toLowerCase()
                              ?.includes(searchInputs?.SKU?.toLowerCase()),
                        )?.map((option) => ({ label: option, value: option })),
                        ...options?.SKU?.filter(
                          (option) =>
                            !selections?.SKU?.includes(option) &&
                            option
                              ?.toLowerCase()
                              ?.includes(searchInputs?.SKU?.toLowerCase()),
                        )?.map((option) => ({ label: option, value: option })),
                      ]?.map((item) => (
                        <div
                          id={'menu-item'}
                          key={item.value}
                          className={classes['menu-content-filter']}
                          onClick={() => handleChange('SKU', item?.value, true)}
                        >
                          <Checkbox
                            onClick={(e) => e.stopPropagation()}
                            id={'menu-item-checkbox'}
                            onChange={() =>
                              handleChange('SKU', item?.value, true)
                            }
                            checked={selections?.SKU?.includes(item?.value)}
                            style={{ marginRight: '10px' }}
                          />
                          {item.label}
                        </div>
                      ))}
                    </div>
                  )}
                  onDropdownVisibleChange={(isOpen) =>
                    handleDropdownVisibleChange('SKU', isOpen)
                  }
                  loading={loading.SKU}
                  disabled={!selections?.Brand}
                  onDeselect={(value) => handleChange('SKU', value, true)}
                  onSearch={(value) => handleSearchChange('SKU', value)}
                  filterOption={false}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default FilterOptions;
