export const navData = (
  showPortfolio,
  showBrand,
  showSop,
  showBusinessPlan,
) => {
  return [
    {
      categoryName: 'Performance',
      link: '/performance',
      categoryIcon: 'performance',
      disabled: false,
      submenu: [
        showPortfolio && {
          categoryName: 'Portfolio',
          link: '/portfolio',
          categoryIcon: 'portfolio',
          disabled: false,
        },
        showBrand && {
          categoryName: 'Brand',
          link: '/brand',
          categoryIcon: 'brand',
          disabled: false,
        },
        showSop && {
          categoryName: 'S&OP',
          link: '/sop',
          categoryIcon: 'sop',
          disabled: false,
        },
      ].filter(Boolean),
    },
    showBusinessPlan && {
      categoryName: 'Business Plan',
      link: '/businessplan',
      categoryIcon: 'businessplan',
      disabled: false,
      submenu: [],
    },
    {
      categoryName: 'My View',
      link: '/myview',
      categoryIcon: 'myview',
      disabled: false,
      submenu: [],
    },
    // Removed for  now--
    // {
    //   categoryName: 'Archived Reports',
    //   link: '/reports',
    //   categoryIcon: 'archive',
    //   disabled: true,
    //   submenu: [],
    // },
  ];
};
