// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Xv1CneElDsQ30JFwTvu3{border:0;background:rgba(0,0,0,0);color:var(--color-tertiary-blue);display:flex;align-items:center}`, "",{"version":3,"sources":["webpack://./src/components/elements/button/LinkButton.module.scss"],"names":[],"mappings":"AAAA,sBACE,QAAA,CACA,wBAAA,CACA,gCAAA,CACA,YAAA,CACA,kBAAA","sourcesContent":[".link-button {\n  border: 0;\n  background: transparent;\n  color: var(--color-tertiary-blue);\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link-button": `Xv1CneElDsQ30JFwTvu3`
};
export default ___CSS_LOADER_EXPORT___;
