import { useEffect, useState } from 'react';
import { Select, Col, Row, Checkbox, Button } from 'antd';
import classes from './myviewFilters.module.scss';
import { useSelector } from 'react-redux';

const MyViewFilters = ({
  handleFilterChange,
  currentTab,
  selectedFilter,
  isApplyDisabled,
  handleCheckFilterSame,
}) => {
  const filterData = useSelector((state) => state?.brandPerformance?.filters);
  const defaultFilter = filterData?.filter(
    (item) => item?.DefaultBrandView === 'Y',
  )[0];
  const versionTypeData = useSelector(
    (state) => state?.brandPerformance?.versionType,
  );

  const [selections, setSelections] = useState({
    Brand: [],
    Business_Unit: '',
    Franchise: [],
    VersionType: '',
    SKU: [],
  });

  const [triggerSource, setTriggerSource] = useState(null);
  const [searchInputs, setSearchInputs] = useState({
    Franchise: '',
    Brand: '',
    SKU: '',
  });

  const [options, setOptions] = useState({
    Brand: [],
    Business_Unit: [],
    Franchise: [],
    VersionType: [...versionTypeData],
    SKU: [],
  });

  const [loading, setLoading] = useState({
    Brand: false,
    Business_Unit: false,
    Franchise: false,
    VersionType: false,
    SKU: false,
  });

  const handleSearchChange = (field, value) => {
    setSearchInputs((prev) => ({ ...prev, [field]: value }));
  };

  useEffect(() => {
    setSelections(selectedFilter);
  }, [defaultFilter, selectedFilter]);

  useEffect(() => {
    handleCheckFilterSame(selections);
  }, [selections]);

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (
        e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  const getUniqueFieldValues = (data, fieldName) => {
    if (!data.length || !data[0].hasOwnProperty(fieldName)) {
      return [];
    }
    return [...new Set(data.map((item) => item[fieldName]))];
  };

  const handleChange = (field, value, isMulti = false) => {
    setTriggerSource(field);

    setSelections((prevSelections) => {
      if (isMulti) {
        // Multi-select logic
        if (value === 'Multiple Selected') {
          return { ...prevSelections, [field]: [] };
        } else if (prevSelections[field].includes(value)) {
          // Remove the value if already selected
          return {
            ...prevSelections,
            [field]: prevSelections[field].filter((item) => item !== value),
          };
        } else {
          // Add the value to the array
          return {
            ...prevSelections,
            [field]: [...prevSelections[field], value],
          };
        }
      } else {
        if (currentTab === 'brand' && field === 'Brand') {
          // For single-select Brand dropdown in brand tab, save the value as an array
          return { ...prevSelections, [field]: [value] };
        }
        // Single-select logic for other fields
        return { ...prevSelections, [field]: value };
      }
    });
  };

  // Consolidated useEffect
  useEffect(() => {
    if (!triggerSource) return;

    switch (triggerSource) {
      case 'Business_Unit':
        // Reset Franchise and Brand
        setSelections((prev) => ({
          ...prev,
          Franchise: [],
          Brand: [],
          SKU: [],
        }));

        // Fetch Franchise options
        fetchDataForDropdown('Franchise').then(() => {
          if (currentTab === 'brand') {
            // Fetch Brand options after Franchise options are updated
            fetchDataForDropdown('Brand', {
              ...selections,
              Franchise: [], // Pass empty Franchise explicitly
            }).then((brandOptions) => {
              if (brandOptions?.length > 0) {
                setSelections((prev) => ({
                  ...prev,
                  Brand: [brandOptions[0]],
                }));
              }
            });
          }
        });
        break;

      case 'Franchise':
        if (currentTab === 'brand') {
          // Fetch Brand options when Franchise changes
          fetchDataForDropdown('Brand').then((brandOptions) => {
            if (brandOptions?.length > 0) {
              setSelections((prev) => ({ ...prev, Brand: [brandOptions[0]] }));
            }
          });
        } else if (currentTab === 's&op') {
          // Reset Brand and SKU for "s&op" tab
          setSelections((prev) => ({ ...prev, Brand: [], SKU: [] }));
        } else if (currentTab !== 'business') {
          // Reset Brand for "businessplan" tab
          setSelections((prev) => ({ ...prev, Brand: [] }));
        }
        break;

      case 'Brand':
        if (currentTab === 's&op') {
          // Reset SKU and fetch SKU options when Brand changes
          setSelections((prev) => ({ ...prev, SKU: [] }));
          fetchDataForDropdown('SKU');
        }
        break;

      default:
        break;
    }

    // Clear triggerSource after processing
    setTriggerSource(null);
  }, [triggerSource, selections, currentTab]);

  // Updated fetchDataForDropdown function to handle overridden selections
  const fetchDataForDropdown = async (field, overriddenSelections = null) => {
    setLoading((prev) => ({ ...prev, [field]: true }));

    // Use overriddenSelections if provided, otherwise default to current selections
    const currentSelections = overriddenSelections || selections;
    const { Business_Unit, Franchise, Brand } = currentSelections;

    const getSortedOptions = (options, field) => {
      return field === 'Business_Unit'
        ? options.sort((a, b) => b?.localeCompare(a))
        : options.sort((a, b) => a?.localeCompare(b));
    };

    let fetchedOptions = [];

    switch (field) {
      case 'Business_Unit':
        fetchedOptions = getUniqueFieldValues(filterData, 'BUName');
        setOptions((prev) => ({
          ...prev,
          Business_Unit: getSortedOptions(fetchedOptions, field),
        }));
        break;

      case 'Franchise':
        if (Business_Unit) {
          fetchedOptions = getUniqueFieldValues(
            filterData?.filter((item) => item.BUName === Business_Unit),
            'SquadName',
          );
          setOptions((prev) => ({
            ...prev,
            Franchise: getSortedOptions(fetchedOptions, field),
          }));
        }
        break;

      case 'Brand':
        if (Business_Unit) {
          fetchedOptions = getUniqueFieldValues(
            filterData?.filter((item) =>
              Franchise?.length > 0
                ? item?.BUName === Business_Unit &&
                  Franchise?.includes(item.SquadName)
                : item?.BUName === Business_Unit,
            ),
            'BrandName',
          );
          setOptions((prev) => ({
            ...prev,
            Brand:
              currentTab === 'brand'
                ? fetchedOptions
                : getSortedOptions(fetchedOptions, field),
          }));
        }
        break;

      case 'SKU':
        if (Business_Unit) {
          fetchedOptions = getUniqueFieldValues(
            filterData?.filter((item) =>
              Franchise?.length > 0
                ? Brand?.length > 0
                  ? item?.BUName === Business_Unit &&
                    Franchise?.includes(item?.SquadName) &&
                    Brand?.includes(item?.BrandName)
                  : item.BUName === Business_Unit &&
                    Franchise?.includes(item?.SquadName)
                : Brand?.length > 0
                ? item?.BUName === Business_Unit &&
                  Brand?.includes(item.BrandName)
                : item?.BUName === Business_Unit,
            ),
            'SkuName',
          );

          setOptions((prev) => ({
            ...prev,
            SKU: getSortedOptions(fetchedOptions, field),
          }));
        }
        break;

      default:
        break;
    }

    setLoading((prev) => ({ ...prev, [field]: false }));
    return fetchedOptions; // Return the fetched options for chaining
  };

  const handleDropdownVisibleChange = (field, isOpen) => {
    if (!isOpen) {
      setSearchInputs((prev) => ({ ...prev, [field]: '' }));
    } else {
      fetchDataForDropdown(field); // Trigger data fetch on open
    }
  };

  return (
    <div className={classes['filter-container']}>
      <Row gutter={[4, 4]} style={{ width: '100%' }}>
        <div className={classes['filter-wrap']}>
          <div className={classes['label-title']}>Business Unit</div>
          <Select
            value={selections?.Business_Unit || 'All'}
            style={{ width: '100%' }}
            showSearch={true}
            onChange={(value) => handleChange('Business_Unit', value, false)}
            onDropdownVisibleChange={() => {
              fetchDataForDropdown('Business_Unit');
            }}
            options={options?.Business_Unit?.map((option) => ({
              label: option,
              value: option,
            }))}
            loading={loading.Business_Unit}
          />
        </div>
        {currentTab !== 'business' && (
          <div className={classes['filter-wrap']}>
            <div className={classes['label-title']}>{'Forecast'}</div>
            <Select
              value={selections?.VersionType || 'All'}
              style={{ width: '100%' }}
              showSearch={true}
              onChange={(value) => handleChange('VersionType', value, false)}
              onDropdownVisibleChange={() =>
                fetchDataForDropdown('VersionType')
              }
              options={options?.VersionType?.map((option) => ({
                label: option,
                value: option,
              }))}
              loading={loading.VersionType}
              disabled={!selections?.Business_Unit}
            />
          </div>
        )}
        <div className={classes['filter-wrap']}>
          <div className={classes['label-title']}>Squad</div>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            showSearch={true}
            options={[
              // Display selected options at the top
              ...options?.Franchise?.filter(
                (option) =>
                  selections?.Franchise?.includes(option) &&
                  option
                    ?.toLowerCase()
                    ?.includes(searchInputs.Franchise.toLowerCase()),
              ).map((option) => ({ label: option, value: option })),
              // Display remaining options matching the search
              ...options?.Franchise?.filter(
                (option) =>
                  !selections?.Franchise?.includes(option) &&
                  option
                    ?.toLowerCase()
                    ?.includes(searchInputs.Franchise.toLowerCase()),
              ).map((option) => ({ label: option, value: option })),
            ]}
            value={
              selections?.Franchise?.length > 1
                ? 'Multiple Selected'
                : selections?.Franchise?.length === 0
                ? undefined
                : selections?.Franchise
            }
            className="custom-select"
            placeholder={<span style={{ color: '#333' }}>All</span>}
            dropdownStyle={{ maxHeight: 200, overflowY: 'auto' }} // Keep dropdown max height and overflow
            dropdownRender={() => {
              if (!options?.Franchise || options?.Franchise.length === 0) {
                return (
                  <div style={{ padding: '10px', textAlign: 'center' }}>
                    No data available
                  </div>
                );
              }

              return (
                <div
                  style={{
                    maxHeight: '200px',
                    overflowY: 'auto', // Enable vertical scroll
                    overflowX: 'hidden', // Prevent horizontal scroll
                    scrollbarWidth: 'thin', // Firefox thin scrollbar
                    scrollbarColor: '#888 #e0e0e0', // Firefox scrollbar colors
                  }}
                >
                  {[
                    ...options?.Franchise?.filter(
                      (option) =>
                        selections?.Franchise?.includes(option) &&
                        option
                          ?.toLowerCase()
                          ?.includes(searchInputs.Franchise.toLowerCase()),
                    ).map((option) => ({ label: option, value: option })),
                    ...options?.Franchise?.filter(
                      (option) =>
                        !selections?.Franchise?.includes(option) &&
                        option
                          ?.toLowerCase()
                          ?.includes(searchInputs.Franchise.toLowerCase()),
                    ).map((option) => ({ label: option, value: option })),
                  ].map((item) => (
                    <div
                      key={item.value}
                      className={classes['menu-content-filter']}
                      onClick={() =>
                        handleChange('Franchise', item?.value, true)
                      }
                    >
                      <Checkbox
                        onClick={(e) => e.stopPropagation()}
                        onChange={() =>
                          handleChange('Franchise', item?.value, true)
                        }
                        checked={selections?.Franchise?.includes(item?.value)}
                        style={{ marginRight: '10px' }}
                      />
                      {item.label}
                    </div>
                  ))}
                </div>
              );
            }}
            onDropdownVisibleChange={(isOpen) =>
              handleDropdownVisibleChange('Franchise', isOpen)
            }
            loading={loading.Franchise}
            disabled={!selections?.VersionType}
            onDeselect={(value) => handleChange('Franchise', value, true)}
            onSearch={(value) => handleSearchChange('Franchise', value)}
          />
        </div>
        {currentTab !== 'portfolio' && (
          <div className={classes['filter-wrap']}>
            <div className={classes['label-title']}>Brand</div>
            {currentTab === 'brand' ? (
              <Select
                value={
                  selections?.Brand.length === 0 ? 'All' : selections?.Brand
                }
                showSearch={true}
                style={{ width: '100%' }}
                onChange={(value) => handleChange('Brand', value, false)}
                onDropdownVisibleChange={() => {
                  fetchDataForDropdown('Brand');
                }}
                options={options?.Brand?.map((option) => ({
                  label: option,
                  value: option,
                }))}
                loading={loading.Brand}
                disabled={!selections?.Franchise}
              />
            ) : (
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                showSearch={true}
                options={[
                  ...options?.Brand?.filter(
                    (option) =>
                      selections?.Brand?.includes(option) &&
                      option
                        ?.toLowerCase()
                        ?.includes(searchInputs.Brand.toLowerCase()),
                  ).map((option) => ({ label: option, value: option })),
                  ...options?.Brand?.filter(
                    (option) =>
                      !selections?.Brand?.includes(option) &&
                      option
                        ?.toLowerCase()
                        ?.includes(searchInputs.Brand.toLowerCase()),
                  ).map((option) => ({ label: option, value: option })),
                ]}
                value={
                  selections?.Brand?.length > 1
                    ? 'Multiple Selected'
                    : selections?.Brand?.length === 0
                    ? undefined
                    : selections?.Brand
                }
                className="custom-select"
                placeholder={<span style={{ color: '#333' }}>All</span>} // Styled placeholder
                dropdownStyle={{ maxHeight: 200, overflowY: 'auto' }} // Keep dropdown max height and overflow
                dropdownRender={() => (
                  <div
                    style={{
                      maxHeight: '200px',
                      overflowY: 'auto', // Enable vertical scroll
                      overflowX: 'hidden', // Prevent horizontal scroll
                      scrollbarWidth: 'thin', // Firefox thin scrollbar
                      scrollbarColor: '#888 #e0e0e0', // Firefox scrollbar colors
                    }}
                  >
                    {[
                      ...options?.Brand?.filter(
                        (option) =>
                          selections?.Brand?.includes(option) &&
                          option
                            ?.toLowerCase()
                            ?.includes(searchInputs.Brand.toLowerCase()),
                      ).map((option) => ({ label: option, value: option })),
                      ...options?.Brand?.filter(
                        (option) =>
                          !selections?.Brand?.includes(option) &&
                          option
                            ?.toLowerCase()
                            ?.includes(searchInputs.Brand.toLowerCase()),
                      ).map((option) => ({ label: option, value: option })),
                    ].map((item) => (
                      <div
                        key={item.value}
                        className={classes['menu-content-filter']}
                        onClick={() => handleChange('Brand', item?.value, true)}
                      >
                        <Checkbox
                          onClick={(e) => e.stopPropagation()}
                          onChange={() =>
                            handleChange('Brand', item?.value, true)
                          }
                          checked={selections?.Brand?.includes(item?.value)}
                          style={{ marginRight: '10px' }}
                        />
                        {item.label}
                      </div>
                    ))}
                  </div>
                )}
                onDropdownVisibleChange={(isOpen) =>
                  handleDropdownVisibleChange('Brand', isOpen)
                }
                loading={loading.Franchise}
                disabled={!selections?.VersionType}
                onDeselect={(value) => handleChange('Brand', value, true)}
                onSearch={(value) => handleSearchChange('Brand', value)}
              />
            )}
          </div>
        )}
        {/* New SKU filter */}
        {currentTab === 's&op' && (
          <div className={classes['filter-wrap']}>
            <div className={classes['label-title']}>SKU</div>
            <Select
              mode="multiple"
              style={{ width: '100%', height: '32px' }}
              showSearch={true}
              options={[
                ...options?.SKU?.filter(
                  (option) =>
                    selections?.SKU?.includes(option) &&
                    option
                      ?.toLowerCase()
                      ?.includes(searchInputs.SKU.toLowerCase()),
                ).map((option) => ({ label: option, value: option })),
                ...options?.SKU?.filter(
                  (option) =>
                    !selections?.SKU?.includes(option) &&
                    option
                      ?.toLowerCase()
                      ?.includes(searchInputs.SKU.toLowerCase()),
                ).map((option) => ({ label: option, value: option })),
              ]}
              value={
                selections?.SKU?.length > 1
                  ? 'Multiple Selected'
                  : selections?.SKU?.length === 0
                  ? undefined
                  : selections?.SKU
              }
              className="custom-select"
              placeholder={<span style={{ color: '#333' }}>All</span>} // Styled placeholder
              dropdownStyle={{ maxHeight: 200, overflowY: 'auto' }} // Keep dropdown max height and overflow
              dropdownRender={() => (
                <div
                  style={{
                    maxHeight: '200px',
                    overflowY: 'auto', // Enable vertical scroll
                    overflowX: 'hidden', // Prevent horizontal scroll
                    scrollbarWidth: 'thin', // Firefox thin scrollbar
                    scrollbarColor: '#888 #e0e0e0', // Firefox scrollbar colors
                  }}
                >
                  {[
                    ...options?.SKU?.filter(
                      (option) =>
                        selections?.SKU?.includes(option) &&
                        option
                          ?.toLowerCase()
                          ?.includes(searchInputs?.SKU?.toLowerCase()),
                    ).map((option) => ({ label: option, value: option })),
                    ...options?.SKU?.filter(
                      (option) =>
                        !selections?.SKU?.includes(option) &&
                        option
                          ?.toLowerCase()
                          ?.includes(searchInputs.SKU.toLowerCase()),
                    ).map((option) => ({ label: option, value: option })),
                  ].map((item) => (
                    <div
                      key={item.value}
                      className={classes['menu-content-filter']}
                      onClick={() => handleChange('SKU', item?.value, true)}
                    >
                      <Checkbox
                        onClick={(e) => e.stopPropagation()}
                        onChange={() => handleChange('SKU', item?.value, true)}
                        checked={selections?.SKU?.includes(item?.value)}
                        style={{ marginRight: '10px' }}
                      />
                      {item.label}
                    </div>
                  ))}
                </div>
              )}
              onDropdownVisibleChange={(isOpen) =>
                handleDropdownVisibleChange('SKU', isOpen)
              }
              loading={loading.SKU}
              disabled={!selections?.Brand}
              onDeselect={(value) => handleChange('SKU', value, true)}
              onSearch={(value) => handleSearchChange('SKU', value)}
            />
          </div>
        )}
        <Col span={24} style={{ textAlign: 'right', marginBottom: '10px' }}>
          <Button
            type="primary"
            onClick={() => handleFilterChange(selections)}
            disabled={isApplyDisabled}
          >
            Apply
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default MyViewFilters;
