/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  menuClasses,
  ProSidebarProvider,
} from 'react-pro-sidebar';
import SearchBar from './SearchBar';
import classes from './MyViewKpi.module.scss';
import { TABNAMES } from '@constant';
import { Tooltip } from 'antd';

// Debounce utility function
const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};

const MyViewKpi = (props) => {
  const {
    setKpi,
    handleSelection,
    editData,
    editMode,
    setShowComment,
    setCommentDetails,
    getPriorityComment,
  } = props;

  const myViewKpi = useSelector(
    (state) => state?.brandPerformance?.myViewKpiDetails,
  );

  const [editModeKpi, setEditModeKpi] = useState(editMode);
  const [kpiLibData, setKpiLibData] = useState(myViewKpi);
  const [selectedKpi, setSelectedKpi] = useState(myViewKpi[0]);
  const [selectedDimension, setSelectedDimension] = useState(
    myViewKpi[0]?.dimensions[0]?.DimensionId,
  );
  const [filteredData, setFilteredData] = useState(myViewKpi);
  const [openKpiId, setOpenKpiId] = useState(myViewKpi[0]?.KpiId);

  const UserAccess = useSelector((state) => state?.brandPerformance?.userAcces);

  // Compute flags for view access
  const viewFlags = UserAccess.reduce((acc, item) => {
    acc[item.ViewName] = true;
    return acc;
  }, {});

  const showPortfolio = viewFlags[TABNAMES.portfolio] || false;
  const showBrand = viewFlags[TABNAMES.brand] || false;
  const showSop = viewFlags[TABNAMES.sop] || false;
  const showBussinessPlan = viewFlags[TABNAMES.bplan] || false;

  const filteredKpis = filteredData?.filter((kpi) => {
    if (kpi?.KpiName === 'Portfolio' && showPortfolio) return true;
    if (kpi?.KpiName === 'Brand' && showBrand) return true;
    if (kpi?.KpiName === 'S&OP' && showSop) return true;
    if (kpi?.KpiName === 'Business' && showBussinessPlan) return true;
    return false;
  });

  const cardIds = filteredKpis.flatMap((kpi) =>
    kpi.dimensions.map((dimension) => dimension.CardId),
  );

  const filtercardId = UserAccess.filter((item) => item.ViewCardAccess === 'Y');
  const showCardIds = filtercardId.map((item) => item.CardId);
  const filteredCardIds = cardIds.filter((cardId) =>
    showCardIds.includes(cardId),
  );

  const finalFilteredData = filteredKpis?.map((kpi) => {
    const filteredDimensions = kpi.dimensions.filter((dimension) =>
      filteredCardIds.includes(dimension?.CardId),
    );
    return {
      ...kpi,
      dimensions: filteredDimensions,
    };
  });

  useEffect(() => {
    if (!editModeKpi) {
      initializeKpiInfo();
    } else {
      setSelectedKpi(editData);
      setOpenKpiId(editData?.KpiId);
      setSelectedDimension(editData?.DimensionId);
      handleDimensionSelectLocal(editData?.ViewName?.split(' ')[0], editData);
    }
    return () => {
      setEditModeKpi(false);
    };
  }, [editModeKpi]);

  const initializeKpiInfo = () => {
    setKpiLibData(myViewKpi);
    setFilteredData(myViewKpi);
    const defaultKpi = myViewKpi[0];
    setSelectedKpi(defaultKpi);
    setOpenKpiId(defaultKpi?.KpiId);

    if (defaultKpi?.dimensions?.length > 0) {
      const defaultDimension = defaultKpi?.dimensions[0];
      setSelectedDimension(defaultDimension?.DimensionId);
      handleSelection(defaultKpi?.KpiName, defaultDimension);
    }
  };

  const handleSearch = (data) => {
    setFilteredData(data);

    if (data?.length > 0) {
      setOpenKpiId(data[0]?.KpiId);
    } else {
      setOpenKpiId(null);
    }
  };

  // Memoized debounced search function
  const debouncedSearch = useMemo(() => debounce(handleSearch, 300), []);

  const handleDimensionSelectLocal = (kpiName, dimension) => {
    setSelectedDimension(dimension?.DimensionId);
    handleSelection(kpiName, dimension, true);
    setShowComment(false);
    setCommentDetails(null);
    getPriorityComment();
  };

  const handleKpiSelect = (item) => {
    setSelectedKpi(item);
    setKpi(item);
    setOpenKpiId(item.KpiId);
    const firstDimension = item?.dimensions[0];
    setSelectedDimension(firstDimension?.DimensionId);
    handleSelection(item?.KpiName, firstDimension, true);
    setShowComment(false);
    setCommentDetails(null);
    getPriorityComment();
  };

  const isActiveKpi = (item) => item?.KpiId === selectedKpi?.KpiId;
  const isActiveDimension = (dimension) =>
    dimension?.DimensionId === selectedDimension;

  const memoizedFilteredData = useMemo(
    () => finalFilteredData,
    [finalFilteredData],
  );

  return (
    <ProSidebarProvider>
      <div className="kpi-sidebar">
        <Sidebar
          backgroundColor="var(--color-light-brown) !important"
          rootStyles={{
            color: 'var(--color-black)',
            fontFamily: 'var(--font-gene-sans)',
            height: '100%',
            fontSize: 'var(--font-size-small)',
            transition: 'all 0.5s ease-out',
          }}
        >
          <Menu
            renderExpandIcon={({ open }) => {
              return open ? (
                <i
                  className={`${classes['nav-link-icon']} icon icon__kpi-collapse`}
                ></i>
              ) : (
                <i
                  className={`${classes['nav-link-icon']} icon icon__kpi-expand`}
                ></i>
              );
            }}
          >
            <MenuItem
              key="searchBar"
              rootStyles={{
                ['.' + menuClasses.button]: {
                  backgroundColor: 'var(--color-light-brown) !important',
                  paddingRight: '10px !important',
                  fontWeight: 'bold !important',
                  transition: 'all 0.5s ease-out',
                },
              }}
            >
              <SearchBar
                data={myViewKpi}
                filterKey="KpiName"
                onSearch={debouncedSearch} // Use the debounced search function
              />
            </MenuItem>

            <div
              className={classes['library-container']}
              style={{ height: '500px' }}
            >
              {memoizedFilteredData?.map((item) =>
                item?.dimensions ? (
                  <SubMenu
                    open={openKpiId === item?.KpiId}
                    onClick={() => handleKpiSelect(item)}
                    label={
                      item?.KpiName === 'Business'
                        ? `${item?.KpiName} Plan`
                        : item?.KpiName
                    }
                    key={item.KpiId}
                    title={item?.kpiName}
                    rootStyles={{
                      ['& > .' + menuClasses.button]: {
                        paddingRight: '10px !important',
                        backgroundColor: 'var(--color-light-brown) !important',
                        fontWeight: 'bold !important',
                        '&:hover': {
                          backgroundColor:
                            'var(--color-tertiary-blue) !important',
                          color: 'var(--color-white) !important',
                          transition: 'all 0.5s ease-out',
                        },
                      },
                      ['.' + menuClasses.label]: {
                        textOverflow: 'initial',
                      },
                    }}
                  >
                    {item.dimensions.map((dimension) => (
                      <MenuItem
                        key={dimension.DimensionId}
                        title={dimension?.DimensionName}
                        onClick={() =>
                          handleDimensionSelectLocal(item?.KpiName, dimension)
                        }
                        active={
                          isActiveKpi(item) && isActiveDimension(dimension)
                        }
                        rootStyles={{
                          ['.' + menuClasses.button]: {
                            backgroundColor: 'var(--color-gray-2)',
                            '&:hover': {
                              backgroundColor:
                                'var(--color-tertiary-blue) !important',
                              color: 'var(--color-white) !important',
                              transition: 'all 0.5s ease-out',
                            },
                          },
                          ['.' + menuClasses.active]: {
                            backgroundColor:
                              'var(--color-tertiary-blue) !important',
                            color: 'var(--color-white) !important',
                            transition: 'all 0.5s ease-out',
                          },
                        }}
                      >
                        {dimension?.DimensionName?.length > 26 ? (
                          <Tooltip title={dimension?.DimensionName}>
                            {`${dimension?.DimensionName?.substring(0, 26)}...`}
                          </Tooltip>
                        ) : (
                          dimension?.DimensionName
                        )}
                      </MenuItem>
                    ))}
                  </SubMenu>
                ) : (
                  <MenuItem
                    active={isActiveKpi(item)}
                    onClick={() => setKpi(item)}
                    key={item.KpiId}
                    title={item.KpiId}
                    rootStyles={{
                      ['.' + menuClasses.button]: {
                        backgroundColor: 'var(--color-light-brown)',
                        paddingRight: '10px !important',
                        fontWeight: 'bold !important',
                        '&:hover': {
                          backgroundColor:
                            'var(--color-light-brown-2) !important',
                        },
                      },
                      ['.' + menuClasses.label]: {
                        fontWeight: 'bold !important',
                      },
                    }}
                  >
                    {item?.KpiName === 'Business'
                      ? `${item?.KpiName} Plan`
                      : item?.KpiName}
                  </MenuItem>
                ),
              )}
            </div>
          </Menu>
        </Sidebar>
      </div>
    </ProSidebarProvider>
  );
};

export default MyViewKpi;
