import { colorLabelSpan } from './colorLabelSpan';

export const shortTermBiasForecastBarChart = ({
  chartData,
  category,
  minMax,
  isViewExpanded,
  expand,
  fixedStart = 100,
  fixedEnd = 75,
  minDataSpan = 10,
  maxDataSpan = 10,
}) => ({
  tooltip: {
    show: true,
    formatter: (params) => {
      let tooltipContent = ' ';
      tooltipContent +=
        colorLabelSpan({ color: params?.color }) +
        params?.name +
        ' : ' +
        `${params?.value}%`;
      return tooltipContent;
    },
  },
  xAxis: [
    {
      type: 'category',
      data: category,
      axisLabel: {
        rotate: 90,
        color: '#000000',
      },
      axisTick: {
        show: false,
      },
      position: 'bottom',
    },
    {
      axisTick: {
        show: false,
      },
      position: 'bottom',
      offset: 0,
      show: true,
    },
  ],
  yAxis: {
    type: 'value',
    name: 'Forecast Bias (%)',
    nameLocation: 'middle',
    nameGap: 40,
    min: minMax?.min < 0 ? minMax?.min - 2 : 0, // Set minimum value
    max: minMax?.max + 2, // Set maximum value
    splitLine: {
      show: false,
    },
    nameTextStyle: {
      color: '#000000',
      fontSize: 13,
    },
    show: true,
    axisLine: {
      show: true,
    },
    axisTick: {
      show: false,
    },
    axisLabel: {
      formatter: (value) => {
        // Hide the min and max value labels
        if (
          value === (minMax?.min < 0 ? minMax?.min - 2 : 0) ||
          value === minMax?.max + 2
        ) {
          return ''; // Don't show label for min/max
        }
        return `${value}%`; // Show label for other values
      },
    },
  },
  grid: expand
    ? { left: '5%', right: '5%', bottom: '3%', containLabel: true }
    : {
        left: '10%',
        containLabel: true,
        bottom: '3%',
      },
  dataZoom: !isViewExpanded && [
    {
      type: 'slider',
      show: true,
      xAxisIndex: [0],
      start: fixedStart,
      end: fixedEnd,
      minValueSpan: minDataSpan,
      maxValueSpan: maxDataSpan,
      brushSelect: false,
      handleSize: '10%',
      height: 14,
      showDetail: false,
      backgroundColor: '#F1F1F1',
      borderRadius: 10,
      fillerColor: '#B5B5B5',
      borderColor: '#FFFFFF',
      z: 2,
      opacity: 1,
      bottom: -9,
    },
  ],
  series: [
    {
      data: chartData,
      type: 'bar',
      label: {
        color: 'inherit',
        // fontSize: 10,
        show: true,
        position: 'top',
        formatter: (params) => {
          return `${
            params?.data?.value > 0
              ? `+${params?.data?.value}%`
              : `${params?.data?.value}%`
          }`;
        },
      },
      labelLayout: {
        hideOverlap: true,
        moveOverlap: 'shiftX',
        fontSize: 8,
      },
    },
  ],
});
