import RadioGroup from '@components/elements/radio/RadioGroup';
import {
  CURSOPVSPREVSOP,
  SOPVSFINANCIALFORECAST,
  VALUE110,
  VALUE350,
  VIEW_MORE,
  VIEW_TYPE_FINANCIAL_FORECAST,
  VIEW_TYPE_PREVIOUS,
  YEAR_FORECAST_COMMENT_VIEW_TYPE,
} from '@constant';
import { GETCOMMENTCREATEDDATE } from '@utils/utils';
import classes from './CommentInViewTile.module.scss';
import { CARD_CONFIG } from '@utils/cardConfig';

const CommentInViewTile = ({
  showComment,
  comment,
  cardId,
  onSelectViewType,
  viewType,
  onExpandCommentDesc,
  sopPreviousOrFinanceHasComment,
}) => {
  return (
    <>
      {showComment || sopPreviousOrFinanceHasComment ? (
        <div className={classes['comment-section']}>
          {cardId === CARD_CONFIG?.SOP_YEAR_FORECAST_CHART?.ChartLabel && (
            <div className={classes['radio-wrap-container-sop']}>
              <div className={classes['radio-wrap-view']}>
                <RadioGroup
                  defaultValue={VIEW_TYPE_PREVIOUS}
                  items={YEAR_FORECAST_COMMENT_VIEW_TYPE}
                  onChange={onSelectViewType}
                  value={viewType}
                />
              </div>
            </div>
          )}

          {cardId === CARD_CONFIG?.SOP_YEAR_FORECAST_CHART?.ChartLabel &&
            (viewType === VIEW_TYPE_FINANCIAL_FORECAST ? (
              <div className={classes['sop-comment-header']}>
                {SOPVSFINANCIALFORECAST}
              </div>
            ) : (
              <div className={classes['sop-comment-header']}>
                {CURSOPVSPREVSOP}
              </div>
            ))}
          <div className={classes['comment-title']}>
            <div>
              {comment?.UserName}
              <span className={classes['comment-info']}>
                {GETCOMMENTCREATEDDATE(comment?.CreatedDate)}
              </span>
            </div>
          </div>
          <div className={classes['comment-desc']}>
            {comment?.Comment?.length > VALUE350 ||
            comment?.Comment?.length > VALUE110 ? (
              <div className={classes['comment-align']}>
                {`${comment?.Comment?.substring(
                  0,
                  cardId === CARD_CONFIG?.SOP_YEAR_FORECAST_CHART?.ChartLabel
                    ? VALUE350
                    : VALUE110,
                )}...`}
                <span
                  className={classes['comment-expand']}
                  onClick={onExpandCommentDesc}
                >
                  {VIEW_MORE}
                </span>
              </div>
            ) : (
              comment?.Comment
            )}
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default CommentInViewTile;
