// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ecKVbx7lqP5xEmP0Hnj4{padding:40px 0px 0px 60px;width:"100%";margin-top:-30px}.SX5I4Yk4Sk6Aney5V33I{font-weight:bold}.HdmrtbNtbE6vA6k0bcbH{display:flex;justify-content:end;margin-top:30px;font-size:var(--font-size-small)}`, "",{"version":3,"sources":["webpack://./src/components/performance/PerformanceContainer.module.scss"],"names":[],"mappings":"AAAA,sBACE,yBAAA,CACA,YAAA,CACA,gBAAA,CAGF,sBACE,gBAAA,CAGF,sBACE,YAAA,CACA,mBAAA,CACA,eAAA,CACA,gCAAA","sourcesContent":[".performance-container {\n  padding: 40px 0px 0px 60px;\n  width: \"100%\";\n  margin-top: -30px;\n}\n\n.title {\n  font-weight: bold;\n}\n\n.week-container {\n  display: flex;\n  justify-content: end;\n  margin-top: 30px;\n  font-size: var(--font-size-small);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"performance-container": `ecKVbx7lqP5xEmP0Hnj4`,
	"title": `SX5I4Yk4Sk6Aney5V33I`,
	"week-container": `HdmrtbNtbE6vA6k0bcbH`
};
export default ___CSS_LOADER_EXPORT___;
