import { createSlice } from '@reduxjs/toolkit';
import { initialBplanState } from './bplanInitialState';

const bplanSlice = createSlice({
  name: 'Business Plan View',
  initialState: initialBplanState,
  reducers: {
    setDataOptions: (state, { payload }) => {
      state.dataOptions = payload;
    },
    setEventOptions: (state, { payload }) => {
      state.eventOptions = payload;
    },
    setMetricOptions: (state, { payload }) => {
      state.metricOption = payload;
    },
    setAdjustedGridData: (state, { payload }) => {
      state.AdjustedGridData = payload;
    },
    setUnAdjustedGridData: (state, { payload }) => {
      state.UnAdjustedGridData = payload;
    },
    setQuarterOptions: (state, { payload }) => {
      state.quarterOptions = payload;
    },
  },
});

export const {
  setDataOptions,
  setEventOptions,
  setMetricOptions,
  setAdjustedGridData,
  setUnAdjustedGridData,
  setQuarterOptions,
} = bplanSlice.actions;

export default bplanSlice.reducer;
