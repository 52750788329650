import { Button, Modal } from 'antd';
import classes from './BusinessPlan.module.scss';
import { CLOSE, NarrationConstant } from '@constant';

const BussinessPlanComment = ({
  setIsExpandCommentModalOpen,
  isExpandCommentModalOpen,
  firstComment,
}) => {
  const handleCancel = () => {
    setIsExpandCommentModalOpen(false);
  };

  return (
    <>
      <Modal
        title={NarrationConstant?.businessPlanNarrative}
        open={isExpandCommentModalOpen}
        width={600}
        onCancel={handleCancel}
        footer={[
          <Button key="close" onClick={handleCancel} type="link">
            {CLOSE}
          </Button>,
        ]}
      >
        <div className={classes['modal-content']}>{firstComment}</div>
      </Modal>
    </>
  );
};

export default BussinessPlanComment;
