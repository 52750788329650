/* eslint-disable no-unused-vars */
import Chart from '@components/elements/chart/Chart';
import classes from '../components/performance/portfolio/eqouts/Eqouts.module.scss'; // Adjust the path
import { B_ORDER, CATEGORYUPPERCASE, T0, VREPLACESTRING } from '../constants';
import * as XLSX from 'xlsx';
import _ from 'lodash';

export const COLUMNS_EQOUTS = (selectedFilters, RENDER_CELL_PORTFOLIO) => {
  return [
    {
      title: '',
      children: [
        {
          title: <div className={classes['main-header']}>Squad</div>,
          dataIndex: 'title',
          key: 'title',
          fixed: 'left',
          minWidth: 100, // Adjust based on content size
        },
        {
          title: (
            <div className={classes['sub-header']}>
              <div>Brand</div>
            </div>
          ),
          dataIndex: 'title1',
          key: 'title1',
          fixed: 'left',
          minWidth: 120, // Adjust based on content size
          render: (text, record) =>
            RENDER_CELL_PORTFOLIO(text, { ...record, column: 'Brand' }),
        },
        {
          title: (
            <div className={classes['sub-header']}>
              <div>13-week OUTs</div>
            </div>
          ),
          dataIndex: 'week13outs',
          key: 'week1',
          width: '200px', // Adjusted for chart and text
          fixed: 'left',
          minWidth: '200px',
          render: (text, record) => (
            <div>
              {record?.week13outs?.data.length > 0 ? (
                <Chart
                  chartType={'linegriddata'}
                  chartData={record?.week13outs?.data}
                  category={record?.week13outs?.category}
                  legend={{ show: true }}
                  style={{ width: '100%', height: '25px' }} // Adjust height to fit grid
                  opts={{ renderer: 'svg' }}
                  notMerge={true} // Ensures updates when data changes
                />
              ) : (
                <div style={{ width: '100%', height: '25px' }}></div> // 🔹 Empty div instead of chart to keep spacing
              )}
            </div>
          ),
        },
      ],
    },
    {
      title: (
        <div style={{ fontWeight: 'bold', width: '100%' }}>
          <div>Weekly Average Equivalent OUTs (Units)</div>
          <hr className="header-line" />
        </div>
      ),
      children: [
        {
          title: (
            <div className={classes['sub-header']}>
              <div>Last Week Actuals</div>
            </div>
          ),
          dataIndex: 'lastweekactuals',
          key: 'lastweekactuals',
          width: '130px', // Adequate width for content
          render: (text, record) => RENDER_CELL_PORTFOLIO(text, record),
        },
        {
          title: (
            <div className={classes['sub-header']}>
              <div>1 Month (4 Wk) Avg.</div>
            </div>
          ),
          dataIndex: 'week4',
          key: 'week4',
          width: '150px', // Adequate width for content
          render: (text, record) => RENDER_CELL_PORTFOLIO(text, record),
        },
        {
          title: (
            <div className={classes['sub-header']}>
              <div>3 Month (12 Wk) Avg.</div>
            </div>
          ),
          dataIndex: 'week12',
          key: 'week12',
          width: '150px', // Adequate width for content
          render: (text, record) => RENDER_CELL_PORTFOLIO(text, record),
        },
        {
          title: (
            <div className={classes['sub-header']}>
              <div>6 Month (26 Wk) Avg.</div>
            </div>
          ),
          dataIndex: 'week26',
          key: 'week26',
          width: '150px', // Adequate width for content
          render: (text, record) => RENDER_CELL_PORTFOLIO(text, record),
        },
        {
          title: (
            <div className={classes['sub-header']}>
              <div>12 Month (52 Wk) Avg.</div>
            </div>
          ),
          dataIndex: 'week52',
          key: 'week52',
          width: '150px', // Adequate width for content
          render: (text, record) => RENDER_CELL_PORTFOLIO(text, record),
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: (
            <div className={classes['sub-header']}>
              <div>YTD OUTs/wk</div>
            </div>
          ),
          dataIndex: 'ytdoutswk',
          key: 'ytdoutswk',
          width: '150px', // Adequate width for content
          render: (text, record) => RENDER_CELL_PORTFOLIO(text, record),
        },
        {
          title: (
            <div className={classes['sub-header']}>
              <div>vs. PY YTD</div>
            </div>
          ),
          dataIndex: 'vspyytd',
          key: 'vspyytd',
          width: '120px', // Adjusted for content
          render: (text, record) => RENDER_CELL_PORTFOLIO(text, record),
        },
        {
          title: (
            <div className={classes['sub-header']}>
              <div>vs. {selectedFilters?.VersionType} YTD</div>
            </div>
          ),
          dataIndex: 'vssquadplanytd',
          key: 'vssquadplanytd',
          width: '150px', // Adequate width for content
          render: (text, record) => RENDER_CELL_PORTFOLIO(text, record),
        },
        {
          title: (
            <div className={classes['sub-header']}>
              <div>ROY OUTs/wk to {selectedFilters?.VersionType}</div>
            </div>
          ),
          dataIndex: 'royoutswkto',
          key: 'royoutswkto',
          width: '150px', // Adequate width for content
          render: (text, record) => RENDER_CELL_PORTFOLIO(text, record),
        },
      ],
    },
  ];
};

export const GETSECTIONSEXPRESSION2 = (data, CardId, ChartId, expression) => {
  //setIsLoading(true);
  const expressionData = data
    .filter((e) => e.CardId === CardId && e.ChartId === ChartId)
    .map((obj) =>
      JSON.stringify({
        CardName: obj.CardName,
        id: obj.KpiId,
        name: obj.KpiName,
        expression: obj.Expression.replaceAll(VREPLACESTRING, expression),
      }),
    );

  let dimension = [];
  const dimensionNames = new Set();

  data
    .filter((e) => e.CardId === CardId && e.ChartId === ChartId)
    .forEach((obj) => {
      if (obj.DimensionName && !dimensionNames.has(obj.DimensionName)) {
        dimensionNames.add(obj.DimensionName);
        dimension.push(
          JSON.stringify({
            dimensionType: CATEGORYUPPERCASE,
            dimensionName: obj.DimensionName,
          }),
        );
      }
    });
  //setIsLoading(false);
  return {
    expression: expressionData,
    dimension: dimension,
  };
};

export const GETSECTIONSEXPRESSIONVARIANCE2 = (
  data,
  CardId,
  ChartId,
  expressionVariance,
) => {
  //setIsLoading(true);
  const expressionData = data
    .filter((e) => e.CardId === CardId && e.ChartId === ChartId)
    .map((obj) =>
      JSON.stringify({
        CardName: obj.CardName,
        id: obj.KpiId,
        name: obj.KpiName,
        expression: obj.Expression.replaceAll(
          VREPLACESTRING,
          expressionVariance,
        ),
      }),
    );

  let dimension = [];
  const dimensionNames = new Set();

  data
    .filter((e) => e.CardId === CardId && e.ChartId === ChartId)
    .forEach((obj) => {
      if (obj.DimensionName && !dimensionNames.has(obj.DimensionName)) {
        dimensionNames.add(obj.DimensionName);
        dimension.push(
          JSON.stringify({
            dimensionType: CATEGORYUPPERCASE,
            dimensionName: obj.DimensionName,
          }),
        );
      }
    });
  //setIsLoading(false);
  return {
    expression: expressionData,
    dimension: dimension,
  };
};
// working
export function GETSECTIONSEXPRESSION(data, CardId, ChartId, expression) {
  const expressionData = data
    .filter((e) => e.CardId === CardId && e.ChartId === ChartId)
    .sort((a, b) => a?.KpiId - b?.KpiId)
    .map((obj) =>
      JSON.stringify({
        CardName: obj.CardName,
        id: obj.KpiId,
        name: obj.KpiName,
        expression: obj.Expression.replaceAll(VREPLACESTRING, expression),
      }),
    );

  let dimension = [];
  const dimensionNames = new Set();

  data
    .filter((e) => e.CardId === CardId && e.ChartId === ChartId)
    .forEach((obj) => {
      if (obj.DimensionName && !dimensionNames.has(obj.DimensionName)) {
        dimensionNames.add(obj.DimensionName);
        const dimensionName = obj.DimensionName?.split(';');
        dimensionName?.map((item) => {
          const dim = {
            dimensionType: 'Category',
            dimensionName: item,
          };
          dimension.push(JSON.stringify(dim));
        });
      }
    });
  return {
    expression: expressionData,
    dimension: dimension,
  };
}

export function GETSECTIONSEXPRESSIONGRID(
  data,
  CardId,
  ChartId,
  expression,
  label,
) {
  const expressionData = data
    .filter((e) => e.CardId === CardId && e.ChartId === ChartId)
    .sort((a, b) => a?.KpiId - b?.KpiId)
    .map((obj) => {
      // If label is empty, process as before
      if (!label || label.length === 0) {
        return JSON.stringify({
          CardName: obj.CardName,
          id: obj.KpiId,
          name: obj.KpiName,
          expression: obj.Expression.replaceAll(VREPLACESTRING, expression),
        });
      }

      // Find the matching label for the current obj
      let expObj = expression?.split(',')?.reduce((obj, item) => {
        const [key, value] = item?.split('=');
        obj[key?.trim()] = value
          ?.replace(/["{}]/g, '')
          ?.replace(/"/g, '')
          ?.trim();
        return obj;
      }, {});
      const matchingLabel = label.find((lbl) => lbl.id === obj.KpiId);

      // Replace KpiName with label.data if there's a match
      let kpiName =
        matchingLabel && matchingLabel?.data !== '-'
          ? matchingLabel.data
          : obj.KpiName;

      if (kpiName?.includes('T0')) {
        kpiName = kpiName?.replace(/T0/g, expObj?.Version_Type?.toString());
      }

      return JSON.stringify({
        CardName: obj.CardName,
        id: obj.KpiId,
        name: kpiName,
        expression: obj.Expression.replaceAll(VREPLACESTRING, expression),
      });
    });

  let dimension = [];
  const dimensionNames = new Set();

  data
    .filter((e) => e.CardId === CardId && e.ChartId === ChartId)
    .forEach((obj) => {
      if (obj.DimensionName && !dimensionNames.has(obj.DimensionName)) {
        dimensionNames.add(obj.DimensionName);
        const dimensionName = obj.DimensionName?.split(';');
        dimensionName?.map((item) => {
          const dim = {
            dimensionType: 'Category',
            dimensionName: item,
          };
          dimension.push(JSON.stringify(dim));
        });
      }
    });

  return {
    expression: expressionData,
    dimension: dimension,
  };
}

export function GETLABELEXPRESSION(data, CardId, ChartId, expression) {
  const expressionData = data
    .filter((e) => e.CardId === CardId && e.ChartId === ChartId)
    .sort((a, b) => a?.KpiId - b?.KpiId)
    .map((obj) =>
      JSON.stringify({
        CardName: obj.CardName,
        id: obj.KpiId,
        name: obj.KpiName,
        expression: obj.KpiName,
      }),
    );

  let dimension = [];
  return {
    expression: expressionData,
    dimension: dimension,
  };
}

export const GETSECTIONSEXPRESSIONEXPORTEQ = (
  data,
  CardId,
  ChartId,
  expression,
) => {
  const expressionData = data
    ?.filter((e) => {
      // Filter based on CardId, ChartId, and additional KpiId logic
      const isMatchingCardAndChart =
        e.CardId === CardId && e.ChartId === ChartId;

      if (!isMatchingCardAndChart) return false;

      const expObj = expression?.split(',')?.reduce((obj, item) => {
        const [key, value] = item?.split('=');
        obj[key?.trim()] = value
          ?.replace(/["{}]/g, '')
          ?.replace(/"/g, '')
          ?.trim();
        return obj;
      }, {});

      // Exclude KpiId based on Version_Type
      if (expObj?.Version_Type === "'Squad Plan'" && e.KpiId === 11) {
        return false; // Exclude KpiId 11 for Squad Plan
      }
      if (expObj?.Version_Type === "'T0'" && e.KpiId === 10) {
        return false; // Exclude KpiId 10 for T0
      }

      return true; // Keep the object
    })
    ?.map((obj) => {
      let expObj = expression?.split(',')?.reduce((obj, item) => {
        const [key, value] = item?.split('=');
        obj[key?.trim()] = value
          ?.replace(/["{}]/g, '')
          ?.replace(/"/g, '')
          ?.trim();
        return obj;
      }, {});

      let kpiname =
        obj?.KpiName?.indexOf(T0) !== -1
          ? obj?.KpiName?.replace(
              /T0/g,
              expObj?.Version_Type?.toString().replace(/['"]/g, ''),
            )
          : obj.KpiName.replace(/['"]/g, '');

      return {
        CardName: obj?.CardName,
        id: obj?.KpiId,
        name: kpiname,
        expression: obj?.Expression?.replaceAll(VREPLACESTRING, expression),
      };
    });

  let dimension = [];
  const dimensionNames = new Set();

  data
    ?.filter((e) => e?.CardId === CardId && e?.ChartId === ChartId)
    ?.forEach((obj) => {
      if (obj?.DimensionName && !dimensionNames?.has(obj.DimensionName)) {
        dimensionNames?.add(obj?.DimensionName);
        const dimensionName = obj?.DimensionName?.split(';');
        dimensionName?.forEach((item) => {
          const dim = {
            dimensionType: CATEGORYUPPERCASE,
            dimensionName: item,
          };
          if (item !== 'WeeklyReportLinks') {
            dimension?.push(dim);
          }
        });
      }
    });

  return {
    expression: expressionData,
    dimension: dimension,
  };
};
export const GETSECTIONSEXPRESSIONEXPORT = (
  data,
  CardId,
  ChartId,
  expression,
  label,
) => {
  // console.log("expression: ", expression.split(','), typeof(expression))
  const expressionData = data
    ?.filter((e) => e.CardId === CardId && e.ChartId === ChartId)
    ?.sort((a, b) => a?.KpiId - b?.KpiId)
    ?.map((obj) => {
      // If label is empty, process as before
      if (!label || label.length === 0) {
        let expObj = expression?.split(',')?.reduce((obj, item) => {
          const [key, value] = item?.split('=');
          obj[key?.trim()] = value
            ?.replace(/["{}]/g, '')
            ?.replace(/"/g, '')
            ?.trim();
          return obj;
        }, {});

        let kpiname =
          obj?.KpiName?.indexOf('T0') !== -1
            ? obj?.KpiName?.replace(/T0/g, expObj?.Version_Type?.toString())
            : obj.KpiName;

        return {
          CardName: obj?.CardName,
          id: obj?.KpiId,
          name: kpiname,
          expression: obj?.Expression?.replaceAll('vReplaceString', expression),
        };
      }

      // Process with label if available
      const matchingLabel = label.find((lbl) => lbl.id === obj.KpiId);

      let expObj = expression?.split(',')?.reduce((obj, item) => {
        const [key, value] = item?.split('=');
        obj[key?.trim()] = value
          ?.replace(/["{}]/g, '')
          ?.replace(/"/g, '')
          ?.trim();
        return obj;
      }, {});

      let kpiname =
        matchingLabel && matchingLabel?.data !== '-'
          ? matchingLabel?.data
          : obj?.KpiName;

      // Step 2: Replace 'T0' in the updated kpiname if it exists
      if (kpiname?.includes('T0')) {
        kpiname = kpiname?.replace(/T0/g, expObj?.Version_Type?.toString());
      }

      return {
        CardName: obj?.CardName,
        id: obj?.KpiId,
        name: kpiname,
        expression: obj?.Expression?.replaceAll('vReplaceString', expression),
      };
    });

  let dimension = [];
  // eslint-disable-next-line no-undef
  const dimensionNames = new Set();

  data
    ?.filter((e) => e?.CardId === CardId && e?.ChartId === ChartId)
    ?.forEach((obj) => {
      if (obj?.DimensionName && !dimensionNames?.has(obj.DimensionName)) {
        dimensionNames?.add(obj?.DimensionName);
        const dimensionName = obj?.DimensionName?.split(';');
        dimensionName?.map((item) => {
          const dim = {
            dimensionType: 'Category',
            dimensionName: item,
          };
          if (item !== 'B_Order' || item !== 'WeeklyReportLinks') {
            dimension?.push(dim);
          }
        });
      }
    });

  return {
    expression: expressionData,
    dimension: dimension,
  };
};
export const GETSECTIONSEXPRESSIONVARIANCE = (
  data,
  CardId,
  ChartId,
  expressionVariance,
) => {
  const expressionData = data
    .filter((e) => e.CardId === CardId && e.ChartId === ChartId)
    .sort((a, b) => a?.KpiId - b?.KpiId)
    .map((obj) =>
      JSON.stringify({
        CardName: obj.CardName,
        id: obj.KpiId,
        name: obj.KpiName,
        expression: obj.Expression.replaceAll(
          VREPLACESTRING,
          expressionVariance,
        ),
      }),
    );

  let dimension = [];
  const dimensionNames = new Set();

  data
    .filter((e) => e.CardId === CardId && e.ChartId === ChartId)
    .forEach((obj) => {
      if (obj.DimensionName && !dimensionNames.has(obj.DimensionName)) {
        dimensionNames.add(obj.DimensionName);
        dimension.push(
          JSON.stringify({
            dimensionType: CATEGORYUPPERCASE,
            dimensionName: obj.DimensionName,
          }),
        );
      }
    });
  return {
    expression: expressionData,
    dimension: dimension,
  };
};

export const GETCOMMENTCREATEDDATE = (commentDate) => {
  let formattedDate = '';
  if (commentDate) {
    const date = new Date(commentDate);

    if (!isNaN(date)) {
      const day = String(date.getUTCDate()).padStart(2, '0');

      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const year = date.getUTCFullYear();

      formattedDate = `(${month}/${day}/${year})`;
    }
  }
  return <span style={{ fontSize: '0.6em' }}>{formattedDate}</span>;
};

export function deepEqualIgnoreArrayOrder(a, b) {
  // If both are the same value (or both null/undefined)
  if (a === b) return true;

  // If either is not an object or is null, return false
  if (
    typeof a !== 'object' ||
    typeof b !== 'object' ||
    a === null ||
    b === null
  ) {
    return false;
  }

  // If one of them is an array, compare it by sorting both arrays
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) return false;
    // Sort arrays to ignore order
    let as = [...a].sort();
    let bs = [...b].sort();
    for (let i = 0; i < a.length; i++) {
      if (!deepEqualIgnoreArrayOrder(as[i], bs[i])) {
        return false;
      }
    }
    return true;
  }

  // Get the keys of both objects
  let keysA = Object.keys(a);
  let keysB = Object.keys(b);

  // If the number of keys is different, objects are not equal
  if (keysA.length !== keysB.length) {
    return false;
  }

  // Check all keys and their corresponding values
  for (let key of keysA) {
    // If key doesn't exist in object B, return false
    if (!keysB.includes(key)) {
      return false;
    }

    // If the value of the key is an object or array, recurse
    if (typeof a[key] === 'object' && typeof b[key] === 'object') {
      if (!deepEqualIgnoreArrayOrder(a[key], b[key])) {
        return false;
      }
    } else {
      // For primitive values, do a direct comparison
      if (a[key] !== b[key]) {
        return false;
      }
    }
  }

  return true;
}

export const extractMeasureSOP = (input) => {
  // Regular expression to match '[Measure S&OP]=' and extract the value inside the curly braces
  const regex = /\[Measure S&OP\]=\{['"]([^'"]+)['"]\}/;

  // Match the input string against the regex
  const match = input?.match(regex);

  // If a match is found, return the extracted value
  if (match) {
    return match[1]; // The value inside the quotes is captured in match[1]
  } else {
    return ''; // Return a default message if not found
  }
};

export const extractMeasureEventType = (input) => {
  // Regular expression to extract the value of `EventType`
  const eventTypeMatch = input.match(/EventType=\{([^}]+)\}/);

  if (eventTypeMatch) {
    // Extract the EventType values and replace commas with spaces
    const eventType = eventTypeMatch[1];
    return eventType;
  } else {
    return '';
  }
};

export const extractInfoDetails = (arr, idsToFilter) => {
  let infoText = {};
  if (arr?.length > 0 && idsToFilter?.length > 0) {
    infoText = arr
      .filter((item) => idsToFilter.includes(item.CardId))
      .reduce((acc, item) => {
        acc[item.CardId] = item?.Info_Text;
        return acc;
      }, {});
  }
  return infoText;
};

export const extractBPBusinessUnit = (inputString) => {
  // Use a regular expression to find the value for BP Business Unit
  const regex = /\[BP Business Unit\]\s*=\s*{([^}]+)}/;
  const match = inputString.match(regex);

  if (match && match[1]) {
    // Return the BP Business Unit value (e.g., "Portfolio")
    return match[1].trim();
  }

  // Return null if BP Business Unit value is not found
  return null;
};

export const REPLACE_KEYS = (
  dataArray,
  keyMap,
  keysToRemove = [],
  selectedFilters = {},
) => {
  return dataArray.map((obj) => {
    let newObj = {};
    for (let key in obj) {
      if (!keysToRemove.includes(key)) {
        let mappedKey = keyMap[key] || key;
        if (
          typeof mappedKey === 'string' &&
          mappedKey.includes('{selectedFilters?.VersionType}')
        ) {
          mappedKey = mappedKey.replace(
            '{selectedFilters?.VersionType}',
            selectedFilters.VersionType || 'Default',
          );
        }
        newObj[mappedKey] = obj[key];
      }
    }
    return newObj;
  });
};
export const generateAOA = (dataArray) => {
  const keyAlterArray = {
    brand: 'Brand',
    Sku: 'SKU',
    comment: 'Comment',
  };
  // Collect all keys from every object
  const keySet = new Set();
  dataArray.forEach((obj) => {
    Object.keys(obj).forEach((key) => keySet.add(key));
  });
  const allKeys = Array.from(keySet);

  // Separate non-numeric keys and numeric keys.
  // We use a regular expression to check if a key consists entirely of digits.
  const nonNumericKeys = allKeys
    .filter((key) => !/^\d+$/.test(key))
    .filter((key) => key !== 'comment');
  const numericKeys = allKeys.filter((key) => /^\d+$/.test(key));
  const commentKey = allKeys.filter((key) => key === 'comment');
  // Ordered header: non-numeric keys first, then numeric keys.
  const header = [...nonNumericKeys, ...numericKeys, ...commentKey];

  // Build the AOA: the first row is the header
  const aoa = [header];

  // For each object, build a row following the header order.
  dataArray.forEach((obj) => {
    const row = header.map((key) => (obj[key] !== undefined ? obj[key] : ''));
    aoa.push(row);
  });
  const updateHeaderCaseSensitive = aoa[0].map(
    (item) => keyAlterArray[item] ?? item,
  );
  aoa[0] = updateHeaderCaseSensitive;
  return aoa;
};

export const EXPORT_EXCEL = (data, fileName) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  XLSX.writeFileXLSX(workbook, `${fileName}.xlsx`);
};
export const EXPORT_EXCEL_AOA = (data, fileName) => {
  /**
   * Create a worksheet from the array-of-arrays, then create a workbook,
   * append the worksheet, and finally export it as an XLSX file.
   */
  const worksheet = XLSX.utils.aoa_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Write the file (this will trigger a download in a browser or create a file in Node.js)
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

export const getUniqueInfoTextString = (objects, cardId) => {
  const uniqueInfoText = new Set();

  objects?.forEach((obj) => {
    if (obj.CardId === cardId) {
      uniqueInfoText.add(obj?.Info_Text);
    }
  });
  return [...uniqueInfoText].join(', '); // Convert Set to a string
};

export const generateAOABusinessPlan = (dataArray) => {
  const keyAlterArray = {
    brand: 'Brand',
    diseasearea: 'Disease Area',
    launch: 'Launch',
    pol: 'Pol%',
  };

  // Collect all keys from every object
  const keySet = new Set();
  dataArray.forEach((obj) => {
    Object.keys(obj).forEach((key) => keySet.add(key));
  });
  const allKeys = Array.from(keySet);

  // Separate non-numeric keys and numeric keys.
  // We use a regular expression to check if a key consists entirely of digits.
  const nonNumericKeys = allKeys
    .filter((key) => !/^\d+$/.test(key))
    .filter((key) => key !== 'bporder');
  const numericKeys = allKeys.filter((key) => /^\d+$/.test(key));
  // Ordered header: non-numeric keys first, then numeric keys.
  const header = [...nonNumericKeys, ...numericKeys];

  // Build the AOA: the first row is the header
  const aoa = [header];

  // For each object, build a row following the header order.
  dataArray.forEach((obj) => {
    const row = header.map((key) => (obj[key] !== undefined ? obj[key] : ''));
    aoa.push(row);
  });
  const updateHeaderCaseSensitive = aoa[0].map(
    (item) => keyAlterArray[item] ?? item,
  );
  aoa[0] = updateHeaderCaseSensitive;
  return aoa;
};

export const MAP_KPI_CURRENT_YEAR = (data) => {
  let result = [];
  let dataMap = {};

  if (data.length === 0) {
    return [];
  }

  // Create a mapping of name to data array
  data?.forEach((item) => {
    dataMap[item.name] = item?.data;
  });

  let length = dataMap['Indication'].length;
  let quarterYearKey = Object.keys(dataMap)?.find((key) =>
    key.includes("Quarter'Year"),
  );
  let quarterYears = dataMap[quarterYearKey];

  let groupedResults = {};

  for (let i = 0; i < length; i++) {
    let key = `${dataMap['Indication'][i] || '-'}_${
      dataMap['KPI Name'][i] || ''
    }_${dataMap['Units'][i] || ''}`;

    if (!groupedResults[key]) {
      groupedResults[key] = {
        Indication: dataMap['Indication'][i] || '-',
        'KPI Name': dataMap['KPI Name'][i] || '',
        Units: dataMap['Units'][i] || '',
      };
    }

    let quarter = quarterYears[i];

    Object.keys(dataMap)?.forEach((metricKey) => {
      if (
        !['Indication', 'KPI Name', 'Units', quarterYearKey].includes(metricKey)
      ) {
        groupedResults[key][`${quarter} ${metricKey}`] =
          dataMap[metricKey][i] || '';
      }
    });
  }

  result = Object.values(groupedResults);
  return result;
};

export const MERGE_KPI_ROWS = (array1, array2) => {
  const mergedData = {};

  // Helper function to generate a unique key for matching
  function generateKey(item) {
    return `${item.Indication}_${item['KPI Name']}_${item.Units}`;
  }

  // Process second array (historical data) first to ensure order
  array2.forEach((item) => {
    const key = generateKey(item);
    mergedData[key] = { ...item }; // Clone to avoid mutation
  });

  // Process first array (current data)
  array1.forEach((item) => {
    const key = generateKey(item);
    if (mergedData[key]) {
      Object.assign(mergedData[key], item); // Merge data if exists
    } else {
      mergedData[key] = { ...item }; // Otherwise, add new entry
    }
  });

  // Convert merged data back to an array
  return Object.values(mergedData);
};

export const SORTED_EXCEL_EXPORT = (data, fileName, versionType) => {
  // Sort data by B_Order but exclude B_Order from export
  const sortedData = data
    ?.sort((a, b) => parseInt(a?.B_Order, 10) - parseInt(b?.B_Order, 10))
    ?.map(({ B_Order, WeeklyReportLinks, ...rest }) => rest); // Remove B_Order

  let worksheet = XLSX.utils.json_to_sheet(sortedData);

  // Rename column "T0" to versionType
  const range = XLSX.utils.decode_range(worksheet['!ref']);
  for (let C = range.s.c; C <= range.e.c; ++C) {
    const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
    if (worksheet[cellAddress]?.v === 'T0') {
      worksheet[cellAddress].v = versionType; // Replace column name
    }
  }

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  XLSX.writeFileXLSX(workbook, `${fileName}.xlsx`);
};

export function removeNegativeZero(data) {
  return _.forEach(_.cloneDeep(data), (obj) => {
    _.forOwn(obj, (value, key) => {
      if (value === '-0') {
        obj[key] = '0';
      }
    });
  });
}
