import { colorLabelSpan } from './colorLabelSpan';

export const yoylineChartOptions = ({
  chartData,

  showEndLabel,

  category,

  expand,
}) => ({
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
    formatter: function (params) {
      let tooltipContent = `Week : ${params[0]?.axisValue}<br/>`; // Add week info at the start
      params?.forEach((param) => {
        const color = chartData?.filter((item) => {
          return item?.name === param?.seriesName;
        })[0]?.lineStyle?.color;
        const value =
          param?.value === null ||
          param?.value === undefined ||
          param?.value === '-'
            ? '-'
            : param?.value;
        const data =
          colorLabelSpan({ color }) +
          ' ' +
          param?.seriesName +
          ' : ' +
          (value === '-' ? '-' : `${value}K`) +
          '<br/>';
        tooltipContent += data;
      });
      return tooltipContent;
    },
  },
  xAxis: {
    type: 'category',
    boundaryGap: true,
    data: category, // Original weekly data
    axisLabel: {
      show: true,
      color: '#000000',
      fontSize: 12,
      interval: 0, // Ensure labels are spaced correctly
      formatter: function (index) {
        const totalWeeks = category?.length;
        const halfWeeks = Math.floor(totalWeeks / 2);
        if (Number(index) === Math.floor(halfWeeks / 2)) {
          return 'H1';
        } else if (Number(index) === Math.floor(halfWeeks + halfWeeks / 2)) {
          return 'H2';
        } else {
          return ''; // Hide other labels
        }
      },
    },
    axisTick: {
      show: false,
    },
    axisLine: {
      show: true,
    },
  },
  yAxis: {
    type: 'value',
    name: 'K units',
    nameLocation: 'middle',
    nameGap: 55,
    splitLine: {
      show: false,
    },
    nameTextStyle: {
      color: '#000000',
      fontSize: 13,
    },
    axisLine: { show: true },
  },
  grid: expand
    ? {
        left: '5%',
        right: '0%',
        bottom: '15%',
        top: '10%',
        containLabel: true,
      }
    : {
        left: '15%',
        right: '10%',
        bottom: '15%',
        top: '10%',
        containLabel: true,
      },
  series: chartData?.map((item) => {
    return {
      ...item,
      showSymbol: false,
      lineStyle: {
        ...item?.lineStyle,
        width: 3,
      },
      type: 'line',
      endLabel: {
        show: showEndLabel,
        formatter: item?.name,
        color: item?.lineStyle?.color,
        align: 'right',
        verticalAlign: 'bottom',
        offset: [25, 15],
        padding: [0, 15, 0, 0],
      },
      labelLayout: {
        hideOverlap: true,
        moveOverlap: 'shiftX',
      },
      markLine: {
        symbol: 'none',
        data: [
          {
            xAxis: Math.floor(category?.length / 2), // Adjust the midpoint
            lineStyle: {
              type: 'dashed', // Dotted line
              color: '#999',
              width: 1,
            },
            label: {
              show: false, // Hide the label on the dashed line
            },
          },
        ],
        silent: true,
      },
    };
  }),
});
