import { Radio } from 'antd';

const RadioGroup = ({ onChange, defaultValue, items, style, value }) => {
  const { Group, Button } = Radio;
  return (
    <Group
      onChange={onChange}
      defaultValue={defaultValue}
      style={style}
      value={value}
    >
      {items?.map((item, index) => (
        <Button value={item?.value} key={index}>
          {item?.key}
        </Button>
      ))}
    </Group>
  );
};

export default RadioGroup;
