import TextArea from 'antd/es/input/TextArea';
import DialogBox from '@elements/dialogbox/DialogBox';
import { Button } from 'antd';
import {
  ADD_COMMENT_MODAL_HEADER,
  DELETE_BUTTON_TEXT,
  DELETE_COMMENT,
  DELETE_CONFIRMATION_TEXT,
  EDIT_COMMENT_MODAL_HEADER,
  SUBMIT,
  COMMENTLIMIT,
  COMMENTLIMITERROR,
  COMMNETMAXLENGTH,
  COMMENTAUTORESIZE,
  CANCELTEXT,
  SUBMITTEXT,
} from '@constant';
import classes from './BusinessSummary.module.scss';

const BusinessSummaryComment = ({
  isDeleteModalOpen,
  handleCancel,
  handleDeleteComment,
  isAddModalOpen,
  newComment,
  setNewComment,
  setError,
  error,
  isEditModalOpen,
  handleAddComment,
  commentDesc,
  setCommentDesc,
  handleEditComment,
  setCommentError,
  commentError,
}) => {
  const onChangeHandle = (value) => {
    if (value.length > COMMENTLIMIT) setCommentError(true);
    else setCommentError(false);
  };

  return (
    <>
      <DialogBox
        title={
          <span className={classes['comment-modal-header']}>
            <i className={'icon icon__delete_black'}></i>
            {DELETE_COMMENT}
          </span>
        }
        isModalOpen={isDeleteModalOpen}
        okText={DELETE_BUTTON_TEXT}
        handleCancel={handleCancel}
        handleOk={handleDeleteComment}
        width={370}
      >
        <div className={classes['modal-content']}>
          {DELETE_CONFIRMATION_TEXT}
        </div>
      </DialogBox>
      <DialogBox
        title={
          <span className={classes['comment-modal-header']}>
            <i className={'icon icon__add_black'}></i>
            {ADD_COMMENT_MODAL_HEADER}
          </span>
        }
        isModalOpen={isAddModalOpen}
        okText={SUBMIT}
        handleCancel={handleCancel}
        handleOk={handleAddComment}
        width={600}
        footer={[
          <Button key="{CANCELTEXT}" onClick={handleCancel}>
            {CANCELTEXT}
          </Button>,
          <Button
            key="{SUBMITTEXT}"
            disabled={newComment?.length > COMMENTLIMIT}
            type="primary"
            onClick={handleAddComment}
          >
            {SUBMITTEXT}
          </Button>,
        ]}
      >
        <div className={classes['modal-content']}>
          <TextArea
            value={newComment}
            onChange={(e) => {
              setNewComment(e?.target?.value);
              onChangeHandle(e?.target?.value);
              setError(false);
            }}
            rows={4}
            className={classes['text-area']}
            maxLength={COMMNETMAXLENGTH}
            autoSize={COMMENTAUTORESIZE}
          />
          <p className={classes['text-align-end']}>
            Remaining characters: {COMMENTLIMIT - newComment?.length}
          </p>
          {error && <div className={classes['error-message']}>{error}</div>}
          {commentError ? (
            <span className={classes['error-message']}>
              {COMMENTLIMITERROR}
            </span>
          ) : (
            ''
          )}
        </div>
      </DialogBox>
      <DialogBox
        title={
          <span className={classes['comment-modal-header']}>
            <i className={'icon icon__edit_black'}></i>
            {EDIT_COMMENT_MODAL_HEADER}
          </span>
        }
        isModalOpen={isEditModalOpen}
        okText={SUBMIT}
        handleCancel={handleCancel}
        handleOk={handleEditComment}
        width={600}
        footer={[
          <Button key="{CANCELTEXT}" onClick={handleCancel}>
            {CANCELTEXT}
          </Button>,
          <Button
            key="{SUBMITTEXT}"
            disabled={commentDesc?.length > COMMENTLIMIT}
            type="primary"
            onClick={handleEditComment}
          >
            {SUBMITTEXT}
          </Button>,
        ]}
      >
        <div className={classes['modal-content']}>
          <TextArea
            value={commentDesc}
            onChange={(e) => {
              setCommentDesc(e?.target?.value);
              onChangeHandle(e?.target?.value);
            }}
            rows={4}
            className={classes['text-area']}
            maxLength={COMMNETMAXLENGTH}
            autoSize={COMMENTAUTORESIZE}
          />
          <p className={classes['text-align-end']}>
            Remaining characters: {COMMENTLIMIT - commentDesc?.length}
          </p>
          {error && <div className={classes['error-message']}>{error}</div>}
          {commentError ? (
            <span className={classes['error-message']}>
              {COMMENTLIMITERROR}
            </span>
          ) : (
            ''
          )}
        </div>
      </DialogBox>
    </>
  );
};

export default BusinessSummaryComment;
