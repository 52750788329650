import { Skeleton } from 'antd';
import { getUserUnixId } from '@utils/getUser';
import classes from './BusinessPlanBanner.module.scss';
import NarrationComment from '../Modal/NarrationComment';
import { NarrationConstant } from '@constant';
import { useState } from 'react';
import InfoDialog from '../Modal/InfoDialog';

const Banner = ({
  bannerName,
  bannerContent,
  isCollapsed,
  onCollapseBanner,
  onConfigureComment,
  commentsData,
  isLoading,
  isModalVisible,
  handleCommentClose,
  infoMessages,
}) => {
  const unixId = getUserUnixId();
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);

  const handleInfoModalClose = () => {
    setIsInfoModalVisible(false);
  };

  const handleInfoModalOpen = () => {
    setIsInfoModalVisible(true);
  };
  return (
    <div
      className={classes['banner-container-wrap']}
      style={isCollapsed ? { height: '12vh' } : {}}
    >
      <Skeleton loading={isLoading} active>
        <div className={classes['banner-container']}>
          <div className={`d-flex ${classes['banner']}`}>
            {bannerName}
            <i
              className={`icon icon__info__gray ${classes['icon-align']}`}
              onClick={handleInfoModalOpen}
            ></i>
          </div>
          {bannerContent}
        </div>
        <span onClick={() => onCollapseBanner()}>
          {isCollapsed ? (
            <i className={`icon icon__add ${classes['icon-add']}`}></i>
          ) : (
            <i className={`icon icon__minimise ${classes['icon-min']}`}></i>
          )}
        </span>
        {/* 
        
        Added a new component Narration Comment to show only naration comment 
        */}
        <NarrationComment
          visible={isModalVisible}
          onClose={handleCommentClose}
          commentsData={commentsData}
          currentUser={unixId}
          onConfigureComment={onConfigureComment}
          title={NarrationConstant.businessPlanNarratives}
        />
        <InfoDialog
          isVisible={isInfoModalVisible}
          onClose={handleInfoModalClose}
          message={infoMessages}
        />
      </Skeleton>
    </div>
  );
};

export default Banner;
