import { GETLABELCOLOR } from '../utils';

export const ytdGridData = [
  {
    key: 1,
    metric: 'Price',
    sales: 11,
  },
  {
    key: 2,
    metric: 'Reserve',
    sales: 5,
  },
  {
    key: 3,
    metric: 'Dist Inv',
    sales: 15,
  },
  {
    key: 4,
    metric: 'Rev Days',
    sales: 53,
  },
  {
    key: 5,
    metric: 'Demand',
    sales: 149,
  },
];

export const ytdVarianceGridData = [
  {
    key: 1,
    metric: 'Price',
    sales: 11,
  },
  {
    key: 2,
    metric: 'Reserve',
    sales: 5,
  },
  {
    key: 3,
    metric: 'Dist Inv',
    sales: 15,
  },
  {
    key: 4,
    metric: 'Rev Days',
    sales: 53,
  },
  {
    key: 5,
    metric: 'Demand',
    sales: 149,
  },
];

export const ytdSalesvsForecastGridData = [
  {
    key: 1,
    metric: 'Actuals',
    sales: `$${8064}`,
    growth: 7,
  },
  {
    key: 2,
    metric: 'T0',
    sales: `$${7855}`,
    growth: 2.3,
  },
];

export const ytdSalesvsPyGridData = [
  {
    key: 1,
    metric: 'Actuals',
    sales: `$${8064}`,
    growth: 5,
  },
  {
    key: 2,
    metric: 'PY YTD',
    sales: `$${7571}`,
    growth: 2.3,
  },
];

export const ytdyoyGridData = [
  {
    key: 1,
    metric: 'Price',
    sales: 340,
  },
  {
    key: 2,
    metric: 'Reserve',

    sales: -100,
  },
  {
    key: 3,
    metric: 'Dist Inv',

    sales: 205,
  },
  {
    key: 4,
    metric: 'Rev Days',

    sales: 117,
  },
  {
    key: 5,
    metric: 'Demand',

    sales: 214,
  },
];

export const yoyGridData = [
  {
    key: 1,
    metric: '01/09/23',
    t0: 210,
    '4week': 220,
    '12week': 300,
    trend: 410,

    sales: 340,
  },
  {
    key: 2,
    metric: '01/16/23',
    t0: 210,
    '4week': 220,
    '12week': 300,
    trend: 410,
    sales: -100,
  },
  {
    key: 3,
    metric: '01/23/23',
    t0: 210,
    '4week': 220,
    '12week': 300,
    trend: 410,
    sales: 205,
  },
  {
    key: 4,
    metric: '01/30/23',
    t0: 210,
    '4week': 220,
    '12week': 300,
    trend: 410,
    sales: 117,
  },
  {
    key: 5,
    metric: '02/06/23',
    t0: 210,
    '4week': 220,
    '12week': 300,
    trend: 410,
    sales: 214,
  },
];

export const ytdDemadGridData = [
  {
    key: 1,
    metric: '01/09/23',
    t0: 210,
    '4week': 220,
    '12week': 300,
    trend: 410,

    sales: 340,
  },
  {
    key: 2,
    metric: '01/16/23',
    t0: 210,
    '4week': 220,
    '12week': 300,
    trend: 410,
    sales: -100,
  },
  {
    key: 3,
    metric: '01/23/23',
    t0: 210,
    '4week': 220,
    '12week': 300,
    trend: 410,
    sales: 205,
  },
  {
    key: 4,
    metric: '01/30/23',
    t0: 210,
    '4week': 220,
    '12week': 300,
    trend: 410,
    sales: 117,
  },
  {
    key: 5,
    metric: '02/06/23',
    t0: 210,
    '4week': 220,
    '12week': 300,
    trend: 410,
    sales: 214,
  },
];
export const ytdSalesGridData = [
  {
    key: 1,
    metric: '01/09/23',
    t0: 210,
    '4week': 220,
    '12week': 300,
    trend: 410,

    sales: 340,
  },
  {
    key: 2,
    metric: '01/16/23',
    t0: 210,
    '4week': 220,
    '12week': 300,
    trend: 410,
    sales: 100,
  },
  {
    key: 3,
    metric: '01/23/23',
    t0: 210,
    '4week': 220,
    '12week': 300,
    trend: 410,
    sales: 200,
  },
  {
    key: 4,
    metric: '01/30/23',
    t0: 210,
    '4week': 220,
    '12week': 300,
    trend: 410,
    sales: 300,
  },
  {
    key: 5,
    metric: '02/06/23',
    t0: 210,
    '4week': 220,
    '12week': 300,
    trend: 410,
    sales: 500,
  },
];

export const yoySalesData = [
  {
    key: 1,
    metric: '01/09/23',

    sales: 340,
    t0: 210,
    demand: 250,
  },
  {
    key: 2,
    metric: '01/16/23',
    sales: 220,
    t0: 240,
    demand: 150,
  },
  {
    key: 3,
    metric: '01/23/23',
    sales: 210,
    t0: 220,
    demand: 250,
  },
  {
    key: 4,
    metric: '01/30/23',
    sales: 340,
    t0: 210,
    demand: 250,
  },
  {
    key: 5,
    metric: '02/06/23',
    sales: 340,
    t0: 210,
    demand: 250,
  },
];

export const ytdRoyChartData = {
  ytdHigh: 1250,
  ytdLow: 250,
  values: [
    {
      key: 1,
      name: 'T0',
      data: [null, null, null, null, 600, 650, 620],
      lineStyle: {
        type: 'dotted',
        color: '#AF1685',
      },
    },
    {
      key: 2,
      name: 'Trend',
      data: [null, null, null, null, 600, 650, 700],
      lineStyle: {
        type: 'dotted',
        color: '#56A944',
      },
    },
    {
      key: 3,
      name: '12 Wk MA',
      data: [600, 312, 880, 760, 580, null, null],
      lineStyle: {
        color: '#DB930B',
      },
    },
    {
      key: 4,
      name: '4 Wk MA',
      data: [620, 332, 901, 780, 600, null, null],
      lineStyle: {
        color: '#4B80B1',
      },
    },
    {
      key: 5,
      name: 'Actual',
      data: [300, 450, 1200, 1050, 820, null, null],
      lineStyle: {
        color: '#13294B',
      },
    },
  ],
};

export const ytdRoyChartSalesData = {
  ytdHigh: 800,
  ytdLow: 100,
  values: [
    {
      key: 1,
      name: 'T0',
      data: [200, 300, 320, 210, 200, 300, 320],
      lineStyle: {
        type: 'dotted',
        color: '#AF1685',
      },
    },
    {
      key: 2,
      name: 'Trends',
      data: [200, 300, 320, 210, 110, 120, 210],
      lineStyle: {
        type: 'dotted',
        color: '#56A944',
      },
    },
    {
      key: 3,
      name: '12 Wk MA:580',
      data: [111, 211, 211, 230, 300, null, null],
      lineStyle: {
        color: '#DB930B',
      },
    },
    {
      key: 4,
      name: '4 Wk MA: 600',
      data: [140, 160, 200, 260, null, null],
      lineStyle: {
        color: '#4B80B1',
      },
    },
    {
      key: 5,
      name: 'Actual Sales',
      data: [100, 120, 180, 300, 600, null, null],
      lineStyle: {
        color: '#13294B',
      },
    },
  ],
};

export const salesAndDemand = {
  values: [
    {
      key: 1,
      name: 'T0',
      data: [1, 1, 1, 1, 1, 1, 1, 1, 1, 10],
      lineStyle: {
        color: '#DB930B',
      },
    },
    {
      key: 2,
      name: 'Demand',
      data: [5, 5, 5, 5, 7, 7, 8, 9, 9, 9],
      lineStyle: {
        color: '#4B80B1',
      },
    },
    {
      key: 3,
      name: 'Sales',
      data: [5, 5, 5, 5, 5, 5, 5, 5, 7, 7],
      lineStyle: {
        color: '#13294B',
      },
    },
  ],
};

export const PortfolioData = {
  values: [
    {
      key: 1,
      name: 'T0',
      data: [10],
      lineStyle: {
        color: '#FF5733', // Unique color for T0
      },
    },
    {
      key: 2,
      name: 'Avg. Weekly Sales',
      data: [20],
      lineStyle: {
        color: '#33FF57',
      },
    },
    {
      key: 3,
      name: 'Trend',
      data: [30],
      lineStyle: {
        color: '#3357FF',
      },
    },
    {
      key: 4,
      name: '4 Wk Avg.',
      data: [40],
      lineStyle: {
        color: '#FF33A2',
      },
    },
    {
      key: 5,
      name: '12 Wk Avg.',
      data: [50],
      lineStyle: {
        color: '#FFD700',
      },
    },
  ],
};
export const GridLinePortfolioData = {
  values: [
    {
      key: 1,
      name: 'T0',
      data: [10],
      lineStyle: {
        color: '#FF5733', // Unique color for T0
      },
    },
    {
      key: 2,
      name: 'Avg. Weekly Sales',
      data: [20],
      lineStyle: {
        color: '#33FF57',
      },
    },
    {
      key: 3,
      name: 'Trend',
      data: [30],
      lineStyle: {
        color: '#3357FF',
      },
    },
    {
      key: 4,
      name: '4 Wk Avg.',
      data: [40],
      lineStyle: {
        color: '#FF33A2',
      },
    },
    {
      key: 5,
      name: '12 Wk Avg.',
      data: [50],
      lineStyle: {
        color: '#FFD700',
      },
    },
  ],
};

export const yoyGrowth = {
  values: [
    {
      key: 1,
      name: '2023',
      data: [
        100, 102, 104, 106, 108, 110, 112, 114, 116, 118, 120, 122, 124, 126,
        128, 130, 132, 134, 136, 138, 140, 142, 144, 146, 261, 262, 263,
      ],
      lineStyle: {
        color: '#DB930B',
      },
    },
    {
      key: 2,
      name: '2022',
      data: [
        102, 104, 106, 108, 110, 112, 114, 116, 118, 120, 122, 124, 126, 100,
        130, 132, 134, 136, 138, 140, 142, 144, 146, 148, 150, 152, 154, 156,
        158, 160, 162, 164, 166, 168, 170, 172, 174, 176, 178, 180, 182, 184,
        186, 188, 190, 192, 194, 196, 198, 201, 203,
      ],
      lineStyle: {
        color: '#4B80B1',
      },
    },
    {
      key: 3,
      name: '2021',
      data: [
        104, 108, 112, 116, 120, 124, 128, 132, 136, 140, 144, 148, 152, 156,
        160, 164, 168, 172, 176, 180, 184, 188, 192, 196, 200, 204, 208, 212,
        216, 220, 224, 228, 232, 236, 240, 244, 248, 252, 256, 260, 264, 268,
        272, 276, 280, 284, 288, 292, 296, 300, 303,
      ],
      lineStyle: {
        color: '#13294B',
      },
    },
  ],
};

export const ytdChartGridCols = [
  {
    title: '',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        Sales ($M)
      </div>
    ),
    dataIndex: 'data',
    key: 'data',
    render: (text) => (
      <div
        style={{
          color: GETLABELCOLOR(text),
          display: 'flex',
          justifyContent: 'flex-end',
          fontWeight: 'bold',
        }}
      >
        {text > 0
          ? `+${Number(text).toLocaleString()}`
          : `${Number(text).toLocaleString()}`}
      </div>
    ),
  },
];

export const ytdSalesVsForecastChartGridCols = [
  {
    title: '',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        YTD Sales ($M)
      </div>
    ),
    dataIndex: 'data',
    key: 'data',
    render: (text) => (
      <div
        style={{
          fontWeight: 'bold',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {Number(text)?.toLocaleString() < 0
          ? `-$${Math.abs(Number(text))?.toLocaleString()}M`
          : `$${Number(text)?.toLocaleString()}M`}
      </div>
    ),
  },
  {
    title: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        Growth
      </div>
    ),
    dataIndex: 'growth',
    key: 'growth',
    render: (text) => (
      <div
        style={{
          color:
            text === 'N/A'
              ? 'black'
              : text?.split('%')[0] > 0
              ? '#56A944'
              : '#C23934',
          display: 'flex',
          justifyContent: 'flex-end',
          fontWeight: 'bold',
        }}
      >
        {text > 0 ? `${text}%` : text}
      </div>
    ),
  },
];

export const demandChartGridCols = [
  {
    title: 'Metric',
    dataIndex: 'metric',
    key: 'metric',
    render: (value) => {
      return value ? value : '-';
    },
  },
  {
    title: 'YTD Variance Sales ($M)',
    dataIndex: 'sales',
    key: 'sales',
    render: (value) => {
      return value ? value : '-';
    },
  },
  {
    title: 'YTD Variance Demand ($M)',
    dataIndex: 'demand',
    key: 'demand',
    render: (value) => {
      return value ? value : '-';
    },
  },
];

export const ytdSalesChartGridCols = (versionType) => {
  return [
    {
      title: 'Metric',
      dataIndex: 'metric',
      key: 'metric',
      render: (value) => {
        return value ? value : '-';
      },
    },
    {
      title: `${versionType} ($M)`,
      dataIndex: 't0',
      key: 't0',
      render: (value) => {
        return value ? value?.toLocaleString() : '-';
      },
    },
    {
      title: '4 Wk MA ($M)',
      dataIndex: '4week',
      key: '4week',
      render: (value) => {
        return value ? value?.toLocaleString() : '-';
      },
    },
    {
      title: '12 Wk MA ($M)',
      dataIndex: '12week',
      key: '12week',
      render: (value) => {
        return value ? value?.toLocaleString() : '-';
      },
    },
    {
      title: 'Trend ($M)',
      dataIndex: 'trend',
      key: 'trend',
      render: (value) => {
        return value ? value?.toLocaleString() : '-';
      },
    },
    {
      title: 'Actual Sales ($M)',
      dataIndex: 'sales',
      key: 'sales',
      render: (value) => {
        return value ? value?.toLocaleString() : '-';
      },
    },
  ];
};

export const salesChartGridCols = [
  {
    title: 'Metric',
    dataIndex: 'metric',
    key: 'metric',
    align: 'center',
  },
  {
    title: 'Sales',
    dataIndex: 'sales',
    key: 'sales',
    align: 'center',
  },
  {
    title: 'Demand',
    dataIndex: 'demand',
    key: 'demand',
    align: 'center',
  },
  {
    title: 'T0',
    dataIndex: 't0',
    key: 't0',
    align: 'center',
  },
];

export const yoyGridCols = (versionType) => {
  return [
    {
      title: 'Metric',
      dataIndex: 'metric',
      key: 'metric',
      render: (value) => {
        return value ? value : '-';
      },
    },
    {
      title: `${versionType} (K)`,
      dataIndex: 't0',
      key: 't0',
      render: (value) => {
        return value ? value?.toLocaleString() : '-';
      },
    },
    {
      title: '12 Wk MA (K)',
      dataIndex: '12week',
      key: '12week',
      render: (value) => {
        return value ? value?.toLocaleString() : '-';
      },
    },
    {
      title: 'Trend (K)',
      dataIndex: 'trend',
      key: 'trend',
      render: (value) => {
        return value ? value?.toLocaleString() : '-';
      },
    },
    {
      title: 'Actual Sales (K)',
      dataIndex: 'sales',
      key: 'sales',
      render: (value) => {
        return value ? value?.toLocaleString() : '-';
      },
    },
  ];
};

export const fyProjectionGridCols = (versionType) => {
  return [
    {
      title: 'Metric',
      dataIndex: 'metric',
      key: 'metric',
      render: (value) => {
        return value ? value : '-';
      },
    },
    {
      title: `${versionType} ($M)`,
      dataIndex: 't0',
      key: 't0',
      render: (value) => {
        return value ? value?.toLocaleString() : '-';
      },
    },
    {
      title: '12 Wk MA ($M)',
      dataIndex: '12week',
      key: '12week',
      render: (value) => {
        return value ? value?.toLocaleString() : '-';
      },
    },
    {
      title: 'Trend ($M)',
      dataIndex: 'trend',
      key: 'trend',
      render: (value) => {
        return value ? value?.toLocaleString() : '-';
      },
    },
    {
      title: 'Actual Sales ($M)',
      dataIndex: 'sales',
      key: 'sales',
      render: (value) => {
        return value ? value?.toLocaleString() : '-';
      },
    },
  ];
};

export const chartActionsAccess = [
  {
    chartId: 'ytd-sales-v-forec',
    chartKey: 1,
    chart: true,
    grid: true,
    comments: false,
    labels: false,
  },
  {
    chartId: 'ytd-sales-v-py',
    chartKey: 2,
    chart: true,
    grid: true,
    comments: false,
    labels: false,
  },
  {
    chartId: 'fy-projection',
    chartKey: 3,
    chart: true,
    grid: true,
    comments: false,
    labels: true,
  },
  {
    chartId: 'ytd-variance',
    chartKey: 4,
    chart: true,
    grid: true,
    comments: true,
    labels: false,
  },
  {
    chartId: 'ytd-py',
    chartKey: 5,
    chart: true,
    grid: true,
    comments: true,
    labels: false,
  },
  {
    chartId: 'ytd-roy',
    chartKey: 6,
    chart: true,
    grid: true,
    comments: false,
    labels: true,
  },
  {
    chartId: 'sales-demand',
    chartKey: 7,
    chart: true,
    grid: true,
    comments: false,
    labels: true,
  },
  {
    chartId: 'yoy-sales',
    chartKey: 8,
    chart: true,
    grid: true,
    comments: false,
    labels: false,
  },
  {
    chartId: 'yoy-growth',
    chartKey: 9,
    chart: false,
    grid: false,
    comments: false,
    labels: true,
  },
  {
    chartId: 'year-forecast',
    chartKey: 10,
    chart: true,
    grid: true,
    comments: true,
    labels: true,
  },

  {
    chartId: 'agg-forecast',
    chartKey: 11,
    chart: true,
    grid: true,
    comments: false,
    labels: true,
  },

  {
    chartId: 'short-termforecast',
    chartKey: 12,
    chart: true,
    grid: true,
    comments: true,
    labels: false,
  },
  {
    chartId: 'net-sales',
    chartKey: 13,
    chart: true,
    grid: false,
    comments: true,
    labels: true,
  },
  {
    chartId: 'disease-area',
    chartKey: 14,
    chart: false,
    grid: true,
    comments: true,
    labels: true,
  },
];

export const yoyGrowthGrid = {
  values: [
    {
      key: 1,
      title: 'YTD Avg OUTs (Eq.Vials)',
      2020: '184,978',
      2021: '150,342',
      2022: '147,053',
      YTD2023: '148,791',
    },
    {
      key: 2,
      title: 'YoY%',

      2020: '-10.0%',
      2021: '-20.3%',
      2022: '-3.2%',
      YTD2023: '+1.7%',
    },
  ],
};
export const yoySalesGrid = {
  values: [
    {
      key: 1,
      title: 'Net Sales Growth',
      2020: '+5.9%',
      2021: '+11.9%',
      2022: '-0.6%',
      YTD2023: '+7.8%',
    },
    {
      key: 2,
      title: 'Gross Sales Growth',

      2020: '-10.0%',
      2021: '-20.3%',
      2022: '-3.2%',
      YTD2023: '+1.7%',
    },
  ],
};

export const yoySalesGridView = {
  values: [
    {
      key: 1,
      title: 'Net Sales',
      2020: '$3M',
      2021: '$5M',
      2022: '$5M',
      YTD2023: '$1M',
    },
    {
      key: 2,
      title: 'Gross Sales',

      2020: '$15M',
      2021: '$15M',
      2022: '$15M',
      YTD2023: '$16M',
    },
    {
      key: 3,
      title: 'Net Sales Growth',
      2020: '+5.9%',
      2021: '+11.9%',
      2022: '-0.6%',
      YTD2023: '+7.8%',
    },
    {
      key: 4,
      title: 'Net Sales Growth',
      2020: '+5.9%',
      2021: '+11.9%',
      2022: '-0.6%',
      YTD2023: '+7.8%',
    },
  ],
};
