import { Select, Checkbox, Tag } from 'antd';
import classes from './SelectMenu.module.scss';

const SelectMenu = ({
  selectStyle,
  onSelectChange,
  menuOptions,
  checkboxValues,
  defaultValue,
}) => {
  const tagRender = (props) => {
    const { label } = props;
    return (
      <Tag
        closeIcon={null}
        bordered={checkboxValues?.length !== menuOptions?.length}
      >
        {label}
      </Tag>
    );
  };
  return (
    <Select
      mode="multiple"
      style={selectStyle}
      showSearch={false}
      options={menuOptions}
      defaultValue={defaultValue}
      value={
        checkboxValues?.length === menuOptions?.length
          ? ['All']
          : checkboxValues
      }
      maxTagCount={'responsive'}
      tagRender={tagRender}
      dropdownRender={() =>
        menuOptions?.map((item) => (
          <div className={classes['menu-content']} key={item?.value}>
            <Checkbox
              onChange={(e) => onSelectChange(item?.value, e.target)}
              checked={checkboxValues?.includes(item?.value)}
            />
            <div className={classes['checkbox-label']}>{item?.label}</div>
          </div>
        ))
      }
    />
  );
};

export default SelectMenu;
