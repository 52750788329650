import { colorLabelSpan } from './colorLabelSpan';
export const aggForecastLineChart = ({
  chartData,

  showEndLabel,

  category,

  isViewExpanded,
  expand,
  fixedStart = 100,
  fixedEnd = 75,
  minDataSpan = 10,
  maxDataSpan = 10,
}) => ({
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
    formatter: function (params) {
      let tooltipContent = ' ';
      params?.forEach((param) => {
        const color = chartData?.filter((item) => {
          return item?.name === param?.seriesName;
        })[0]?.lineStyle?.color;
        const value =
          param.value === null || param.value === undefined ? '-' : param.value;
        const data =
          colorLabelSpan({ color }) +
          ' ' +
          param?.seriesName +
          ' : ' +
          (value === '-' ? '-' : `${value.toLocaleString()}K`) +
          '<br/>';
        tooltipContent += data;
      });
      return tooltipContent;
    },
  },
  xAxis: {
    type: 'category',
    boundaryGap: true,
    data: category,
    axisLabel: {
      show: true,
      rotate: 90,
      color: '#000000',
      fontSize: 12,
    },
    axisTick: { show: false },
  },
  dataZoom: !isViewExpanded && [
    {
      type: 'slider',
      show: true,
      xAxisIndex: [0],
      start: fixedStart,
      end: fixedEnd,
      minValueSpan: minDataSpan,
      maxValueSpan: maxDataSpan,
      brushSelect: false,
      handleSize: '10%',
      height: 14,
      showDetail: false,
      backgroundColor: '#F1F1F1',
      borderRadius: 10,
      fillerColor: '#B5B5B5',
      borderColor: '#FFFFFF',
      z: 2,
      opacity: 1,
      bottom: -9,
    },
  ],
  yAxis: {
    type: 'value',
    name: 'Total K Packs',
    nameLocation: 'middle',
    nameGap: 55,
    splitLine: {
      show: false,
    },
    nameTextStyle: {
      color: '#000000',
      fontSize: 13,
    },
    axisLine: { show: true },
    axisLabel: {
      show: true,
    },
  },
  grid: expand
    ? { left: '5%', right: 90, containLabel: true }
    : {
        left: 75,
        right: 90, // Increase the right margin to prevent end labels from overflowing
      },
  series: chartData?.map((item) => {
    return {
      ...item,
      showSymbol: false,
      lineStyle: {
        ...item?.lineStyle,
        width: 3,
      },
      type: 'line',
      endLabel: {
        show: showEndLabel,
        formatter: item?.name,
        color: item?.lineStyle?.color,
      },
      labelLayout: {
        hideOverlap: true,
        moveOverlap: 'shiftY',
      },
    };
  }),
});
