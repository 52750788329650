import { CHART, GRID } from '@constant';
import classes from './ChartActions.module.scss';
import { Tooltip } from 'antd';

const ViewChartActions = ({
  cardActions,
  actionsAllowed,
  onEditCardTitle,
  isEditMode,
  tab,
  onSelectView,
}) => {
  const handleViewSelection = (view) => {
    onSelectView(view);
  };
  return (
    <div className={classes['actions-wrap-myview']}>
      {!isEditMode && (
        <>
          <div style={{ display: 'flex', marginTop: 8 }}>
            {tab === 'myview' && (
              <Tooltip title="Edit Title">
                <div style={{ marginTop: '-6px' }}>
                  <i
                    className={`icon icon__edit ${classes['icon-banner']}`}
                    onClick={onEditCardTitle}
                  ></i>
                </div>
              </Tooltip>
            )}
            {actionsAllowed?.chart && (
              <Tooltip title="View Chart">
                <i
                  className={`icon ${
                    cardActions?.chartView ? 'icon__chart' : 'icon__chart_grey'
                  }`}
                  onClick={() => handleViewSelection(CHART)}
                ></i>
              </Tooltip>
            )}
            {actionsAllowed?.grid && (
              <Tooltip title="View Table">
                <i
                  className={`icon ${
                    cardActions?.gridView ? 'icon__table' : 'icon__table_grey'
                  } ${classes['icon-alignment']}`}
                  onClick={() => handleViewSelection(GRID)}
                ></i>
              </Tooltip>
            )}{' '}
          </div>
        </>
      )}
    </div>
  );
};

export default ViewChartActions;
