// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Wo4ErL4uTzlNrK3Ulbr1{background:rgba(12,10,10,.9098039216) !important}`, "",{"version":3,"sources":["webpack://./src/components/layout/compliance/Compliance.module.scss"],"names":[],"mappings":"AAAA,sBACE,gDAAA","sourcesContent":[".compliance-wrapper {\n  background: #0c0a0ae8 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"compliance-wrapper": `Wo4ErL4uTzlNrK3Ulbr1`
};
export default ___CSS_LOADER_EXPORT___;
