// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JLgFQaGmWTTNV9t_Mn4V{padding:0px 15px 20px 0px;flex:1}.ivkpKct9wx5J7C2cexf1{font-size:12px;padding:10px 0px;font-weight:bold}.lfxFm3rK7AKbo8Fdfp2w{display:flex;margin:10px !important;align-items:center;cursor:pointer}.RDhRQSrddWCJGIJYMysJ .kCb0dnUmwgpPfna5cC4l .Hu9tPWSA86B5YE9nX2E4{display:none}.Hu9tPWSA86B5YE9nX2E4{display:none !important}.ABEnSar0ypZaaSI2DBo6 .bn5LvIUoExi0iwkJPGUO{color:#000;font-weight:500}.qvjM6u3FI2xN_bAxjUTA{display:flex;flex-wrap:wrap;justify-content:space-between}`, "",{"version":3,"sources":["webpack://./src/components/myviewFilter/myviewFilters.module.scss"],"names":[],"mappings":"AAAA,sBACE,yBAAA,CACA,MAAA,CAGF,sBACE,cAAA,CACA,gBAAA,CACA,gBAAA,CAGF,sBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,cAAA,CAGF,kEAGE,YAAA,CAGF,sBACE,uBAAA,CAGF,4CACE,UAAA,CACA,eAAA,CAEF,sBACE,YAAA,CACA,cAAA,CACA,6BAAA","sourcesContent":[".filter-wrap {\n  padding: 0px 15px 20px 0px;\n  flex: 1;\n}\n\n.label-title {\n  font-size: 12px;\n  padding: 10px 0px;\n  font-weight: bold;\n}\n\n.menu-content-filter {\n  display: flex;\n  margin: 10px !important;\n  align-items: center;\n  cursor: pointer;\n}\n\n.ant-select-multiple\n  .ant-select-selection-overflow\n  .ant-select-selection-item-remove {\n  display: none;\n}\n\n.ant-select-selection-item-remove {\n  display: none !important;\n}\n\n.custom-select .ant-select-selection-placeholder {\n  color: black; /* Darker color for the placeholder */\n  font-weight: 500; /* Make it seem like a selected value */\n}\n.filter-container {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter-wrap": `JLgFQaGmWTTNV9t_Mn4V`,
	"label-title": `ivkpKct9wx5J7C2cexf1`,
	"menu-content-filter": `lfxFm3rK7AKbo8Fdfp2w`,
	"ant-select-multiple": `RDhRQSrddWCJGIJYMysJ`,
	"ant-select-selection-overflow": `kCb0dnUmwgpPfna5cC4l`,
	"ant-select-selection-item-remove": `Hu9tPWSA86B5YE9nX2E4`,
	"custom-select": `ABEnSar0ypZaaSI2DBo6`,
	"ant-select-selection-placeholder": `bn5LvIUoExi0iwkJPGUO`,
	"filter-container": `qvjM6u3FI2xN_bAxjUTA`
};
export default ___CSS_LOADER_EXPORT___;
