/* eslint-disable no-undef */
import axiosDBClient from '../../axiosDBClient';

export const getMyViewCardsDetails = async (unixId) => {
  const response = await axiosDBClient.get('/myview', {
    params: { unixId: unixId },
  });

  if (response.error) {
    throw new Error('Could not fetch cards data!');
  }

  return response.data.result;
};

export const getMyViewKPIDetails = async (unixId) => {
  const response = await axiosDBClient.get('/myview/kpi', {
    params: { unixId: unixId },
  });

  if (response.error) {
    throw new Error('Could not fetch cards data!');
  }

  return response.data.result;
};

export const addCardToView = async (payload) => {
  try {
    const response = await axiosDBClient.post('myview/card', {
      ...payload,
    });
    if (response.error) {
      throw new Error('Could not add card!');
    }

    return response.data;
  } catch (error) {
    console.log('error', error);
  }
};

export const updateCardView = async (payload) => {
  try {
    const response = await axiosDBClient.put('myview/update', {
      ...payload,
    });
    if (response.error) {
      throw new Error('Could not update card!');
    }

    return response.data.message;
  } catch (error) {
    console.log('error', error);
  }
};

export const deleteCardFromView = async (cardId, unixId) => {
  const setDeleteCard = async () => {
    const response = await axiosDBClient.delete(`myview/delete/${cardId}`, {
      params: {
        unixId: unixId,
      },
    });
    if (response.data.error) {
      throw new Error('Could not delete card!');
    }
    return response.data;
  };
  try {
    const data = await setDeleteCard();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const editCardTitle = async (payload) => {
  try {
    const response = await axiosDBClient.post('myview/edit', {
      ...payload,
    });
    if (response.error) {
      throw new Error('Could not update cardname!');
    }

    return response.data.message;
  } catch (error) {
    console.log('error', error);
  }
};

export const updateCardPositions = async (payload) => {
  const updatePositions = async () => {
    const response = await axiosDBClient.put(`myview/configure`, {
      ...payload,
    });

    if (response.data.error) {
      throw new Error('Could not update positions!');
    }

    return response.data;
  };

  try {
    const data = await updatePositions();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};
