import { getSeries } from './getSeries';
import { colorLabelSpan } from './colorLabelSpan';

export const BusinessplanNetSalesLineChart = ({
  chartType,

  chartData,
  category,
  showEndLabel,
  scatterPlotDatatoPlot,

  expand,
}) => ({
  color: ['#13294B', '#13294B', '#4B80B1', '#4B80B1', '#EE6666', '#EE6666'],
  tooltip: {
    trigger: 'axis',
    axisPointer: { type: 'line' },
    formatter: function (params) {
      let tooltipContent = '';
      params?.forEach((param) => {
        const value =
          param?.value == null || param?.value === '-' ? '-' : param?.value;
        tooltipContent += `${colorLabelSpan({ color: param?.color })} ${
          param?.seriesName
        } : ${value === '-' ? '-' : `$${value.toLocaleString()}M`}<br/>`;
      });
      return tooltipContent;
    },
  },
  grid: expand
    ? { left: '5%', right: '10%', containLabel: true }
    : {
        left: '5%',
        right: '15%',
        containLabel: true,
      },
  xAxis: {
    type: 'category',
    boundaryGap: true,
    data: category,
    axisLabel: {
      show: true,
      color: '#000000',
      fontSize: 12,
      fontFamily: 'Gene Sans',
    },
    axisTick: { show: false },
  },
  yAxis: {
    type: 'value',
    name: 'Net Sales ($M)',
    nameLocation: 'middle',
    nameGap: 50,
    splitLine: { show: false },
    nameTextStyle: {
      color: '#000000',
      fontSize: 13,
    },
    axisLine: { show: true },
    axisLabel: { show: true },
  },
  series:
    chartType == 'BusinessplanNetSalesLineChart'
      ? [
          ...getSeries({ chartType, chartData, showEndLabel }),

          {
            name: 'Scatter Points',
            type: 'scatter',
            data: scatterPlotDatatoPlot,
            symbolSize: 10,
            itemStyle: { color: '#56A944' },
            label: {
              show: true,
              formatter: function (params) {
                const labelText = params?.data.name || '';
                const count = labelText.startsWith('|')
                  ? 1
                  : labelText.split('|').length;
                return `${count} Event${count !== 1 ? 's' : ''}`;
              },
              position: 'right',
              color: '#56A944',
            },
            tooltip: {
              trigger: 'item',
              enterable: true,
              triggerOn: 'mousemove',

              formatter: function (params) {
                const eventNames = params?.data?.name.split('|');
                const formattedNames = eventNames.map((name) => {
                  return name.length > 20
                    ? name.match(/.{1,70}/g).join('<br/>')
                    : name;
                });
                const bulletList = formattedNames
                  .map((name) => `&#8226; ${name}`)
                  .join('<br/>');

                const isScrollable = eventNames.length > 10;
                const tooltipStyle = isScrollable
                  ? 'max-height: 200px; overflow-y: auto;z-index: 1000'
                  : '';
                return `<div style="${tooltipStyle}">${bulletList}</div>`;
              },
            },
          },
        ]
      : [getSeries({ chartType, chartData, showEndLabel })],
});
