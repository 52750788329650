export const CARD_CONFIG = {
  //Portfolio configuration
  PORTFOLIO_PERFORMANCE_BANNER_CHART: {
    ViewId: 2,
    ViewName: 'Portfolio',
    CardId: 21,
    CardName: 'Portfolio Performance',
    ChartId: 1,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
    ChartType: 'portfolioTimeline',
  },
  PORTFOLIO_PERFORMANCE_BANNER_GRID: {
    ViewId: 2,
    ViewName: 'Portfolio',
    CardId: 20,
    CardName: 'Portfolio Performance',
    ChartId: 1,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
  },
  PORTFOLIO_YTD_FORECAST_ACTUAL_CHART: {
    ViewId: 2,
    ViewName: 'Portfolio',
    CardId: 16,
    CardName: 'YTD Sales vs. Forecast ($M)',
    ChartId: 1,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
    ChartLabel: 'ytd-sales-v-forec',
    ChartType: 'Sidebar',
  },
  PORTFOLIO_YTD_VARIANCE_CHART: {
    ViewId: 2,
    ViewName: 'Portfolio',
    CardId: 16,
    CardName: 'YTD Sales vs. Forecast ($M)',
    ChartId: 2,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
    ChartLabel: 'ytd-sales-v-forec',
    ChartType: 'Sidebar',
  },
  PORTFOLIO_YTD_PY_FORECAST_ACTUAL_CHART: {
    ViewId: 2,
    ViewName: 'Portfolio',
    CardId: 17,
    CardName: 'YTD Sales vs. PY YTD ($M)',
    ChartId: 1,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
    ChartLabel: 'ytd-sales-v-py',
    ChartType: 'SalespySidebar',
  },
  PORTFOLIO_YTD_PY_VARIANCE_CHART: {
    ViewId: 2,
    ViewName: 'Portfolio',
    CardId: 17,
    CardName: 'YTD Sales vs. PY YTD ($M)',
    ChartId: 2,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
    ChartLabel: 'ytd-sales-v-py',
    ChartType: 'SalespySidebar',
  },
  PORTFOLIO_FY_PROJECTION_CHART_YTDAVG_LABEL: {
    ViewId: 2,
    ViewName: 'Portfolio',
    CardId: 18,
    CardName: 'YTD & RoY Projections',
    ChartId: 1,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
    ChartLabel: 'fy-projection',
    ChartType: 'fyprojectionlineChart',
  },
  PORTFOLIO_FY_PROJECTION_CHART_12WEEK_LABEL: {
    ViewId: 2,
    ViewName: 'Portfolio',
    CardId: 18,
    CardName: 'YTD & RoY Projections',
    ChartId: 3,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
    ChartLabel: 'fy-projection',
    ChartType: 'fyprojectionlineChart',
  },
  PORTFOLIO_FY_PROJECTION_CHART_TREND_LABEL: {
    ViewId: 2,
    ViewName: 'Portfolio',
    CardId: 18,
    CardName: 'YTD & RoY Projections',
    ChartId: 4,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
    ChartLabel: 'fy-projection',
    ChartType: 'fyprojectionlineChart',
  },
  PORTFOLIO_FY_PROJECTION_CHART_TO_LABEL: {
    ViewId: 2,
    ViewName: 'Portfolio',
    CardId: 18,
    CardName: 'YTD & RoY Projections',
    ChartId: 5,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
    ChartLabel: 'fy-projection',
    ChartType: 'fyprojectionlineChart',
  },
  PORTFOLIO_FY_PROJECTION_CHART: {
    ViewId: 2,
    ViewName: 'Portfolio',
    CardId: 18,
    CardName: 'YTD & RoY Projections',
    ChartId: 6,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
    ChartLabel: 'fy-projection',
    ChartType: 'fyprojectionlineChart',
  },
  PORTFOLIO_YTD_GRID: {
    ViewId: 2,
    ViewName: 'Portfolio',
    CardId: 19,
    CardName: 'YTD Portfolio Sales by Brand through week #',
    ChartId: 1,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
  },
  PORTFOLIO_YTD_TOTAL_COL_GRID: {
    ViewId: 2,
    ViewName: 'Portfolio',
    CardId: 19,
    CardName: 'YTD Portfolio Sales by Brand through week #',
    ChartId: 3,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
  },
  PORTFOLIO_QTD_GRID: {
    ViewId: 2,
    ViewName: 'Portfolio',
    CardId: 19,
    CardName: 'QTD Portfolio Sales by Brand through week #',
    ChartId: 2,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
  },
  PORTFOLIO_QTD_TOTAL_COL_GRID: {
    ViewId: 2,
    ViewName: 'Portfolio',
    CardId: 19,
    CardName: 'QTD Portfolio Sales by Brand through week #',
    ChartId: 4,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
  },
  PORTFOLIO_EQOUTS_GRID: {
    ViewId: 2,
    ViewName: 'Portfolio',
    CardId: 15,
    CardName: 'Equivalized OUTs by Brand through week #',
    ChartId: 24,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
  },
  //Brand configuration

  BRAND_PERFORMANCE_BANNER: {
    ViewId: 1,
    ViewName: 'Brand Performance',
    CardId: 10,
    ChartId: 1,
    CardName: 'Brand Performance',
    MyViewEnabled: 'Y',
    IsActive: 'Y',
  },
  BRAND_YTD_VARIANCE_CHART: {
    ViewId: 1,
    ViewName: 'Brand Performance',
    CardId: 1,
    ChartId: 1,
    CardName: 'YTD Variance Drivers (T0, $M)',
    MyViewEnabled: 'Y',
    IsActive: 'Y',
    ChartLabel: 'ytd-variance',
    ChartType: 'bar',
  },
  BRAND_YTD_YOY_VARIANCE_CHART: {
    ViewId: 1,
    ViewName: 'Brand Performance',
    CardId: 2,
    ChartId: 2,
    CardName: 'YTD YoY Variance Drivers (PY YTD, $M) for',
    MyViewEnabled: 'Y',
    IsActive: 'Y',
    ChartLabel: 'ytd-py',
    ChartType: 'bar',
  },
  BRAND_YTD_ROY_CHART: {
    ViewId: 1,
    ViewName: 'Brand Performance',
    CardId: 3,
    CardName: 'YTD & RoY',
    ChartId: 9,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
    ChartLabel: 'ytd-roy',
    ChartType: 'demandlineChart',
  },
  BRAND_YTD_ROY_CHART_LABEL: {
    ViewId: 1,
    ViewName: 'Brand Performance',
    CardId: 3,
    CardName: 'YTD & RoY',
    ChartId: 10,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
  },
  BRAND_YTD_SD_CHART: {
    ViewId: 1,
    ViewName: 'Brand Performance',
    CardId: 4,
    CardName: 'YTD Variance vs.',
    ChartId: 9,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
    ChartLabel: 'sales-demand',
    ChartType: 'sdline',
  },
  BRAND_YOY_SALES_CHART: {
    ViewId: 1,
    ViewName: 'Brand Performance',
    CardId: 5,
    CardName: 'YoY Sales Growth for',
    ChartId: 10,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
    ChartLabel: 'yoy-sales',
    ChartType: 'stackedBar',
  },
  BRAND_YOY_SALES_GRID: {
    ViewId: 1,
    ViewName: 'Brand Performance',
    CardId: 5,
    CardName: 'YoY Sales Growth for',
    ChartId: 11,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
  },
  BRAND_YOY_OUTS_GROWTH_CHART: {
    ViewId: 1,
    ViewName: 'Brand Performance',
    CardId: 6,
    CardName: 'YoY OUTs Growth for',
    ChartId: 12,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
    ChartLabel: 'yoy-growth',
    ChartType: 'linechart',
  },
  BRAND_YOY_OUTS_GROWTH_GRID: {
    ViewId: 1,
    ViewName: 'Brand Performance',
    CardId: 6,
    CardName: 'YoY OUTs Growth for',
    ChartId: 13,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
  },
  BRAND_QUARTERLY_CURRENTYEAR_GRID: {
    ViewId: 1,
    ViewName: 'Brand Performance',
    CardId: 11,
    CardName: 'Quarterly Reports for',
    ChartId: 20,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
  },
  BRAND_QUARTERLY_PREVIOUSYEAR_GRID: {
    ViewId: 1,
    ViewName: 'Brand Performance',
    CardId: 14,
    CardName: 'Quarterly Reports for',
    ChartId: 23,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
  },
  BRAND_MONTHLY_CURRENTYEAR_GRID: {
    ViewId: 1,
    ViewName: 'Brand Performance',
    CardId: 12,
    CardName: 'Monthly Reports for',
    ChartId: 21,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
  },
  BRAND_MONTHLY_PREVIOUSYEAR_GRID: {
    ViewId: 1,
    ViewName: 'Brand Performance',
    CardId: 13,
    CardName: 'Monthly Reports for',
    ChartId: 22,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
  },
  //S&OP configurations
  SOP_BANNER: {
    ViewId: 3,
    ViewName: 'SOP',
    CardId: 28,
    CardName: 'S&OP Performance (YTD)',
    ChartId: 1,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
  },
  SOP_YEAR_FORECAST_CHART: {
    ViewId: 3,
    ViewName: 'SOP',
    CardId: 23,
    CardName: '4 Year Forecast View',
    ChartId: 1,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
    ChartLabel: 'year-forecast',
    ChartType: 'yearforecastlinechart',
  },
  SOP_YEAR_FORECAST_CHART_LABEL: {
    ViewId: 3,
    ViewName: 'SOP',
    CardId: 23,
    CardName: '3 Year Forecast View',
    ChartId: 2,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
    ChartLabel: 'year-forecast',
    ChartType: 'yearforecastlinechart',
  },
  SOP_YEAR_FORECAST_CHART_MONTHLABEL: {
    ViewId: 3,
    ViewName: 'SOP',
    CardId: 23,
    CardName: '3 Year Forecast View',
    ChartId: 3,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
    ChartLabel: 'year-forecast',
    ChartType: 'yearforecastlinechart',
  },
  SOP_AGGFORECAST_CHART: {
    ViewId: 3,
    ViewName: 'SOP',
    CardId: 24,
    CardName: 'Aggregated Forecast Accuracy',
    ChartId: 1,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
    ChartLabel: 'agg-forecast',
    ChartType: 'aggforecastlinechart',
  },
  SOP_AGG_ACCURACY_FORECAST_CHART: {
    ViewId: 3,
    ViewName: 'SOP',
    CardId: 26,
    CardName: 'Aggregated Forecast Accuracy',
    ChartId: 1,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
    ChartLabel: 'agg-forecast',
    ChartType: 'accuracyforecastlinechart',
  },
  SOP_AGGFORECAST_CHART_LABEL: {
    ViewId: 3,
    ViewName: 'SOP',
    CardId: 26,
    CardName: 'Aggregated Forecast Accuracy',
    ChartId: 2,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
    ChartLabel: 'agg-forecast',
    ChartType: 'aggforecastlinechart',
  },
  SOP_SHORTTERM_BIAS_26WKS_CHART: {
    ViewId: 3,
    ViewName: 'SOP',
    CardId: 29,
    CardName: 'Short-term Forecast BIAS',
    ChartId: 1,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
    ChartLabel: 'short-termforecast',
    ChartType: 'barChartOptionsSop',
  },
  SOP_SHORTTERM_BIAS_4WKS_CHART: {
    ViewId: 3,
    ViewName: 'SOP',
    CardId: 27,
    CardName: 'Short-term Forecast BIAS',
    ChartId: 1,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
    ChartLabel: 'short-termforecast',
    ChartType: 'barChartOptionsSop',
  },
  SOP_FORECASTCHANGE_PREVIOUS_GRID: {
    ViewId: 3,
    ViewName: 'SOP',
    CardId: 25,
    CardName: 'Forecast % Change (Curr vs Prev S&OP Forecast)',
    ChartId: 1,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
  },
  SOP_FORECASTCHANGE_FINANCIAL_GRID: {
    ViewId: 3,
    ViewName: 'SOP',
    CardId: 25,
    CardName: 'Forecast % Difference (Current S&OP vs. Financial Forecast)',
    ChartId: 2,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
  },
  //Business plan configuration
  BP_BANNER: {
    ViewId: 4,
    ViewName: 'Business Plan Performance',
    CardId: 32,
    CardName: 'Business Plan Performance',
    ChartId: 1,
    IsActive: 'Y',
  },
  BP_NETSALES_CHART: {
    ViewId: 4,
    ViewName: 'Business Plan',
    CardId: 30,
    CardName: 'Net Sales for',
    ChartId: 1,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
    ChartLabel: 'net-sales',
    ChartType: 'BusinessplanNetSalesLineChart',
  },
  BP_NETSALES_CHARTGRID: {
    ViewId: 4,
    ViewName: 'Business Plan',
    CardId: 31,
    CardName: 'Net Sales for',
    ChartId: 1,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
  },
  BP_NETSALES_EVENTS_DETAILS: {
    ViewId: 4,
    ViewName: 'Business Plan',
    CardId: 38,
    CardName: 'Net Sales for',
    ChartId: 1,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
  },
  BP_UNADJUSTED_GRID: {
    ViewId: 4,
    ViewName: 'Business Plan',
    CardId: 33,
    CardName: 'Disease Area Data for',
    ChartId: 1,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
  },
  BP_ADJUSTED_GRID: {
    ViewId: 4,
    ViewName: 'Business Plan',
    CardId: 33,
    CardName: 'Disease Area Data for',
    ChartId: 2,
    MyViewEnabled: 'Y',
    IsActive: 'Y',
  },
};
