import classes from './DefaultMessage.module.scss';

const DefaultMessage = ({ onOpenConfigureModal }) => {
  return (
    <div className={classes.defaultMessage}>
      <h3 className={classes.defaultMessageHeading}>{`No Cards Configured`}</h3>
      <p className={classes.defaultMessageText}>
        Please{' '}
        <span onClick={onOpenConfigureModal} className="link">
          click here
        </span>{' '}
        to configure your cards!
      </p>
    </div>
  );
};

export default DefaultMessage;
