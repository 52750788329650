import { Button, Modal } from 'antd';

const DialogBox = ({
  title,
  isModalOpen,
  handleOk,
  handleCancel,
  children,
  okText,
  width,
  footer = null,
}) => {
  const footerButtons = [
    <Button key="back" onClick={handleCancel}>
      Cancel
    </Button>,
    <Button key="submit" type="primary" onClick={handleOk}>
      Submit
    </Button>,
  ];
  return (
    <>
      <Modal
        title={title}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={okText}
        cancelText="Cancel"
        width={width}
        cancel
        zIndex={20000}
        footer={footer ? footer : footerButtons}
      >
        {children}
      </Modal>
    </>
  );
};

export default DialogBox;
