import { Route, Routes } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { getQlikSenseUnformattedData } from './service/qlik.service';
import BusinessPlanContainer from './components/businessplan/BusinessPlanContainer';
import PerformanceContainer from './components/performance/PerformanceContainer';
import MyViewContainer from './components/myview/MyViewContainer';
import { useInitializsApp } from './hook/useInitializeApp';
import { MY_VIEW_CATEGORY, VREPLACESTRING } from './constants';

const AppRoutes = () => {
  /*
    This custom hook is used to initialize the app and call some intial api
    such as fetching expressions, filter Api. 
  */
  const {
    showBrand,
    showPortfolio,
    showSop,
    brandExpressions,
    card_config_db,
  } = useInitializsApp();

  const getSectionsExpression = (data, CardId, ChartId, currentExpression) => {
    const expressionData = data
      .filter((e) => e?.CardId === CardId && e?.ChartId === ChartId)
      .map((obj) =>
        JSON.stringify({
          CardName: obj?.CardName,
          id: obj?.KpiId,
          name: obj?.KpiName,
          expression: obj.Expression.replaceAll(
            VREPLACESTRING,
            currentExpression,
          ),
        }),
      );

    let dimension = [];
    const dimensionNames = new Set();

    data
      .filter((e) => e?.CardId === CardId && e?.ChartId === ChartId)
      .forEach((obj) => {
        if (obj?.DimensionName && !dimensionNames.has(obj.DimensionName)) {
          dimensionNames.add(obj?.DimensionName);

          const dimensionName = obj?.DimensionName?.split(';');
          dimensionName?.map((item) => {
            const dim = {
              dimensionType: 'Category',
              dimensionName: item,
            };
            dimension.push(JSON.stringify(dim));
          });
        }
      });
    return {
      expression: expressionData,
      dimension: dimension,
    };
  };
  const getQlikData = async (
    data, // expression
    cardName, // card name
    dim = [], // dimension
    type = undefined, // chart data type = line, bar
    isSortDate = false,
    currentCard,
  ) => {
    const payload = {
      connection_details: [
        {
          dimension: dim,
          appId:
            currentCard?.ViewName?.split(' ')[0] === 'S&OP'
              ? process.env.APP_ID_SOP
              : process.env.APP_ID,
          expression: data,
        },
      ],
      dataType: type,
      isSortDate: isSortDate,
    };

    try {
      const postResponse = await getQlikSenseUnformattedData(payload); // Wait for API response

      if (postResponse && postResponse.length > 0) {
        const dataFromResponse = postResponse[0]; // Extract the first result

        return dataFromResponse; // Return the actual data synchronously
      }
      return null; // Return null if no valid response
    } catch (error) {
      return null; // Return null in case of an error
    }
  };

  // is week data fetching with use query
  const { data: currentWeek } = useQuery({
    queryKey: [
      'As of week',
      brandExpressions?.length,
      brandExpressions,
      Object.keys(card_config_db)?.length,
    ],
    queryFn: () => {
      const getCurrentWeek = getSectionsExpression(
        brandExpressions,
        card_config_db?.AS_OF_WEEK_TEXT?.CardId,
        card_config_db?.AS_OF_WEEK_TEXT?.ChartId,
      );
      return getQlikData(
        getCurrentWeek.expression,
        'Week',
        getCurrentWeek.dimension,
        'single',
      );
    },
    enabled:
      brandExpressions?.length > 0 && Object.keys(card_config_db)?.length > 0,
    onError: () => {
      toast.error(
        `Error occured in  [As Of Week] - Please try after sometime.`,
      );
    },
  });

  return (
    <Routes>
      {showPortfolio && (
        <Route
          path="analyticshub/saif"
          element={
            <PerformanceContainer currentTab={'portfolio'} week={currentWeek} />
          }
        />
      )}
      {showBrand && (
        <Route
          path="analyticshub/saif"
          element={
            <PerformanceContainer currentTab={'brand'} week={currentWeek} />
          }
        />
      )}
      {showSop && (
        <Route
          path="analyticshub/saif"
          element={
            <PerformanceContainer currentTab={'sop'} week={currentWeek} />
          }
        />
      )}
      <Route
        path="analyticshub/saif/portfolio"
        element={
          <PerformanceContainer currentTab={'portfolio'} week={currentWeek} />
        }
      />
      <Route
        path="analyticshub/saif/brand"
        element={
          <PerformanceContainer currentTab={'brand'} week={currentWeek} />
        }
      />
      <Route
        path="analyticshub/saif/sop"
        element={<PerformanceContainer currentTab={'sop'} week={currentWeek} />}
      />

      <Route
        path="analyticshub/saif/businessplan"
        element={
          <BusinessPlanContainer
            currentTab={'businessplan'}
            week={currentWeek}
          />
        }
      />
      <Route
        path="analyticshub/saif/myview"
        element={<MyViewContainer currentTab={MY_VIEW_CATEGORY?.MYVIEW} />}
      />
    </Routes>
  );
};

export default AppRoutes;
