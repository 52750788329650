import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {
  MY_VIEW,
  MY_VIEW_CATEGORY,
  MYVIEW_NOTIFICATION,
  SUCCESS,
} from '@constant';
import ConfigureMyView from './configuremyview/ConfigureMyView';
import {
  addCardToView,
  deleteCardFromView,
  updateCardView,
} from '@service/myview.service';
import {
  setMyViewCardsList,
  setUpdateMyViewCardsList,
} from '@store/brand/brandViewSlice';
import { fetchOptions } from '@service/qlik.service';
import { Notification } from '@elements/notification/Notification';
import MyViewTab from './myviewtab/MyViewTab';
import classes from './MyViewContainer.module.scss';
import {
  setDataOptions,
  setEventOptions,
  setMetricOptions,
} from '@store/bplan/bplanViewSlice';
import { getAllComments } from '@service/comments.service';
import { getUserUnixId } from '@utils/getUser';

const MyViewContainer = ({ currentTab, labelytd }) => {
  const unixId = getUserUnixId();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [key, setKey] = useState(MY_VIEW_CATEGORY?.MYVIEW);
  const [showModalContainer, setShowModalContainer] = useState(false);
  const [editData, setEditData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [currentPage, setCurrentPage] = useState(false);
  const [allComments, setAllComments] = useState([]);
  const [addHandleLoader, setAddHandleLoader] = useState(false);

  const brandExpressions = useSelector(
    (state) => state?.brandPerformance?.brandExpressions,
  );
  // fetching cards from the redux store
  const myViewCards = useSelector(
    (state) => state?.brandPerformance?.myViewCardsList,
  );

  const bpDataOptions = useSelector((state) => state?.bpPlan?.dataOptions); // fetching data filter options from the store
  const bpEventOptions = useSelector((state) => state?.bpPlan?.eventOptions); // fetching events filter options from the store
  const metricOptions = useSelector((state) => state?.bpPlan?.metricOption); // fetching metric filter options from the store

  useEffect(() => {
    setKey(currentTab);
  }, [currentTab]);

  const onSetTabKey = (tab) => {
    setKey(tab);
    navigate(`/analyticshub/saif/${tab}`);
  };

  const getDataFiltersOption = async () => {
    const data = await fetchOptions('Version Name', {});
    dispatch(setDataOptions(data)); // storing data filter options in the store
  };

  const getEventFiltersOption = async () => {
    const data = await fetchOptions('EventType', {});
    dispatch(setEventOptions(data)); // storing event filter options in the store
  };

  const getAllCommentsForUnixId = async () => {
    const data = await getAllComments(unixId);
    setAllComments(data);
  };
  const getMetricFiltersOption = async () => {
    const data = await fetchOptions('Measure S&OP', {}, 'sop');
    dispatch(setMetricOptions(data));
  };

  useEffect(() => {
    if (bpDataOptions?.length === 0 && bpEventOptions?.length === 0) {
      // api is called only when filter options are not present in the store.
      getDataFiltersOption();
      getEventFiltersOption();
    }
    getAllCommentsForUnixId();
    if (metricOptions?.length === 0) {
      getMetricFiltersOption();
    }
  }, []);

  const tabView = {
    Portfolio: 1,
    Brand: 2,
    'S&OP': 3,
    Business: 4,
  };

  const handleAddToView = async (
    currentKpiTab,
    filterSelection,
    currentExpression,
    cardId,
    dimensionName,
    chartId,
    chartType,
    chartLabel,
    dimensionId,
    KpiId,
    chartDataType,
    chartData,
    category,
    formattedGridData,
    gridColumns,
    varianceChartData,
    varianceGridData,
    chartText,
    viewId,
    labelytd,
    currentNetSalesStore,
    bpData,
    scatterEvents,
  ) => {
    if (myViewCards?.length === 20) {
      //display warning message if the cards added reaches a certain limit.
      Notification('warning', MYVIEW_NOTIFICATION.limitExceed);
    } else {
      setAddHandleLoader(true);
      const payload = {
        ViewId: viewId || tabView[currentKpiTab],
        ViewName: `${currentKpiTab} Performance`,
        CardId: cardId,
        CardName: dimensionName,
        IsActive: 'Y',
        PositionIndex: myViewCards?.length + 1,
        UnixId: unixId,
        Filter: JSON.stringify(filterSelection),
        Expression: currentExpression,
        ChartId: chartId,
        ChartType: chartType,
        ChartLabel: chartLabel,
        DimensionId: dimensionId,
        KpiId: KpiId,
        ChartDataType: chartDataType,
      };
      const data = await addCardToView(payload);
      if (data) {
        const cardData = {
          ...payload,
          ID: data?.cardId,
          chartData: chartData,
          category: category,
          gridData: formattedGridData,
          gridColumns: gridColumns ?? [],
          varianceChartData: varianceChartData,
          varianceGridData: varianceGridData,
          chartText: chartText,
          labelytd: labelytd,
          currentNetSales: currentNetSalesStore,
          bpData: bpData,
          scatterEvents: scatterEvents,
        };

        dispatch(
          dimensionName.startsWith('Copy of')
            ? setUpdateMyViewCardsList([...myViewCards, cardData])
            : setMyViewCardsList([...myViewCards, cardData]),
        );
      }
      Notification(SUCCESS, MYVIEW_NOTIFICATION.add);
      setAddHandleLoader(false);
    }
  };

  const handleUdpateMyView = async (
    currentKpiTab,
    filterSelection,
    currentExpression,
    cardId,
    dimensionName,
    chartId,
    chartType,
    chartLabel,
    dimensionId,
    id,
    chartDataType,
    chartData,
    category,
    formattedGridData,
    gridColumns,
    varianceChartData,
    varianceGridData,
    chartText,
    viewId,
    labelytd,
    currentNetSalesStore,
    bpData,
    scatterEvents,
  ) => {
    const payload = {
      ViewId: tabView[currentKpiTab],
      ViewName: `${currentKpiTab} Performance`,
      CardId: cardId,
      CardName: dimensionName,
      Filter: JSON.stringify(filterSelection),
      Expression: currentExpression,
      ChartId: chartId,
      ChartType: chartType,
      ChartLabel: chartLabel,
      DimensionId: dimensionId,
      KpiId: tabView[currentKpiTab],
      Id: id,
      ChartDataType: chartDataType,
    };

    const data = await updateCardView(payload);
    if (data) {
      const cardData = {
        ...payload,
        chartData: chartData,
        category: category,
        gridData: formattedGridData,
        gridColumns: gridColumns ?? [],
        varianceChartData: varianceChartData,
        varianceGridData: varianceGridData,
        chartText: chartText,
        labelytd: labelytd,
        currentNetSalesStore: currentNetSalesStore,
        bpData: bpData,
        scatterEvents: scatterEvents,
      };
      const filteredCard = myViewCards?.map((item) => {
        if (item?.ID === id) {
          return { ID: id, ...payload, ...cardData };
        }
        return item;
      });

      dispatch(setUpdateMyViewCardsList(filteredCard));
    }
    Notification(SUCCESS, MYVIEW_NOTIFICATION.update);
  };

  const onRemoveFromView = async (id) => {
    const response = await deleteCardFromView(id, unixId);
    if (response?.message) {
      const filteredCards = myViewCards?.filter((item) => item?.ID !== id);
      dispatch(setUpdateMyViewCardsList(filteredCards));
      Notification(SUCCESS, MYVIEW_NOTIFICATION.delete);
    }
  };

  const onDuplicateCard = (id) => {
    const currentCard = myViewCards?.filter((item) => item?.ID === id)[0];

    if (currentCard) {
      handleAddToView(
        currentCard?.ViewName?.split(' ')[0] ||
          currentCard?.viewName?.split(' ')[0],
        JSON.parse(currentCard?.Filter),
        currentCard?.Expression,
        currentCard?.CardId,
        `Copy of ${currentCard?.CardName}`,
        currentCard?.ChartId,
        currentCard?.ChartType,
        currentCard?.ChartLabel,
        currentCard?.DimensionId,
        currentCard?.KpiId,
        currentCard?.ChartDataType,
        currentCard?.chartData,
        currentCard?.category,
        currentCard?.gridData,
        currentCard?.gridColumns ?? [],
        currentCard?.varianceChartData,
        currentCard?.varianceGridData,
        currentCard?.chartText,
        currentCard?.ViewId,
        currentCard?.labelytd,
        currentCard?.currentNetSalesStore,
        currentCard?.bpData,
        currentCard?.scatterEvents,
      );
    }
  };

  const onEditCard = (id) => {
    const currentCard = myViewCards?.filter((item) => item?.ID === id);
    setEditData(currentCard[0]);
    setEditMode(true);
    setShowModalContainer(true);
    setCurrentPage(true);
  };

  return (
    <div className={classes['myview-container']}>
      <div className={classes['container-tab']}>
        <Tabs
          id={MY_VIEW_CATEGORY?.MYVIEW}
          activeKey={key}
          onSelect={(k) => {
            onSetTabKey(k);
          }}
          className="mb-3"
          style={{ width: '100%' }}
        >
          <Tab
            eventKey={MY_VIEW_CATEGORY?.MYVIEW}
            title={
              <span
                className={`d-flex title__bold title__base ${classes['title']}`}
              >
                <div>
                  <div>{MY_VIEW}</div>
                </div>
              </span>
            }
          ></Tab>
        </Tabs>
        <ConfigureMyView
          handleAddToView={handleAddToView}
          addHandleLoader={addHandleLoader}
          showModalContainer={showModalContainer}
          setShowModalContainer={setShowModalContainer}
          editData={editData}
          page={currentPage}
          setCurrentPage={setCurrentPage}
          handleUdpateMyView={handleUdpateMyView}
          editMode={editMode}
          setEditMode={setEditMode}
          eventFilterOptions={bpEventOptions}
          dataFilterOptions={bpDataOptions}
          labelytd={labelytd}
          setEditData={setEditData}
          metricOptions={metricOptions}
          allComments={allComments}
        />
      </div>
      {/* break down component for my view where all cards are displayed.*/}
      <MyViewTab
        myViewCards={myViewCards}
        brandExpressions={brandExpressions}
        onRemoveFromView={onRemoveFromView}
        onDuplicateCard={onDuplicateCard}
        onEditCard={onEditCard}
        onOpenConfigureModal={() => setShowModalContainer(true)}
        dataFilterOptions={bpDataOptions}
        eventFilterOptions={bpEventOptions}
        labelytd={labelytd}
        allComments={allComments}
      />
    </div>
  );
};

export default MyViewContainer;
