import axiosDBClient from '../../axiosDBClient';

export const getComplianceMessage = async (unixId) => {
  const response = await axiosDBClient.get('/compliance', {
    params: { unixId: unixId },
  });

  if (response.error) {
    throw new Error('Could not fetch data!');
  }

  return response;
};

export const addCompliance = async (payload) => {
  try {
    const response = await axiosDBClient.post('/compliance', {
      ...payload,
    });
    if (response.error) {
      throw new Error('Could not add compliance!');
    }
    return response;
  } catch (error) {
    return error?.response || error;
  }
};
