export function getSeries({ chartType, chartData, showEndLabel }) {
  if (chartType == 'BusinessplanNetSalesLineChart') {
    let data = chartData?.map((item) => ({
      ...item,
      showSymbol: false,
      lineStyle: {
        ...item?.lineStyle,
        width: 3,
      },
      type: 'line',
      endLabel: {
        show: showEndLabel,
        formatter: item?.name,
        color: item?.lineStyle?.color,
        overflow: 'break',
      },
      labelLayout: {
        hideOverlap: true,
        moveOverlap: 'shiftY',
        width: 120,
      },
    }));
    return data;
  }
  return [];
}
