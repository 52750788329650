import { List, Modal } from 'antd';
import classes from './Comment.module.scss';
import { GETCOMMENTCREATEDDATE } from '@utils/utils';
import { CLOSE } from '@constant';

const NarrationComment = ({ visible, onClose, commentsData, title }) => {
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      width={600}
      height={606}
      title={
        <div className={classes['comment-container']}>
          <i
            className={`icon icon__comments_black ${classes['icon-align-comment']}`}
          ></i>
          <p className={classes['header']}>{title}</p>
        </div>
      }
    >
      <div className={classes['card-modal-wrap']}>
        <div id="comment-scroll" className={classes['align-wrap']}>
          <div className={classes['catalogue-wrap']}>
            <List
              itemLayout="horizontal"
              dataSource={commentsData}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={
                      <div className={classes['catalogue-title']}>
                        <div>
                          <span className={classes['comment-info']}>
                            {GETCOMMENTCREATEDDATE(item?.Updated_Date)}
                          </span>
                        </div>
                      </div>
                    }
                    description={
                      <div className={classes['catalogue-desc']}>
                        {item?.Narrative}
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </div>
        </div>
      </div>
      <div className={classes['modal-footer']}>
        <p className={classes['modal-close']} onClick={onClose}>
          {CLOSE}
        </p>
      </div>
    </Modal>
  );
};

export default NarrationComment;
