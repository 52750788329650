import { CARD_CONFIG } from '@utils/cardConfig';
import {
  CHART,
  CHART_ICON_WHITE,
  SUB_VIEWNAMES,
  TABLE,
  TABLE_ICON_WHITE,
} from '../../../constants';

export const BRAND_VIEWNAME = 'Brand Performance';

//Brand Container
export const EQUILIZED_OUTS = 'Equilized OUTs';
export const FORECASTED_OUTS = 'Forecasted OUTs';
export const PATIENTS = 'Patients';
export const PATIENT = 'Patient';
export const NET_SALES = 'Net Sales';
export const FORECASTED_SALES = 'Forecasted Sales';
export const BANNER_HEADER = 'Brand Performance';
export const YOY_EQUILIZED_OUTS = '%YoY Equilized OUTs';
export const YOY_NET_SALES = '% YoY Net Sales';
export const YOY = '%YoY';

export const STATE_BRAND_CONTAINER = {
  BANNER: 'banner',
  MONTHLY: 'monthly',
  QUATERLY: 'quarterly',
  YEARLY: 'yearly',
  MONTHLYPREVIOUS: 'monthlyPrev',
  QUATERLYPREVIOUS: 'quarterlyPrev',
};

export const QUERY_ERROR_BRAND = {};
export const QUERY_KEY_BRAND = {
  BANNER: 'Brand Banner details',
  MONTHLY: 'Monthly details',
  QUATERLY: 'Quarterly details',
  SUMMARY_DETAILS: 'Summary details',
  KPI: 'Kpis details',
};

//Brand Sumary

export const ACTUAL_SALES = 'Actual Sales';
export const ACTUAL = 'Actual';
export const WK_MA = 'wk MA';

export const STATE_BRAND_SUMMARY = {
  YOYSALES: 'yoySales',
  YOYOUTSGRID: 'yoyOutsGrid',
  YTDVARIANCE: 'ytdVariance',
  YOYVARIANCE: 'yoyVariance',
  YTDROYDEMAND: 'ytdRoyDemand',
  YTDROYDEMANDTEXT: 'ytdRoyDemandText',
  SALESDEMAND: 'salesDemand',
  YOYOUTS: 'yoyOuts',
  YOYOUTSCHARTLABEL: 'youOutsLabel',
  YOYSALESGRID: 'yoySalesGrid',
  YTDROYDEMANDYTD: 'ytdRoyDemandYtd',
  YTDROYDEMAND4WEEK: 'ytdRoyDemand4Week',
  YTDROYSALESYTD: 'ytdRoySalesYtd',
  YTDROYSALES4WEEK: 'ytdRoySales4Week',
};

export const BRAND_SECTION_EXPRESSION_NAME = {
  CHARTDATA1: 'YTD Variance Drivers (T0, $M)',
  CHARTDATA2: 'YTD YoY Variance Drivers (PY YTD, $M)',
  CHARTDATA3DEMAND: 'Demand YTD & RoY',
  CHARTDATA3DEMANDTEXT: 'Demand YTD & RoY Text',
  CHARTDATASALESDEMAND: 'Sales & Demand',
  CHARTDATAYOYOUTS: 'Year over Year OUTs Growth',
  CHARTDATAYOYSALES: 'Year Over Year Sales Growth',
  CHARTDATA3SALESYTD: 'Sales YTD & RoY',
};

export const CARD_TITLE_BRAND = {
  YOY_GROWTH: 'YoY OUTs Growth for',
  YOY_SALES: 'YoY Sales Growth for',
  DEMAND: 'YTD Variance vs.',
  YTD_ROY: 'YTD & RoY',
  YTD_PY: 'YTD YoY Variance Drivers (PY YTD, $M) for',
  YTD_VARIANCE: 'YTD Variance Drivers',
};
export const CARD_ID_BRAND = {
  YOY_GROWTH: 'yoy-growth',
  YOY_SALES: 'yoy-sales',
  DEMAND: 'sales-demand',
  YTD_ROY: 'ytd-roy',
  YTD_PY: 'ytd-py',
  YTD_VARIANCE: 'ytd-variance',
};
export const CHART_TYPE_BRAND = {
  YOY_GROWTH: 'linechart',
  YOY_SALES: 'stackedBar',
  DEMAND: 'sdline',
  YTD_ROY: 'demandlineChart',
  YTD_PY: 'bar',
  YTD_VARIANCE: 'bar',
};

//Brand Monthly
export const CURRENT = 'current';
export const PREVIOUS = 'previous';
export const CURRENT_YEAR = new Date().getFullYear();
export const INFO_MESSAGE_MONTHLY = 'Monthly Reports for';

//Brand Quaterly

export const INFO_MESSAGE_QUATERLY = 'Quarterly Reports for';

export const METRIC = 'Metric';

//excel

export const EXCEL_DOWNLOAD_MONTHLY = {
  MONTHLY_CURRENT: 'Monthly Current.xlsx',
  MONTHLY_PREVIOUS: 'Monthly Previous.xlsx',
};

export const EXCEL_DOWNLOAD_KPIS = {
  QUARTERLY: 'Quarterly.xlsx',
  YEARLY: 'Yearly.xlsx',
  KPI: 'Kpi.xlsx',
};

export const EXCEL_DOWNLOAD_QUATERLY = {
  CURRENT: 'Quarterly Current.xlsx',
  PREVIOUS: 'Quarterly Previous.xlsx',
};

export const YTD_ROY_LINESTYLE = {
  T0: { type: 'dotted', color: '#AF1685' },
  Trend: { type: 'dotted', color: '#56A944' },
  '12 wk MA': { color: '#DB930B' },
  Actual: { color: '#13294B' },
  Low: { type: 'dotted', color: '#CBCBCB' },
  High: { type: 'dotted', color: '#919698' },
};

const SUMMARY_CARD_ID = [
  CARD_CONFIG.BRAND_YTD_VARIANCE_CHART.CardId,
  CARD_CONFIG.BRAND_YTD_YOY_VARIANCE_CHART.CardId,
  CARD_CONFIG.BRAND_YTD_ROY_CHART.CardId,
  CARD_CONFIG.BRAND_YTD_SD_CHART.CardId,
  CARD_CONFIG.BRAND_YOY_SALES_CHART.CardId,
  CARD_CONFIG.BRAND_YOY_OUTS_GROWTH_CHART.CardId,
];
const QUARTERLY_CARD_ID = [
  CARD_CONFIG.BRAND_QUARTERLY_CURRENTYEAR_GRID.CardId,
  CARD_CONFIG.BRAND_QUARTERLY_PREVIOUSYEAR_GRID.CardId,
];
const MONTHLY_CARD_ID = [
  CARD_CONFIG.BRAND_MONTHLY_CURRENTYEAR_GRID.CardId,
  CARD_CONFIG.BRAND_MONTHLY_PREVIOUSYEAR_GRID.CardId,
];

export const getBrandViewTypes = (
  viewType,
  showSummary,
  showQuaterly,
  showMonthly,
  showCardIds,
) => [
  ...(showSummary && SUMMARY_CARD_ID.some((id) => showCardIds.includes(id))
    ? [
        {
          value: SUB_VIEWNAMES?.SUMMARY,
          key: (
            <>
              <i
                className={`icon icon__${
                  viewType === SUB_VIEWNAMES?.SUMMARY ? CHART_ICON_WHITE : CHART
                }`}
              ></i>
              Summary
            </>
          ),
        },
      ]
    : []),

  ...(showQuaterly && QUARTERLY_CARD_ID.some((id) => showCardIds.includes(id))
    ? [
        {
          value: SUB_VIEWNAMES?.QUARTERLY,
          key: (
            <>
              <i
                className={`icon icon__${
                  viewType === SUB_VIEWNAMES?.QUARTERLY
                    ? TABLE_ICON_WHITE
                    : TABLE
                }`}
              ></i>
              Quarterly
            </>
          ),
        },
      ]
    : []),
  ...(showMonthly && MONTHLY_CARD_ID.some((id) => showCardIds.includes(id))
    ? [
        {
          value: SUB_VIEWNAMES?.MONTHLY,
          key: (
            <>
              <i
                className={`icon icon__${
                  viewType === SUB_VIEWNAMES?.MONTHLY ? TABLE_ICON_WHITE : TABLE
                }`}
              ></i>
              Monthly
            </>
          ),
        },
      ]
    : []),
];

export const VIEW_TYPES_YEAR = [
  {
    value: 'current',
    key: <>Quarterly</>,
  },

  {
    value: 'yearly',
    key: <>Yearly</>,
  },
];

export const PUBLISH_DATE = 'Publish Date: Nov 1,2024';
export const KPIS = 'KPIs';
export const CONSTANTS_BANNER_MAPPING = {
  netsale: 'Net Sales',
  forecastedsales: 'Forecasted YTD Sales',
  yoynetsales: '% YoY Net Sales',
  equilizedouts: 'Equilized OUTs',
  forecastedouts: 'Forecasted YTD OUTs',
  yoyequilizedouts: '%YoY Equilized OUTs',
  patients: 'Patients',
  pysales: 'PY YTD Sales',
  pyouts: 'PY YTD OUTs',
  fynetsales: 'FY Projection Net Sales',
  fyeqout: 'FY Projection Equilized OUTs',
  netsaleversion: 'Net Sales vs T0',
  eqoutversion: 'Equilized OUTs vs T0',
  fyprojectionsales: 'FY Projection Sales',
  fyprojectionouts: 'FY Projection OUTs',
  ytdactualsales: 'YTD Actual Sales',
  ytdactualout: 'YTD Actual OUTs',
};

export const CONSTANT_BANNER_NAME = {
  NETSALE: 'netsale',
  FORECASTEDSALES: 'forecastedsales',
  YOYNETSALES: 'yoynetsales',
  EQUILIZEDOUTS: 'equilizedouts',
  FORECASTEDOUTS: 'forecastedouts',
  YOYEQUILIZEDOUTS: 'yoyequilizedouts',
  PATIENTS: 'patients',
  PYSALES: 'pysales',
  PYOUTS: 'pyouts',
};
