import { colorLabelSpan } from './colorLabelSpan';

export const yearforecastlinechart = ({
  chartData,

  showEndLabel,

  category,

  currentNetSales,
  expand,
}) => {
  return {
    grid: expand
      ? {
          containLabel: true,
          left: '6%',
          right: '8%',
          bottom: 90, // Add extra space for the scroller
        }
      : { bottom: 90, containLabel: true, right: '12%' },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params) {
        let tooltipContent = ' ';
        params?.forEach((param) => {
          const color = chartData?.filter((item) => {
            return item?.name === param?.seriesName;
          })[0]?.lineStyle?.color;
          const value =
            param?.value === null || param?.value === undefined
              ? '-'
              : param?.value;

          let suffix = '';
          if (currentNetSales?.toLowerCase()?.includes('packs')) {
            suffix = 'K';
          } else if (currentNetSales?.toLowerCase()?.includes('sales')) {
            suffix = 'M';
          } else {
            suffix = 'Kg';
          }
          const data =
            colorLabelSpan({ color }) +
            ' ' +
            param?.seriesName +
            ' : ' +
            (value === '-'
              ? '-'
              : suffix === 'M'
              ? `$${value?.toLocaleString()}${suffix}`
              : `${value?.toLocaleString()}${suffix}`) +
            '<br/>';
          tooltipContent += data;
        });
        return tooltipContent;
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      data: category,
      axisLabel: {
        show: true,
        rotate: 90,
        color: '#000000',
        fontSize: 12,
        interval: 0,
      },
      axisTick: { show: false },
    },

    dataZoom: [
      {
        type: 'slider',
        show: true,
        xAxisIndex: [0],
        start: 0,
        end: 0,
        minValueSpan: 35,
        maxValueSpan: 35,
        brushSelect: false,
        handleSize: '10%',
        height: 14,
        showDetail: false,
        backgroundColor: '#F1F1F1',
        borderRadius: 10,
        fillerColor: '#B5B5B5',
        borderColor: '#FFFFFF',
        z: 2,
        opacity: 1,
        bottom: -9,
      },
    ],
    yAxis: {
      type: 'value',
      name: currentNetSales?.toLowerCase()?.includes('packs')
        ? 'Avg. Units Sales (K Packs)'
        : currentNetSales?.toLowerCase()?.includes('sales')
        ? 'Avg. Units Sales ($M)'
        : 'Avg. Units Sales (Kg)',

      nameLocation: 'middle',
      nameGap: 40,
      nameTextStyle: {
        color: '#000000',
        // fontFamily: " Gene Sans",
        fontSize: 13,
      },
      splitLine: {
        show: false,
      },
      axisLine: { show: true },
      axisLabel: {
        show: true,
      },
    },
    series: chartData?.map((item) => {
      return {
        ...item,
        showSymbol: false,
        lineStyle: {
          ...item?.lineStyle,
          width: 3,
        },
        type: 'line',
        endLabel: {
          show: showEndLabel,
          formatter: item?.name,
          color: item?.lineStyle?.color,
          position: 'left',
          offset: [0, -10],
        },
        labelLayout: {
          hideOverlap: true,
          moveOverlap: 'shiftY',
        },
      };
    }),
  };
};
