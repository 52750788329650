import { toast } from 'react-toastify';
import axiosDBClient from '../../axiosDBClient';

export const getComments = async (unixId, brand) => {
  const response = await axiosDBClient.get('/comments', {
    params: { unixId: unixId, brand: brand },
  });

  if (response.error) {
    throw new Error('Could not fetch comments data!');
  }

  return response.data.result;
};

export const getAllComments = async (unixId) => {
  const response = await axiosDBClient.get('comments/allcomment', {
    params: { unixId: unixId },
  });

  if (response.error) {
    throw new Error('Could not fetch comments data!');
  }

  return response.data.result;
};

export const addComment = async (payload) => {
  try {
    const response = await axiosDBClient.post('comments/comment', {
      ...payload,
    });
    if (response.error) {
      throw new Error('Could not add comment!');
    }

    return response.data.message;
  } catch (error) {
    console.log('error', error);
  }
};

export const deleteComment = async (commentId, unixId) => {
  const setDeleteComment = async () => {
    const response = await axiosDBClient.delete(
      `comments/comment/${commentId}`,
      {
        params: {
          unixId: unixId,
        },
      },
    );
    if (response.data.error) {
      throw new Error('Could not delete comment!');
    }
    return response.data;
  };
  try {
    const data = await setDeleteComment();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const updateComment = async (payload) => {
  const updateComment = async () => {
    const response = await axiosDBClient.put(`comments/comment`, {
      ...payload,
    });
    return response.data;
  };

  try {
    const data = await updateComment();
    return data;
  } catch (error) {
    toast.error(`Error occured - Please try after sometime.`);
    console.log(error.message);
  }
};
// API Initatior for Narration Comment
export const narrationComment = async (payload) => {
  return await axiosDBClient.post('/comments/comment/narration', {
    ...payload,
  });
};
