/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserUnixId } from '@utils/getUser';
import { isCookieExpired } from '@utils/token.expired';
import {
  getExpressions,
  getFilters,
  getUserAccessDetails,
  getVersion,
} from '@service/expression.service';
import { fetchQlikToken } from '@service/qlik.service';
import {
  getMyViewCardsDetails,
  getMyViewKPIDetails,
} from '@service/myview.service';
import {
  setBrandExpressions,
  setCardConfig,
  setFilters,
  setMyViewCardsList,
  setMyViewKpiDetails,
  setUserAccesses,
  setUserSquadMap,
  setVersionType,
} from '@store/brand/brandViewSlice';
import { TABNAMES } from '@constant';
import { setOptions } from '@store/globalFilter/globalFilterSlice';

export const useInitializsApp = () => {
  const dispatch = useDispatch();
  const unixId = getUserUnixId();
  const brandExpressions = useSelector(
    (state) => state?.brandPerformance?.brandExpressions,
  );
  const card_config_db = useSelector(
    (state) => state?.brandPerformance?.cardConfig,
  );
  const UserAccess = useSelector((state) => state?.brandPerformance?.userAcces);
  const viewFlags = UserAccess?.reduce((acc, item) => {
    acc[item?.ViewName] = true;
    return acc;
  }, {});

  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(true);

  let cookieCheckerTime = process.env.COKKIECHECKERTIME || 15;

  useEffect(() => {
    const interval = setInterval(() => {
      isCookieExpired(unixId);
    }, cookieCheckerTime * 60 * 1000);

    // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchData(); // Call the async function
  }, [unixId]); // Adding unixId as a dependency in case it changes

  const getUserAccess = async () => {
    const response = await getUserAccessDetails(unixId);
    const uniqueSquadMaps = [
      ...new Set(response?.result?.map((item) => item?.SquadMap)),
    ];
    dispatch(setUserSquadMap(uniqueSquadMaps));
    dispatch(setUserAccesses(response?.result));
  };

  const getBrandPageExpressions = async () => {
    const response = await getExpressions(unixId);
    const cardConfig = cardConfigMapping(response?.result);
    dispatch(setBrandExpressions(response?.result));

    dispatch(setCardConfig(cardConfig));
  };

  const getFilterMetaData = async () => {
    const response = await getFilters(unixId);
    dispatch(setFilters(response?.result));
  };

  const getVersionType = async () => {
    const response = await getVersion(unixId);
    const result = response?.result?.map((item) => item?.version);
    dispatch(setVersionType(result));
    dispatch(setOptions({ VersionType: result }));
  };

  const showPortfolio = viewFlags[TABNAMES.portfolio] || false;
  const showBrand = viewFlags[TABNAMES.brand] || false;
  const showSop = viewFlags[TABNAMES.sop] || false;

  const fetchCards = async () => {
    const response = await getMyViewCardsDetails(unixId);
    dispatch(setMyViewCardsList(response));
  };

  const getMyViewKPI = async () => {
    const response = await getMyViewKPIDetails();
    dispatch(setMyViewKpiDetails(response));
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      // if (!qlikToken && !qlikCookie) {
      // TODO: fix me  with better check on jwt decode
      await fetchQlikToken(unixId);
      // }

      // Once token is fetched, proceed to getBrandPageExpressions

      await getVersionType();
      await getFilterMetaData();
      await getBrandPageExpressions();
      await getMyViewKPI();
      await getUserAccess();
      await fetchCards();
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const cardConfigMapping = (data) =>
    data?.reduce((acc, item) => {
      const key = item?.CardDefinition;
      acc[key] = {
        ViewId: item?.ViewId,
        ViewName: item?.ViewName,
        CardId: item?.CardId,
        ChartId: item?.ChartId,
        CardName: item?.CardName,
        MyViewEnabled: item?.MyViewEnabled,
        IsActive: item?.IsActive,
      };
      return acc;
    }, {});

  return {
    showPortfolio,
    showBrand,
    showSop,
    brandExpressions,
    card_config_db,
  };
};
