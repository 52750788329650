import classes from './LinkButton.module.scss';

const LinkButton = ({ onClick, children, className, disabled }) => {
  return (
    <button
      className={`${classes['link-button']} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default LinkButton;
