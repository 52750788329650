import { colorLabelSpan } from './colorLabelSpan';

export const lineChartOptions = ({
  chartData,

  category,

  expand,
}) => ({
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
    formatter: function (params) {
      let tooltipContent = ' ';
      params?.forEach((param) => {
        const color = chartData?.filter((item) => {
          return item?.name === param?.seriesName;
        })[0]?.lineStyle?.color;
        const value =
          param?.value === null || param?.value === undefined
            ? '-'
            : param?.value;
        const data =
          colorLabelSpan({ color }) +
          ' ' +
          param?.seriesName +
          ' : ' +
          (value === '-' ? '-' : `${value?.toLocaleString()}K`) +
          '<br/>';
        tooltipContent += data;
      });
      return tooltipContent;
    },
  },
  xAxis: {
    type: 'category',
    boundaryGap: true,
    data: category,
    axisLabel: {
      show: true,
      rotate: 90,
      color: '#000000',
      fontSize: 12,
    },
    axisTick: { show: false },
  },
  yAxis: {
    type: 'value',
    name: 'OUTs/wk (K units)',
    nameLocation: 'middle',
    nameGap: 55,
    splitLine: {
      show: false,
    },
    nameTextStyle: {
      color: '#000000',
      fontSize: 13,
    },
    axisLine: { show: true },
    axisLabel: {
      show: true,
      color: '#000000',
      fontSize: 12,
    },
  },
  grid: expand
    ? {
        left: '5%',
        right: '2%',
        containLabel: true,
      }
    : {
        containLabel: true,
        right: '2%',
      },
  series: chartData?.map((item) => {
    return {
      ...item,
      showSymbol: false,
      lineStyle: {
        ...item?.lineStyle,
        width: 3,
      },
      type: 'line',
      endLabel: {
        show: false,
        formatter: item?.name,
        color: item?.lineStyle?.color,
      },
    };
  }),
});
