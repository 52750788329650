import { GETLABELCOLOR } from '../utils';

const lineStyle2 = [{ color: '#4B80B1' }, { color: '#13294B' }];
const lineStyles3 = [
  { color: '#DB930B' },
  { color: '#4B80B1' },
  { color: '#13294B' },
];
const currentYear = new Date().getFullYear();

export const EXTRACTCATEGORYARRAY = (data) => {
  if (
    // eslint-disable-next-line no-prototype-builtins
    data?.hasOwnProperty('category') &&
    Array?.isArray(data?.category) &&
    data?.category?.length > 0
  ) {
    return data?.category[0];
  }
  return null;
};

export const TRANSFORM_YTDFORECASTGRIDDATA = (data, versionType) => {
  if (data) {
    return [
      {
        key: 1,
        name: 'Actuals',
        data: data[0]?.data,
        growth: data[2]?.data,
      },

      {
        key: 2,
        name: versionType,
        data: data[1]?.data,
        growth: 'N/A',
      },
    ];
  }
};

export const TRANSFORM_YTDFORECASTPY_GRIDDATA = (data) => {
  if (data) {
    return [
      {
        key: 1,
        name: 'Actuals',
        data: data[0]?.data,
        growth: data[2]?.data,
      },
      {
        key: 2,
        name: 'PY YTD',
        data: data[1]?.data,
        growth: 'N/A',
      },
    ];
  }
};

export const TRANSFORM_FYPROJECTION_CHARTDATA = (inputData, versionType) => {
  if (
    !inputData ||
    !inputData?.category ||
    !inputData?.category[0] ||
    !inputData?.values ||
    !Array.isArray(inputData?.values) ||
    inputData?.values?.length === 0
  ) {
    return []; // Return an empty array or handle the error as needed
  }

  const transformedData = [];

  // Define line styles for each item by name
  const lineStyles = {
    T0: { type: 'dotted', color: '#AF1685' },
    Trend: { type: 'dotted', color: '#56A944' },
    '12 wk MA': { color: '#DB930B' },
    Actuals: { color: '#13294B' },
  };

  // Map over the values array to transform the data
  inputData?.values?.forEach((item, index) => {
    let transformedItem = {
      key: index + 1, // Assign a unique key starting from 1
      name: item?.name?.includes('wk MA')
        ? `${item?.name}`
        : item?.name === 'T0'
        ? versionType
        : item?.name, // Adjust name for MA items
      data: item?.data?.map((value) =>
        value === '-' ? null : parseFloat(value),
      ), // Convert "-" to null and strings to numbers
      lineStyle: lineStyles[item?.name] || {}, // Assign line style based on the name
    };

    // Add the transformed item to the result array

    transformedData?.push(transformedItem);
  });
  return transformedData;
};

export const TRANSFORM_FYPROJECTION_GRIDDATA = (inputData) => {
  const result = [];

  // Check if inputData is valid and has the required structure
  if (
    !inputData ||
    !Array.isArray(inputData?.category) ||
    !Array.isArray(inputData?.values)
  ) {
    return result; // Return empty array if inputData is not in the expected format
  }
  const categories = inputData?.category[0];
  const actualData = inputData?.values[0]?.data;
  const twelveWeekMA = inputData?.values[1]?.data;
  const trendData = inputData?.values[2]?.data;
  const t0Data = inputData?.values[3]?.data;

  const formattedTrendData = formatData(trendData);
  const formattedActualData = formatData(actualData);
  const formattedTwelveWeekMA = formatData(twelveWeekMA);
  const formattedT0Data = formatData(t0Data);

  categories?.forEach((metric, index) => {
    result.push({
      key: index + 1,
      metric: metric,
      t0: formattedT0Data[index] || null,
      '12week': formattedTwelveWeekMA[index] || null,
      trend: formattedTrendData[index] || null,
      sales: formattedActualData[index] || null,
    });
  });

  return result;
};

export const TRANSFORM_VARIANCE_CHARTDATA = (inputData) => {
  return inputData?.values?.map((item) => {
    return {
      value: item?.data === '-0' ? '-0' : Number(item?.data),
      itemStyle: {
        color: GETLABELCOLOR(item?.data),
      },
    };
  });
};

export const TRANSFORM_SD_CHARTDATA = (inputData) => {
  return inputData?.values?.map((item, index) => ({
    key: index + 1,
    name: item?.name,
    data: item?.data.map((value) => Number(value?.replace(/,/g, ''))),
    lineStyle: lineStyle2[index],
  }));
};

export const TRANSFORM_SD_GRIDDATA = (inputData) => {
  // Check if inputData is empty or missing expected keys
  if (
    !inputData ||
    !inputData?.category ||
    !inputData?.values ||
    inputData?.category?.length === 0 ||
    inputData?.values?.length < 2
  ) {
    return []; // Return an empty array if the data object is not valid
  }

  const dates = inputData?.category[0] || [];
  const salesData =
    inputData?.values[0]?.data?.map((value) =>
      Number(value.replace(/,/g, '')),
    ) || [];
  const demandData =
    inputData?.values[1]?.data?.map((value) =>
      Number(value.replace(/,/g, '')),
    ) || [];

  return dates.map((date, index) => ({
    key: index + 1,
    metric: date || '',
    demand:
      demandData[index] === '-' || demandData[index] === undefined
        ? ''
        : parseFloat(demandData[index]),
    sales: salesData[index] === undefined ? '' : parseFloat(salesData[index]),
  }));
};

export const TRANSFORM_YOYSALES_CHARTDATA = (inputData) => {
  if (
    !inputData ||
    !inputData?.category ||
    !inputData?.category[0] ||
    !inputData?.values ||
    !Array.isArray(inputData?.values) ||
    inputData?.values?.length === 0
  ) {
    return [];
  }

  const categories = inputData?.category?.[0];
  if (!categories || categories?.length === 0) {
    throw new Error('Categories are undefined or empty.');
  }

  const transformedData = inputData?.values?.map((valueObj) => {
    const mappedData = categories?.map((category, index) => ({
      category,
      value: Number(valueObj?.data?.[index]?.replace(/,/g, '')) || 0,
    }));

    const sortedData = mappedData?.sort((a, b) =>
      a?.category?.localeCompare(b?.category),
    );

    return {
      name: valueObj?.name,
      type: valueObj?.type,
      stack: valueObj?.stack,
      data: sortedData,
    };
  });
  return transformedData;
};

export const TRANSFORM_YOYSALES_GRIDDATA = (inputData) => {
  if (
    !inputData ||
    !inputData.category ||
    !inputData.category[0] ||
    !inputData.values ||
    !Array.isArray(inputData.values) ||
    inputData.values.length === 0
  ) {
    return []; // Return an empty array or handle the error as needed
  }
  // Define the years based on your category input
  const years = inputData.category[0];

  // Map over the values array and transform it to the desired structure
  return inputData.values.map((item, index) => {
    let transformedItem = {
      key: index + 1, // Assign a unique key starting from 1
      title: item.name, // The title is the name from the input data
    };

    // Map over the years and add them as properties to the transformed item
    years?.forEach((year, i) => {
      if (year?.includes(currentYear)) {
        transformedItem[`${year}`] = item?.data[i]; // Use YTD for the last year
      } else {
        transformedItem[year] = item?.data[i];
      }
    });

    return transformedItem;
  });
};

export const TRANSFORM_YOYOUTS_CHARTDATA = (inputData) => {
  return inputData?.values?.map((item, index) => ({
    key: index + 1,
    name: item?.name?.split(' ')[0],
    data: item?.data,
    lineStyle: lineStyles3[index],
  }));
};

export const TRANSFORM_YOYOUTS_GRIDATA = (inputData) => {
  if (
    !inputData ||
    !inputData?.category ||
    !inputData?.category[0] ||
    !inputData?.values ||
    !Array.isArray(inputData?.values) ||
    inputData?.values?.length === 0
  ) {
    return []; // Return an empty array or handle the error as needed
  }
  // Define the years based on your category input
  const years = inputData?.category[0];

  // Map over the values array and transform it to the desired structure
  return inputData?.values?.map((item, index) => {
    let transformedItem = {
      key: index + 1, // Assign a unique key starting from 1
      title: item?.name, // The title is the name from the input data
    };

    // Map over the years and add them as properties to the transformed item
    years.forEach((year, i) => {
      if (year?.includes(currentYear)) {
        transformedItem[`YTD ${year}`] = item?.data[i]; // Use YTD for the last year
      } else {
        transformedItem[year] = item?.data[i];
      }
      // if (i < years?.length - 1) {
      //   transformedItem[year] = item?.data[i];
      // } else {
      //   transformedItem[`YTD${year}`] = item?.data[i]; // Use YTD for the last year
      // }
    });

    return transformedItem;
  });
};

export const TRANSFORM_YTD_CHARTDATA = (inputData, versionType) => {
  if (
    !inputData ||
    !inputData.category ||
    !inputData.category[0] ||
    !inputData.values ||
    !Array.isArray(inputData.values) ||
    inputData.values.length === 0
  ) {
    return []; // Return an empty array or handle the error as needed
  }

  const transformedData = [];

  // Define line styles for each item by name

  const lineStyles = {
    T0: { type: 'dotted', color: '#AF1685' },
    Trend: { type: 'dotted', color: '#56A944' },
    '12 wk MA': { color: '#DB930B' },
    Actual: { color: '#13294B' },
    Low: { type: 'dotted', color: '#CBCBCB' },
    High: { type: 'dotted', color: '#919698' },
  };

  // Map over the values array to transform the data
  inputData?.values?.forEach((item, index) => {
    let transformedItem = {
      key: index + 1, // Assign a unique key starting from 1
      name: item?.name.includes('wk MA')
        ? `${item?.name}`
        : item?.name === 'T0'
        ? versionType
        : item?.name, // Adjust name for MA items
      data: item?.data?.map((value) =>
        value === '-' ? null : parseFloat(value),
      ), // Convert "-" to null and strings to numbers
      lineStyle: lineStyles[item?.name] || {}, // Assign line style based on the name
    };

    // Add the transformed item to the result array

    transformedData.push(transformedItem);
  });

  return transformedData;
};

export const TRANSFORM_YTD_GRIDDATA = (inputData) => {
  const result = [];

  // Check if inputData is valid and has the required structure
  if (
    !inputData ||
    !Array.isArray(inputData?.category) ||
    !Array.isArray(inputData?.values)
  ) {
    return result; // Return empty array if inputData is not in the expected format
  }
  const categories = inputData?.category[0];
  const actualData = inputData?.values[0]?.data;
  const twelveWeekMA = inputData?.values[1]?.data;
  const trendData = inputData?.values[2]?.data;
  const t0Data = inputData?.values[3]?.data;

  categories?.forEach((metric, index) => {
    result.push({
      key: index + 1,
      metric: metric,
      t0: parseFloat(t0Data[index]) || null,
      '12week': parseFloat(twelveWeekMA[index]) || null,
      trend: parseFloat(trendData[index]) || null,
      sales: parseFloat(actualData[index]) || null,
    });
  });

  return result;
};

export const TRANSFORM_AGGFORECAST_CHARTDATA = (inputData) => {
  if (
    !inputData ||
    !inputData.category ||
    !inputData.category[0] ||
    !inputData.values ||
    !Array.isArray(inputData.values) ||
    inputData.values.length === 0
  ) {
    return []; // Return an empty array or handle the error as needed
  }

  const transformedData = [];
  // Define line styles for each item by name
  const lineStyles = {
    'S&OP': { color: '#4B80B1' },
    Actual: { color: '#13294B' },
    '4 Weeks': { color: '#4B80B1' },
    '26 Weeks': { color: '#13294B' },
  };

  // Map over the values array to transform the data
  inputData?.values?.forEach((item, index) => {
    let transformedItem = {
      key: index + 1, // Assign a unique key starting from 1
      name: item?.name, // Adjust name for MA items
      data: item.data.map((value) =>
        value === '-' ? null : parseFloat(value),
      ), // Convert "-" to null and strings to numbers
      lineStyle: lineStyles[item.name] || {}, // Assign line style based on the name
    };

    // Add the transformed item to the result array

    transformedData.push(transformedItem);
  });

  return transformedData;
};

export const TRANSFORM_AGGFORECAST_GRIDDATA = (inputData) => {
  const result = [];

  // Check if inputData is valid and has the required structure
  if (
    !inputData ||
    !Array.isArray(inputData?.category) ||
    !Array.isArray(inputData?.values)
  ) {
    return result; // Return empty array if inputData is not in the expected format
  }
  const categories = inputData?.category[0];
  const actualData = inputData?.values[0]?.data;
  const sopData = inputData?.values[1]?.data;

  categories?.forEach((metric, index) => {
    result.push({
      key: index + 1,
      metric: metric,
      Actual: parseFloat(actualData[index]) || null,
      'S&OP': parseFloat(sopData[index]) || null,
    });
  });

  return result;
};

export const TRANSFORM_SHORTTERMBIAS_CHARTDATA = (inputData) => {
  const result = inputData?.values?.map((item) => {
    return {
      value: item === '-0' ? '-0' : Number(item),
      itemStyle: {
        color: GETLABELCOLOR(item),
      },
    };
  });
  return result;
};

export const TRANSFORM_SHORTBIAS_GRIDDATA = (inputData) => {
  const result = [];

  // Check if inputData is valid and has the required structure
  if (
    !inputData ||
    !Array.isArray(inputData?.category) ||
    !Array.isArray(inputData?.values)
  ) {
    return result; // Return empty array if inputData is not in the expected format
  }
  const categories = inputData?.category;
  const actualData = inputData?.values;

  categories?.forEach((metric, index) => {
    result.push({
      key: index + 1,
      metric: metric,
      data: parseFloat(actualData[index]) || null,
    });
  });

  return result;
};

export const TRANSFORM_FORECASTTREND_CHARTDATA = (inputData, versionType) => {
  if (
    !inputData ||
    !inputData.category ||
    !inputData.category[0] ||
    !inputData.values ||
    !Array.isArray(inputData.values) ||
    inputData.values.length === 0
  ) {
    return []; // Return an empty array or handle the error as needed
  }

  const transformedData = [];
  // Define line styles for each item by name
  const lineStyles = {
    T0: { type: 'dotted', color: '#56A944' },
    Prior: { type: 'dotted', color: '#AF1685' },
    Current: { type: 'dotted', color: '#DB930B' },
    Actual: { color: '#13294B' },
  };

  // Map over the values array to transform the data
  inputData?.values?.forEach((item, index) => {
    let transformedItem = {
      key: index + 1, // Assign a unique key starting from 1
      name: item?.name === 'T0' ? versionType : item?.name, // Adjust name
      data: item.data.map((value) =>
        value === '-' ? null : parseFloat(value),
      ), // Convert "-" to null and strings to numbers
      lineStyle: lineStyles[item.name] || {}, // Assign line style based on the name
    };

    // Add the transformed item to the result array

    transformedData.push(transformedItem);
  });

  return transformedData;
};

export const formatData = (data) => {
  return data?.map((value) => {
    const numberValue = Number(value);
    return isNaN(numberValue) ? '-' : numberValue.toLocaleString();
  });
};
export const TRANSFORM_FORECASTTREND_GRIDDATA = (inputData) => {
  const result = [];

  // Check if inputData is valid and has the required structure
  if (
    !inputData ||
    !Array.isArray(inputData?.category) ||
    !Array.isArray(inputData?.values)
  ) {
    return result; // Return empty array if inputData is not in the expected format
  }
  const categories = inputData?.category[0];
  const actualData = inputData?.values[0]?.data;
  const currentData = inputData?.values[1]?.data;
  const priorData = inputData?.values[2]?.data;
  const t0Data = inputData?.values[3]?.data;

  const formattedActualData = formatData(actualData);
  const formattedcurrentData = formatData(currentData);
  const formattedpriorData = formatData(priorData);
  const formattedt0Data = formatData(t0Data);

  categories?.forEach((metric, index) => {
    result.push({
      key: index + 1,
      metric: metric,
      t0: formattedt0Data[index] || null,
      current: formattedcurrentData[index] || null,
      prior: formattedpriorData[index] || null,
      actual: formattedActualData[index] || null,
    });
  });

  return result;
};

export const TRANSFORM_BPLAN_CHARTDATA = (inputData, selectedOption) => {
  if (
    !inputData ||
    !inputData.category ||
    !inputData.category[0] ||
    !inputData.values ||
    !Array.isArray(inputData.values) ||
    inputData.values.length === 0
  ) {
    return []; // Return an empty array or handle the error as needed
  }

  const transformedData = [];

  // Define line styles for each item by name
  const lineStyles = {
    'Net Sales (Unadjusted)': { type: 'dotted' },
    'Net Sales (Adjusted)': {},
  };

  // Map over the values array to transform the data
  inputData?.values?.forEach((item, index) => {
    if (
      item?.name?.includes('Unadjusted') ||
      item?.name?.includes('Adjusted')
    ) {
      let transformedItem = {
        key: index + 1, // Assign a unique key starting from 1
        name: `${selectedOption} ${
          item?.name?.includes('Unadjusted') ? 'Unadjusted' : 'Adjusted'
        }`,
        data: item.data.map((value) =>
          value === '-' ? null : parseFloat(value),
        ), // Convert "-" to null and strings to numbers
        lineStyle: lineStyles[item.name] || {}, // Assign line style based on the name
      };

      // Add the transformed item to the result array
      transformedData.push(transformedItem);
    }
  });

  return transformedData;
};

export const FINDMINMAX = (inputData) => {
  if (
    !inputData ||
    !inputData.category ||
    !inputData.category[0] ||
    !inputData.values ||
    !Array.isArray(inputData.values) ||
    inputData.values.length === 0
  ) {
    return []; // Return an empty array or handle the error as needed
  }

  const numArray = inputData?.values?.map(Number);

  const min = Math.min(...numArray);
  const max = Math.max(...numArray);

  return { min, max };
};

export const FINDMINMAXTILE = (inputData) => {
  // Initialize variables for min and max with the value of the first item in the array
  let min = inputData[0]?.value;
  let max = inputData[0]?.value;

  // Loop through the array to find the min and max values
  for (let i = 1; i < inputData.length; i++) {
    if (inputData[i]?.value < min) {
      min = inputData[i]?.value;
    }
    if (inputData[i]?.value > max) {
      max = inputData[i]?.value;
    }
  }

  return { min, max };
};

export const TRANSFORM_BPCHART_SCATTER = (inputData) => {
  let scatterPlotData = [];
  if (
    inputData?.category &&
    inputData?.category.length &&
    inputData?.values &&
    inputData?.values[0]?.data
  ) {
    const maxValue = Math.max(...inputData?.values[0]?.data?.map(Number)) || 0;
    for (let i = 0; i < inputData?.values[0]?.data?.length; i++) {
      let tempData = {};
      tempData.value = [inputData?.category[0][i], maxValue + 300];

      tempData.name = inputData?.values[2].data[i] || '-';
      if (tempData.name !== '-') {
        scatterPlotData.push(tempData);
      }
    }
  }

  return scatterPlotData;
};

export const SORTYEARS = (yearList) => {
  if (yearList?.length > 0) {
    const ytd = yearList[0]?.filter((year) => year === `YTD ${currentYear}`);
    const sortedYears = yearList[0]
      ?.filter((year) => year !== `YTD ${currentYear}`)
      ?.sort();
    return sortedYears?.concat(ytd);
  }
};

export const shiftElementPlacement = (array = [], oldIndex, newIndex) => {
  if (newIndex >= array?.length) {
    newIndex = array?.length - 1;
  }
  if (newIndex < 0) {
    newIndex = 0;
  }
  array?.splice(newIndex, 0, array?.splice(oldIndex, 1)[0]);
  return array;
};
