export const BrandPerfInitialState = {
  brandExpressions: [],
  filters: [],
  filterSelections: {},
  eqOutDataStore: [],
  myViewKpiDetails: [],
  myViewCardsList: [],
  userAcces: [],
  brandViewFiltersSelected: {
    Brand: [],
    Business_Unit: '',
    Franchise: [],
    VersionType: '',
    SKU: [], // Added SKU in selections
  },
  userSquadMap: [],
  versionType: [],
  isFromPortfolioTab: false,
  isViewFirstLoad: false,

  summaryTab: {
    ytdVariance: {},
    yoyVariance: {},
    ytdRoySales: {},
    ytdRoyDemand: {},
    salesDemand: {},
    yoySales: {},
    yoySalesGrid: {},
    yoyOuts: {},
    yoyOutsGrid: {},
    ytdRoySalesYtd: '',
    ytdRoyDemandYtd: '',
    ytdRoySales4Week: '',
    ytdRoyDemand4Week: '',
    ytdRoyDemandText: '',
  },
  increment: 0,
  monthlyTabData: {
    monthly: [],
    monthlyPrevious: [],
  },
  monthlyTabColumn: {
    monthly: [],
    monthlyPrevious: [],
  },
  quarterlyTabData: {
    quarterly: [],
    quarterlyPrevious: [],
  },
  quarterlyTabColumn: {
    quarterly: [],
    quarterlyPrevious: [],
  },
  cardConfig: {},
  pointerIndex: null,
};
