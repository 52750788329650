import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import Layout from './components/layout/Layout';
import store from './store/store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

//object creation for QueryClient that will be leverged in useQuery.
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function Root() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <div style={{ display: 'flex' }}>
            <Layout />

            <App />
          </div>
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  );
}
