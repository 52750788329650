import { colorLabelSpan } from './colorLabelSpan';

export const stackedBarChartOptions = ({
  chartData,

  category,

  expand,
}) => ({
  tooltip: {
    show: true,
    formatter: (params) => {
      let tooltipContent = `Year : ${params?.name}<br/>`; // Add week info at the start
      const value =
        params?.value === null || params?.value === undefined
          ? '-'
          : params?.value;
      const data =
        colorLabelSpan({ color: params?.color }) +
        ' ' +
        params?.seriesName +
        ' : ' +
        (value === '-' ? '-' : `$${value?.toLocaleString()}M`) +
        '<br/>';
      tooltipContent += data;
      return tooltipContent;
    },
  },
  xAxis: {
    type: 'category',
    data: category,
    axisLabel: {
      color: '#000000',
    },
    axisTick: {
      show: false,
    },
  },
  yAxis: {
    type: 'value',
    splitLine: { show: false },
    name: 'Sales ($M)',
    nameLocation: 'middle',
    nameGap: 55,
    nameTextStyle: {
      color: '#000000',
      fontSize: 13,
    },
    axisLabel: {
      color: '#000000',
    },
    axisLine: {
      show: true,
    },
  },
  legend: { top: '90%', left: '15%', itemWidth: 20, itemHeight: 20 },
  grid: expand
    ? { left: '5%', right: '0%', containLabel: true }
    : {
        left: '15%',
      },
  series: chartData?.map((item) => {
    return {
      ...item,
      itemStyle: {
        color: item?.name === 'Net Sales' ? '#13294B' : '#4B80B1',
      },
      label: {
        show: true,
        formatter: (params) => {
          return `$${params?.value?.toLocaleString()}`;
        },
      },
    };
  }),
});
