import { colorLabelSpan } from './colorLabelSpan';

export const linegridData = ({ chartData = [], category = [] }) => {
  const hasData = chartData.length > 0;

  return {
    grid: {
      top: 0,
      bottom: 1,
      left: 10,
      right: 10,
      containLabel: false,
    },
    tooltip: {
      appendToBody: true,
      trigger: 'axis',
      position: 'right', // ✅ Ensures tooltip appears on the right
      axisPointer: { type: 'none' }, // 🔹 Removes default axis hover effect
      formatter: function (params) {
        let tooltipContent = `Week : ${params[0]?.axisValue}<br/>`; // Add week info at the start
        params?.forEach((param) => {
          const color = '#13294B';
          const value =
            param?.value === null ||
            param?.value === undefined ||
            param?.value === '-'
              ? '-'
              : param?.value;
          const data =
            colorLabelSpan({ color }) +
            ' ' +
            (value === '-' ? '-' : `${value}`) +
            '<br/>';
          tooltipContent += data;
        });
        return tooltipContent;
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: hasData ? category : [],
      axisLabel: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      show: false,
      splitLine: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
    },
    series: hasData
      ? [
          {
            data: chartData,
            type: 'line',
            lineStyle: {
              color: '#13294B',
            },
            symbol: 'circle',
          },
        ]
      : [], //  No series if no data, avoiding the default "No Data"
    backgroundColor: 'transparent',
  };
};
