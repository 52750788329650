export const globalFilterInitialState = {
  options: {
    Brand: [],
    Business_Unit: [],
    Franchise: [],
    VersionType: [],
    SKU: [], // Added SKU in options
  },
  selections: {
    Brand: [],
    Business_Unit: '',
    Franchise: [],
    VersionType: '',
    SKU: [],
  },
};
