import axiosDBClient from '../../axiosDBClient';

export const addFeedback = async (payload) => {
  try {
    const response = await axiosDBClient.post('/feedback', {
      ...payload,
    });
    if (response.error) {
      throw new Error('Could not add comment!');
    }

    return response.data.message;
  } catch (error) {
    console.log('error', error);
  }
};
