import { Dropdown } from 'antd';

const AnchorDropdown = ({
  items,
  children,
  onClick,
  placement = 'bottomRight',
}) => (
  <Dropdown
    menu={{
      items,
      onClick,
    }}
    trigger={'click'}
    placement={placement}
  >
    {children}
  </Dropdown>
);
export default AnchorDropdown;
