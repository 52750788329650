import { useEffect } from 'react';
import { Table } from 'antd';

const Grid = ({
  dataSource,
  columns,
  rowClassName,
  onHeaderRow,

  className,
  scroll,
}) => {
  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (
        e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);
  return (
    <Table
      className={className}
      dataSource={dataSource}
      columns={columns}
      size="small"
      align="left"
      width="67vw"
      rowClassName={rowClassName}
      scroll={scroll}
      onHeaderRow={onHeaderRow}
      pagination={false}
    />
  );
};

export default Grid;
