// @components
import 'bootstrap/dist/css/bootstrap.min.css';

import AppRoutes from './AppRoutes';
// @Styles
import './App.scss';
import '@assets/styles/styles.scss';

const App = () => {
  return (
    <>
      <div className="app-wrapper">
        <AppRoutes />
      </div>
    </>
  );
};

export default App;
