import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import BusinessPlan from './business/BusinessPlan';
import {
  AppRoute,
  BUSINESS_PLAN_CATEGORY,
  DISCLAIMER,
  LEGAL_COMPLIANCE_TEXT,
  TABNAMES,
} from '../../constants';
import { Spin } from 'antd';
import { BusinessPlanConstant } from './businessPlanConstant';
import classes from './BusinessPlanContainer.module.scss';

const BusinessPlanContainer = ({ currentTab, week }) => {
  const navigate = useNavigate();
  const [key, setKey] = useState(BUSINESS_PLAN_CATEGORY?.BUSINESSPLAN);

  const UserAccess = useSelector((state) => state?.brandPerformance?.userAcces);

  const distinctViewNames = [
    ...new Set(UserAccess?.map((item) => item.ViewName)),
  ];
  const showBusinessPlan = distinctViewNames?.includes(TABNAMES.bplan);

  useEffect(() => {
    setKey(currentTab);
  }, [currentTab]);

  const onSetTabKey = (tab) => {
    setKey(tab);
    navigate(`${AppRoute}${tab}`);
  };
  if (showBusinessPlan) {
    return (
      <div className={classes['businessplan-container']}>
        <span className={classes['week-container']}>{week}</span>
        <Tabs
          id={BUSINESS_PLAN_CATEGORY?.BUSINESSPLAN}
          activeKey={key}
          onSelect={(k) => {
            onSetTabKey(k);
          }}
          className="mb-3"
          style={{ marginTop: '-40px' }}
        >
          <Tab
            eventKey={BUSINESS_PLAN_CATEGORY?.BUSINESSPLAN}
            title={
              <span
                className={`d-flex title__bold title__base ${classes['title']}`}
              >
                {BusinessPlanConstant.BUSINESS_PLAN_TAB}
              </span>
            }
          >
            <BusinessPlan
              active={key === BUSINESS_PLAN_CATEGORY?.BUSINESSPLAN}
            />
          </Tab>
        </Tabs>
        {showBusinessPlan && (
          <div className={'disclaimer-text'}>
            {DISCLAIMER} <em>{LEGAL_COMPLIANCE_TEXT}</em>
          </div>
        )}
      </div>
    );
  }
  return <Spin />;
};

export default BusinessPlanContainer;
