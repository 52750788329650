// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Nuyfu399HYtfW_l5qKKt{display:flex;padding:20px 0px 0px 12px}.fpTkLbOTuUN_yIxptgT3{display:flex;padding:12px 0px 0px 12px}.iOKRkVZyAAXtdLz1rnPO{margin-left:3px}.lYvopPXuGID4OLw7_NKS{margin:-3px 0px 0px -5px;color:var(--color-gray-4);font-size:var(--font-size-x-small)}`, "",{"version":3,"sources":["webpack://./src/components/elements/chart/actions/ChartActions.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,yBAAA,CAEF,sBACE,YAAA,CACA,yBAAA,CAGF,sBACE,eAAA,CAGF,sBACE,wBAAA,CACA,yBAAA,CACA,kCAAA","sourcesContent":[".actions-wrap {\n  display: flex;\n  padding: 20px 0px 0px 12px;\n}\n.actions-wrap-myview {\n  display: flex;\n  padding: 12px 0px 0px 12px;\n}\n\n.icon-alignment {\n  margin-left: 3px;\n}\n\n.comment-length {\n  margin: -3px 0px 0px -5px;\n  color: var(--color-gray-4);\n  font-size: var(--font-size-x-small);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions-wrap": `Nuyfu399HYtfW_l5qKKt`,
	"actions-wrap-myview": `fpTkLbOTuUN_yIxptgT3`,
	"icon-alignment": `iOKRkVZyAAXtdLz1rnPO`,
	"comment-length": `lYvopPXuGID4OLw7_NKS`
};
export default ___CSS_LOADER_EXPORT___;
