import { useState } from 'react';
import { Radio } from 'antd';

import classes from './RadioButton.module.scss';

const RadioButtonGroup = ({ defaultValue, options, onChange }) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (e) => {
    setValue(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <Radio.Group onChange={handleChange} value={value}>
      {options.map((option) => (
        <Radio
          key={option.value}
          value={option.value}
          className={classes['label']}
        >
          {option.label}
        </Radio>
      ))}
    </Radio.Group>
  );
};

export default RadioButtonGroup;
