import { useSelector } from 'react-redux';
import Header from './header/Header';
import SideBar from './sidebar/SideBar';
import { navData } from '@mockdata/navData';
import { TABNAMES } from '@constant';
import ComplianceModal from './compliance/ComplianceModal';

const Layout = () => {
  const UserAccess = useSelector((state) => state?.brandPerformance?.userAcces);
  const viewFlags = UserAccess?.reduce((acc, item) => {
    acc[item?.ViewName] = true;
    return acc;
  }, {});

  const showPortfolio = viewFlags[TABNAMES?.portfolio] || false;
  const showBrand = viewFlags[TABNAMES?.brand] || false;
  const showSop = viewFlags[TABNAMES?.sop] || false;
  const showBusinessPlan = viewFlags[TABNAMES?.bplan] || false;

  const filteredNavData = navData(
    showPortfolio,
    showBrand,
    showSop,
    showBusinessPlan,
  )?.filter(Boolean);

  return (
    <div className={{ dispaly: 'flex' }}>
      <Header />
      <div style={{ height: '100%', position: 'absolute' }}>
        <SideBar items={filteredNavData} />
      </div>
      <ComplianceModal />
    </div>
  );
};

export default Layout;
