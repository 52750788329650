import { useState } from 'react';
import { Input } from 'antd';
import classes from './SearchBar.module.scss';

const SearchBar = ({ data, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    const value = e?.target?.value;
    setSearchTerm(value);
    if (value.trim() === '') {
      onSearch(data);
      return;
    }

    const searchTermLower = value?.toLowerCase();

    const searchResult = data?.reduce((memo, item) => {
      const kpiNameMatch =
        item?.KpiName?.toLowerCase()?.includes(searchTermLower);

      const dimensionMatch = item?.dimensions?.filter((dimension) =>
        dimension?.DimensionName?.toLowerCase()?.includes(searchTermLower),
      );

      if (kpiNameMatch) {
        memo.push(item);
      } else if (dimensionMatch?.length) {
        memo.push({ ...item, dimensions: dimensionMatch });
      }

      return memo;
    }, []);

    onSearch(searchResult);
  };

  return (
    <div className={classes['search-container']}>
      <Input
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={handleSearch}
        suffix={
          <i className={`icon icon__search ${classes['search-icon']}`}></i>
        }
      />
    </div>
  );
};

export default SearchBar;
