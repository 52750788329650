import { createSlice } from '@reduxjs/toolkit';
import { portfolioInitialState } from './portfolioIntialState';

const portfolioSlice = createSlice({
  name: 'portfolio view',
  initialState: portfolioInitialState,
  reducers: {
    setPerformanceChartData: (state, { payload }) => {
      state.performanceChartData = payload;
    },
    setPerformanceTableData: (state, { payload }) => {
      state.performanceTableData = payload;
    },
    setPerformanceCommentData: (state, { payload }) => {
      state.performanceCommentData = [...payload];
    },
    setPerformanceWeek: (state, { payload }) => {
      state.performanceWeek = payload;
    },
    setPerformanceYear: (state, { payload }) => {
      state.performanceYear = payload;
    },

    setPortfolioSummaryCommentData: (state, { payload }) => {
      state.portfolioSummaryCommentData = payload;
    },
    setPortfolioSummaryFirstCommentData: (state, { payload }) => {
      state.portfolioSummaryCommentData = [
        payload,
        ...state.portfolioSummaryCommentData,
      ];
    },
    setExpressionAndFilters: (state, { payload }) => {
      state.portfolioExpression = payload.portfolioExpression;
      state.portfolioTableExpression = payload.portfolioTableExpression;
      state.portfolioExpressionVariance = payload.portfolioExpressionVariance;
      state.selectedFilters = payload.selectedFilters;
    },
    setPortfolioCommentUpdate: (state) => {
      state.portfolioCommentUpdate = state.portfolioCommentUpdate + 1;
    },
    setSummaryTabData: (state, { payload }) => {
      state.summaryTab = {
        ...state.summaryTab,
        ...payload,
      };
    },
    setPortfolioTabData: (state, { payload }) => {
      state.portfolioTab = {
        ...state.portfolioTab,
        ...payload,
      };
    },
  },
});

export const {
  setPerformanceChartData,
  setPerformanceTableData,
  setPerformanceCommentData,
  setPerformanceWeek,
  setPortfolioSummaryCommentData,
  setExpressionAndFilters,
  setSummaryTabData,
  setPortfolioTabData,
  setPortfolioSummaryFirstCommentData,
  setPerformanceYear,
  setPortfolioCommentUpdate,
} = portfolioSlice.actions;

export default portfolioSlice.reducer;
