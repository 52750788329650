import classes from './ChartActions.module.scss';
import { Tooltip } from 'antd';
import { CHART, COMMENTLOWERCASE, GRID } from '@constant';

const ChartActions = ({
  cardActions,
  actionsAllowed,
  commentsData,
  onSelectView,
}) => {
  const handleViewSelection = (view) => {
    onSelectView(view);
  };
  return (
    <div className={classes['actions-wrap']}>
      {actionsAllowed?.chart && (
        <Tooltip title="View Chart">
          <i
            className={`icon ${
              cardActions?.chartView ? 'icon__chart' : 'icon__chart_grey'
            }`}
            onClick={() => handleViewSelection(CHART)}
          ></i>
        </Tooltip>
      )}
      {actionsAllowed?.grid && (
        <Tooltip title="View Table">
          <i
            className={`icon ${
              cardActions?.gridView ? 'icon__table' : 'icon__table_grey'
            } ${classes['icon-alignment']}`}
            onClick={() => handleViewSelection(GRID)}
          ></i>
        </Tooltip>
      )}
      {actionsAllowed?.chartId !== 'year-forecast' &&
        actionsAllowed?.comments && (
          <>
            <Tooltip title="View Comments">
              <i
                className={`icon ${
                  cardActions?.commentView
                    ? 'icon__comments_blue'
                    : 'icon__comments_grey'
                }  ${classes['icon-alignment']}`}
                onClick={() => handleViewSelection(COMMENTLOWERCASE)}
              ></i>
            </Tooltip>
            <span className={classes['comment-length']}>
              {commentsData?.length}
            </span>
          </>
        )}
    </div>
  );
};

export default ChartActions;
