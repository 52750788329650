export const GETSECTIONSEXPRESSION = (data, CardId, expression) => {
  const expData = data
    .filter((e) => e?.CardId === CardId)
    .map((obj) =>
      JSON.stringify({
        CardName: obj?.CardName,
        id: obj?.KpiId,
        name: obj?.KpiName,
        expression: obj?.Expression.replaceAll('vReplaceString', expression),
      }),
    );
  return expData;
};

export const GETLABELCOLOR = (value) => {
  if (value === '0') {
    return '#919698';
  } else if (value === '-0' || Number(value) < 0) {
    return '#C23934';
  } else {
    return '#56A944';
  }
};

export const LINESTYLE_YTD_ROY = {
  T0: { type: 'dotted', color: '#AF1685' },
  Trend: { type: 'dotted', color: '#56A944' },
  '12 wk MA': { color: '#DB930B' },
  '4 wk MA': { color: '#4B80B1' },
  Actuals: { color: '#13294B' },
};

export const LINESTYLES_AGG_FORECAST = {
  'S&OP': { color: '#4B80B1' },
  Actual: { color: '#13294B' },
  '4 Weeks': { color: '#4B80B1' },
  '26 Weeks': { color: '#13294B' },
};

export const LINESTYLES_YEAR_FORECAST = {
  T0: { type: 'dotted', color: '#56A944' },
  Prior: { type: 'dotted', color: '#AF1685' },
  Current: { type: 'dotted', color: '#DB930B' },
  Actual: { color: '#13294B' },
};

export const YTD_ROY_BRAND_LINESTYLE = {
  Actual: '#13294B',
  '12 wk MA': '#DB930B',
  Trend: '#56A944',
  T0: '#af1685',
  High: '#919698',
  Low: '#CBCBCB',
};

export const LINESTYLE2 = [
  // { color: "#DB930B" },
  { color: '#4B80B1' },
  { color: '#13294B' },
];

export const LINESTYLE3 = [
  { color: '#DB930B' },
  { color: '#4B80B1' },
  { color: '#13294B' },
];
