// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zvpKbPPAKXdbL_tEI1jY{height:48px;width:100%;background:rgba(0,0,0,0) var(--gradient-primary) 0% 0% no-repeat padding-box;display:flex;align-items:center;color:var(--color-white);padding-left:80px;position:absolute;top:0;z-index:1000;cursor:pointer}.zvpKbPPAKXdbL_tEI1jY .W2mcDgsHTRAS8SFVLYF6{color:var(--color-white)}.oKLkPL61pRWgpQexRYD7{display:flex;justify-content:flex-end}.oKLkPL61pRWgpQexRYD7 .IcEAo2kEel2ciIgANUek{display:flex;align-items:center;margin-right:20px}.FoR5TBUC3KpwaI4Xa4vb{display:flex;align-items:center}`, "",{"version":3,"sources":["webpack://./src/components/layout/header/Header.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,UAAA,CACA,4EAAA,CACA,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,iBAAA,CACA,iBAAA,CACA,KAAA,CACA,YAAA,CACA,cAAA,CACA,4CACE,wBAAA,CAGJ,sBACE,YAAA,CACA,wBAAA,CACA,4CACE,YAAA,CACA,kBAAA,CACA,iBAAA,CAGJ,sBACE,YAAA,CACA,kBAAA","sourcesContent":[".gne-header {\r\n  height: 48px;\r\n  width: 100%;\r\n  background: transparent var(--gradient-primary) 0% 0% no-repeat padding-box;\r\n  display: flex;\r\n  align-items: center;\r\n  color: var(--color-white);\r\n  padding-left: 80px;\r\n  position: absolute;\r\n  top: 0;\r\n  z-index: 1000;\r\n  cursor: pointer;\r\n  .links {\r\n    color: var(--color-white);\r\n  }\r\n}\r\n.header-cta {\r\n  display: flex;\r\n  justify-content: flex-end;\r\n  .cta {\r\n    display: flex;\r\n    align-items: center;\r\n    margin-right: 20px;\r\n  }\r\n}\r\n.content-wrap {\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gne-header": `zvpKbPPAKXdbL_tEI1jY`,
	"links": `W2mcDgsHTRAS8SFVLYF6`,
	"header-cta": `oKLkPL61pRWgpQexRYD7`,
	"cta": `IcEAo2kEel2ciIgANUek`,
	"content-wrap": `FoR5TBUC3KpwaI4Xa4vb`
};
export default ___CSS_LOADER_EXPORT___;
