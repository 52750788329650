import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { Tab } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import Banner from '@elements/banner/BusinessPlanBanner';
import RadioGroup from '@elements/radio/RadioGroup';
import BusinessSummary from './businesssummary/BusinessSummary';
import BusinessDetails from './businessdetails/BusinessDetails';
import Filters from '../../filters/Filters';
import { fetchOptions } from '@service/qlik.service';
import {
  BUSINESS_PLAN_CATEGORY,
  ISACTIVEFLAG,
  SUB_TABNAMES_BPLAN,
  SUB_VIEWNAMES,
  TABNAMES,
  TOAST_ERROR_MESSAGE,
  NO_NARRATIVE_AVAIALABLE,
  NarrationConstant,
  VIEW_MORE,
  NARRATION_VIEW,
} from '@constant';
import {
  GETCOMMENTCREATEDDATE,
  extractInfoDetails,
  getUniqueInfoTextString,
} from '@utils/utils';
import { getAllComments, narrationComment } from '@service/comments.service';
import { setDataOptions, setEventOptions } from '@store/bplan/bplanViewSlice';
import BussinessPlanComment from './BussinessPlanComment';
import { CARD_CONFIG } from '@utils/cardConfig';
import { getBusinessPlanViewTypes } from '../businessPlanConstant';
import classes from './BusinessPlan.module.scss';
import { getUserUnixId } from '@utils/getUser';
import _ from 'lodash';

const BusinessPlan = () => {
  const dispatch = useDispatch();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const [isExpandCommentModalOpen, setIsExpandCommentModalOpen] =
    useState(false);
  const [commentsData, setCommentsData] = useState([]);
  const [isBannerCommentModalVisible, setIsBannerCommentModalVisible] =
    useState(false);
  const [expression, setExpression] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [bpDiseaseDataType, setBpDiseaseDataType] = useState('adjusted');
  const [isLoading, setIsLoading] = useState(false);
  const brandExpressions = useSelector(
    (state) => state?.brandPerformance?.brandExpressions,
  );

  const bpDataOptions = useSelector((state) => state?.bpPlan?.dataOptions);

  const bpEventOptions = useSelector((state) => state?.bpPlan?.eventOptions);

  const UserAccess = useSelector((state) => state?.brandPerformance?.userAcces);

  const card_config_db = useSelector(
    (state) => state?.brandPerformance.cardConfig,
  );

  const distinctSubViews = UserAccess.reduce((acc, item) => {
    const { ViewName, SubViewName } = item;

    if (!acc[ViewName]) {
      acc[ViewName] = new Set();
    }

    acc[ViewName].add(SubViewName);

    return acc;
  }, {});

  const subViews = Object.keys(distinctSubViews).map((viewName) => ({
    ViewName: viewName,
    SubViewNames: Array.from(distinctSubViews[viewName]),
  }));

  const businessPlanView = subViews.filter(
    (view) => view.ViewName === TABNAMES.bplan,
  );
  const subViewNames =
    businessPlanView.length > 0 ? businessPlanView[0].SubViewNames : [];

  const showSummary = subViewNames.includes(SUB_TABNAMES_BPLAN.summary);
  const showDetails = subViewNames.includes(SUB_TABNAMES_BPLAN.details);

  const [viewType, setViewType] = useState(
    showSummary
      ? SUB_VIEWNAMES.SUMMARY
      : showDetails
      ? SUB_VIEWNAMES.DETAILS
      : null,
  );

  const filtercardId = UserAccess.filter(
    (item) =>
      item.ViewName === TABNAMES.bplan && item.ViewCardAccess === ISACTIVEFLAG,
  );

  const commentCardIds = Array.from(
    new Set(
      UserAccess.filter((item) => item.CommentEditAccess === ISACTIVEFLAG).map(
        (item) => item.CardId,
      ),
    ),
  );

  const showCardIds = filtercardId.map((item) => item.CardId);

  const infoMessages = extractInfoDetails(brandExpressions, showCardIds);

  const formatOutput = (data) => {
    const franchiseCondition =
      data?.Franchise?.length === 0
        ? `Franchise=`
        : `Franchise={'${data?.Franchise.join("','")}'}`;
    const BrandCondition =
      data?.Brand?.length === 0
        ? `[BP Brand]=`
        : `[BP Brand]={'${data?.Brand?.join("','")}'}`;
    const formattedOutput = ` [BP Business Unit]= {'${data?.Business_Unit}'} , ${franchiseCondition}, ${BrandCondition}`;

    return formattedOutput;
  };

  const onSelectViewType = (e) => {
    setViewType(e.target.value);
  };

  const onFilterSelection = (filters) => {
    const data = formatOutput(filters);
    setExpression(data);
    setSelectedFilters(filters);
  };

  const getDataFiltersOption = useCallback(async () => {
    setIsLoading(true);
    const data = await fetchOptions('Version Name', {});
    setIsLoading(false);
    dispatch(setDataOptions(data));
  }, [dispatch]);

  const getEventFiltersOption = useCallback(async () => {
    setIsLoading(true);
    const data = await fetchOptions('EventType', {});
    setIsLoading(false);
    dispatch(setEventOptions(data));
  }, [dispatch]);

  useEffect(() => {
    if (bpDataOptions?.length === 0 && bpEventOptions?.length === 0) {
      getDataFiltersOption();
      getEventFiltersOption();
    }
  }, [
    bpDataOptions?.length,
    bpEventOptions?.length,
    getDataFiltersOption,
    getEventFiltersOption,
  ]);

  const showBannerCommentsModal = () => {
    setIsBannerCommentModalVisible(true);
  };

  const handleCommentBannerClose = () => {
    setIsBannerCommentModalVisible(false);
  };

  const getSummaryComments = useCallback(async () => {
    setIsLoading(true);

    const response = await getAllComments(getUserUnixId());

    setCommentsData(response);
    setIsLoading(false);
    return response;
  }, [getUserUnixId()]);

  const fetchData = async () => {
    try {
      if (
        brandExpressions?.length &&
        expression?.length > 0 &&
        bpDataOptions?.length
      ) {
        return await getSummaryComments();
      }
    } catch (error) {
      toast.error(TOAST_ERROR_MESSAGE);
    }
    return {};
  };

  const { isLoading: isBannerCommentLoading } = useQuery({
    queryKey: [
      'summary comment',
      brandExpressions?.length,
      expression?.length,
      bpDataOptions?.length,
    ],
    queryFn: fetchData,
    enabled: brandExpressions?.length > 0,
  });
  /*
    Narrative Comment Api call
  
  */
  const { isLoading: narrationLoading, data: narrationData } = useQuery({
    queryKey: ['narrationCommentBP', selectedFilters],
    queryFn: () => {
      return narrationComment({
        ...selectedFilters,
        view: NARRATION_VIEW.BUSSINESS_PLAN,
      });
    },
    enabled: Object.keys(selectedFilters).length > 0,
  });

  const onExpandCommentDesc = () => {
    setIsExpandCommentModalOpen(true);
  };

  const bannerContent = (
    <div>
      {!isCollapsed && (
        <div className={classes['banner-comment']}>
          <div className={classes['banner-comment-area']}>
            <div className={classes['banner-desc']}>
              {narrationData?.data && narrationData?.data.length > 0 ? (
                narrationData?.data[0]?.Narrative.length > 320 ? (
                  <div>
                    {`${narrationData?.data[0]?.Narrative.substring(
                      0,
                      320,
                    )}...`}
                    <span
                      className={classes['show-more-wrap']}
                      onClick={onExpandCommentDesc}
                    >
                      {VIEW_MORE}
                    </span>
                  </div>
                ) : (
                  narrationData?.data[0].Narrative
                )
              ) : selectedFilters?.Brand?.length > 1 ||
                (selectedFilters?.Franchise?.length > 1 &&
                  selectedFilters?.Brand?.length === 0) ? (
                NarrationConstant?.bpMessage
              ) : (
                NO_NARRATIVE_AVAIALABLE
              )}
            </div>
            <div className={classes['banner-operations']}>
              <div className={classes['comment-username']}>
                <span className={classes['comment-info']}>
                  {GETCOMMENTCREATEDDATE(narrationData?.data[0]?.Updated_Date)}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  const onCollapseBanner = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleDiseaseDataType = (value) => {
    setBpDiseaseDataType(value);
  };

  return (
    <>
      <Spin spinning={isLoading || narrationLoading || isBannerCommentLoading}>
        <Filters
          handleFilterChange={_.debounce(onFilterSelection, 800)}
          currentTab={BUSINESS_PLAN_CATEGORY.BUSINESSPLAN}
        />
        {showCardIds.includes(card_config_db?.BP_BANNER?.CardId) && (
          <Banner
            bannerName={<strong>{CARD_CONFIG?.BP_BANNER?.CardName}</strong>}
            bannerContent={bannerContent}
            onCollapseBanner={onCollapseBanner}
            isCollapsed={isCollapsed}
            commentsData={narrationData?.data}
            isLoading={narrationLoading}
            isModalVisible={isBannerCommentModalVisible}
            showModal={showBannerCommentsModal}
            handleCommentClose={handleCommentBannerClose}
            infoMessages={getUniqueInfoTextString(
              brandExpressions,
              card_config_db?.BP_BANNER?.CardId,
            )}
          />
        )}
        <Tab.Container
          activeKey={viewType}
          id="left-tabs-example"
          defaultActiveKey={SUB_VIEWNAMES.SUMMARY}
        >
          <div className={classes['viewtype-wrap']}>
            <RadioGroup
              onChange={onSelectViewType}
              defaultValue={
                showSummary
                  ? SUB_VIEWNAMES.SUMMARY
                  : showDetails
                  ? SUB_VIEWNAMES.DETAILS
                  : null
              }
              items={getBusinessPlanViewTypes(
                showSummary,
                showDetails,
                viewType,
                showCardIds,
              )}
              value={viewType}
            />
          </div>
          <Tab.Content>
            <Tab.Pane eventKey={SUB_VIEWNAMES.SUMMARY}>
              <BusinessSummary
                dataFilterOptions={bpDataOptions}
                eventFilterOptions={bpEventOptions}
                expression={expression}
                selectedFilters={selectedFilters}
                businessComments={commentsData}
                showCardIds={showCardIds}
                infoMessages={infoMessages}
                active={viewType === SUB_VIEWNAMES.SUMMARY}
              />
            </Tab.Pane>
            <Tab.Pane eventKey={SUB_VIEWNAMES.DETAILS}>
              <BusinessDetails
                expression={expression}
                handleDiseaseDataType={handleDiseaseDataType}
                bpDiseaseDataType={bpDiseaseDataType}
                selectedFilters={selectedFilters}
                businessComments={commentsData}
                dataFilterOptions={bpDataOptions}
                commentCardIds={commentCardIds}
                infoMessages={infoMessages}
                active={viewType === SUB_VIEWNAMES.DETAILS}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Spin>
      <BussinessPlanComment
        isExpandCommentModalOpen={isExpandCommentModalOpen}
        setIsExpandCommentModalOpen={setIsExpandCommentModalOpen}
        firstComment={narrationData?.data[0]?.Narrative}
      />
    </>
  );
};

export default BusinessPlan;
