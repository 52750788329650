// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Lk_UVG5ApX379SkJWvKa{min-height:88vh;overflow:auto;transition:all .5s ease-out}`, "",{"version":3,"sources":["webpack://./src/components/layout/myviewsearchbar/MyViewKpi.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,aAAA,CACA,2BAAA","sourcesContent":[".library-container {\n  min-height: 88vh;\n  overflow: auto;\n  transition: all 0.5s ease-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"library-container": `Lk_UVG5ApX379SkJWvKa`
};
export default ___CSS_LOADER_EXPORT___;
