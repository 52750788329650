import { accuracyForecastLineChart } from './chartOptions/accuracyForecastLineChart';
import { aggForecastLineChart } from './chartOptions/aggForecastLineChart';
import { barChartOptions } from './chartOptions/barChartOptions';
import { BusinessplanNetSalesLineChart } from './chartOptions/BusinessplanNetSalesLineChart';
import { fYProjectionLineChartOptions } from './chartOptions/fYProjectionLineChartOptions';
import { lineChartOptions } from './chartOptions/lineChartOptions';
import { linegridData } from './chartOptions/linegridData';
import { pieChartOptions } from './chartOptions/pieChartOptions';
import { portfolioBannerTimelineOptions } from './chartOptions/portfolioBannerTimelineOptions';
import { portfolioYPYTDActualsForecastOptions } from './chartOptions/portfolioYPYTDActualsForecastOptions';
import { portfolioYTDActualsForeacastOptions } from './chartOptions/portfolioYTDActualsForeacastOptions';
import { SDlineChartOptions } from './chartOptions/SDlineChartOptions';
import { shortTermBiasForecastBarChart } from './chartOptions/shortTermBiasForecastBarChart';
import { stackedBarChartOptions } from './chartOptions/stackedBarChartOptions';
import { yearforecastlinechart } from './chartOptions/yearforecastlinechart';
import { yoylineChartOptions } from './chartOptions/yoylineChartOptions';

export const ChartOptions = ({
  chartType,
  chartData,
  legend,
  radius,
  colorPallete,
  labelText,
  showEndLabel,
  showMarker,
  category,
  minMax,
  growthPercentage,
  scatterPlotDatatoPlot,
  currentNetSales,
  isViewExpanded,
  expand,
}) => {
  const chartOptions = {
    chartType,
    chartData,
    legend,
    radius,
    colorPallete,
    labelText,
    showEndLabel,
    showMarker,
    category,
    minMax,
    growthPercentage,
    scatterPlotDatatoPlot,
    currentNetSales,
    isViewExpanded,
    expand,
  };

  switch (chartType) {
    case 'pie':
      return pieChartOptions(chartOptions);
    case 'bar':
      return barChartOptions(chartOptions);
    case 'stackedBar':
      return stackedBarChartOptions(chartOptions);
    case 'demandlineChart':
      return lineChartOptions(chartOptions);
    case 'sdline':
      return SDlineChartOptions(chartOptions);
    case 'linechart':
      return yoylineChartOptions(chartOptions);
    case 'BusinessplanNetSalesLineChart':
      return BusinessplanNetSalesLineChart(chartOptions);
    case 'Sidebar':
      return portfolioYTDActualsForeacastOptions(chartOptions);
    case 'SalespySidebar':
      return portfolioYPYTDActualsForecastOptions(chartOptions);
    case 'fyprojectionlineChart':
      return fYProjectionLineChartOptions(chartOptions);
    case 'linegriddata':
      return linegridData(chartOptions);
    case 'portfolioTimeline':
      return portfolioBannerTimelineOptions(chartOptions);
    case 'yearforecastlinechart':
      return yearforecastlinechart(chartOptions);
    case 'aggforecastlinechart':
      return aggForecastLineChart(chartOptions);
    case 'accuracyforecastlinechart':
      return accuracyForecastLineChart(chartOptions);
    case 'barChartOptionsSop':
      return shortTermBiasForecastBarChart(chartOptions);
    case 'barChartOptionsSopMonth':
      return shortTermBiasForecastBarChart(chartOptions);
    default:
      return pieChartOptions(chartOptions);
  }
};
