export const YearForcecastTrend = {
  ytdHigh: 1250,
  ytdLow: 250,
  values: [
    {
      key: 1,
      name: 'Current Forecast',
      data: [null, null, null, null, 600, 650, 620],
      lineStyle: {
        type: 'dotted',
        color: '#004677',
      },
    },
    {
      key: 2,
      name: 'Adjusted CF',
      data: [null, null, null, null, 600, 650, 700],
      lineStyle: {
        color: '#004677',
      },
    },
    {
      key: 3,
      name: 'BP 2022',
      data: [600, 312, 880, 760, 580, 420, null],
      lineStyle: {
        type: 'dotted',
        color: '#4B80B1',
      },
    },
    {
      key: 4,
      name: 'Adjusted BP 2022',
      data: [620, 332, 901, 780, 600, 780, null],
      lineStyle: {
        color: '#4B80B1',
      },
    },
    {
      key: 5,
      name: 'BP 2023',
      data: [300, 450, 1200, 1050, 820, null, null],
      lineStyle: {
        type: 'dotted',
        color: '#DB930B',
      },
    },
    {
      key: 6,
      name: 'Adjusted BP 2023',
      data: [300, 450, 1200, 1050, 880, null, null],
      lineStyle: {
        color: '#DB930B',
      },
    },
  ],
};
export const yoyGrowth = {
  values: [
    {
      key: 1,
      name: 'S&OP',
      data: [300, 450, 1200, 1050, 820, 600, 700],
      lineStyle: {
        color: '#4B80B1',
      },
    },
    {
      key: 2,
      name: 'Actual',
      data: [200, 425, 1100, 1150, 1300, null, null],
      lineStyle: {
        color: '#13294B',
      },
    },
  ],
};

export const yoyGridData = [
  {
    key: 1,
    metric: '01/09/23',
    t0: 210,
    '4week': 220,
    '12week': 300,
    trend: 410,

    sales: 340,
  },
  {
    key: 2,
    metric: '01/16/23',
    t0: 210,
    '4week': 220,
    '12week': 300,
    trend: 410,
    sales: -100,
  },
  {
    key: 3,
    metric: '01/23/23',
    t0: 210,
    '4week': 220,
    '12week': 300,
    trend: 410,
    sales: 205,
  },
  {
    key: 4,
    metric: '01/30/23',
    t0: 210,
    '4week': 220,
    '12week': 300,
    trend: 410,
    sales: 117,
  },
  {
    key: 5,
    metric: '02/06/23',
    t0: 210,
    '4week': 220,
    '12week': 300,
    trend: 410,
    sales: 214,
  },
];

export const AggForecastWeekData = {
  values: [
    {
      key: 1,
      name: 'Actual',
      data: [100, 150, 200, 150, 250, 300],
      lineStyle: {
        color: '#13294B',
      },
    },
    {
      key: 2,
      name: 'S&OP',
      data: [50, 100, 150, 200, 150, 100],
      lineStyle: {
        type: 'dotted',
        color: '#4B80B1',
      },
    },
  ],
};

export const AggForecastMonthData = {
  values: [
    {
      key: 1,
      name: 'Actual',
      data: [100, 150, 200, 150, 250, 30, 400],
      lineStyle: {
        color: '#13294B',
      },
    },
    {
      key: 2,
      name: 'S&OP',
      data: [50, 100, 150, 200, 150, 100],
      lineStyle: {
        type: 'dotted',
        color: '#4B80B1',
      },
    },
  ],
};

export const ytdVarianceGridData = [
  {
    key: 1,
    metric: '01/02/23',
    sales: -11,
  },
  {
    key: 2,
    metric: '01/09/24',
    sales: 5,
  },
  {
    key: 3,
    metric: '01/16/24',
    sales: 15,
  },
  {
    key: 4,
    metric: '01/23/24',
    sales: 10,
  },
  {
    key: 5,
    metric: '01/30/24',
    sales: 23,
  },
  {
    key: 6,
    metric: '02/07/24',
    sales: -23,
  },
  {
    key: 7,
    metric: '02/14/24',
    sales: 23,
  },
  {
    key: 8,
    metric: '02/21/24',
    sales: 23,
  },
];

export const ytdVarianceGridDataMonth = [
  {
    key: 1,
    metric: 'Jan 24',
    sales: -11,
  },
  {
    key: 2,
    metric: 'Feb 24',
    sales: 5,
  },
  {
    key: 3,
    metric: 'Mar 24',
    sales: 15,
  },
  {
    key: 4,
    metric: 'Apr 24',
    sales: 10,
  },
  {
    key: 5,
    metric: 'May 24',
    sales: 23,
  },
  {
    key: 6,
    metric: 'Jun 24',
    sales: -23,
  },
  {
    key: 7,
    metric: 'Jul 24',
    sales: 23,
  },
  {
    key: 8,
    metric: 'Aug 24',
    sales: 23,
  },
  {
    key: 9,
    metric: 'Sep 24',
    sales: 23,
  },
  {
    key: 10,
    metric: 'Oct 24',
    sales: 23,
  },
  {
    key: 11,
    metric: 'Nov 24',
    sales: 23,
  },
  {
    key: 8,
    metric: 'Dec 24',
    sales: 23,
  },
];

export const AggForecastWeekChartGridCols = [
  {
    title: 'Metric',
    dataIndex: 'metric',
    key: 'metric',
    align: 'center',
    render: (value) => {
      return value ? value : '-';
    },
  },
  {
    title: 'S&OP',
    dataIndex: 'sop',
    key: 'sop',
    align: 'center',
    render: (value) => {
      return value ? value : '-';
    },
  },
  {
    title: 'Actual',
    dataIndex: 'actual',
    key: 'actual',
    align: 'center',
    render: (value) => {
      return value ? value : '-';
    },
  },
];

export const AggForecastMonthChartGridCols = [
  {
    title: 'Metric',
    dataIndex: 'metric',
    key: 'metric',
    align: 'center',
    render: (value) => {
      return value ? value : '-';
    },
  },
  {
    title: 'S&OP',
    dataIndex: 'sop',
    key: 'sop',
    align: 'center',
    render: (value) => {
      return value ? value : '-';
    },
  },
  {
    title: 'Actual',
    dataIndex: 'actual',
    key: 'actual',
    align: 'center',
    render: (value) => {
      return value ? value : '-';
    },
  },
];

export const AggForecastWeekGridData = [
  {
    key: 1,
    metric: '01/09/23',
    actual: 22,
    sop: 340,
  },
  {
    key: 2,
    metric: '01/16/23',
    actual: 22,
    sop: -100,
  },
  {
    key: 3,
    metric: '01/23/23',
    actual: 22,
    sop: 205,
  },
  {
    key: 4,
    metric: '01/30/23',
    actual: 22,
    sop: 117,
  },
  {
    key: 5,
    metric: '02/06/23',
    actual: 22,
    sop: 214,
  },
];
export const AggForecastMonthGridData = [
  {
    key: 1,
    metric: 'Jan 24',
    actual: 22,
    sop: 340,
  },
  {
    key: 2,
    metric: 'Feb 24',
    actual: 22,
    sop: 100,
  },
  {
    key: 3,
    metric: 'Mar 24',
    actual: 22,
    sop: 200,
  },
  {
    key: 4,
    metric: 'Apr 24',
    actual: 22,
    sop: 300,
  },
  {
    key: 5,
    metric: 'May 24',
    actual: 22,
    sop: 500,
  },
  {
    key: 6,
    metric: 'Jun 24',
    actual: 22,
    sop: 500,
  },
  {
    key: 7,
    metric: 'Jul 24',
    actual: 22,
    sop: 500,
  },
  {
    key: 8,
    metric: 'Aug 24',
    actual: 22,
    sop: 500,
  },
  {
    key: 9,
    metric: 'Sept 24',
    actual: 22,
    sop: 500,
  },
  {
    key: 10,
    metric: 'Oct 24',
    actual: 22,
    sop: 500,
  },
  {
    key: 11,
    metric: 'Nov 24',
    actual: 22,
    sop: 500,
  },
  {
    key: 12,
    metric: 'Dec 24',
    actual: 22,
    sop: 500,
  },
];

const renderWithColor = (value) => {
  const isPercentage = typeof value === 'string' && value.endsWith('%');
  const displayValue = isPercentage ? value.slice(0, -1) : value;
  const numericValue = parseFloat(displayValue);

  const color = isPercentage
    ? numericValue > 0
      ? 'green'
      : numericValue < 0
      ? 'red'
      : 'black'
    : 'black';

  return {
    children: isPercentage ? `${displayValue}%` : value ? value : '-',
    props: {
      style: { color },
    },
  };
};

export const ShortWeekChartGridCols = [
  {
    title: 'Metric',
    dataIndex: 'metric',
    key: 'metric',
    align: 'center',
    render: renderWithColor,
  },
  {
    title: 'Overforecast',
    dataIndex: 'overforecast',
    key: 'overforecast',
    align: 'center',
    render: renderWithColor,
  },
  {
    title: 'Oversell',
    dataIndex: 'oversell',
    key: 'oversell',
    align: 'center',
    render: renderWithColor,
  },
];

export const ShortMonthChartGridCols = [
  {
    title: 'Metric',
    dataIndex: 'metric',
    key: 'metric',
    align: 'center',
    render: renderWithColor,
  },
  {
    title: 'Overforecast',
    dataIndex: 'overforecast',
    key: 'overforecast',
    align: 'center',
    render: renderWithColor,
  },
  {
    title: 'Oversell',
    dataIndex: 'oversell',
    key: 'oversell',
    align: 'center',
    render: renderWithColor,
  },
];

export const ShortWeekGridData = [
  {
    key: 1,
    metric: '01/09/23',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 2,
    metric: '01/16/23',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 3,
    metric: '01/23/23',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 4,
    metric: '01/30/23',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 5,
    metric: '02/06/23',
    overforecast: '-10%',
    oversell: '10%',
  },
];
export const ShortMonthGridData = [
  {
    key: 1,
    metric: 'Jan 24',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 2,
    metric: 'Feb 24',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 3,
    metric: 'Mar 24',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 4,
    metric: 'Apr 24',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 5,
    metric: 'May 24',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 6,
    metric: 'Jun 24',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 7,
    metric: 'Jul 24',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 8,
    metric: 'Aug 24',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 9,
    metric: 'Sept 24',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 10,
    metric: 'Oct 24',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 11,
    metric: 'Nov 24',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 12,
    metric: 'Dec 24',
    overforecast: '-10%',
    oversell: '10%',
  },
];

export const YearForecastGridCols = [
  {
    title: 'Metric',
    dataIndex: 'metric',
    key: 'metric',
    align: 'center',
    render: (value) => {
      return value ? value : '-';
    },
  },
  {
    title: 'Current Forecast',
    dataIndex: 'currforecast',
    key: 'currforecast',
    align: 'center',
    render: (value) => {
      return value ? value : '-';
    },
  },
  {
    title: 'Adjusted CF',
    dataIndex: 'adjustedcf',
    key: 'adjustedcf',
    align: 'center',
    render: (value) => {
      return value ? value : '-';
    },
  },
  {
    title: 'BP2022',
    dataIndex: 'bp2022',
    key: 'bp2022',
    align: 'center',
    render: (value) => {
      return value ? value : '-';
    },
  },
  {
    title: 'Adjusted BP 2022',
    dataIndex: 'adjustedbp2022',
    key: 'adjustedbp2022',
    align: 'center',
    render: (value) => {
      return value ? value : '-';
    },
  },
  {
    title: 'BP 2023',
    dataIndex: 'bp2023',
    key: 'bp2023',
    align: 'center',
    render: (value) => {
      return value ? value : '-';
    },
  },
  {
    title: 'Adjusted BP 2023',
    dataIndex: 'adjustedbp',
    key: 't0',
    align: 'center',
    render: (value) => {
      return value ? value : '-';
    },
  },
];

export const YearForecastGridData = [
  {
    key: 1,
    metric: '2023',
    currforecast: 210,
    adjustedcf: 220,
    bp2022: 300,
    adjustedbp2022: 410,

    bp2023: 340,
    adjustedbp: 340,
  },
  {
    key: 2,
    metric: '2024',
    currforecast: 210,
    adjustedcf: 220,
    bp2022: 300,
    adjustedbp2022: 410,

    bp2023: 340,
    adjustedbp: 340,
  },
  {
    key: 3,
    metric: '2025',
    currforecast: 210,
    adjustedcf: 220,
    bp2022: 300,
    adjustedbp2022: 410,

    bp2023: 340,
    adjustedbp: 340,
  },
  {
    key: 4,
    metric: '2026',
    currforecast: 210,
    adjustedcf: 220,
    bp2022: 300,
    adjustedbp2022: 410,

    bp2023: 340,
    adjustedbp: 340,
  },
  {
    key: 5,
    metric: '2027',
    currforecast: 210,
    adjustedcf: 220,
    bp2022: 300,
    adjustedbp2022: 410,

    bp2023: 340,
    adjustedbp: 340,
  },
  {
    key: 6,
    metric: '2028',
    currforecast: 210,
    adjustedcf: 220,
    bp2022: 300,
    adjustedbp2022: 410,

    bp2023: 340,
    adjustedbp: 340,
  },
  {
    key: 7,
    metric: '2029',
    currforecast: 210,
    adjustedcf: 220,
    bp2022: 300,
    adjustedbp2022: 410,

    bp2023: 340,
    adjustedbp: 340,
  },
  {
    key: 8,
    metric: '2030',
    currforecast: 210,
    adjustedcf: 220,
    bp2022: 300,
    adjustedbp2022: 410,

    bp2023: 340,
    adjustedbp: 340,
  },
  {
    key: 9,
    metric: '2031',
    currforecast: 210,
    adjustedcf: 220,
    bp2022: 300,
    adjustedbp2022: 410,

    bp2023: 340,
    adjustedbp: 340,
  },
  {
    key: 10,
    metric: '2032',
    currforecast: 210,
    adjustedcf: 220,
    bp2022: 300,
    adjustedbp2022: 410,

    bp2023: 340,
    adjustedbp: 340,
  },
];

export const NetSalesGrid = {
  values: [
    {
      key: 1,
      title: 'Current Forecast Unadjusted ($M)',
      2023: '184.0',
      2024: '150.0',
      2025: '147.0',
      2026: '148.0',
      2027: '156.0',
      2028: '156.0',
      2029: '156.0',
      2030: '166.0',
      2031: '176.0',
      2032: '186.0',
    },
    {
      key: 2,
      title: 'Current Forecast Adjusted ($M)',
      2023: '184.0',
      2024: '150.0',
      2025: '147.0',
      2026: '148.0',
      2027: '156.0',
      2028: '156.0',
      2029: '156.0',
      2030: '166.0',
      2031: '176.0',
      2032: '186.0',
    },
    {
      key: 3,
      title: 'Current Forecast YoY%',
      2023: '18%',
      2024: '-2%',
      2025: '-53%',
      2026: '-4%',
      2027: '10%',
      2028: '11%',
      2029: '12%',
      2030: '-12%',
      2031: '13%',
      2032: '-14%',
    },
    {
      key: 4,
      title: 'Business Plan 2022 Unadjusted ($M)',
      2023: '184.0',
      2024: '150.0',
      2025: '147.0',
      2026: '148.0',
      2027: '156.0',
      2028: '156.0',
      2029: '156.0',
      2030: '166.0',
      2031: '176.0',
      2032: '186.0',
    },
    {
      key: 5,
      title: 'Business Plabn 2022 Adjusted ($M)',
      2023: '184.0',
      2024: '150.0',
      2025: '147.0',
      2026: '148.0',
      2027: '156.0',
      2028: '156.0',
      2029: '156.0',
      2030: '166.0',
      2031: '176.0',
      2032: '186.0',
    },
    {
      key: 6,
      title: 'Business Plan 2022 YoY%',
      2023: '184.0',
      2024: '150.0',
      2025: '147.0',
      2026: '148.0',
      2027: '156.0',
      2028: '156.0',
      2029: '156.0',
      2030: '166.0',
      2031: '176.0',
      2032: '186.0',
    },
  ],
};
