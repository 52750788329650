// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SAFPZhIIPt8PLmH3WZg9{width:100%;background:var(--color-secondary-blue) 0% 0% no-repeat padding-box;color:var(--color-white);padding:1.5% 3%;border-radius:6px;display:flex;justify-content:space-between}.aQCPqxCsXGW0V0ezM8Ko{margin:6px}.SJnis7XEMpcbsb1uA0Bn{width:100%}.L7KIc_CuVHutSiHi28O9{z-index:0;padding-bottom:25px}.urIZx6cvr9Fkdpfq8JcA{margin-top:4px;font-size:var(--font-size-xx-small)}`, "",{"version":3,"sources":["webpack://./src/components/elements/banner/BusinessPlanBanner.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,kEAAA,CACA,wBAAA,CACA,eAAA,CACA,iBAAA,CACA,YAAA,CACA,6BAAA,CAGF,sBACE,UAAA,CAEF,sBACE,UAAA,CAGF,sBACE,SAAA,CACA,mBAAA,CAGF,sBACE,cAAA,CACA,mCAAA","sourcesContent":[".banner-container-wrap {\n    width: 100%;\n    background: var(--color-secondary-blue) 0% 0% no-repeat padding-box;\n    color: var(--color-white);\n    padding: 1.5% 3%;\n    border-radius: 6px;\n    display: flex;\n    justify-content: space-between;\n  }\n  \n  .icon-align {\n    margin: 6px;\n  }\n  .banner-container {\n    width: 100%;\n  }\n  \n  .banner {\n    z-index: 0;\n    padding-bottom: 25px;\n  }\n  \n  .comment-length {\n    margin-top: 4px;\n    font-size: var(--font-size-xx-small);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner-container-wrap": `SAFPZhIIPt8PLmH3WZg9`,
	"icon-align": `aQCPqxCsXGW0V0ezM8Ko`,
	"banner-container": `SJnis7XEMpcbsb1uA0Bn`,
	"banner": `L7KIc_CuVHutSiHi28O9`,
	"comment-length": `urIZx6cvr9Fkdpfq8JcA`
};
export default ___CSS_LOADER_EXPORT___;
