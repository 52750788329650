import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setIsViewFirstLoad,
  setPointerIndex,
  setUpdateMyViewCardsList,
} from '@store/brand/brandViewSlice';
import { getQlikSenseUnformattedData } from '@service/qlik.service';
import TileLayoutContainer from '../configuremyview/TileLayoutContainer';
import {
  demandChartGridCols,
  fyProjectionGridCols,
  yoyGridCols,
  ytdChartGridCols,
  ytdSalesVsForecastChartGridCols,
} from '@mockdata/brandChartData';
import {
  EXTRACTCATEGORYARRAY,
  SORTYEARS,
  TRANSFORM_AGGFORECAST_CHARTDATA,
  TRANSFORM_AGGFORECAST_GRIDDATA,
  TRANSFORM_BPCHART_SCATTER,
  TRANSFORM_BPLAN_CHARTDATA,
  TRANSFORM_FORECASTTREND_CHARTDATA,
  TRANSFORM_FORECASTTREND_GRIDDATA,
  TRANSFORM_FYPROJECTION_CHARTDATA,
  TRANSFORM_FYPROJECTION_GRIDDATA,
  TRANSFORM_SD_CHARTDATA,
  TRANSFORM_SD_GRIDDATA,
  TRANSFORM_SHORTBIAS_GRIDDATA,
  TRANSFORM_SHORTTERMBIAS_CHARTDATA,
  TRANSFORM_VARIANCE_CHARTDATA,
  TRANSFORM_YOYOUTS_CHARTDATA,
  TRANSFORM_YOYOUTS_GRIDATA,
  TRANSFORM_YOYSALES_CHARTDATA,
  TRANSFORM_YOYSALES_GRIDDATA,
  TRANSFORM_YTD_CHARTDATA,
  TRANSFORM_YTD_GRIDDATA,
  TRANSFORM_YTDFORECASTGRIDDATA,
  TRANSFORM_YTDFORECASTPY_GRIDDATA,
} from '@utils/dataFormatter';
import {
  CATEGORYUPPERCASE,
  CHART_DATA,
  CHART_GRID_COLUMNS,
  FILTERS,
  GROWTH,
  SINGLE,
  STATE_BRAND_SUMMARY,
  T0,
  VREPLACESTRING,
} from '@constant';
import {
  AggForecastGridCols,
  ShortWeekChartGridCols,
  YearForecastGridCols,
} from '@mockdata/SopChartData';
import {
  extractMeasureEventType,
  GETLABELEXPRESSION,
  GETSECTIONSEXPRESSIONGRID,
} from '@utils/utils';
import { CARD_CONFIG } from '@utils/cardConfig';

const MyViewTab = ({
  brandExpressions,
  onRemoveFromView,
  onDuplicateCard,
  onEditCard,
  onOpenConfigureModal,
  dataFilterOptions,
  eventFilterOptions,
  allComments,
}) => {
  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [summaryData, setSummaryData] = useState({
    Week: '',
    ytdAvgLabel: '',
    ytd12weekLabel: '',
    ytdTrendLabel: '',
    ytdTOLabel: '',
    ytdRoyDemandText: '',
    yearForecastLabel: [],
    aggForecastLabel: '',
    ytdRoyDemandLabel: '',
  });

  const [bpData, setBpData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [category, setCategory] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [scatterEvents, setScatterEvents] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isViewMoreLoading, setIsViewMoreLoading] = useState(false);
  const [isViewMoreClicked, setIsViewMoreClicked] = useState(false);
  const myViewCards = useSelector(
    (state) => state?.brandPerformance?.myViewCardsList,
  );
  const pointerIndex = useSelector(
    (state) => state?.brandPerformance?.pointerIndex,
  );

  const isFirstLoad = useSelector(
    (state) => state?.brandPerformance?.isViewFirstLoad,
  );

  const card_config_db = useSelector(
    (state) => state?.brandPerformance.cardConfig,
  );

  //api function for variance charts.
  const getQlikDataVariance = async (
    data,
    cardId,
    dim = [],
    type = undefined,
    isSortDate = false,
    currentCard,
  ) => {
    const payload = {
      connection_details: [
        {
          dimension: dim,
          appId:
            currentCard?.ViewName?.split(' ')[0] === 'S&OP'
              ? process.env.APP_ID_SOP
              : process.env.APP_ID,
          expression: data,
        },
      ],
      dataType: type,
      isSortDate: isSortDate,
    };
    const postResponse = await getQlikSenseUnformattedData(payload);
    if (postResponse) {
      const dataFromResponse = postResponse[0];

      return dataFromResponse;
    }
  };

  //api function for fetching chart labels text.
  const getQlikDataChartText = async (
    data,
    cardId,
    dim = [],
    type = undefined,
    isSortDate = false,
    currentCard,
  ) => {
    const payload = {
      connection_details: [
        {
          dimension: dim,
          appId:
            currentCard?.ViewName?.split(' ')[0] === 'S&OP'
              ? process.env.APP_ID_SOP
              : process.env.APP_ID,
          expression: data,
        },
      ],
      dataType: type,
      isSortDate: isSortDate,
    };

    const postResponse = await getQlikSenseUnformattedData(payload);

    if (postResponse) {
      const dataFromResponse = postResponse[0];

      if (dataFromResponse) {
        return dataFromResponse;
      }
    }

    // setError(null);
  };

  //transformation for business plan ( Net sales) chart
  const getBpChartData = (datas = []) => {
    const resultMap = {};
    let result = datas?.length > 0 ? datas : bpData;
    result?.forEach((item) => {
      if (resultMap[item?.name]) {
        resultMap[item?.name].value = item?.value;
      } else {
        resultMap[item?.name] = { ...item };
      }
    });
    const filteredData = Object.values(resultMap)?.flatMap((item) =>
      item?.value?.map((v) => v),
    );
    return filteredData;
  };

  //function to build expressions for the variance charts.
  const getSectionsExpressionVariance = (
    data,
    CardId,
    ChartId,
    currentExpression,
  ) => {
    const varianceExp = currentExpression.replaceAll(
      'Version_Type',
      'Variance_Version_Type',
    );
    const expressionData = data
      .filter((e) => e.CardId === CardId && e.ChartId === ChartId)
      .map((obj) =>
        JSON.stringify({
          CardName: obj.CardName,
          id: obj.KpiId,
          name: obj.KpiName,
          expression: obj.Expression.replaceAll(VREPLACESTRING, varianceExp),
        }),
      );

    let dimension = [];
    const dimensionNames = new Set();

    data
      .filter((e) => e.CardId === CardId && e.ChartId === ChartId)
      .forEach((obj) => {
        if (obj.DimensionName && !dimensionNames.has(obj.DimensionName)) {
          dimensionNames.add(obj.DimensionName);
          dimension.push(
            JSON.stringify({
              dimensionType: CATEGORYUPPERCASE,
              dimensionName: obj.DimensionName,
            }),
          );
        }
      });
    return {
      expression: expressionData,
      dimension: dimension,
    };
  };

  //api function to fetch data for business plan (Net Sales) chart
  const getQlikDataBP = async (
    data,
    cardId,
    dim = [],
    type = undefined,
    isSortDate = false,
    currentCard,
  ) => {
    const payload = {
      connection_details: [
        {
          dimension: dim,
          appId:
            currentCard?.ViewName?.split(' ')[0] === 'S&OP'
              ? process.env.APP_ID_SOP
              : process.env.APP_ID,
          expression: data,
        },
      ],
      dataType: type,
      isSortDate: isSortDate,
    };
    const postResponse = await getQlikSenseUnformattedData(payload);

    return postResponse;
  };

  //api function to fetch data for charts with no variance data
  const getQlikData = async (
    data, // expression
    cardName, // card name
    dim = [], // dimension
    type = undefined, // chart data type = line, bar
    isSortDate = false,
    currentCard,
  ) => {
    const payload = {
      connection_details: [
        {
          dimension: dim,
          appId:
            currentCard?.ViewName?.split(' ')[0] === 'S&OP'
              ? process.env.APP_ID_SOP
              : process.env.APP_ID,
          expression: data,
        },
      ],
      dataType: type,
      isSortDate: isSortDate,
    };

    try {
      const postResponse = await getQlikSenseUnformattedData(payload); // Wait for API response

      if (postResponse && postResponse.length > 0) {
        const dataFromResponse = postResponse[0]; // Extract the first result

        return dataFromResponse; // Return the actual data synchronously
      }
      return null; // Return null if no valid response
    } catch (error) {
      // eslint-disable-next-line no-undef
      console.error('Error fetching Qlik data:', error);
      return null; // Return null in case of an error
    }
  };

  //transformation function for portfolio forecast chartdata
  const ytdVarianceActualChartData = (inputData, VersionType) => {
    return inputData?.values
      ?.filter((item) => {
        return item?.name !== GROWTH;
      })
      ?.map((item) => {
        return {
          name: item?.name === T0 ? VersionType : item?.name,
          value: Number(item?.data),
        };
      })
      ?.reverse();
  };

  //transformation function for portfolio PY -forecast chartdata
  const ytdPyActualChartData = (inputData) => {
    return inputData?.values
      ?.filter((item) => {
        return item?.name !== GROWTH;
      })
      ?.map((item) => {
        return {
          name: item?.name,
          value: Number(item?.data),
        };
      })
      ?.reverse();
  };

  const inputString = summaryData?.ytdRoyDemandLabel;
  const transformLabel = (inputString) => {
    const lineStyle = {
      Actual: '#13294B',
      '12 wk MA': '#DB930B',
      Trend: '#56A944',
      T0: '#af1685',
      High: '#919698',
      Low: '#CBCBCB',
    };
    const result = [];

    if (inputString) {
      const splitData = inputString.split('|');

      result.push(...splitData);
    }

    const transformed = result.map((item, index) => {
      const parts = item.split(':');
      let name = parts[0].trim();

      if (name === 'Actual Sales') {
        name = 'Actual';
      }

      return {
        key: index + 1,
        name: name,
        data: item.trim(),
        linsStyle: lineStyle[name],
      };
    });

    return transformed; // Return the transformed array
  };

  const labelytd = transformLabel(inputString);

  //mapping for each card for chart,grid,gridcolumns and category for chart.
  const MYVIEW_MAPPING = {
    'ytd-sales-v-forec': {
      chartData: (inputData, VersionType) =>
        ytdVarianceActualChartData(inputData, VersionType),
      gridColumns: () => ytdSalesVsForecastChartGridCols,
      gridData: (inputData, VersionType) =>
        TRANSFORM_YTDFORECASTGRIDDATA(inputData?.values, VersionType),
      category: () => {
        return [];
      },
      varianceChartData: (inputData) => TRANSFORM_VARIANCE_CHARTDATA(inputData),
      varianceGridData: (inputData) => inputData?.values,
    },
    'ytd-sales-v-py': {
      chartData: (inputData) => ytdPyActualChartData(inputData),
      gridColumns: () => ytdSalesVsForecastChartGridCols,
      gridData: (inputData) =>
        TRANSFORM_YTDFORECASTPY_GRIDDATA(inputData?.values),
      category: () => {
        return [];
      },
      varianceChartData: (inputData) => TRANSFORM_VARIANCE_CHARTDATA(inputData),
      varianceGridData: (inputData) => inputData?.values,
    },
    'fy-projection': {
      chartData: (inputData, VersionType) =>
        TRANSFORM_FYPROJECTION_CHARTDATA(inputData, VersionType),
      gridColumns: () => (VersionType) => fyProjectionGridCols(VersionType),
      gridData: (inputData) => TRANSFORM_FYPROJECTION_GRIDDATA(inputData),
      category: (inputData) => {
        return inputData;
      },
    },
    'ytd-variance': {
      chartData: (inputData) => TRANSFORM_VARIANCE_CHARTDATA(inputData),
      gridColumns: () => ytdChartGridCols,
      gridData: (inputData) => inputData?.values,
      category: () => {
        return [];
      },
    },
    'ytd-py': {
      chartData: (inputData) => TRANSFORM_VARIANCE_CHARTDATA(inputData),
      gridColumns: () => ytdChartGridCols,
      gridData: (inputData) => inputData?.values,
      category: () => {
        return [];
      },
    },
    'ytd-roy': {
      chartData: (inputData, VersionType) =>
        TRANSFORM_YTD_CHARTDATA(inputData, VersionType),
      gridColumns: (VersionType) => yoyGridCols(VersionType),
      gridData: (inputData) => TRANSFORM_YTD_GRIDDATA(inputData),
      category: (inputData) => {
        return inputData;
      },
    },
    'sales-demand': {
      chartData: (inputData) => TRANSFORM_SD_CHARTDATA(inputData),
      gridColumns: () => demandChartGridCols,
      gridData: (inputData) => TRANSFORM_SD_GRIDDATA(inputData),
      category: (inputData) => {
        return inputData;
      },
    },
    'yoy-sales': {
      chartData: (inputData) => TRANSFORM_YOYSALES_CHARTDATA(inputData),
      gridColumns: (inputData) =>
        inputData &&
        Object?.keys(inputData)?.length > 0 &&
        inputData?.category[0]?.length > 0 &&
        CHART_GRID_COLUMNS(inputData?.category[0]),
      gridData: (inputData) => TRANSFORM_YOYSALES_GRIDDATA(inputData),
      category: (inputData) => SORTYEARS(inputData?.category),
    },
    'yoy-growth': {
      chartData: (inputData) => TRANSFORM_YOYOUTS_CHARTDATA(inputData),
      gridColumns: (inputData) =>
        inputData &&
        Object?.keys(inputData)?.length > 0 &&
        inputData?.category[0]?.length > 0 &&
        CHART_GRID_COLUMNS(inputData?.category[0]),
      gridData: (inputData) => TRANSFORM_YOYOUTS_GRIDATA(inputData),
      category: (inputData) => inputData,
    },
    'year-forecast': {
      chartData: (inputData, VersionType) =>
        TRANSFORM_FORECASTTREND_CHARTDATA(inputData, VersionType),
      gridColumns: (VersionType) => YearForecastGridCols(VersionType),
      gridData: (inputData) => TRANSFORM_FORECASTTREND_GRIDDATA(inputData),
      category: (inputData) => inputData,
    },
    'agg-forecast': {
      chartData: (inputData) => TRANSFORM_AGGFORECAST_CHARTDATA(inputData),
      gridColumns: () => AggForecastGridCols,
      gridData: (inputData) => TRANSFORM_AGGFORECAST_GRIDDATA(inputData),
      category: (inputData) => inputData,
      varianceChartData: (inputData) =>
        TRANSFORM_AGGFORECAST_CHARTDATA(inputData),
      varianceGridData: (inputData) =>
        TRANSFORM_AGGFORECAST_GRIDDATA(inputData),
    },
    'short-termforecast': {
      chartData: (inputData) => TRANSFORM_SHORTTERMBIAS_CHARTDATA(inputData),
      gridColumns: () => ShortWeekChartGridCols,
      gridData: (inputData) => TRANSFORM_SHORTBIAS_GRIDDATA(inputData),
      category: (inputData) => inputData?.category,
      varianceChartData: (inputData) =>
        TRANSFORM_SHORTTERMBIAS_CHARTDATA(inputData),
      varianceGridData: (inputData) => TRANSFORM_SHORTBIAS_GRIDDATA(inputData),
    },
    'net-sales': {
      chartData: (inputData) => TRANSFORM_BPLAN_CHARTDATA(inputData),
      gridColumns: () => ShortWeekChartGridCols,
      gridData: () => [],
      category: (inputData) => inputData?.category,
    },
  };

  const onClickViewMore = () => {
    setIsViewMoreClicked(true);
    dispatch(setPointerIndex());
  };

  const fetchMyViewData = async (remainingData) => {
    isViewMoreClicked ? setIsViewMoreLoading(true) : setIsLoading(true);
    try {
      if (
        myViewCards?.length > 0 &&
        brandExpressions?.length > 0 &&
        pointerIndex &&
        remainingData
      ) {
        const updatedCards = [...myViewCards]; // Create a copy of the cards list
        // eslint-disable-next-line no-unused-vars
        for (const [index, item] of remainingData?.entries()) {
          const myviewIndex = myViewCards?.findIndex(
            (item2) => item2?.ID === item?.ID,
          );

          if (
            item?.ChartLabel ===
              CARD_CONFIG.PORTFOLIO_YTD_VARIANCE_CHART.ChartLabel ||
            item?.ChartLabel ===
              CARD_CONFIG.PORTFOLIO_YTD_PY_VARIANCE_CHART.ChartLabel
          ) {
            const getPortfolioActualTO = getSectionsExpression(
              brandExpressions,
              item?.CardId,
              1,
              item?.Expression,
            );
            const getPortfolioYtd = getSectionsExpressionVariance(
              brandExpressions,
              item?.CardId,
              2,
              item?.Expression,
            );
            const dataytd = await getQlikData(
              getPortfolioActualTO.expression,
              item?.CardName,
              getPortfolioActualTO.dimension,
              item?.ChartDataType,
              true,
              item,
            );
            const dataytdr = await getQlikDataVariance(
              getPortfolioYtd.expression,
              item?.ChartLabel,
              getPortfolioYtd.dimension,
              item?.ChartDataType,
              false,
              item,
            );
            const updatedItem = {
              ...item,
              chartData: MYVIEW_MAPPING[item?.ChartLabel].chartData(
                dataytd,
                JSON.parse(item?.Filter)?.VersionType,
              ),
              gridColumns: MYVIEW_MAPPING[item?.ChartLabel].gridColumns,
              gridData: MYVIEW_MAPPING[item?.ChartLabel].gridData(
                dataytd,
                JSON.parse(item?.Filter)?.VersionType,
              ),
              category: MYVIEW_MAPPING[item?.ChartLabel].category(dataytd),
              varianceChartData:
                MYVIEW_MAPPING[item?.ChartLabel].varianceChartData(dataytdr),
              varianceGridData:
                MYVIEW_MAPPING[item?.ChartLabel].varianceGridData(dataytdr),
            };

            updatedCards[myviewIndex] = updatedItem;
          } else if (item?.ChartLabel === 'fy-projection') {
            const getYtdAvgLabel = getSectionsExpression(
              brandExpressions,
              card_config_db?.PORTFOLIO_FY_PROJECTION_CHART_YTDAVG_LABEL
                ?.CardId,
              card_config_db?.PORTFOLIO_FY_PROJECTION_CHART_YTDAVG_LABEL
                ?.ChartId,
              item?.Expression,
            );
            const get12weekLabel = getSectionsExpression(
              brandExpressions,
              card_config_db?.PORTFOLIO_FY_PROJECTION_CHART_12WEEK_LABEL
                ?.CardId,
              card_config_db?.PORTFOLIO_FY_PROJECTION_CHART_12WEEK_LABEL
                ?.ChartId,
              item?.Expression,
            );
            const getTrendLabel = getSectionsExpression(
              brandExpressions,
              card_config_db?.PORTFOLIO_FY_PROJECTION_CHART_TREND_LABEL?.CardId,
              card_config_db?.PORTFOLIO_FY_PROJECTION_CHART_TREND_LABEL
                ?.ChartId,
              item?.Expression,
            );
            const getTOLabel = getSectionsExpression(
              brandExpressions,
              card_config_db?.PORTFOLIO_FY_PROJECTION_CHART_TO_LABEL?.CardId,
              card_config_db?.PORTFOLIO_FY_PROJECTION_CHART_TO_LABEL?.ChartId,
              item?.Expression,
            );
            const getChartData = getSectionsExpression(
              brandExpressions,
              item?.CardId,
              item?.ChartId,
              item?.Expression,
            );
            const ytdAvgLabel = await getQlikDataChartText(
              getYtdAvgLabel.expression,
              'ytdAvgLabel',
              [],
              SINGLE,
              false,
              item,
            );
            const ytd12weekLabel = await getQlikDataChartText(
              get12weekLabel.expression,
              'ytd12weekLabel',
              [],
              SINGLE,
              false,
              item,
            );
            const ytdTrendLabel = await getQlikDataChartText(
              getTrendLabel.expression,
              'ytdTrendLabel',
              [],
              SINGLE,
              false,
              item,
            );
            const ytdTOLabel = await getQlikDataChartText(
              getTOLabel.expression,
              'ytdTOLabel',
              [],
              SINGLE,
              false,
              item,
            );

            const chartData = await getQlikData(
              getChartData.expression,
              item?.CardName,
              getChartData.dimension,
              item?.ChartDataType,
              true,
              item,
            );

            const updatedItem = {
              ...item,
              chartData: MYVIEW_MAPPING[item?.ChartLabel].chartData(
                chartData,
                JSON.parse(item?.Filter)?.VersionType,
              ),
              gridColumns: MYVIEW_MAPPING[item?.ChartLabel].gridColumns(
                JSON.parse(item?.Filter)?.VersionType,
              ),
              gridData: MYVIEW_MAPPING[item?.ChartLabel].gridData(chartData),
              category: EXTRACTCATEGORYARRAY(
                MYVIEW_MAPPING[item?.ChartLabel].category(chartData),
              ),
              chartText: {
                ytdAvg: ytdAvgLabel || '-',
                twelveWeek: ytd12weekLabel || '-',
                trend: ytdTrendLabel || '-',
                to: ytdTOLabel || '-',
              },
            };

            updatedCards[myviewIndex] = updatedItem;
          } else if (
            item?.ChartLabel ===
              CARD_CONFIG.BRAND_YTD_VARIANCE_CHART.ChartLabel ||
            item?.ChartLabel ===
              CARD_CONFIG.BRAND_YTD_YOY_VARIANCE_CHART.ChartLabel
          ) {
            const getChartData = getSectionsExpression(
              brandExpressions,
              item?.CardId,
              item?.ChartId,
              item?.Expression,
            );

            const chartData = await getQlikData(
              getChartData.expression,
              item?.CardName,
              getChartData.dimension,
              item?.ChartDataType,
              true,
              item,
            );

            const updatedItem = {
              ...item,
              chartData: MYVIEW_MAPPING[item?.ChartLabel].chartData(chartData),
              gridColumns: MYVIEW_MAPPING[item?.ChartLabel].gridColumns,
              gridData: MYVIEW_MAPPING[item?.ChartLabel].gridData(chartData),
              category: EXTRACTCATEGORYARRAY(
                MYVIEW_MAPPING[item?.ChartLabel].category(chartData),
              ),
            };

            updatedCards[myviewIndex] = updatedItem;
          } else if (
            item?.ChartLabel === CARD_CONFIG.BRAND_YTD_ROY_CHART.ChartLabel
          ) {
            const getChartData = getSectionsExpression(
              brandExpressions,
              item?.CardId,
              item?.ChartId,
              item?.Expression,
            );

            const chartData = await getQlikData(
              getChartData.expression,
              item?.CardName,
              getChartData.dimension,
              item?.ChartDataType,
              true,
              item,
            );
            const chartdata3DemandText = getSectionsExpression(
              brandExpressions,
              card_config_db?.BRAND_CHARTLABEL_YTD?.CardId,
              card_config_db?.BRAND_CHARTLABEL_YTD?.ChartId,
              item?.Expression,
            );
            const chartdata3ytdLabel = getSectionsExpression(
              brandExpressions,
              card_config_db?.BRAND_YTD_ROY_CHART_LABEL?.CardId,
              card_config_db?.BRAND_YTD_ROY_CHART_LABEL?.ChartId,
              item?.Expression,
            );

            const labelvalue = await getQlikData(
              chartdata3ytdLabel.expression,
              'ytdRoyDemandLabel',
              [],
              SINGLE,
              true,
              item,
            );
            const ytdLabel = await getQlikDataChartText(
              chartdata3DemandText.expression,
              'ytdRoyDemandText',
              chartdata3DemandText.dimension,
              SINGLE,
              false,
              item,
            );

            const updatedItem = {
              ...item,
              chartData: MYVIEW_MAPPING[item?.ChartLabel].chartData(
                chartData,
                JSON.parse(item?.Filter)?.VersionType,
              ),
              gridColumns: MYVIEW_MAPPING[item?.ChartLabel].gridColumns(
                JSON.parse(item?.Filter)?.VersionType,
              ),
              gridData: MYVIEW_MAPPING[item?.ChartLabel].gridData(chartData),
              category: EXTRACTCATEGORYARRAY(
                MYVIEW_MAPPING[item?.ChartLabel].category(chartData),
              ),
              labelytd: transformLabel(labelvalue),
              chartText: { ytd: ytdLabel || '-' },
            };

            updatedCards[myviewIndex] = updatedItem;
          } else if (
            item?.ChartLabel === CARD_CONFIG.BRAND_YTD_SD_CHART.ChartLabel
          ) {
            const getChartData = getSectionsExpression(
              brandExpressions,
              item?.CardId,
              item?.ChartId,
              item?.Expression,
            );

            const chartData = await getQlikData(
              getChartData.expression,
              item?.CardName,
              getChartData.dimension,
              item?.ChartDataType,
              true,
              item,
            );

            const updatedItem = {
              ...item,
              chartData: MYVIEW_MAPPING[item?.ChartLabel].chartData(chartData),
              gridColumns: MYVIEW_MAPPING[item?.ChartLabel].gridColumns,
              gridData: MYVIEW_MAPPING[item?.ChartLabel].gridData(chartData),
              category: EXTRACTCATEGORYARRAY(
                MYVIEW_MAPPING[item?.ChartLabel].category(chartData),
              ),
            };

            updatedCards[myviewIndex] = updatedItem;
          } else if (
            item?.ChartLabel === CARD_CONFIG.BRAND_YOY_SALES_CHART.ChartLabel
          ) {
            const getChartData = getSectionsExpression(
              brandExpressions,
              item?.CardId,
              item?.ChartId,
              item?.Expression,
            );
            const chartData = await getQlikData(
              getChartData.expression,
              item?.CardName,
              getChartData.dimension,
              item?.ChartDataType,
              true,
              item,
            );
            const getYoySalesGrid = getSectionsExpression(
              brandExpressions,
              card_config_db?.BRAND_YOY_SALES_GRID?.CardId,
              card_config_db?.BRAND_YOY_SALES_GRID?.ChartId,
              item?.Expression,
            );

            const gridData = await getQlikData(
              getYoySalesGrid.expression,
              item?.CardName,
              getYoySalesGrid.dimension,
              item?.ChartDataType,
              true,
              item,
            );

            const updatedItem = {
              ...item,
              chartData: MYVIEW_MAPPING[item?.ChartLabel].chartData(chartData),
              gridColumns:
                MYVIEW_MAPPING[item?.ChartLabel].gridColumns(chartData),
              gridData: [
                ...TRANSFORM_YOYSALES_GRIDDATA(chartData),
                ...TRANSFORM_YOYSALES_GRIDDATA(gridData),
              ],
              category: MYVIEW_MAPPING[item?.ChartLabel].category(chartData),
            };

            updatedCards[myviewIndex] = updatedItem;
          } else if (
            item?.ChartLabel ===
            CARD_CONFIG.BRAND_YOY_OUTS_GROWTH_CHART.ChartLabel
          ) {
            const getYoyGrowthGrid = getSectionsExpression(
              brandExpressions,
              card_config_db?.BRAND_YOY_OUTS_GROWTH_GRID?.CardId,
              card_config_db?.BRAND_YOY_OUTS_GROWTH_GRID?.ChartId,
              item?.Expression,
            );
            const gridData = await getQlikData(
              getYoyGrowthGrid.expression,
              item?.CardName,
              getYoyGrowthGrid.dimension,
              item?.ChartDataType,
              true,
              item,
            );

            const chartdatayoyOutsLabel = GETLABELEXPRESSION(
              brandExpressions,
              card_config_db?.BRAND_YOY_OUTS_GROWTH_CHART?.CardId,
              card_config_db?.BRAND_YOY_OUTS_GROWTH_CHART?.ChartId,
              item?.Expression,
            );

            const labelResponse = await getQlikData(
              chartdatayoyOutsLabel.expression,
              STATE_BRAND_SUMMARY.YOYOUTSCHARTLABEL,
              chartdatayoyOutsLabel.dimension,
            );

            const getChartData = GETSECTIONSEXPRESSIONGRID(
              brandExpressions,
              item?.CardId,
              item?.ChartId,
              item?.Expression,
              labelResponse?.values,
            );

            const chartData = await getQlikData(
              getChartData.expression,
              item?.CardName,
              getChartData.dimension,
              item?.ChartDataType,
              true,
              item,
            );

            const updatedItem = {
              ...item,
              chartData: MYVIEW_MAPPING[item?.ChartLabel].chartData(chartData),
              gridColumns:
                MYVIEW_MAPPING[item?.ChartLabel].gridColumns(gridData),
              gridData: MYVIEW_MAPPING[item?.ChartLabel].gridData(gridData),
              category: EXTRACTCATEGORYARRAY(
                MYVIEW_MAPPING[item?.ChartLabel].category(chartData),
              ),
            };

            updatedCards[myviewIndex] = updatedItem;
          } else if (
            item?.ChartLabel === CARD_CONFIG.SOP_YEAR_FORECAST_CHART.ChartLabel
          ) {
            const getChartData = getSectionsExpression(
              brandExpressions,
              item?.CardId,
              item?.ChartId,
              item?.Expression,
            );

            const chartData = await getQlikData(
              getChartData.expression,
              item?.CardName,
              getChartData.dimension,
              item?.ChartDataType,
              true,
              item,
            );
            const yearForecastLabelText = getSectionsExpression(
              brandExpressions,
              item?.CardId,
              2,
              item?.Expression,
            );
            const yearForecastLabel = await getQlikDataChartText(
              yearForecastLabelText.expression,
              'yearForecastLabel',
              yearForecastLabelText.dimension,
              SINGLE,
              true,
              item,
            );

            const forecastTrendMonth = getSectionsExpression(
              brandExpressions,
              item?.CardId,
              3,
              item?.Expression,
            );
            const trendMonth = await getQlikDataChartText(
              forecastTrendMonth.expression,
              'forecastTrendMonth',
              forecastTrendMonth.dimension,
              SINGLE,
              true,
              item,
            );
            const updatedItem = {
              ...item,
              chartData: MYVIEW_MAPPING[item?.ChartLabel].chartData(
                chartData,
                JSON.parse(item?.Filter)?.VersionType,
              ),
              gridColumns: MYVIEW_MAPPING[item?.ChartLabel].gridColumns(
                JSON.parse(item?.Filter)?.VersionType,
              ),
              gridData: MYVIEW_MAPPING[item?.ChartLabel].gridData(chartData),
              category: EXTRACTCATEGORYARRAY(
                MYVIEW_MAPPING[item?.ChartLabel].category(chartData),
              ),
              chartText: {
                kpis: yearForecastLabel?.values,
                forecastTrendMonth: trendMonth,
              },
            };

            updatedCards[myviewIndex] = updatedItem;
          } else if (
            item?.ChartLabel === CARD_CONFIG.SOP_AGGFORECAST_CHART.ChartLabel
          ) {
            const getChartData = getSectionsExpression(
              brandExpressions,
              item?.CardId,
              item?.ChartId,
              item?.Expression,
            );
            const getSopAccuracy = getSectionsExpression(
              brandExpressions,
              card_config_db?.SOP_AGG_ACCURACY_FORECAST_CHART?.CardId,
              card_config_db?.SOP_AGG_ACCURACY_FORECAST_CHART?.ChartId,
              item?.Expression,
            );
            const chartData = await getQlikData(
              getChartData.expression,
              item?.CardName,
              getChartData.dimension,
              item?.ChartDataType,
              true,
              item,
            );

            const dataytdr = await getQlikDataVariance(
              getSopAccuracy.expression,
              item?.ChartLabel,
              getSopAccuracy.dimension,
              item?.ChartDataType,
              false,
              item,
            );

            const getAggForecastabel = getSectionsExpression(
              brandExpressions,
              card_config_db?.SOP_AGG_ACCURACY_FORECAST_CHART_LABEL?.CardId,
              card_config_db?.SOP_AGG_ACCURACY_FORECAST_CHART_LABEL?.ChartId,
              item?.Expression,
            );
            const aggLabel = await getQlikDataChartText(
              getAggForecastabel.expression,
              'aggForecastLabel',
              getAggForecastabel.dimension,
              SINGLE,
              true,
              item,
            );

            const updatedItem = {
              ...item,
              chartData: MYVIEW_MAPPING[item?.ChartLabel].chartData(chartData),
              gridColumns: MYVIEW_MAPPING[item?.ChartLabel].gridColumns,
              gridData: MYVIEW_MAPPING[item?.ChartLabel].gridData(chartData),
              category: EXTRACTCATEGORYARRAY(
                MYVIEW_MAPPING[item?.ChartLabel].category(chartData),
              ),
              varianceChartData:
                MYVIEW_MAPPING[item?.ChartLabel].varianceChartData(dataytdr),
              varianceGridData:
                MYVIEW_MAPPING[item?.ChartLabel].varianceGridData(dataytdr),
              chartText: aggLabel || '-',
            };

            updatedCards[myviewIndex] = updatedItem;
          } else if (
            item?.ChartLabel ===
            CARD_CONFIG.SOP_SHORTTERM_BIAS_26WKS_CHART.ChartLabel
          ) {
            const getChartData = getSectionsExpression(
              brandExpressions,
              item?.CardId,
              item?.ChartId,
              item?.Expression,
            );
            const getSopBiasForecast26Weeks = getSectionsExpression(
              brandExpressions,
              card_config_db?.SOP_SHORTTERM_BIAS_26WKS_CHART?.CardId,
              card_config_db?.SOP_SHORTTERM_BIAS_26WKS_CHART?.ChartId,
              item?.Expression,
            );
            const chartData = await getQlikData(
              getChartData.expression,
              item?.CardName,
              getChartData.dimension,
              item?.ChartDataType,
              true,
              item,
            );
            const dataytdr = await getQlikDataVariance(
              getSopBiasForecast26Weeks.expression,
              item?.ChartLabel,
              getSopBiasForecast26Weeks.dimension,
              item?.ChartDataType,
              false,
              item,
            );
            const updatedItem = {
              ...item,
              chartData: MYVIEW_MAPPING[item?.ChartLabel].chartData(chartData),
              gridColumns: MYVIEW_MAPPING[item?.ChartLabel].gridColumns,
              gridData: MYVIEW_MAPPING[item?.ChartLabel].gridData(chartData),
              category: MYVIEW_MAPPING[item?.ChartLabel].category(chartData),
              varianceChartData:
                MYVIEW_MAPPING[item?.ChartLabel].varianceChartData(dataytdr),
              varianceGridData:
                MYVIEW_MAPPING[item?.ChartLabel].varianceGridData(dataytdr),
            };

            updatedCards[myviewIndex] = updatedItem;
          } else if (
            item?.ChartLabel === CARD_CONFIG.BP_NETSALES_CHART.ChartLabel
          ) {
            let updatedItem;
            let data = [];

            for (let option of dataFilterOptions) {
              const getChartData = getSectionsExpressionBP(
                brandExpressions,
                item?.CardId,
                item?.ChartId,
                item?.Expression,
                option,
                item,
              );

              const qlikData = await getQlikDataBP(
                getChartData.expression,
                item?.CardName,
                getChartData.dimension,
                item?.ChartDataType,
                true,
                item,
                option,
                myviewIndex,
              );
              data.push(qlikData);
            }

            let bpdatas = [...bpData];
            let scatterData = [];
            let categoryDta = [];
            if (data?.length > 0) {
              for (let i = 0; i < dataFilterOptions?.length; i++) {
                if (data[i]?.length > 0) {
                  setBpData((prev) => [
                    ...prev,
                    {
                      name: dataFilterOptions[i],
                      value: TRANSFORM_BPLAN_CHARTDATA(
                        data[i][0],
                        dataFilterOptions[i],
                      ),
                    },
                  ]);
                  bpdatas = [
                    ...bpdatas,
                    {
                      name: dataFilterOptions[i],
                      value: TRANSFORM_BPLAN_CHARTDATA(
                        data[i][0],
                        dataFilterOptions[i],
                      ),
                    },
                  ];
                  const years = data[i][0]?.category[0];
                  const uniqueYears = [...new Set(years)];
                  categoryDta = uniqueYears;
                  setCategory(uniqueYears);

                  scatterData = TRANSFORM_BPCHART_SCATTER(data[i][0]);
                  setScatterEvents(TRANSFORM_BPCHART_SCATTER(data[i]));
                }
              }
            }

            updatedItem = {
              ...item,
              chartData: getBpChartData(bpdatas),
              bpData: bpdatas?.length > 0 ? bpdatas : bpData,
              gridColumns: [],
              gridData: [],
              category: categoryDta,
              scatterEvents: scatterData,
            };

            updatedCards[myviewIndex] = updatedItem;
          } else {
            console.warn(
              `No mapping found for ChartLabel: ${item?.ChartLabel}`,
            );
          }
        }
        dispatch(setIsViewFirstLoad(true));

        // Replace the old cards list with the updated list
        dispatch(setUpdateMyViewCardsList(updatedCards)); // Use your state management or Redux here
      }
    } catch (error) {
      console.error('Error fetching My View data:', error);
    } finally {
      isViewMoreClicked ? setIsViewMoreLoading(false) : setIsLoading(false);
      setIsViewMoreClicked(false);
    }
  };
  /*
    ----- @logic for view more -----
    added a pointerindex which start with 6 and every time we add 6 more on view more cta
    also check in myview card data that if chartdata is not present in array of myview card
    till the pointer index we call api for that add that.
  */

  useEffect(() => {
    if (myViewCards?.length === 0) {
      setIsLoading(false);
    }
    if (!isFirstLoad || myViewCards?.length) {
      const remainingCards = myViewCards
        ?.slice(0, pointerIndex)
        .filter((item) => !Object.hasOwn(item, CHART_DATA));
      if (remainingCards?.length && dataFilterOptions?.length) {
        if (brandExpressions?.length) {
          fetchMyViewData(remainingCards);
        }
      }
    }
  }, [
    myViewCards?.length,
    pointerIndex,
    brandExpressions?.length,
    dataFilterOptions?.length,
  ]);

  // function to build expression for chart
  const getSectionsExpression = (data, CardId, ChartId, currentExpression) => {
    const expressionData = data
      .filter((e) => e?.CardId === CardId && e?.ChartId === ChartId)
      .map((obj) =>
        JSON.stringify({
          CardName: obj?.CardName,
          id: obj?.KpiId,
          name: obj?.KpiName,
          expression: obj.Expression.replaceAll(
            VREPLACESTRING,
            currentExpression,
          ),
        }),
      );

    let dimension = [];
    const dimensionNames = new Set();

    data
      .filter((e) => e?.CardId === CardId && e?.ChartId === ChartId)
      .forEach((obj) => {
        if (obj?.DimensionName && !dimensionNames.has(obj.DimensionName)) {
          dimensionNames.add(obj?.DimensionName);

          const dimensionName = obj?.DimensionName?.split(';');
          dimensionName?.map((item) => {
            const dim = {
              dimensionType: CATEGORYUPPERCASE,
              dimensionName: item,
            };
            dimension.push(JSON.stringify(dim));
          });
        }
      });
    return {
      expression: expressionData,
      dimension: dimension,
    };
  };

  // function to build expression for busines plan (Net Sales) chart
  const getSectionsExpressionBP = (
    data,
    CardId,
    ChartId,
    expression,
    item,
    obj,
  ) => {
    let eventOptions = extractMeasureEventType(obj.Expression);

    const EventCondiiton =
      eventOptions && eventOptions.length > 0
        ? `${FILTERS.EVENT_TYPE}={${extractMeasureEventType(obj.Expression)}}`
        : `${FILTERS.EVENT_TYPE}=`;
    const expressionData = data
      .filter((e) => e?.CardId === CardId && e?.ChartId === ChartId)
      .map((obj) =>
        JSON.stringify({
          CardName: obj.CardName,
          id: obj.KpiId,
          name: obj.KpiName,
          expression: obj.Expression.replaceAll(
            VREPLACESTRING,
            expression.concat(
              `, ${FILTERS.VERSION_NAME} ={'${item}'},${EventCondiiton}`,
            ),
          ),
        }),
      );

    let dimension = [];
    const dimensionNames = new Set();

    data
      .filter((e) => e.CardId === CardId && e.ChartId === ChartId)
      .forEach((obj) => {
        if (obj.DimensionName && !dimensionNames.has(obj.DimensionName)) {
          dimensionNames.add(obj.DimensionName);
          dimension.push(
            JSON.stringify({
              dimensionType: CATEGORYUPPERCASE,
              dimensionName: obj.DimensionName,
            }),
          );
        }
      });
    return {
      expression: expressionData,
      dimension: dimension,
    };
  };
  return (
    //container to display all the cards.
    <div>
      <TileLayoutContainer
        list={myViewCards}
        onRemoveFromView={onRemoveFromView}
        onDuplicateCard={onDuplicateCard}
        onEditCard={onEditCard}
        onOpenConfigureModal={onOpenConfigureModal}
        dataFilterOptions={dataFilterOptions}
        eventFilterOptions={eventFilterOptions}
        loader={isLoading}
        labelytd={labelytd}
        allComments={allComments}
        isViewMoreLoading={isViewMoreLoading}
        onClickViewMore={onClickViewMore}
        setIsViewMoreClicked={setIsViewMoreClicked}
      />
    </div>
  );
};

export default MyViewTab;
