import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import BrandReducer from './brand/brandViewSlice';
import PortfolioReducer from './portfolio/portfolioViewSlice';
import SopReducer from './sop/sopViewSlice';
import BpReducer from './bplan/bplanViewSlice';
import globalFilterReducer from './globalFilter/globalFilterSlice';
const rootReducer = combineReducers({
  brandPerformance: BrandReducer,
  portfolio: PortfolioReducer,
  sopPerformance: SopReducer,
  bpPlan: BpReducer,
  globalFilter: globalFilterReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
