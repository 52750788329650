import axiosDBClient from '../../axiosDBClient';

export const addPinnedComment = async (payload) => {
  try {
    const response = await axiosDBClient.post('pinnedComment', {
      ...payload,
      type: payload?.type || 'BRAND',
    });
    if (response.error) {
      throw new Error('Could not add comment!');
    }
    return response;
  } catch (error) {
    return error?.response || error;
  }
};

export const deletePinnedComment = async (payload) => {
  try {
    const response = await axiosDBClient.put('pinnedComment', {
      ...payload,
    });
    if (response.error) {
      throw new Error('Could not add comment!');
    }
    return response.data.message;
  } catch (error) {
    console.log('error', error);
  }
};
