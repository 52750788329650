// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IZV9_ArqfmHK0lcpc1yj{width:552px;height:96px;border-radius:var(--border-radius);border:1px solid var(--color-light-brown-2)}.N5Icd62FxmXRvPp67V9G{text-align:end}`, "",{"version":3,"sources":["webpack://./src/components/elements/feedback/Feedback.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,WAAA,CACA,kCAAA,CACA,2CAAA,CAEF,sBACE,cAAA","sourcesContent":[".comments {\n  width: 552px;\n  height: 96px;\n  border-radius: var(--border-radius);\n  border: 1px solid var(--color-light-brown-2);\n}\n.text-align-end {\n  text-align: end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comments": `IZV9_ArqfmHK0lcpc1yj`,
	"text-align-end": `N5Icd62FxmXRvPp67V9G`
};
export default ___CSS_LOADER_EXPORT___;
