import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin } from 'antd';
import Card from '@elements/card/Card';
import { YearForcecastTrend } from '@mockdata/BusinessPlanChartData';
import { getQlikSenseUnformattedData } from '@service/qlik.service';
import classes from './BusinessSummary.module.scss';
import {
  COMMENT_API_NOTIFICATION,
  NET_SALES_CHART_GRID_COLUMNS,
  MYVIEW_NOTIFICATION,
  ACTION_ADD,
  ACTION_EDIT,
  SUCCESS,
  ERROR_MESSAGE,
  BUSINESSUPPERCASE,
  NETSALESKIPICARDID,
  ACTION_DELETE,
  ERROR,
  PIN_COMMENT_API_NOTIFICATION,
} from '@constant';
import {
  addComment,
  deleteComment,
  getAllComments,
  updateComment,
} from '@service/comments.service';
import { Notification } from '@elements/notification/Notification';
import { addCardToView } from '@service/myview.service';
import { setMyViewCardsList } from '@store/brand/brandViewSlice';
import BusinessSummaryComment from './BusinessSummaryComment';
import { GETSECTIONSEXPRESSION2 } from '@utils/utils';
import { getUserName, getUserUnixId } from '@utils/getUser';
import { CARD_CONFIG } from '@utils/cardConfig';
import {
  addPinnedComment,
  deletePinnedComment,
} from '@service/pinnedComment.service';

const chartDataOptions = {
  option1: YearForcecastTrend.values,
  option2: '',
  option3: '',
  option4: '',
  option5: '',
};

const BusinessSummary = ({
  dataFilterOptions,
  expression,
  selectedFilters,
  businessComments,
  eventFilterOptions,
  showCardIds,
  infoMessages,
  active,
}) => {
  const unixId = getUserUnixId();
  const userName = getUserName();
  const dispatch = useDispatch();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [currentCommentId, setCurrentCommentId] = useState(1);
  const [commentDesc, setCommentDesc] = useState('');
  const [newComment, setNewComment] = useState('');
  const [error, setError] = useState('');
  const [checknetsales, setchecknetsales] = useState(false);
  const [checkevent, setcheckevent] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [checkboxValues, setCheckBoxValues] = useState([]);
  const [checkboxValuesEvent, setCheckBoxValuesEvent] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [chartDataYearForecast, setChartDataYearForecast] = useState(
    YearForcecastTrend.values,
  );
  const [summaryData, setSummaryData] = useState([]);
  const [netsalesChartData, setNetSalesChartData] = useState([]);
  const [netSalesGridData, setNetSalesGridData] = useState([]);
  const [scatterEvents, setScatterEvents] = useState([]);
  const [scatterPlotChartData, setScatterPlotChartData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [gridYearCols, setGridYearCols] = useState([]);
  const [commentsData, setCommentsData] = useState(
    businessComments?.filter((item) => {
      return (
        item?.ChartId === NETSALESKIPICARDID &&
        item?.BrandName === selectedFilters?.Brand[0]
      );
    }),
  );
  const [isLoading, setIsLoading] = useState(true);
  const [currentCardId, setCurrentCardId] = useState(0);
  const [eventDetails, setEventDetails] = useState([]);
  const [bpExpression, setBpExpression] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [commentError, setCommentError] = useState(false);

  const [mount, setMount] = useState(false);
  useEffect(() => {
    if (active) {
      setMount(true);
    }
  }, [active]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getSummaryComments = async () => {
    if (selectedFilters?.Brand?.length > 0) {
      const brandName = selectedFilters?.Brand[0];
      const response = await getAllComments(unixId);
      const filteredComments = response?.filter((item) => {
        return (
          item?.ChartId === NETSALESKIPICARDID && item?.BrandName === brandName
        );
      });
      setCommentsData(filteredComments);
    }
  };
  const brandExpressions = useSelector(
    (state) => state?.brandPerformance?.brandExpressions,
  );

  const myViewKpi = useSelector(
    (state) => state?.brandPerformance?.myViewKpiDetails,
  );
  const myViewCards = useSelector(
    (state) => state?.brandPerformance?.myViewCardsList,
  );

  const card_config_db = useSelector(
    (state) => state?.brandPerformance.cardConfig,
  );

  useEffect(() => {
    setCheckBoxValuesEvent(eventFilterOptions);
  }, [eventFilterOptions]);

  useEffect(() => {
    getSummaryComments();
  }, [selectedFilters.Brand]);

  const onConfigurePinnedComment = async (
    action,
    commentId,
    chartId,
    // no-unused-vars
    // cardId,
  ) => {
    if (action === ACTION_ADD) {
      const payload = {
        chartId: chartId,
        unixId: unixId,
        commentId: commentId,
        brandName:
          selectedFilters?.Brand?.length >= 1
            ? selectedFilters?.Brand?.[0]
            : '',
      };
      const result = await addPinnedComment(payload);
      if (result?.status === 200) {
        await getSummaryComments();
        Notification(SUCCESS, PIN_COMMENT_API_NOTIFICATION.add);
      } else {
        Notification(
          ERROR,
          result?.data?.Reason || PIN_COMMENT_API_NOTIFICATION.fail,
        );
      }
    } else if (action === ACTION_DELETE) {
      const payload = {
        chartId: chartId,
        unixId: unixId,
        commentId: commentId,
      };
      const result = await deletePinnedComment(payload);
      if (result) {
        await getSummaryComments();
        Notification(SUCCESS, PIN_COMMENT_API_NOTIFICATION.delete);
      } else {
        Notification(ERROR, PIN_COMMENT_API_NOTIFICATION.fail);
      }
    }
  };

  const onConfigureComment = (action, id, cardId) => {
    setCurrentCommentId(id);
    setCurrentCardId(cardId);
    if (action === ACTION_ADD) {
      setNewComment('');
      setIsAddModalOpen(true);
    } else if (action === ACTION_EDIT) {
      setCommentDesc(
        commentsData?.filter((item) => item?.ID === id)[0]?.Comment,
      );
      setIsEditModalOpen(true);
      setIsEditModalOpen(true);
    } else {
      setIsDeleteModalOpen(true);
    }
  };

  const handleCancel = () => {
    setIsDeleteModalOpen(false);
    setIsEditModalOpen(false);
    setIsAddModalOpen(false);
    setNewComment('');
    setCommentError(false);
  };

  const handleDeleteComment = async () => {
    setIsDeleteModalOpen(false);
    const response = await deleteComment(currentCommentId, unixId);
    if (response?.message) {
      await getSummaryComments();
      Notification(SUCCESS, COMMENT_API_NOTIFICATION.delete);
    } else {
      Notification(ERROR, ERROR_MESSAGE);
    }
  };

  const handleEditComment = async () => {
    if (commentDesc.trim() === '') {
      return;
    }
    setIsEditModalOpen(false);
    const payload = {
      unixId: unixId,
      comment: commentDesc,
      id: currentCommentId,
    };
    const response = await updateComment(payload);
    if (response?.message) {
      await getSummaryComments();
      Notification(SUCCESS, COMMENT_API_NOTIFICATION.update);
    } else {
      Notification(ERROR, ERROR_MESSAGE);
    }
  };

  const handleAddComment = async () => {
    let error = false;
    if (newComment === '') {
      error = true;
    } else {
      setIsAddModalOpen(false);
      const payload = {
        buname: selectedFilters?.Business_Unit || '',
        squad:
          selectedFilters.Franchise.length === 0
            ? ''
            : selectedFilters.Franchise.join(','),
        brandName:
          selectedFilters?.Brand?.length === 0 ? '' : selectedFilters?.Brand,
        brandclassification: '',
        commenttype: '',
        level: '',
        timeperiod: '-',
        userRole: '',
        chartid: currentCardId,
        pageid: 1,
        isActive: 'Y',
        unixId: unixId,
        comment: newComment,
        userName: userName,
        sku: '',
      };
      const result = await addComment(payload);
      if (result) {
        await getSummaryComments();
        Notification(SUCCESS, COMMENT_API_NOTIFICATION.add);
      } else {
        Notification(ERROR, ERROR_MESSAGE);
      }
      setNewComment('');
    }
    setError(error);
  };

  const transformDataChart = (inputData, selectedOption) => {
    if (
      !inputData ||
      !inputData.category ||
      !inputData.category[0] ||
      !inputData.values ||
      !Array.isArray(inputData.values) ||
      inputData.values.length === 0
    ) {
      return []; // Return an empty array or handle the error as needed
    }

    const transformedData = [];

    // Define line styles for each item by name
    const lineStyles = {
      'Net Sales (Unadjusted)': { type: 'dotted' },
      'Net Sales (Adjusted)': {},
    };

    // Map over the values array to transform the data
    inputData?.values?.forEach((item, index) => {
      if (
        item?.name?.includes('Unadjusted') ||
        item?.name?.includes('Adjusted')
      ) {
        let transformedItem = {
          key: index + 1, // Assign a unique key starting from 1
          name: `${selectedOption} ${
            item?.name?.includes('Unadjusted') ? 'Unadjusted' : 'Adjusted'
          }`,
          data: item.data.map((value) =>
            value === '-' ? null : parseFloat(value),
          ), // Convert "-" to null and strings to numbers
          lineStyle: lineStyles[item.name] || {}, // Assign line style based on the name
        };

        // Add the transformed item to the result array
        transformedData.push(transformedItem);
      }
    });

    return transformedData;
  };

  const transformDataScatterChart = (inputData) => {
    let scatterPlotData = [];
    if (
      inputData?.category &&
      inputData?.category.length &&
      inputData?.values &&
      inputData?.values[0]?.data
    ) {
      const maxValue =
        Math.max(...inputData?.values[0]?.data?.map(Number)) || 0;
      for (let i = 0; i < inputData?.values[0]?.data?.length; i++) {
        let tempData = {};
        tempData.value = [inputData?.category[0][i], maxValue + 300];

        tempData.name = inputData?.values[2].data[i] || '-';
        if (tempData.name !== '-') {
          scatterPlotData.push(tempData);
        }
      }
    }

    return scatterPlotData;
  };

  const onSelectOptionChange = (selectedKey) => {
    const newChartData = chartDataOptions[selectedKey] || [];
    setChartDataYearForecast(newChartData);
  };

  const onSelectChangesNetSales = (updatedSelection) => {
    setchecknetsales(!checknetsales);
    setCheckBoxValues(updatedSelection);
    const resultMap = {};
    const gridResultMap = {};
    summaryData?.forEach((item) => {
      if (resultMap[item.name]) {
        resultMap[item.name].value = item?.value;
      } else {
        resultMap[item.name] = { ...item };
      }
    });
    const filteredData = Object.values(resultMap)?.flatMap((item) =>
      item?.value?.map((v) => v),
    );
    const resultData = filteredData?.filter((item) =>
      updatedSelection?.some((option) => item?.name?.includes(option)),
    );
    setNetSalesChartData(resultData);

    netSalesGridData?.forEach((item) => {
      if (gridResultMap[item.name]) {
        gridResultMap[item.name].value = item?.value;
      } else {
        gridResultMap[item.name] = { ...item };
      }
    });
    const filteredGridData = Object.values(gridResultMap)?.flatMap((item) =>
      item?.value?.map((v) => v),
    );
    const resultGridData = filteredGridData?.filter((item) =>
      updatedSelection?.some((option) => item?.metric?.includes(option)),
    );
    setGridData(resultGridData);
  };

  const onSelectChangesEvent = (updatedSelection) => {
    setcheckevent(!checkevent);
    setCheckBoxValuesEvent(updatedSelection);
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        if (brandExpressions.length && expression.length > 0 && mount) {
          dataFilterOptions?.map((item) => {
            const getNetSales = getSectionsExpression(
              brandExpressions,
              card_config_db?.BP_NETSALES_CHART?.CardId,
              card_config_db?.BP_NETSALES_CHART?.ChartId,
              item,
            );
            const getNetSalesGridData = GETSECTIONSEXPRESSION2(
              brandExpressions,
              card_config_db?.BP_NETSALES_CHARTGRID?.CardId,
              card_config_db?.BP_NETSALES_CHARTGRID?.ChartId,
              expression.concat(`, [Version Name] ={'${item}'}`),
            );
            getQlikData(
              getNetSales.expression,
              'summaryData',
              getNetSales.dimension,
              'line',
              true,
              item,
            );
            getQlikData(
              getNetSalesGridData.expression,
              'netSalesGridData',
              getNetSalesGridData.dimension,
              'kpi',
              true,
              item,
            );
          });

          const getEventsDetails = getSectionsExpression(
            brandExpressions,
            card_config_db?.BP_NETSALES_EVENTS_DETAILS?.CardId,
            card_config_db?.BP_NETSALES_EVENTS_DETAILS?.ChartId,
            dataFilterOptions[0],
          );

          getQlikData(
            getEventsDetails.expression,
            'eventDetails',
            getEventsDetails.dimension,
            'line',
            true,
          );
        }
      } catch (error) {
        // console.error('Error fetching data:', error);
      } finally {
        // Set loading to false once all promises resolve
      }
    };

    fetchData(); // Call the async function
  }, [
    selectedFilters?.Franchise,
    selectedFilters?.Brand,
    selectedFilters?.Business_Unit,
    dataFilterOptions,
    checkboxValuesEvent,
  ]);

  const getSectionsExpression = (data, CardId, ChartId, item) => {
    const EventCondiiton =
      checkboxValuesEvent?.length === eventFilterOptions?.length
        ? `EventType=`
        : `EventType={'${checkboxValuesEvent?.join("','")}'}`;
    const expressionData = data
      .filter((e) => e?.CardId === CardId && e?.ChartId === ChartId)
      .map((obj) =>
        JSON.stringify({
          CardName: obj.CardName,
          id: obj.KpiId,
          name: obj.KpiName,
          expression: obj.Expression.replaceAll(
            'vReplaceString',
            expression.concat(
              `, [Version Name] ={'${item}'}, ${EventCondiiton}`,
            ),
          ),
        }),
      );
    setBpExpression(
      expression.concat(`, [Version Name] ={'${item}'}, ${EventCondiiton}`),
    );

    let dimension = [];
    const dimensionNames = new Set();

    data
      .filter((e) => e.CardId === CardId && e.ChartId === ChartId)
      .forEach((obj) => {
        if (obj.DimensionName && !dimensionNames.has(obj.DimensionName)) {
          dimensionNames.add(obj.DimensionName);
          dimension.push(
            JSON.stringify({
              dimensionType: 'Category',
              dimensionName: obj.DimensionName,
            }),
          );
        }
      });
    return {
      expression: expressionData,
      dimension: dimension,
    };
  };

  const processKPIData = (data, item) => {
    const years = data?.category[0];
    const currentYear = new Date()?.getFullYear();
    const uniqueYears = [...new Set(years)];

    const yearsToInclude = uniqueYears?.filter((year) => year >= currentYear);

    setGridYearCols(uniqueYears);
    const outputData = data?.values?.map((value) => ({
      metric: `${item} ${value?.name}`,
      ...yearsToInclude?.reduce(
        (acc, year, index) => ({ ...acc, [year]: value?.data[index] }),
        {},
      ),
    }));

    return outputData;
  };

  const transformBpEventDetailsData = (inputData) => {
    const destination = [];
    inputData?.category?.forEach((year, index) => {
      const events = inputData?.values[index]?.split('|');
      events?.forEach((event) => {
        // Split the event string by the dollar sign '$' to separate name and description
        const [name, desc] = event?.split('$');
        destination?.push({
          name: name?.trim(),
          desc: desc?.trim(),
          date: year,
        });
      });
    });
    return destination;
  };

  const getQlikData = async (
    data,
    cardId,
    dim = [],
    type = undefined,
    isSortDate = false,
    item,
  ) => {
    const payload = {
      connection_details: [
        {
          dimension: dim,
          appId: process.env.APP_ID,
          expression: data,
        },
      ],
      dataType: type,
      isSortDate: isSortDate,
    };
    const postResponse = await getQlikSenseUnformattedData(payload);

    if (postResponse) {
      const dataFromResponse = postResponse[0];
      if (dataFromResponse) {
        if (cardId === 'summaryData') {
          setSummaryData((prev) => [
            ...prev,
            { name: item, value: transformDataChart(dataFromResponse, item) },
          ]);
          setScatterEvents(transformDataScatterChart(dataFromResponse));
        } else if (cardId === 'eventDetails') {
          setEventDetails(transformBpEventDetailsData(dataFromResponse));
        } else {
          setNetSalesGridData((prev) => [
            ...prev,
            { name: item, value: processKPIData(dataFromResponse, item) },
          ]);
        }
      }
    }
    setIsLoading(false);

    setError(null);
  };

  const handleAddToView = async (
    currentCardDimension,
    currentCardTitle,
    chartData,
    gridData,
    varianceChartData,
    varianceGridData,
    chartText,
    category,
    griCols,
    labelytd,
    bpData,
  ) => {
    const payload = {
      ViewId: 4,
      ViewName: `Business Performance`,
      CardId: currentCardDimension?.CardId,
      CardName: currentCardTitle,
      IsActive: 'Y',
      PositionIndex: myViewCards?.length + 1,
      UnixId: unixId,
      Filter: JSON.stringify(selectedFilters),
      Expression: bpExpression,
      ChartId: currentCardDimension?.ChartId,
      ChartType: currentCardDimension?.ChartType,
      ChartLabel: currentCardDimension?.ChartLabel,
      DimensionId: currentCardDimension?.DimensionId,
      KpiId: currentCardDimension?.KPIId,
      ChartDataType: currentCardDimension?.ChartDataType,
    };
    const data = await addCardToView(payload);

    const cardData = {
      ...payload,
      ID: data?.cardId,
      chartData,
      gridData: [],
      varianceChartData,
      varianceGridData,
      chartText,
      category,
      gridColumns: [],
      labelytd,
      bpData: bpData,
      scatterEvents: scatterPlotChartData,
    };
    dispatch(setMyViewCardsList([...myViewCards, cardData]));
    Notification(SUCCESS, MYVIEW_NOTIFICATION.add);
  };

  const netSaleTitleName = () => {
    if (selectedFilters?.Brand?.length > 0) {
      return selectedFilters?.Brand?.join(', ');
    }
    if (selectedFilters?.Franchise?.length > 0) {
      return selectedFilters?.Franchise?.join(', ');
    }
    return selectedFilters?.Business_Unit;
  };

  const onAddToMyView = async (
    id,
    title,
    chartData,
    gridData,
    varianceChartData,
    varianceGridData,
    chartText,
    category,
    griCols,
    labelytd,
    bpData,
    scatterEvents,
  ) => {
    if (myViewCards?.length === 20) {
      //display warning message if the cards added reaches a certain limit.
      Notification('warning', MYVIEW_NOTIFICATION.limitExceed);
    } else {
      const data = myViewKpi
        ?.filter((item) => item?.KpiName === BUSINESSUPPERCASE)[0]
        ?.dimensions?.filter((item) => item?.ChartLabel === id);
      handleAddToView(
        data[0],
        `Net Sales for ${netSaleTitleName()}`,
        chartData,
        gridData,
        varianceChartData,
        varianceGridData,
        chartText,
        category,
        griCols,
        [],
        bpData,
        scatterEvents,
      );
    }
  };

  useEffect(() => {
    if (summaryData?.length > 0 && netSalesGridData?.length > 0) {
      const resultMap = {};
      summaryData?.forEach((item) => {
        if (resultMap[item.name]) {
          resultMap[item.name].value = item?.value;
        } else {
          resultMap[item.name] = { ...item };
        }
      });

      const filteredData = Object.values(resultMap)?.flatMap((item) =>
        item?.value?.map((v) => v),
      );

      setNetSalesChartData(filteredData);
      setScatterPlotChartData(scatterEvents);

      const gridResultMap = {};
      netSalesGridData?.forEach((item) => {
        if (gridResultMap[item.name]) {
          gridResultMap[item.name].value = item?.value;
        } else {
          gridResultMap[item.name] = { ...item };
        }
      });
      const filteredGridData = Object.values(gridResultMap)?.flatMap((item) =>
        item?.value?.map((v) => v),
      );
      setGridData(filteredGridData);
    }
  }, [summaryData, netSalesGridData, scatterEvents]);

  const myViewKpiBusiness = useMemo(() => {
    return myViewKpi?.filter((item) => item?.KpiName === BUSINESSUPPERCASE)[0]
      ?.dimensions;
  }, [myViewKpi, BUSINESSUPPERCASE]);

  const getKPIChartId = (myViewKpiBusiness, ChartLabel) => {
    return myViewKpiBusiness?.filter(
      (item) => item?.ChartLabel === ChartLabel,
    )[0];
  };
  // const netSaleTitleName = () => {
  //   if (selectedFilters?.Brand?.length > 0) {
  //     return selectedFilters?.Brand?.join(',')?.length > 20 ? (
  //       <Tooltip
  //         title={selectedFilters?.Brand?.join(', ')}
  //       >{`${selectedFilters?.Brand?.join(', ')?.substring(
  //         0,
  //         20,
  //       )}...`}</Tooltip>
  //     ) : (
  //       selectedFilters?.Brand?.join(', ')
  //     );
  //   }
  //   if (selectedFilters?.Franchise?.length > 0) {
  //     return selectedFilters?.Franchise?.join(',')?.length > 20 ? (
  //       <Tooltip
  //         title={selectedFilters?.Franchise?.join(', ')}
  //       >{`${selectedFilters?.Franchise?.join(', ')?.substring(
  //         0,
  //         20,
  //       )}...`}</Tooltip>
  //     ) : (
  //       selectedFilters?.Franchise?.join(', ')
  //     );
  //   }
  //   return selectedFilters?.Business_Unit;
  // };

  return (
    <>
      {showCardIds.includes(card_config_db?.BP_NETSALES_CHART?.CardId) &&
        showCardIds.includes(card_config_db?.BP_NETSALES_CHARTGRID?.CardId) && (
          <Spin spinning={isLoading}>
            <Card
              cardTitle={
                <div>
                  {CARD_CONFIG?.BP_NETSALES_CHART?.CardName}{' '}
                  <span className={classes['sub-header']}>
                    {netSaleTitleName()}
                  </span>
                </div>
              }
              cardHeadingTitle={CARD_CONFIG?.BP_NETSALES_CHART?.CardName}
              leastFilter={netSaleTitleName()}
              chartData={netsalesChartData}
              griCols={NET_SALES_CHART_GRID_COLUMNS(gridYearCols)}
              cardId={CARD_CONFIG?.BP_NETSALES_CHART?.ChartLabel}
              gridData={gridData}
              onConfigureComment={onConfigureComment}
              onConfigurePinnedComment={onConfigurePinnedComment}
              cardWidth={'100%'}
              chartWidth={'100%'}
              chartHeight={400}
              cardHeight={900}
              chartType={CARD_CONFIG?.BP_NETSALES_CHART?.ChartType}
              onSelectChange={onSelectOptionChange}
              infoMessage={
                infoMessages[card_config_db?.BP_NETSALES_CHART?.CardId]
                  ? infoMessages[card_config_db?.BP_NETSALES_CHART?.CardId]
                  : 'Net Sales'
              }
              dataFilterOptions={dataFilterOptions}
              eventFilterOptions={eventFilterOptions}
              category={gridYearCols}
              onSelectChangesNetSales={onSelectChangesNetSales}
              onSelectChangesEvent={onSelectChangesEvent}
              cardComments={commentsData}
              selectedFilters={selectedFilters}
              scatterPlotDatatoPlot={scatterPlotChartData}
              cardNumber={card_config_db?.BP_NETSALES_CHART?.CardId}
              bpEventList={eventDetails}
              bpData={summaryData}
              scatterEvents={scatterPlotChartData}
              onAddToMyView={onAddToMyView}
              KPICardId={getKPIChartId(
                myViewKpiBusiness,
                CARD_CONFIG?.BP_NETSALES_CHART?.ChartLabel,
              )}
            />
          </Spin>
        )}
      <BusinessSummaryComment
        commentDesc={commentDesc}
        error={error}
        handleAddComment={handleAddComment}
        handleCancel={handleCancel}
        handleDeleteComment={handleDeleteComment}
        handleEditComment={handleEditComment}
        isAddModalOpen={isAddModalOpen}
        isDeleteModalOpen={isDeleteModalOpen}
        isEditModalOpen={isEditModalOpen}
        newComment={newComment}
        setCommentDesc={setCommentDesc}
        setError={setError}
        setNewComment={setNewComment}
        setCommentError={setCommentError}
        commentError={commentError}
      />
    </>
  );
};

export default BusinessSummary;
