import { GETLABELCOLOR } from '../utils';

export const YearForcecastTrend = {
  ytdHigh: 1250,
  ytdLow: 250,
  values: [
    {
      key: 1,
      name: 'Forecast',
      data: [null, null, null, null, 600, 650, 620],
      lineStyle: {
        color: '#4B80B1',
      },
    },
    {
      key: 2,
      name: 'Actuals',
      data: [null, null, null, null, 600, 650, 700],
      lineStyle: {
        color: '#13294B',
      },
    },
    {
      key: 3,
      name: 'Current',
      data: [600, 312, 880, 760, 580, 420, null],
      lineStyle: {
        color: '#DB930B',
      },
    },
    {
      key: 4,
      name: 'Prior',
      data: [620, 332, 901, 780, 600, 780, null],
      lineStyle: {
        type: 'dotted',
        color: '#AF1685',
      },
    },
    {
      key: 5,
      name: 'T0',
      data: [300, 450, 1200, 1050, 820, null, null],
      lineStyle: {
        type: 'dotted',
        color: '#56A944',
      },
    },
  ],
};
export const yoyGrowth = {
  values: [
    {
      key: 1,
      name: 'S&OP',
      data: [300, 450, 1200, 1050, 820, 600, 700],
      lineStyle: {
        color: '#4B80B1',
      },
    },
    {
      key: 2,
      name: 'Actual',
      data: [200, 425, 1100, 1150, 1300, null, null],
      lineStyle: {
        color: '#13294B',
      },
    },
  ],
};

export const yoyGridData = [
  {
    key: 1,
    metric: '01/09/23',
    t0: 210,
    '4week': 220,
    '12week': 300,
    trend: 410,

    sales: 340,
  },
  {
    key: 2,
    metric: '01/16/23',
    t0: 210,
    '4week': 220,
    '12week': 300,
    trend: 410,
    sales: -100,
  },
  {
    key: 3,
    metric: '01/23/23',
    t0: 210,
    '4week': 220,
    '12week': 300,
    trend: 410,
    sales: 205,
  },
  {
    key: 4,
    metric: '01/30/23',
    t0: 210,
    '4week': 220,
    '12week': 300,
    trend: 410,
    sales: 117,
  },
  {
    key: 5,
    metric: '02/06/23',
    t0: 210,
    '4week': 220,
    '12week': 300,
    trend: 410,
    sales: 214,
  },
];

export const AggForecastWeekData = {
  values: [
    {
      key: 1,
      name: 'Actual',
      data: [100, 150, 200, 150, 250, 300],
      lineStyle: {
        color: '#13294B',
      },
    },
    {
      key: 2,
      name: 'S&OP',
      data: [50, 100, 150, 200, 150, 100],
      lineStyle: {
        type: 'dotted',
        color: '#4B80B1',
      },
    },
  ],
};

export const AggForecastMonthData = {
  values: [
    {
      key: 1,
      name: 'Actual',
      data: [100, 150, 200, 150, 250, 30, 400],
      lineStyle: {
        color: '#13294B',
      },
    },
    {
      key: 2,
      name: 'S&OP',
      data: [50, 100, 150, 200, 150, 100],
      lineStyle: {
        type: 'dotted',
        color: '#4B80B1',
      },
    },
  ],
};

export const ytdVarianceGridData = [
  {
    key: 1,
    metric: '01/02/23',
    sales: -11,
  },
  {
    key: 2,
    metric: '01/09/24',
    sales: 5,
  },
  {
    key: 3,
    metric: '01/16/24',
    sales: 15,
  },
  {
    key: 4,
    metric: '01/23/24',
    sales: 10,
  },
  {
    key: 5,
    metric: '01/30/24',
    sales: 23,
  },
  {
    key: 6,
    metric: '02/07/24',
    sales: -23,
  },
  {
    key: 7,
    metric: '02/14/24',
    sales: 23,
  },
  {
    key: 8,
    metric: '02/21/24',
    sales: 23,
  },
];

export const ytdVarianceGridDataMonth = [
  {
    key: 1,
    metric: 'Jan 24',
    sales: -11,
  },
  {
    key: 2,
    metric: 'Feb 24',
    sales: 5,
  },
  {
    key: 3,
    metric: 'Mar 24',
    sales: 15,
  },
  {
    key: 4,
    metric: 'Apr 24',
    sales: 10,
  },
  {
    key: 5,
    metric: 'May 24',
    sales: 23,
  },
  {
    key: 6,
    metric: 'Jun 24',
    sales: -23,
  },
  {
    key: 7,
    metric: 'Jul 24',
    sales: 23,
  },
  {
    key: 8,
    metric: 'Aug 24',
    sales: 23,
  },
  {
    key: 9,
    metric: 'Sep 24',
    sales: 23,
  },
  {
    key: 10,
    metric: 'Oct 24',
    sales: 23,
  },
  {
    key: 11,
    metric: 'Nov 24',
    sales: 23,
  },
  {
    key: 8,
    metric: 'Dec 24',
    sales: 23,
  },
];

export const AggForecastGridCols = [
  {
    title: 'Metric',
    dataIndex: 'metric',
    key: 'metric',
    render: (value) => {
      return value ? value : '-';
    },
  },
  {
    title: 'S&OP',
    dataIndex: 'S&OP',
    key: 'S&OP',
    render: (value) => {
      return value ? `${value?.toLocaleString()}K` : '-';
    },
  },
  {
    title: 'Actual',
    dataIndex: 'Actual',
    key: 'Actual',
    render: (value) => {
      return value ? `${value?.toLocaleString()}K` : '-';
    },
  },
];

export const AccuracyForecastGridCols = [
  {
    title: 'Metric',
    dataIndex: 'metric',
    key: 'metric',
    render: (value) => {
      return value ? value : '-';
    },
  },
  {
    title: '26 Weeks',
    dataIndex: 'S&OP',
    key: 'S&OP',
    render: (value) => {
      return value ? `${value}%` : '-';
    },
  },
  {
    title: '4 Weeks',
    dataIndex: 'Actual',
    key: 'Actual',
    render: (value) => {
      return value ? `${value}%` : '-';
    },
  },
];

export const AggForecastWeekGridData = [
  {
    key: 1,
    metric: '01/09/23',
    actual: 22,
    sop: 340,
  },
  {
    key: 2,
    metric: '01/16/23',
    actual: 22,
    sop: -100,
  },
  {
    key: 3,
    metric: '01/23/23',
    actual: 22,
    sop: 205,
  },
  {
    key: 4,
    metric: '01/30/23',
    actual: 22,
    sop: 117,
  },
  {
    key: 5,
    metric: '02/06/23',
    actual: 22,
    sop: 214,
  },
];
export const AggForecastMonthGridData = [
  {
    key: 1,
    metric: 'Jan 24',
    actual: 22,
    sop: 340,
  },
  {
    key: 2,
    metric: 'Feb 24',
    actual: 22,
    sop: 100,
  },
  {
    key: 3,
    metric: 'Mar 24',
    actual: 22,
    sop: 200,
  },
  {
    key: 4,
    metric: 'Apr 24',
    actual: 22,
    sop: 300,
  },
  {
    key: 5,
    metric: 'May 24',
    actual: 22,
    sop: 500,
  },
  {
    key: 6,
    metric: 'Jun 24',
    actual: 22,
    sop: 500,
  },
  {
    key: 7,
    metric: 'Jul 24',
    actual: 22,
    sop: 500,
  },
  {
    key: 8,
    metric: 'Aug 24',
    actual: 22,
    sop: 500,
  },
  {
    key: 9,
    metric: 'Sept 24',
    actual: 22,
    sop: 500,
  },
  {
    key: 10,
    metric: 'Oct 24',
    actual: 22,
    sop: 500,
  },
  {
    key: 11,
    metric: 'Nov 24',
    actual: 22,
    sop: 500,
  },
  {
    key: 12,
    metric: 'Dec 24',
    actual: 22,
    sop: 500,
  },
];

const renderWithColor = (value) => {
  const isPercentage = typeof value === 'string' && value.endsWith('%');
  const displayValue = isPercentage ? value.slice(0, -1) : value;
  const numericValue = parseFloat(displayValue);

  const color = isPercentage
    ? numericValue > 0
      ? 'green'
      : numericValue < 0
      ? 'red'
      : 'black'
    : 'black';

  return {
    children: isPercentage ? `${displayValue}%` : value ? value : '-',
    props: {
      style: { color },
    },
  };
};

export const ShortWeekChartGridCols = [
  {
    title: 'Metric',
    dataIndex: 'metric',
    key: 'metric',
  },
  {
    title: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        Forecast Bias
      </div>
    ),
    dataIndex: 'data',
    key: 'data',
    render: (text) => (
      <div
        style={{
          color: GETLABELCOLOR(text),
          display: 'flex',
          justifyContent: 'flex-end',
          fontWeight: 'bold',
        }}
      >
        {text > 0 ? `+${text}%` : `${text}%`}
      </div>
    ),
  },
];

export const ShortMonthChartGridCols = [
  {
    title: 'Metric',
    dataIndex: 'metric',
    key: 'metric',
    align: 'center',
    render: renderWithColor,
  },
  {
    title: 'Overforecast',
    dataIndex: 'overforecast',
    key: 'overforecast',
    align: 'center',
    render: renderWithColor,
  },
  {
    title: 'Oversell',
    dataIndex: 'oversell',
    key: 'oversell',
    align: 'center',
    render: renderWithColor,
  },
];

export const ShortWeekGridData = [
  {
    key: 1,
    metric: '01/09/23',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 2,
    metric: '01/16/23',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 3,
    metric: '01/23/23',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 4,
    metric: '01/30/23',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 5,
    metric: '02/06/23',
    overforecast: '-10%',
    oversell: '10%',
  },
];
export const ShortMonthGridData = [
  {
    key: 1,
    metric: 'Jan 24',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 2,
    metric: 'Feb 24',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 3,
    metric: 'Mar 24',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 4,
    metric: 'Apr 24',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 5,
    metric: 'May 24',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 6,
    metric: 'Jun 24',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 7,
    metric: 'Jul 24',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 8,
    metric: 'Aug 24',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 9,
    metric: 'Sept 24',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 10,
    metric: 'Oct 24',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 11,
    metric: 'Nov 24',
    overforecast: '-10%',
    oversell: '10%',
  },
  {
    key: 12,
    metric: 'Dec 24',
    overforecast: '-10%',
    oversell: '10%',
  },
];

export const YearForecastGridCols = (versionType) => {
  return [
    {
      title: 'Metric',
      dataIndex: 'metric',
      key: 'metric',
      render: (value) => {
        return value ? value : '-';
      },
    },
    {
      title: 'Actuals (K)',
      dataIndex: 'actual',
      key: 'actual',
      render: (value) => {
        return value ? value : '-';
      },
    },
    {
      title: 'Current (K)',
      dataIndex: 'current',
      key: 'current',
      render: (value) => {
        return value ? value : '-';
      },
    },
    {
      title: 'Prior (K)',
      dataIndex: 'prior',
      key: 'prior',
      render: (value) => {
        return value ? value : '-';
      },
    },
    {
      title: `${versionType} (K)`,
      dataIndex: 't0',
      key: 't0',
      render: (value) => {
        return value ? value : '-';
      },
    },
  ];
};

export const YearForecastGridData = [
  {
    key: 1,
    metric: 'Jan 24',
    forecast: 210,
    actual: 220,
    current: 300,
    prior: 410,

    t0: 340,
  },
  {
    key: 2,
    metric: 'Feb 24',
    forecast: 210,
    actual: 220,
    current: 300,
    prior: 410,

    t0: 340,
  },
  {
    key: 3,
    metric: 'Mar 24',
    forecast: 210,
    actual: 220,
    current: 300,
    prior: 410,

    t0: 340,
  },
  {
    key: 4,
    metric: 'Apr 24',
    forecast: 210,
    actual: 220,
    current: 300,
    prior: 410,

    t0: 340,
  },
  {
    key: 5,
    metric: 'May 24',
    forecast: 210,
    actual: 220,
    current: 300,
    prior: 410,

    t0: 340,
  },
  {
    key: 6,
    metric: 'Jun 24',
    forecast: 210,
    actual: 220,
    current: 300,
    prior: 410,

    t0: 340,
  },
  {
    key: 7,
    metric: 'Jul 24',
    forecast: 210,
    actual: 220,
    current: 300,
    prior: 410,

    t0: 340,
  },
  {
    key: 8,
    metric: 'Aug 24',
    forecast: 210,
    actual: 220,
    current: 300,
    prior: 410,

    t0: 340,
  },
  {
    key: 9,
    metric: 'Sep 24',
    forecast: 210,
    actual: 220,
    current: 300,
    prior: 410,

    t0: 340,
  },
  {
    key: 10,
    metric: 'Oct 24',
    forecast: 210,
    actual: 220,
    current: 300,
    prior: 410,

    t0: 340,
  },
  {
    key: 11,
    metric: 'Nov 24',
    forecast: 210,
    actual: 220,
    current: 300,
    prior: 410,

    t0: 340,
  },
  {
    key: 12,
    metric: 'Dec 24',
    forecast: 210,
    actual: 220,
    current: 300,
    prior: 410,

    t0: 340,
  },
];
