const { createSlice } = require('@reduxjs/toolkit');
const { globalFilterInitialState } = require('./globalFilterInitialState');

const globalFilterSlice = createSlice({
  name: 'Global Filter',
  initialState: globalFilterInitialState,
  reducers: {
    setGlobalSelections: (state, { payload }) => {
      state.selections = { ...state.selections, ...payload };
    },

    setOptions: (state, { payload }) => {
      state.options = { ...state.options, ...payload };
    },
  },
});

export const { setOptions, setGlobalSelections } = globalFilterSlice.actions;
export default globalFilterSlice.reducer;
