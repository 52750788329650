import { Select, Tag } from 'antd';

const SelectOption = ({
  selectStyle,
  onSelectChange,
  menuOptions,
  selectedValues,
  defaultValue,
}) => {
  const tagRender = (props) => {
    const { label } = props;
    return (
      <Tag
        closeIcon={null}
        bordered={selectedValues?.length !== menuOptions?.length}
      >
        {label}
      </Tag>
    );
  };

  return (
    <Select
      style={selectStyle}
      showSearch={false}
      options={menuOptions}
      defaultValue={defaultValue}
      value={
        selectedValues?.length === menuOptions?.length
          ? ['All']
          : selectedValues
      }
      maxTagCount={'responsive'}
      tagRender={tagRender}
      onChange={onSelectChange}
    />
  );
};

export default SelectOption;
