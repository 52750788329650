import { createSlice } from '@reduxjs/toolkit';
import { initialStatesopState } from './sopIntialState';

const sopViewSlice = createSlice({
  name: 'SOPView',
  initialState: initialStatesopState,
  reducers: {
    setSopBannerData: (state, action) => {
      state.sopBannerData = action.payload;
    },
    setSopPreviousMonthForecastTabData: (state, action) => {
      state.sopPreviousMonthForecastTabData = action.payload;
    },
    setSopFinancialForecastTabData: (state, action) => {
      state.sopFinancialForecastTabData = action.payload;
    },
    setSopSummaryData: (state, { payload }) => {
      state.sopSummaryData = payload;
    },
    setSopSummaryTabData: (state, action) => {
      state.sopSummaryTabData = {
        ...state.sopSummaryTabData,
        ...action.payload,
      };
    },
    setForecastingTrendChartLabel: (state, { payload }) => {
      state.forecastTrendChartLabel = payload;
    },
    setSopFilterSelected: (state, action) => {
      state.sopFilterSelected = action.payload;
    },
    setShallSummaryScreenRefresh: (state, action) => {
      state.shallSummaryScreenRefresh = action.payload;
    },
    setShallForecastScreenRefresh: (state, action) => {
      state.shallForecastScreenRefresh = action.payload;
    },
    setSopExpressionAndFilters: (state, { payload }) => {
      state.sopExpression = payload.sopExpression;
      state.sopTableExpression = payload.sopTableExpression;
      state.sopExpressionVariance = payload.sopExpressionVariance;
      state.sopSelectedFilters = payload.selectedFilters;
    },
    setSopCommentData: (state, { payload }) => {
      state.sopCommentData = [...payload];
    },
  },
});

export const {
  setSopSummaryData,
  setSopBannerData,
  setSopSummaryTabData,
  setSopPreviousMonthForecastTabData,
  setSopExpressionAndFilters,
  setSopFinancialForecastTabData,
  setSopFilterSelected,
  setShallSummaryScreenRefresh,
  setShallForecastScreenRefresh,
  setSopCommentData,
  setForecastingTrendChartLabel,
} = sopViewSlice.actions;

export default sopViewSlice.reducer;
