// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.x7BnP8qr4e7BdqN4LPZ2{display:flex;padding:5px 10px}.n4TaU72wbKo9aueSC1Nb{margin-left:10px}`, "",{"version":3,"sources":["webpack://./src/components/elements/selectmenu/SelectMenu.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,gBAAA,CAGF,sBACE,gBAAA","sourcesContent":[".menu-content {\n  display: flex;\n  padding: 5px 10px;\n}\n\n.checkbox-label {\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu-content": `x7BnP8qr4e7BdqN4LPZ2`,
	"checkbox-label": `n4TaU72wbKo9aueSC1Nb`
};
export default ___CSS_LOADER_EXPORT___;
