import { useEffect, useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import {
  addCompliance,
  getComplianceMessage,
} from '@service/compliance.service';
import { getUserUnixId } from '@utils/getUser';
import classes from './Compliance.module.scss';
const ComplianceModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [content, setContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const unixId = getUserUnixId();

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const getCompliance = async () => {
    const result = await getComplianceMessage(unixId);
    if (result?.status === 200) {
      if (!result?.data?.showCompliance) {
        setIsModalOpen(false);
      } else {
        const txt = `${result?.data?.Content}`;
        let newlineText = txt?.replace(/\n/g, '<br/>');
        newlineText = newlineText.replaceAll('\\n', '');
        newlineText = newlineText.replaceAll('\r', '');
        setContent(newlineText || '');
        setIsModalOpen(true);
      }
    }
  };

  useEffect(() => {
    if (unixId) {
      getCompliance();
    }
  }, [unixId]);

  const handleCompliance = async () => {
    setIsLoading(true);
    const result = await addCompliance({ unixId: unixId });
    if (result?.status === 200) {
      setIsModalOpen(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Spin spinning={isLoading}>
        <Modal
          wrapClassName={`${classes['compliance-wrapper']} compliance-wrapper-ele`}
          zIndex="9999"
          width={'55%'}
          centered={true}
          closable={false}
          className="compliance-container"
          title="Disclaimer"
          maskClosable={false}
          open={isModalOpen}
          onOk={handleOk}
          footer={[
            <Button
              key="{SUBMITTEXT}"
              type="primary"
              disabled={isLoading}
              onClick={() => handleCompliance()}
            >
              I Acknowledge
            </Button>,
          ]}
        >
          <span dangerouslySetInnerHTML={{ __html: content }} />
        </Modal>
      </Spin>
    </>
  );
};
export default ComplianceModal;
