// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RT8anLkWKSN6zUKljRXg{padding:40px 0px 0px 60px;width:"100%"}.gH8Fk7McAhPcZ9s98UZp{font-weight:bold}.HZpiymjg2HoyAUk2I5VA{display:flex;justify-content:end;margin-top:5px;font-size:var(--font-size-small)}`, "",{"version":3,"sources":["webpack://./src/components/businessplan/BusinessPlanContainer.module.scss"],"names":[],"mappings":"AAAA,sBACE,yBAAA,CACA,YAAA,CAGF,sBACE,gBAAA,CAGF,sBACE,YAAA,CACA,mBAAA,CACA,cAAA,CACA,gCAAA","sourcesContent":[".businessplan-container {\n  padding: 40px 0px 0px 60px;\n  width: \"100%\";\n}\n\n.title {\n  font-weight: bold;\n}\n\n.week-container {\n  display: flex;\n  justify-content: end;\n  margin-top: 5px;\n  font-size: var(--font-size-small);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"businessplan-container": `RT8anLkWKSN6zUKljRXg`,
	"title": `gH8Fk7McAhPcZ9s98UZp`,
	"week-container": `HZpiymjg2HoyAUk2I5VA`
};
export default ___CSS_LOADER_EXPORT___;
