export const portfolioBannerTimelineOptions = ({
  chartData,
  colorPallete,
}) => ({
  xAxis: {
    type: 'category',
    data: Array(chartData?.length)
      .fill()
      .map((_, i) => i + 1),
    axisLabel: {
      show: false,
    },
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
  },
  yAxis: {
    type: 'value',
    min: 0,
    max: 2,
    axisLabel: {
      show: false,
    },
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    splitLine: {
      show: false,
    },
  },
  series: [
    {
      name: 'Series',
      type: 'line',
      tooltip: { show: false },
      data: new Array(chartData?.length).fill(1),
      lineStyle: {
        color: '#fff',
        width: 2,
      },
      label: {
        show: true,
        position: 'top',
        formatter: (params) => {
          return params.value === 1 ? '' : `{a|${params.value}}`;
        },
        rich: {
          a: {
            color: '#999',
            fontSize: 12,
            fontWeight: 'bold',
          },
        },
      },
      markLine: {
        data: [{ yAxis: 1 }],
        lineStyle: {
          color: '#fff',
          width: 2,
          type: 'solid',
        },
        label: {
          show: false,
        },
        symbol: [],
        symbolSize: [0, 0],
        tooltip: {
          show: false,
        },
      },
      markPoint: {
        data: chartData?.map((item, index) => {
          return {
            ...item,
            itemStyle: {
              color:
                colorPallete && colorPallete?.length > 0 && colorPallete[index],
            },
            label: {
              rich: {
                name: {
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: 14,
                  verticalAlign: 'bottom',
                  padding: [40, 0, 0, 0],
                  align: 'center',
                },
                value: {
                  color:
                    colorPallete &&
                    colorPallete?.length > 0 &&
                    colorPallete[index],
                  fontSize: 14,
                  fontWeight: 'bold',
                  verticalAlign: 'top',
                  align: 'center',
                  padding: [0, 0, 30, 0],
                },
              },
              formatter: (params) => {
                return `{value|${params.value}}\n{name|${params.name}}`;
              },
            },
            symbol: 'line',
            symbolSize: [2, 50],
          };
        }),
      },
    },
  ],
  tooltip: {
    trigger: 'item',
    formatter: (params) => {
      return params.data ? `${params.data.value}` : '';
    },
  },
});
