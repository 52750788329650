import { useLayoutEffect } from 'react';
import { Modal } from 'antd';
import Catalogue from '../catalogue/Catalogue';
import classes from './Comment.module.scss';

const Comment = ({
  visible,
  onClose,
  commentsData,
  currentUser,
  onConfigureComment,
  onConfigurePinnedComment,
  title,
  cardNumber,
  cardId,
  selectedFilters,
}) => {
  useLayoutEffect(() => {
    const element = document.getElementById('comment-scroll');
    return () => {
      if (element && visible === true) {
        element.scrollTo(0, 0);
      }
    };
  }, [visible]);

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      width={600}
      height={606}
      title={
        <div className={classes['comment-container']}>
          <i
            className={`icon icon__comments_black ${classes['icon-align-comment']}`}
          ></i>
          <p className={classes['header']}>{title}</p>
        </div>
      }
    >
      <div className={classes['card-modal-wrap']}>
        <div id="comment-scroll" className={classes['align-wrap']}>
          <div className={classes['catalogue-wrap']}>
            <Catalogue
              data={commentsData}
              onConfigureComment={onConfigureComment}
              onConfigurePinnedComment={onConfigurePinnedComment}
              currentUser={currentUser}
              cardNumber={cardNumber}
              cardId={cardId}
              selectedFilters={selectedFilters}
            />
          </div>
        </div>
      </div>
      <div className={classes['modal-footer']}>
        <p className={classes['modal-close']} onClick={onClose}>
          Close
        </p>
      </div>
    </Modal>
  );
};

export default Comment;
