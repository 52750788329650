import { PATIENT } from '@components/performance/brand/brandConstant';
// no-unused-vars
// import { PATIENTS } from '@constant';

export const pieChartOptions = ({
  chartType,
  chartData,
  legend,
  radius,
  colorPallete,
  labelText,
}) => ({
  tooltip: {
    show: true,
    formatter: (data) => {
      if (data?.name?.includes('Forecasted')) return undefined;

      const actualValue = data?.data?.actualValue || data?.value;
      let formattedValue = Number(actualValue)?.toLocaleString();

      let modifiedName = data?.name
        .replace(/FY Projection (Sales|OUTs)/g, 'FY Projection')
        .replace(/YTD Actual Sales/g, 'YTD Actuals');

      if (data?.name?.includes('OUTs')) {
        formattedValue += 'K Units'; // Add 'K' for OUTs
      } else if (data?.name?.includes('Sales')) {
        formattedValue = `$${formattedValue}M`; // Add '$' prefix and 'M' suffix for Sales
      }

      return `${
        data?.value === '1' ? PATIENT : modifiedName
      } : ${formattedValue}`;
    },
  },
  legend: legend,
  series: [
    {
      type: chartType,
      radius: radius,
      color: colorPallete,
      avoidLabelOverlap: false,
      label: {
        show: true,
        position: 'center',
        color: '#FFFFFF',
        fontSize: 10,
        formatter: () => {
          return labelText;
        },
      },
      emphasis: {
        label: {
          show: true,
        },
      },
      data: chartData,
    },
  ],
});
