import { createSlice } from '@reduxjs/toolkit';
import { BrandPerfInitialState } from './brandPerfInitialState';

const brandViewSlice = createSlice({
  name: 'brandSummaryview',
  initialState: BrandPerfInitialState,
  reducers: {
    setBrandExpressions: (state, action) => {
      state.brandExpressions = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setFilterSelections: (state, action) => {
      state.filterSelections = { ...state.filterSelections, ...action.payload };
    },
    setUserAccesses: (state, action) => {
      state.userAcces = action.payload;
    },

    setEqOutData: (state, action) => {
      state.eqOutDataStore = action.payload;
    },

    setMyViewKpiDetails: (state, action) => {
      state.myViewKpiDetails = action.payload;
    },

    setMyViewCardsList: (state, action) => {
      state.myViewCardsList = action?.payload;
      state.pointerIndex = 6;
      // action?.payload?.length > 6 ? 6 : action?.payload?.length;
    },
    // eslint-disable-next-line no-unused-vars
    setPointerIndex: (state, _action) => {
      state.pointerIndex = state?.pointerIndex + 6;
    },
    setUpdateMyViewCardsList: (state, action) => {
      state.myViewCardsList = action?.payload;
    },
    setBrandViewFiltersSelected: (state, action) => {
      state.brandViewFiltersSelected = action.payload;
    },
    setUserSquadMap: (state, action) => {
      state.userSquadMap = action.payload;
    },
    setVersionType: (state, action) => {
      state.versionType = action.payload;
    },
    setIsFromPortfolioTab: (state, action) => {
      state.isFromPortfolioTab = action.payload;
      state.increment = action.payload ? state.increment + 1 : 0;
    },
    setIsViewFirstLoad: (state, action) => {
      state.isViewFirstLoad = action.payload;
    },
    setSummaryTabData: (state, { payload }) => {
      state.summaryTab = {
        ...state.summaryTab,
        ...payload,
      };
    },

    setMonthlyTabData: (state, { payload }) => {
      state.monthlyTabData = {
        ...state.monthlyTabData,
        ...payload,
      };
    },

    setMonthlyTabColumn: (state, { payload }) => {
      state.monthlyTabColumn = {
        ...state.monthlyTabColumn,
        ...payload,
      };
    },

    setQuarterlyTabData: (state, { payload }) => {
      state.quarterlyTabData = {
        ...state.quarterlyTabData,
        ...payload,
      };
    },

    setQuarterlyTabColumn: (state, { payload }) => {
      state.quarterlyTabColumn = {
        ...state.quarterlyTabColumn,
        ...payload,
      };
    },
    setCardConfig: (state, action) => {
      state.cardConfig = action.payload;
    },
    setKpiTabData: (state, { payload }) => {
      state.kpiData = {
        ...state.kpiData,
        ...payload,
      };
    },
    setKpiTabColumn: (state, { payload }) => {
      state.kpiTabColumn = {
        ...state.kpiTabColumn,
        ...payload,
      };
    },
  },
});

export const {
  setBrandExpressions,
  setFilterSelections,
  setEqOutData,
  setMyViewKpiDetails,
  setMyViewCardsList,
  setFilters,
  setUserAccesses,
  setBrandViewFiltersSelected,
  setUserSquadMap,
  setVersionType,
  setIsFromPortfolioTab,
  setIsViewFirstLoad,
  setSummaryTabData,
  setMonthlyTabData,
  setMonthlyTabColumn,
  setQuarterlyTabData,
  setQuarterlyTabColumn,
  setPointerIndex,
  setUpdateMyViewCardsList,
  setCardConfig,
  setKpiTabData,
  setKpiTabColumn,
} = brandViewSlice.actions;

export const brandperfViewSliceActions = brandViewSlice.actions;

export default brandViewSlice.reducer;
