import { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import { ChartOptions } from './ChartOptions';

const Chart = ({
  chartType,
  chartData,
  legend,
  radius,
  colorPallete,
  labelText,
  showEndLabel,
  showMarker,
  style,
  category,
  minMax,
  selectedViewType,
  growthPercentage,
  scatterPlotDatatoPlot,
  showscatter,
  currentNetSales,
  isViewExpanded,
  expend,
}) => {
  const [chartDataState, setChartDataState] = useState(0);
  useEffect(() => {
    setChartDataState((prev) => prev + 1);
  }, [chartData?.length, JSON.stringify(chartData)]);
  const chartStyle = style || { height: 150, width: 150 };
  if (
    (!chartData || chartData?.length === 0) &&
    chartType !== 'portfolioTimeline' &&
    chartType !== 'pie'
  ) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ...chartStyle,
        }}
      >
        <i className={'icon icon__nodata'}></i>
      </div>
    );
  }
  return (
    <ReactECharts
      key={JSON.stringify(chartDataState)}
      style={chartStyle}
      option={ChartOptions({
        chartType,
        chartData,
        legend,
        radius,
        colorPallete,
        labelText,
        showEndLabel,
        showMarker,
        category,
        minMax,
        selectedViewType,
        growthPercentage,
        scatterPlotDatatoPlot,
        showscatter,
        currentNetSales,
        isViewExpanded,
        expand: expend,
      })}
    />
  );
};

export default Chart;
