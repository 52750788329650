export const initialStatesopState = {
  sopBannerData: [],
  sopPreviousMonthForecastTabData: [],
  sopFinancialForecastTabData: [],
  sopFilterSelected: {},
  sopSummaryData: [],
  sopSummaryTabData: {
    forecastTrendChartData: [],
    AggForecastLabel: '',
    quarterly: [],
    aggregateChartData: [],
    accuracyChartData: [],
    biasForecast4WksChartData: {},
    biasForecast26WksChartData: {},
    forecastTrendMonth: '',
  },
  shallSummaryScreenRefresh: false,
  shallForecastScreenRefresh: false,
  isFromPortfolioTab: false,
  forecastTrendChartLabel: [],
  sopSelectedFilters: {},
  sopCommentData: [],
  sopExpression: [],
  sopTableExpression: [],
  sopExpressionVariance: [],
};
