import { NO_INFO } from '@constant';
import { Modal } from 'antd';

const InfoDialog = ({ isVisible, onClose, message }) => {
  return (
    <Modal open={isVisible} onCancel={onClose} footer={null} zIndex={9999}>
      <div className="modal-header">
        <i className="icon icon__info"></i> <span>Info</span>
      </div>
      <div>{message ? message : NO_INFO}</div>
    </Modal>
  );
};

export default InfoDialog;
