import moment from 'moment';
import api from '../api';

/**
 * Make an API call to get qlik data
 * @param {*} token
 * @param {*} expression the expression used qMeasure
 * @param {*} dimension the dimension used for qDimension
 * @param {*} limit the number of rows to return
 * @returns
 */

/**
 * makes a call to get qlik data and
 * returns the dataset based on the dataType specified
 * @param {*} expressions expressions in the form of an array or string
 * @param {*} dimension optional dimension used for hypercube as an array or string
 * @param {string} appId the qlik app to open
 * @param {number} limit the number of rows to return
 * @param {*} dataType the type of data expected in the response, the options are in QLIK_DATATYPE as an array or string
 * @returns qlik data
 */
export const getQlikSenseUnformattedData = async (payload) => {
  const response = await api.post(`qliksense/`, payload, {
    headers: {
      qlikCookie: localStorage.getItem('qlikCookie'),
      Authorization: `Bearer ${localStorage.getItem('qlikToken')}`,
    },
  });

  setQlikAuth(
    response?.headers['authorization']?.split(' ')[1],
    response?.headers['qlikcookie'],
  );
  return response.data.data;
};

/**
 * makes a call to get qlik data and
 * returns the dataset based on the dataType specified
 * @param {string} appId the qlik app to open
 * @param {string} expression the expression for the hypercube
 * @param {Array} dimensions dimension as an array
 * @param {number} limit the number of rows to return
 * @param {number} width the number of columns to return
 * @returns qlik data
 */
export const getQlikSenseMultiDimensionData = async (
  appId,
  expression,
  dimensions,
  limit,
  width,
) => {
  const response = await api.get(`qlik/account`, {
    params: {
      expression,
      dimension: dimensions,
      appId,
      limit,
      width,
    },
    headers: {
      qlikCookie: localStorage.getItem('qlikCookie'),
      Authorization: `Bearer ${localStorage.getItem('qlikToken')}`,
    },
  });

  setQlikAuth(
    response?.headers['authorization']?.split(' ')[1],
    response?.headers['qlikcookie'],
  );

  return response.data.data;
};

export const fetchQlikToken = async (userId) => {
  try {
    // gets the qlik jwt token and set it in the store

    const response = await api.get(`qliksense/token?userId=${userId}`);

    if (!response.statusText === 'OK') {
      throw new Error('Could not get token');
    }

    // const data = await response.data;
    // return data;
    setQlikAuth(response?.data?.token, response?.data?.cookie);
  } catch (error) {
    // eslint-disable-next-line no-undef
    console.log(error.message);
  }
};

/**
 * store qlik token and cookie to local storage
 * @param {*} token qlik token
 * @param {*} cookie qlik cookie
 */
export const setQlikAuth = (token, cookie) => {
  if (localStorage.getItem('qlikCookie') !== cookie) {
    localStorage.setItem('__cDate', moment(new Date()));
    localStorage.setItem('__tracker', 5);
  }
  localStorage.setItem('qlikToken', token);
  localStorage.setItem('qlikCookie', cookie);
};

export const fetchOptions = async (fieldName, conditions, page) => {
  try {
    const response = await api.post(
      'qliksense',
      {
        connection_details: {
          fieldName: fieldName,
          appId: page === 'sop' ? process.env.APP_ID_SOP : process.env.APP_ID,
          offset: 0,
          limit: 10,
          conditions: conditions,
        },
        dataType: 'dropdown',
      },
      {
        headers: {
          qlikCookie: localStorage.getItem('qlikCookie'),
          Authorization: `Bearer ${localStorage.getItem('qlikToken')}`,
        },
      },
    );

    return response.data.values;
    // setOptions((prevOptions) => ({
    //   ...prevOptions,
    //   [fieldName]: response.data.options
    // }));
  } catch (error) {
    console.error('Error fetching options:', error);
  }
};

export const exportData = async (
  dimension,
  expression,
  appId,
  fileName = 'exportedFile.xlsx',
) => {
  // console.log('Export Data Request', { dimension, expression, appId });
  // console.log("exported data")

  // Ensure qlikCookie and token are available
  const qlikCookie = localStorage.getItem('qlikCookie');
  const qlikToken = localStorage.getItem('qlikToken');

  if (!qlikCookie || !qlikToken) {
    throw new Error('Authentication data missing. Please log in again.');
  }

  let payload = { export: true };

  // Use js-file-download for saving the file on the client side
  const FileDownload = require('js-file-download');

  let parameters = { expression, dimension, appId };

  try {
    // Send API request to the server
    const response = await api.post(`export`, parameters, {
      headers: {
        qlikCookie: localStorage.getItem('qlikCookie'),
        Authorization: `Bearer ${localStorage.getItem('qlikToken')}`,
      },
      responseType: 'blob', // This ensures that the response is treated as binary data (for file download)
    });

    // Check for HTTP errors
    if (response.status !== 200) {
      throw new Error(`Export failed with status code: ${response.status}`);
    }

    // Save the file to the client
    // console.log("exported data: ", response.data)
    FileDownload(response.data, fileName);
    // window.open(`fileName`)

    // Return the response for further handling if needed
    return response;
  } catch (error) {
    console.error('Error exporting data:', error);
    payload.export = false;
    // Optional: dispatch loader off, setExportLoader(payload)
    throw new Error('Failed to export data. Please try again.');
  }
};
