import classes from './NavSubMenu.module.scss';

const NavSubMenu = ({ item, collapsed }) => {
  return (
    <>
      <i
        className={`icon icon__${item.categoryIcon} nav-link-icon ${classes['nav-icon']} `}
      ></i>
      {!collapsed && item.categoryName}
    </>
  );
};

export default NavSubMenu;
