// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wMES1D7x3YmVXYUbS6sm{font-size:18px;font-weight:bold;text-align:center;font-family:var(--font-gene-sans-bold)}.e5vgpjAFkzc0YPF_bNiM{font-size:14px;text-align:center}.HQZMrHVOsC_uru7ZnYW8{margin:100px auto}`, "",{"version":3,"sources":["webpack://./src/components/myview/defaultMessage/DefaultMessage.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,gBAAA,CACA,iBAAA,CACA,sCAAA,CAEF,sBACE,cAAA,CACA,iBAAA,CAEF,sBACE,iBAAA","sourcesContent":[".defaultMessageHeading {\n  font-size: 18px;\n  font-weight: bold;\n  text-align: center;\n  font-family: var(--font-gene-sans-bold);\n}\n.defaultMessageText {\n  font-size: 14px;\n  text-align: center;\n}\n.defaultMessage {\n  margin: 100px auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"defaultMessageHeading": `wMES1D7x3YmVXYUbS6sm`,
	"defaultMessageText": `e5vgpjAFkzc0YPF_bNiM`,
	"defaultMessage": `HQZMrHVOsC_uru7ZnYW8`
};
export default ___CSS_LOADER_EXPORT___;
