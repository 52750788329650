import { Spin } from 'antd';
import { useState, useEffect, Suspense, lazy } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// store

// Lazy loading the components
const BrandContainer = lazy(() => import('./brand/BrandContainer'));
const PortfolioContainer = lazy(() => import('./portfolio/PortfolioContainer'));
const SopContainer = lazy(() => import('./sop/SopContainer'));

import {
  LEGAL_COMPLIANCE_TEXT,
  PERFORMANCE_CATEGORY,
  TAB,
  TABNAMES,
} from '@constant';
import classes from './PerformanceContainer.module.scss';

const PerformanceContainer = ({ currentTab, week }) => {
  const navigate = useNavigate();
  const [key, setKey] = useState('');
  const UserAccess = useSelector((state) => state?.brandPerformance?.userAcces);

  const viewFlags = UserAccess?.reduce((acc, item) => {
    acc[item?.ViewName] = true;
    return acc;
  }, {});

  const showPortfolio = viewFlags[TABNAMES.portfolio] || false;
  const showBrand = viewFlags[TABNAMES.brand] || false;
  const showSop = viewFlags[TABNAMES.sop] || false;
  useEffect(() => {
    setKey(currentTab);
  }, [currentTab]);

  const onSetTabKey = (tab) => {
    setKey(tab);
    navigate(`/analyticshub/saif/${tab}`);
  };
  /*
    this is used to load tab and remove the conditonal 
    rendering effect that it always mount the component
  */
  if (showBrand && showPortfolio && showSop) {
    return (
      <div className={classes['performance-container']}>
        <span className={classes['week-container']}> {week}</span>
        <Tabs
          id={key}
          activeKey={key}
          onSelect={(k) => {
            onSetTabKey(k);
          }}
          className="mb-3"
          defaultActiveKey={key}
          style={{ marginTop: '-37px' }}
        >
          <Tab
            eventKey={PERFORMANCE_CATEGORY?.PORTFOLIO}
            title={
              <span
                className={`d-flex title__bold title__base ${classes['title']}`}
              >
                {TAB.PORTFOLIO}
              </span>
            }
          >
            <Suspense fallback={<div></div>}>
              <PortfolioContainer
                active={key === PERFORMANCE_CATEGORY?.PORTFOLIO}
              />
            </Suspense>
          </Tab>

          <Tab
            eventKey={PERFORMANCE_CATEGORY?.BRAND}
            title={
              <span
                className={`d-flex title__bold title__base ${classes['title']}`}
              >
                {TAB.BRAND}
              </span>
            }
          >
            <Suspense fallback={<div></div>}>
              <BrandContainer active={key === PERFORMANCE_CATEGORY?.BRAND} />
            </Suspense>
          </Tab>

          <Tab
            eventKey={PERFORMANCE_CATEGORY?.SOP}
            title={
              <span
                className={`d-flex title__bold title__base ${classes['title']}`}
              >
                {TAB.SOP}
              </span>
            }
          >
            <Suspense fallback={<div></div>}>
              <SopContainer active={key === PERFORMANCE_CATEGORY?.SOP} />
            </Suspense>
          </Tab>
        </Tabs>

        {(showPortfolio || showBrand || showSop) && (
          <div className={'disclaimer-text'}>
            RESTRICTED INSIDER INFORMATION: <em>{LEGAL_COMPLIANCE_TEXT}</em>
          </div>
        )}
      </div>
    );
  }
  if (showPortfolio && showBrand) {
    return (
      <div className={classes['performance-container']}>
        <span className={classes['week-container']}> {week}</span>
        <Tabs
          id={key}
          activeKey={key}
          onSelect={(k) => {
            onSetTabKey(k);
          }}
          className="mb-3"
          style={{ marginTop: '-37px' }}
        >
          <Tab
            eventKey={PERFORMANCE_CATEGORY?.PORTFOLIO}
            title={
              <span
                className={`d-flex title__bold title__base ${classes['title']}`}
              >
                {TAB.PORTFOLIO}
              </span>
            }
          >
            <Suspense fallback={<div></div>}>
              <PortfolioContainer
                active={key === PERFORMANCE_CATEGORY?.PORTFOLIO}
              />
            </Suspense>
          </Tab>

          <Tab
            eventKey={PERFORMANCE_CATEGORY?.BRAND}
            title={
              <span
                className={`d-flex title__bold title__base ${classes['title']}`}
              >
                {TAB.BRAND}
              </span>
            }
          >
            <Suspense fallback={<div></div>}>
              <BrandContainer active={key === PERFORMANCE_CATEGORY?.BRAND} />
            </Suspense>
          </Tab>
        </Tabs>

        {(showPortfolio || showBrand || showSop) && (
          <div className={'disclaimer-text'}>
            RESTRICTED INSIDER INFORMATION: <em>{LEGAL_COMPLIANCE_TEXT}</em>
          </div>
        )}
      </div>
    );
  }
  if (showPortfolio && showSop) {
    return (
      <div className={classes['performance-container']}>
        <span className={classes['week-container']}> {week}</span>
        <Tabs
          id={key}
          activeKey={key}
          onSelect={(k) => {
            onSetTabKey(k);
          }}
          className="mb-3"
          style={{ marginTop: '-37px' }}
        >
          <Tab
            eventKey={PERFORMANCE_CATEGORY?.PORTFOLIO}
            title={
              <span
                className={`d-flex title__bold title__base ${classes['title']}`}
              >
                {TAB.PORTFOLIO}
              </span>
            }
          >
            <Suspense fallback={<div></div>}>
              <PortfolioContainer
                active={key === PERFORMANCE_CATEGORY?.PORTFOLIO}
              />
            </Suspense>
          </Tab>

          <Tab
            eventKey={PERFORMANCE_CATEGORY?.SOP}
            title={
              <span
                className={`d-flex title__bold title__base ${classes['title']}`}
              >
                {TAB.SOP}
              </span>
            }
          >
            <Suspense fallback={<div></div>}>
              <SopContainer active={key === PERFORMANCE_CATEGORY?.SOP} />
            </Suspense>
          </Tab>
        </Tabs>

        {(showPortfolio || showBrand || showSop) && (
          <div className={'disclaimer-text'}>
            RESTRICTED INSIDER INFORMATION: <em>{LEGAL_COMPLIANCE_TEXT}</em>
          </div>
        )}
      </div>
    );
  }
  if (showBrand && showSop) {
    return (
      <div className={classes['performance-container']}>
        <span className={classes['week-container']}> {week}</span>
        <Tabs
          id={key}
          activeKey={key}
          onSelect={(k) => {
            onSetTabKey(k);
          }}
          className="mb-3"
          style={{ marginTop: '-37px' }}
        >
          <Tab
            eventKey={PERFORMANCE_CATEGORY?.BRAND}
            title={
              <span
                className={`d-flex title__bold title__base ${classes['title']}`}
              >
                {TAB.BRAND}
              </span>
            }
          >
            <Suspense fallback={<div></div>}>
              <BrandContainer active={key === PERFORMANCE_CATEGORY?.BRAND} />
            </Suspense>
          </Tab>

          <Tab
            eventKey={PERFORMANCE_CATEGORY?.SOP}
            title={
              <span
                className={`d-flex title__bold title__base ${classes['title']}`}
              >
                {TAB.SOP}
              </span>
            }
          >
            <Suspense fallback={<div></div>}>
              <SopContainer active={key === PERFORMANCE_CATEGORY?.SOP} />
            </Suspense>
          </Tab>
        </Tabs>

        {(showPortfolio || showBrand || showSop) && (
          <div className={'disclaimer-text'}>
            RESTRICTED INSIDER INFORMATION: <em>{LEGAL_COMPLIANCE_TEXT}</em>
          </div>
        )}
      </div>
    );
  }
  if (showPortfolio) {
    return (
      <div className={classes['performance-container']}>
        <span className={classes['week-container']}> {week}</span>
        <Tabs
          id={key}
          activeKey={key}
          onSelect={(k) => {
            onSetTabKey(k);
          }}
          className="mb-3"
          style={{ marginTop: '-37px' }}
        >
          <Tab
            eventKey={PERFORMANCE_CATEGORY?.PORTFOLIO}
            title={
              <span
                className={`d-flex title__bold title__base ${classes['title']}`}
              >
                {TAB.PORTFOLIO}
              </span>
            }
          >
            <Suspense fallback={<div></div>}>
              <PortfolioContainer
                active={key === PERFORMANCE_CATEGORY?.PORTFOLIO}
              />
            </Suspense>
          </Tab>
        </Tabs>

        {(showPortfolio || showBrand || showSop) && (
          <div className={'disclaimer-text'}>
            RESTRICTED INSIDER INFORMATION: <em>{LEGAL_COMPLIANCE_TEXT}</em>
          </div>
        )}
      </div>
    );
  }
  if (showBrand) {
    return (
      <div className={classes['performance-container']}>
        <span className={classes['week-container']}> {week}</span>
        <Tabs
          id={key}
          activeKey={key}
          onSelect={(k) => {
            onSetTabKey(k);
          }}
          className="mb-3"
          style={{ marginTop: '-37px' }}
        >
          <Tab
            eventKey={PERFORMANCE_CATEGORY?.BRAND}
            title={
              <span
                className={`d-flex title__bold title__base ${classes['title']}`}
              >
                {TAB.BRAND}
              </span>
            }
          >
            <Suspense fallback={<div></div>}>
              <BrandContainer active={key === PERFORMANCE_CATEGORY?.BRAND} />
            </Suspense>
          </Tab>
        </Tabs>

        {(showPortfolio || showBrand || showSop) && (
          <div className={'disclaimer-text'}>
            RESTRICTED INSIDER INFORMATION: <em>{LEGAL_COMPLIANCE_TEXT}</em>
          </div>
        )}
      </div>
    );
  }
  if (showSop) {
    return (
      <div className={classes['performance-container']}>
        <span className={classes['week-container']}> {week}</span>
        <Tabs
          id={key}
          activeKey={key}
          onSelect={(k) => {
            onSetTabKey(k);
          }}
          className="mb-3"
          style={{ marginTop: '-37px' }}
        >
          <Tab
            eventKey={PERFORMANCE_CATEGORY?.SOP}
            title={
              <span
                className={`d-flex title__bold title__base ${classes['title']}`}
              >
                {TAB.SOP}
              </span>
            }
          >
            <Suspense fallback={<div></div>}>
              <SopContainer active={key === PERFORMANCE_CATEGORY?.SOP} />
            </Suspense>
          </Tab>
        </Tabs>

        {(showPortfolio || showBrand || showSop) && (
          <div className={'disclaimer-text'}>
            RESTRICTED INSIDER INFORMATION: <em>{LEGAL_COMPLIANCE_TEXT}</em>
          </div>
        )}
      </div>
    );
  }
  return <Spin />;
};

export default PerformanceContainer;
