import { notification } from 'antd';

const notificationType = ['success', 'info', 'warning'];
export const Notification = (type, message) => {
  notification.open({
    message: message,
    type: notificationType.includes(type) ? type : 'error',
    duration: 3,
    style: { fontSize: 12 },
  });
};
