import { CATEGORYLOWERCASE, SDLINECHART_YAXIS_LABEL } from '@constant';
import { colorLabelSpan } from './colorLabelSpan';

export const SDlineChartOptions = ({
  chartData,

  showEndLabel,

  category,

  expand,
}) => ({
  grid: expand ? { left: '5%', right: '5%', containLabel: true } : {},
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'line',
    },
    formatter: function (params) {
      let tooltipContent = ' ';
      params?.forEach((param) => {
        const color = chartData?.filter((item) => {
          return item?.name === param?.seriesName;
        })[0]?.lineStyle?.color;
        const value =
          param?.value === null || param?.value === undefined
            ? '-'
            : Number(param?.value)?.toLocaleString() < 0
            ? `-${Math.abs(param?.value)}`
            : param?.value;
        const seriesName = `${param?.seriesName} Variance`;
        const data =
          colorLabelSpan({ color }) +
          ' ' +
          seriesName +
          ' : ' +
          (value === '-'
            ? '-'
            : Number(value)?.toLocaleString() < 0
            ? `-$${Math.abs(value)}M`
            : `$${value}M`) +
          '<br/>';
        tooltipContent += data;
      });
      return tooltipContent;
    },
  },
  xAxis: {
    type: CATEGORYLOWERCASE,
    boundaryGap: true,
    data: category,
    axisLabel: {
      show: true,
      rotate: 90,
      color: '#000000',
      fontSize: 12,
    },
    axisTick: { show: false },
  },
  yAxis: [
    {
      type: 'value',
      name: SDLINECHART_YAXIS_LABEL,
      nameLocation: 'middle',
      nameGap: 50,
      splitLine: {
        show: false,
      },
      nameTextStyle: {
        color: '#000000',
        fontSize: 13,
      },
      axisLine: { show: true },
    },
  ],
  series: chartData?.map((item) => {
    return {
      ...item,
      name: item?.name,
      type: 'line',
      yAxisIndex: 0,
      showSymbol: false,
      lineStyle: {
        color: item?.lineStyle?.color,
        width: 3,
      },
      endLabel: {
        show: showEndLabel,
        formatter: item?.name,
        color: item?.lineStyle?.color,
        align: 'right',
        verticalAlign: 'bottom',
        offset: [-15, -5],
      },
      labelLayout: {
        hideOverlap: true,
        moveOverlap: 'shiftX',
      },
    };
  }),
});
