import axiosDBClient from '../../axiosDBClient';

export const getExpressions = async (unixId) => {
  const response = await axiosDBClient.get('db-expression', {
    params: { unixId: unixId },
  });

  if (response.error) {
    throw new Error('Could not fetch comments data!');
  }
  return response.data;
};

export const getFilters = async (unixId) => {
  const response = await axiosDBClient.get('db-expression/filter', {
    params: { unixId: unixId },
  });

  if (response.error) {
    throw new Error('Could not fetch comments data!');
  }
  return response.data;
};

export const getUserAccessDetails = async (unixId) => {
  const response = await axiosDBClient.get('db-expression/accessDetails', {
    params: { unixId: unixId },
  });

  if (response.error) {
    throw new Error('Could not fetch comments data!');
  }
  return response.data;
};

export const getVersion = async (unixId) => {
  const response = await axiosDBClient.get('db-expression/versiontype', {
    params: { unixId: unixId },
  });

  if (response.error) {
    throw new Error('Could not fetch comments data!');
  }
  return response.data;
};
