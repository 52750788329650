import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Switch, Modal, Button, Tooltip, Select, Row, Col } from 'antd';
import AnchorDropdown from '../dropdown/AnchorDropdown';
import CardModal from '../Modal/CardModal';
import Catalogue from '../catalogue/Catalogue';
import Chart from '../chart/Chart';
import ChartActions from '../chart/actions/ChartActions';
import Grid from '../grid/Grid';
import InfoDialog from '../Modal/InfoDialog';
import RadioGroup from '../radio/RadioGroup';
import SelectMenu from '../selectmenu/SelectMenu';
import {
  CARD_MENU,
  YTD_ROY_DATASELECT_OPTIONS,
  DEFAULT_DEMAND_DATA_OPTIONS,
  FY_PROJECTION_DATASELECT_OPTIONS,
  FYPROJECTION_DEFAULT_DATA_OPTIONS,
  AGG_FORECAST_VIEW_TYPE,
  LEGEND_AGG_FORECAST,
  SHORT_TERM_FORECAST_VIEW_TYPE,
  LEGEND_SHORT_FORECAST,
  LEGENDDATA,
  CARD_MENU_BP,
  VIEW_TYPE_PREVIOUS,
  YEAR_FORECAST_COMMENT_VIEW_TYPE,
  VIEW_TYPE_FINANCIAL_FORECAST,
  CHART,
  GRID,
  INFO,
  EXPAND,
  EVENT,
  LINE_NAME,
  COMMENTS_MESSAGE,
  YES,
  NO,
  CLOSE,
  ACTION_ADD,
  ACTION_EDIT,
  ACTION_DELETE,
  YTDANDROYCOMMENT,
  SOPCARDCOMMENT_ID,
  TREND_FORECASTCHART_COMMENT_MODAL_TITLE,
  LABELCOLORS,
  RIGHTSIDELABELCHARTS,
} from '@constant';
import { chartActionsAccess, ytdChartGridCols } from '@mockdata/brandChartData';
import { GETCOMMENTCREATEDDATE } from '@utils/utils';
import { CARD_CONFIG } from '@utils/cardConfig';
import { getUserUnixId } from '@utils/getUser';
import Comment from '../Modal/Comment';
import classes from './Card.module.scss';
import { shiftElementPlacement } from '@utils/dataFormatter';

const Card = ({
  cardTitle,
  chartData,
  griCols,
  cardId,
  gridData,
  onConfigureComment,
  cardWidth,
  chartHeight,
  chartType,
  infoMessage,
  showMarker,
  cardComments,
  cardHeight,
  handleChange,
  handleForecastType,
  onSelectOptionChange,
  category,
  minMax,
  chartText,
  selectedViewType,
  varianceChartData,
  varianceGridData,
  versionType,
  forecastLabel,
  dataFilterOptions,
  onSelectChangesNetSales,
  selectedFilters,
  onAddToMyView,
  eventFilterOptions,
  onSelectChangesEvent,
  scatterPlotDatatoPlot,
  cardNumber,
  labelytd,
  currentNetSales,
  bpEventList,
  onSelectViewType,
  viewType,
  bpData,
  scatterEvents,
  metricFilterOptions,
  KPICardId,
  showBannerCommentsModal,
  handleCommentBannerClose,
  isBannerCommentModalVisible,
  labelYTDRoYProjection,
  onConfigurePinnedComment,
  leastFilter,
  normalCardTitle,
  cardHeadingTitle,
}) => {
  const franchise = selectedFilters?.Franchise;
  let cardTitleWithLeastFilter = cardTitle;
  const customizedCardTitle = [
    CARD_CONFIG?.SOP_YEAR_FORECAST_CHART?.ChartLabel,
    CARD_CONFIG?.BP_NETSALES_CHART?.ChartLabel,
  ];
  if (!customizedCardTitle.includes(cardId)) {
    if (cardTitle?.endsWith('for')) {
      cardTitleWithLeastFilter = `${cardTitle} ${leastFilter}`;
    } else {
      cardTitleWithLeastFilter = `${cardTitle} for ${leastFilter}`;
    }
  }

  const currentUser = getUserUnixId();
  const actionsAllowed = chartActionsAccess?.filter(
    (item) => item?.chartId === cardId,
  )[0];
  // const { selections } = useSelector((state) => state.globalFilter);
  let commentsData; // setting the value of comments data as per chart
  if (cardId === 'year-forecast') {
    commentsData = cardComments?.filter((item) =>
      viewType === VIEW_TYPE_PREVIOUS
        ? item?.ChartId === SOPCARDCOMMENT_ID.PREVIOUS &&
          item?.BrandName === selectedFilters?.Brand?.[0]
        : item?.ChartId === SOPCARDCOMMENT_ID.FINANCIAL &&
          item?.BrandName === selectedFilters?.Brand?.[0],
    );
  } else if (cardId === 'short-termforecast') {
    commentsData = cardComments?.filter((item) => {
      return (
        item?.ChartId === KPICardId?.CardId &&
        item?.Sku === selectedFilters?.SKU?.[0]
      );
    });
  } else {
    commentsData = cardComments?.filter(
      (item) => item?.ChartId === KPICardId?.CardId,
    );
  }

  const pinnedIndex = commentsData?.findIndex((x) => x?.isPinned);
  if (pinnedIndex > -1) {
    commentsData = shiftElementPlacement(commentsData, pinnedIndex, 0);
  }

  const priorityComment = commentsData?.length > 0 && commentsData[0];
  const UserAccess = useSelector((state) => state?.brandPerformance?.userAcces);

  const distinctCardIds = Array.from(
    new Set(
      UserAccess.filter((item) => item?.CommentEditAccess === 'Y')?.map(
        (item) => item?.CardId,
      ),
    ),
  );

  const cardNumberExists = distinctCardIds?.includes(cardNumber);

  const location = useLocation();
  const [cardActions, setCardActions] = useState({
    chartView: true,
    gridView: false,
    commentView: false,
  });
  const [isLabelChecked, setIsLabelChecked] = useState(true);
  const [isEventChecked, setIsEventChecked] = useState(true);
  const [checkboxValues, setCheckBoxValues] = useState(
    DEFAULT_DEMAND_DATA_OPTIONS,
  );
  const [netSalesCheckboxValuesData, setNetSalesCheckBoxValuesData] =
    useState(dataFilterOptions);
  const [eventCheckboxValuesData, setEventCheckBoxValuesData] =
    useState(eventFilterOptions);
  const [fyProjectionCheckboxValues, setFyProjectionCheckboxValues] = useState(
    FYPROJECTION_DEFAULT_DATA_OPTIONS,
  );
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [isCardModalVisible, setIsCardModalVisible] = useState(false);
  const [isEventDetailsModalVisible, setIsEventDetailsModalVisible] =
    useState(false);
  const [ytdRoyFilteredChartData, setYtdRoyFilteredChartData] = useState(
    cardId === CARD_CONFIG.BRAND_YTD_ROY_CHART?.ChartLabel ? chartData : [],
  );
  const [ytdRoyFilteredLabel, setYtdRoyFilteredLabel] = useState(
    cardId === CARD_CONFIG.BRAND_YTD_ROY_CHART?.ChartLabel ? labelytd : [],
  );
  const [fyProjChartFilteredData, setFyProjChartFilteredData] = useState(
    cardId === 'fy-projection' ? chartData : [],
  );
  const [expandCommentModal, setExpandCommentModal] = useState(false);
  const [isViewExpanded, setIsViewExpanded] = useState(false);
  useEffect(() => {
    setCardActions({
      chartView: true,
      gridView: false,
      commentView: false,
    });
    getYtdRoyChartFilteredData(checkboxValues);
    getYtdRoyLabelFilterted(checkboxValues);
    getFyProjectionFilteredChartData(fyProjectionCheckboxValues);
  }, [location]);

  const onSelectCardAction = (action) => {
    let chart = false;
    let grid = false;
    let comment = false;
    if (action === CHART) {
      chart = true;
    } else if (action === GRID) {
      grid = true;
    } else {
      comment = true;
    }
    setCardActions({
      chartView: chart,
      gridView: grid,
      commentView: comment,
    });
  };

  useEffect(() => {
    setNetSalesCheckBoxValuesData(dataFilterOptions);
  }, [dataFilterOptions, selectedFilters?.Brand]);

  useEffect(() => {
    setEventCheckBoxValuesData(eventFilterOptions);
  }, [eventFilterOptions]);

  useEffect(() => {
    if (cardId == 'fy-projection') {
      getFyProjectionFilteredChartData(fyProjectionCheckboxValues);
    }
  }, [chartData]);

  const onSelectMenu = (e) => {
    const selectedKey = e.key;
    if (selectedKey === INFO) {
      setIsInfoModalVisible(true);
    } else if (selectedKey === EXPAND) {
      setIsCardModalVisible(true);
      setIsViewExpanded(true);
    } else if (selectedKey === EVENT) {
      setIsEventDetailsModalVisible(true);
    } else {
      onAddToMyView(
        cardId,
        cardId === CARD_CONFIG?.SOP_YEAR_FORECAST_CHART?.ChartLabel
          ? normalCardTitle
          : cardTitleWithLeastFilter,
        chartData,
        gridData,
        varianceChartData,
        varianceGridData,
        chartText || forecastLabel,
        category,
        griCols,
        labelytd,
        bpData,
        scatterEvents,
      );
    }
  };

  const handleCardModalClose = () => {
    setIsCardModalVisible(false);
    setIsViewExpanded(false);
  };

  const handleInfoModalClose = () => {
    setIsInfoModalVisible(false);
  };

  const onChangeLabelSwitch = () => {
    setIsLabelChecked(!isLabelChecked);
  };

  const onChangeEventSwitch = () => {
    setIsEventChecked(!isEventChecked);
  };

  const getYtdRoyChartFilteredData = (updatedSelection) => {
    const excludeItems = [LINE_NAME.HIGH, LINE_NAME.LOW, versionType];
    const updatedData = chartData?.filter(
      (item) =>
        updatedSelection?.includes(item?.name) ||
        excludeItems.includes(item?.name),
    );
    setYtdRoyFilteredChartData(updatedData);
  };

  const getYtdRoyLabelFilterted = (updatedSelection) => {
    const updatedData = labelytd?.filter((item) => {
      const excludedItem = [
        LINE_NAME.HIGH,
        LINE_NAME.LOW,
        LINE_NAME.SQUAD_PLAN,
        LINE_NAME.T0,
      ];
      return (
        updatedSelection?.includes(item?.name) ||
        excludedItem.includes(item?.name)
      );
    });

    setYtdRoyFilteredLabel(updatedData);
  };

  const getFyProjectionFilteredChartData = (updatedSelection) => {
    const updatedData = chartData?.filter(
      (item) =>
        updatedSelection?.includes(item?.name) || item?.name === versionType,
    );

    setFyProjChartFilteredData(updatedData);
  };

  const getNetSalesFilteredChartData = (updatedSelection) => {
    const updatedData = chartData?.filter(
      (item) =>
        updatedSelection?.includes(item?.name) || item?.name === versionType,
    );

    setFyProjChartFilteredData(updatedData); // Update chartdata here for net sales
  };

  const onSelectChange = (key, e) => {
    const updatedSelection = e.checked
      ? [...checkboxValues, key]
      : checkboxValues?.filter((item) => item !== key);
    setCheckBoxValues(updatedSelection);
    getYtdRoyChartFilteredData(updatedSelection);
    getYtdRoyLabelFilterted(updatedSelection);
  };

  const onSelectNetSalesDataOption = (key, e) => {
    const updatedSelection = e.checked
      ? [...netSalesCheckboxValuesData, key]
      : netSalesCheckboxValuesData?.filter((item) => item !== key);
    setNetSalesCheckBoxValuesData(updatedSelection);
    getNetSalesFilteredChartData(updatedSelection);
    onSelectChangesNetSales(updatedSelection);
  };

  const onSelectNetSalesEventOption = (key, e) => {
    const updatedSelection = e.checked
      ? [...eventCheckboxValuesData, key]
      : eventCheckboxValuesData?.filter((item) => item !== key);
    setEventCheckBoxValuesData(updatedSelection);
    // getNetSalesFilteredChartData(updatedSelection);
    onSelectChangesEvent(updatedSelection);
  };

  const onSelectFyDataOptions = (key, e) => {
    const updatedSelection = e.checked
      ? [...fyProjectionCheckboxValues, key]
      : fyProjectionCheckboxValues?.filter((item) => item !== key);
    setFyProjectionCheckboxValues(updatedSelection);
    getFyProjectionFilteredChartData(updatedSelection);
  };

  const onExpandCommentDesc = () => {
    setExpandCommentModal(true);
  };

  const handleClose = () => {
    setExpandCommentModal(false);
    setIsEventDetailsModalVisible(false);
  };

  const getClassName = (value) => {
    if (value.includes('%')) {
      const numericValue = parseFloat(value);

      if (numericValue > 0) {
        return classes.positive;
      } else {
        return classes.negative;
      }
    }
  };

  const getClassNameAggForecast = (value) => {
    if (value.includes('%')) {
      const numericValue = parseFloat(value);

      if (numericValue > LEGENDDATA.high) {
        return classes.green;
      } else if (
        numericValue >= LEGENDDATA.mediumlow &&
        numericValue <= LEGENDDATA.mediumhigh
      ) {
        return classes.yellow;
      } else {
        return classes.red;
      }
    }
  };

  const getSubHeading = () => {
    let title = '';
    if (!customizedCardTitle.includes(cardId)) {
      if (cardTitle?.endsWith('for')) {
        title = (
          <div>
            {cardTitle}{' '}
            <span className={classes['sub-header']}>{leastFilter}</span>
          </div>
        );
      } else {
        title = (
          <div>
            {cardTitle}
            {' for '}
            <span className={classes['sub-header']}>{leastFilter}</span>
          </div>
        );
      }
    } else {
      title = cardTitleWithLeastFilter;
    }
    return title;
  };

  const getTooltipText = () => {
    let tooltipText = cardTitleWithLeastFilter;
    if (typeof cardTitleWithLeastFilter === 'object') {
      tooltipText = `${cardHeadingTitle} ${leastFilter}`;
    }
    return tooltipText;
  };

  const getCardTitle = () => {
    return (
      <Tooltip title={getTooltipText()}>
        <span className={`${classes['txt-overflow-css']} card-title-overflow`}>
          {typeof cardTitleWithLeastFilter === 'object'
            ? cardTitleWithLeastFilter
            : getSubHeading()}
        </span>
      </Tooltip>
    );
  };

  const isCommentHistoryEnable = () => {
    if (
      actionsAllowed?.comments &&
      cardId === 'short-termforecast' &&
      selectedFilters?.SKU?.length == 1
    ) {
      return true;
    } else if (
      actionsAllowed?.comments &&
      cardId !== 'short-termforecast' &&
      selectedFilters?.Brand?.length == 1
    ) {
      return true;
    } else return false;
  };

  const netSalesOptions = dataFilterOptions?.map((item) => ({
    value: item,
    label: item,
  }));

  const eventOptions = eventFilterOptions?.map((item) => ({
    value: item,
    label: item,
  }));

  const metricOptions = metricFilterOptions?.map((item) => ({
    value: item,
    label: item,
  }));

  const getCommentsTitle = () => {
    if (cardId === 'short-termforecast' && selectedFilters?.SKU?.length !== 1) {
      return COMMENTS_MESSAGE.SKU_ENABLE;
    } else if (
      cardId === 'year-forecast' &&
      selectedFilters?.Brand?.length !== 1
    ) {
      return COMMENTS_MESSAGE.BRAND_ENABLE;
    } else if (cardId === 'net-sales' && selectedFilters?.Brand?.length !== 1) {
      return COMMENTS_MESSAGE.BRAND_ENABLE;
    } else return COMMENTS_MESSAGE.NO_COMMENT;
  };

  const isValidAddCondition =
    (cardId !== 'year-forecast' &&
      cardId !== 'short-termforecast' &&
      cardId !== 'net-sales') ||
    (cardId === 'short-termforecast' && selectedFilters?.SKU?.length === 1) ||
    (cardId === 'net-sales' && selectedFilters?.Brand?.length === 1) ||
    (cardId === 'year-forecast' && selectedFilters?.Brand?.length === 1);

  const eventInfo = () => {
    if (bpEventList?.length) {
      return (
        <div>
          {bpEventList?.map((item, index) => {
            return (
              <div key={index} className={classes['event-block']}>
                <div className={classes['event-name']}>
                  {item?.name ? item?.name : '-'}
                </div>
                <div className={classes['event-desc']}>
                  {item?.desc ? item?.desc : '-'}
                </div>
                <div className={classes['event-date']}>
                  End Date: {item?.date ? item?.date : '-'}
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };

  return (
    <div
      className={classes['card-wrap']}
      style={{ width: cardWidth || '49%', minHeight: cardHeight }}
    >
      <div className={classes['align-wrap']}>
        <div
          className={
            typeof cardTitleWithLeastFilter === 'object'
              ? `${classes['chartaction-container']} ${classes['chartaction-container-wrapper']}`
              : actionsAllowed?.labels
              ? `${ classes['chartaction-container']} ${cardId === "fy-projection" ? classes['chartaction-container-css']: classes['chartaction-container-label']}`
              : classes['chartaction-container']
          }
        >
          <div className={classes['card-title']}>{getCardTitle()}</div>
          {chartData && (
            <ChartActions
              onSelectView={(view) => onSelectCardAction(view)}
              cardActions={cardActions}
              actionsAllowed={
                isCommentHistoryEnable()
                  ? actionsAllowed
                  : { ...actionsAllowed, comments: false }
              }
              commentsData={commentsData}
            />
          )}
        </div>
        <div className={classes['dropdown-wrap']}>
          {cardId === CARD_CONFIG.BRAND_YTD_ROY_CHART?.ChartLabel && (
            <>
              <div className={classes['radio-wrap']}>
                <div className={classes['radio-text']}>Data</div>
                <SelectMenu
                  menuOptions={YTD_ROY_DATASELECT_OPTIONS}
                  selectStyle={{ width: 200 }}
                  onSelectChange={onSelectChange}
                  checkboxValues={checkboxValues}
                />
              </div>
            </>
          )}
          {cardId === 'net-sales' && (
            <>
              <div className={classes['radio-wrap']}>
                <div className={classes['radio-text']}>Data</div>
                <div className={classes['select-menu-dd']}>
                  <SelectMenu
                    menuOptions={netSalesOptions}
                    selectStyle={{ width: '100%', height: 30 }}
                    onSelectChange={onSelectNetSalesDataOption}
                    checkboxValues={netSalesCheckboxValuesData}
                  />
                </div>
              </div>
              <div className={classes['radio-wrap']}>
                <div className={classes['radio-text']}>Events</div>
                <div className={classes['select-menu-dd']}>
                  <SelectMenu
                    menuOptions={eventOptions}
                    selectStyle={{ width: '100%', height: 30 }}
                    onSelectChange={onSelectNetSalesEventOption}
                    checkboxValues={eventCheckboxValuesData}
                  />
                </div>
              </div>
              <div className={classes['label-wrap']}>
                <div className={classes['label-text']}>Events</div>
                <Switch
                  checkedChildren={YES}
                  unCheckedChildren={NO}
                  defaultChecked
                  checked={isEventChecked}
                  onChange={onChangeEventSwitch}
                />
              </div>
            </>
          )}
          {cardId === 'fy-projection' && (
            <>
              <div className={classes['radio-wrap']}>
                <div className={classes['radio-text']}>Data</div>
                <SelectMenu
                  menuOptions={FY_PROJECTION_DATASELECT_OPTIONS}
                  selectStyle={{ width: 200 }}
                  onSelectChange={onSelectFyDataOptions}
                  checkboxValues={fyProjectionCheckboxValues}
                />
              </div>
            </>
          )}
          {cardId === 'year-forecast' && (
            <>
              <div className={classes['radio-wrap']}>
                <div className={classes['radio-text']}>Metrics</div>
                <Select
                  value={[
                    currentNetSales ||
                      (metricOptions?.length && metricOptions[0]),
                  ]}
                  style={{ width: 200, height: 30 }}
                  onChange={onSelectOptionChange}
                  options={metricOptions}
                />
              </div>
            </>
          )}

          {actionsAllowed?.labels && (
            <div className={classes['label-wrap']}>
              <div className={classes['label-text']}>Labels</div>
              <Switch
                checkedChildren={YES}
                unCheckedChildren={NO}
                defaultChecked
                checked={isLabelChecked}
                onChange={onChangeLabelSwitch}
              />
            </div>
          )}

          <AnchorDropdown
            items={cardId === 'net-sales' ? CARD_MENU_BP : CARD_MENU}
            onClick={onSelectMenu}
          >
            <i className={'icon icon__card-options'}></i>
          </AnchorDropdown>
        </div>
      </div>
      {cardId === 'agg-forecast' && (
        <div className={classes['radio-wrap-container']}>
          <div className={classes['radio-wrap-view']}>
            <RadioGroup
              items={AGG_FORECAST_VIEW_TYPE}
              onChange={handleForecastType}
              value={selectedViewType}
            />
          </div>
        </div>
      )}
      {cardId === 'agg-forecast' && (
        <div className={classes['legend-container']}>
          <div className={classes['info-label-wrap-sop']}>
            <div className={classes['info-label-sop']}>
              <div>
                <span
                  title={`${
                    forecastLabel ? forecastLabel : '-'
                  } Forecast Accuracy`}
                >
                  <span
                    className={`${
                      classes['title-weekly']
                    } ${getClassNameAggForecast(
                      forecastLabel ? forecastLabel : '-',
                    )}`}
                  >
                    {forecastLabel ? forecastLabel : '-'}
                  </span>
                  Forecast Accuracy
                </span>
              </div>
            </div>
          </div>
          <div className={classes['legend']}>
            <span className={classes.greenLegend}></span>{' '}
            <span>{LEGEND_AGG_FORECAST.GREEN}</span>
            <span className={classes.redLegend}></span>{' '}
            <span>{LEGEND_AGG_FORECAST.RED}</span>
            <span className={classes.blackLegend}></span>{' '}
            <span>{LEGEND_AGG_FORECAST.BLACK}</span>
          </div>
        </div>
      )}
      {cardId === 'short-termforecast' && (
        <div className={classes['radio-wrap-container']}>
          <div className={classes['radio-wrap-view']}>
            <RadioGroup
              defaultValue={'4Wk'}
              items={SHORT_TERM_FORECAST_VIEW_TYPE}
              onChange={handleChange}
              value={selectedViewType}
            />
          </div>
        </div>
      )}
      {cardId === 'short-termforecast' && (
        <div className={classes['legend-container-square']}>
          <div className={classes['legend-square']}>
            <span className={classes.redLegendSquare}></span>{' '}
            <span>{LEGEND_SHORT_FORECAST.RED}</span>
            <span className={classes.greenLegendSquare}></span>{' '}
            <span>{LEGEND_SHORT_FORECAST.GREEN}</span>
          </div>
        </div>
      )}

      {cardId === CARD_CONFIG?.PORTFOLIO_FY_PROJECTION_CHART?.ChartLabel &&
        franchise?.length > 1 && (
          <div className={classes['info-label-wrap']}>{YTDANDROYCOMMENT}</div>
        )}

      {!cardActions?.commentView ? (
        <>
          {cardActions?.chartView ? (
            <>
              <Row align="middle">
                <Col
                  span={
                    isLabelChecked
                      ? RIGHTSIDELABELCHARTS.includes(cardId)
                        ? 20
                        : 24
                      : 24
                  }
                >
                  <Chart
                    key={
                      checkboxValues +
                        fyProjectionCheckboxValues +
                        netSalesCheckboxValuesData || varianceChartData
                    }
                    chartType={chartType}
                    chartData={
                      cardId === CARD_CONFIG.BRAND_YTD_ROY_CHART?.ChartLabel
                        ? ytdRoyFilteredChartData?.length === 0
                          ? chartData
                          : ytdRoyFilteredChartData
                        : cardId === 'fy-projection'
                        ? fyProjChartFilteredData?.length === 0
                          ? chartData
                          : fyProjChartFilteredData
                        : chartData
                    }
                    showEndLabel={isLabelChecked}
                    showscatter={isEventChecked}
                    showMarker={showMarker}
                    category={category}
                    minMax={minMax}
                    selectedViewType={selectedViewType}
                    growthPercentage={gridData}
                    scatterPlotDatatoPlot={
                      isEventChecked ? scatterPlotDatatoPlot : []
                    }
                    currentNetSales={currentNetSales}
                    style={{
                      height: chartHeight || 300,
                      margin:
                        cardId ===
                          CARD_CONFIG.BRAND_YOY_SALES_CHART.ChartLabel ||
                        cardId === 'short-termforecast' ||
                        cardId === 'agg-forecast'
                          ? '0px 25px'
                          : '0px',
                    }}
                  />
                </Col>
                <Col
                  span={
                    isLabelChecked
                      ? RIGHTSIDELABELCHARTS.includes(cardId)
                        ? 4
                        : 0
                      : 0
                  }
                >
                  {cardId === CARD_CONFIG.BRAND_YTD_ROY_CHART?.ChartLabel &&
                    isLabelChecked &&
                    chartData?.length > 0 && (
                      <div className={classes['ytd-label']}>
                        {labelytd?.map((item, index) => {
                          const color = item?.linsStyle;

                          return (
                            <span
                              key={index}
                              className={`${classes['ytd-label-text']} ${
                                classes[LABELCOLORS[color?.toLowerCase()]]
                              }`}
                            >
                              {item?.data?.startsWith('T0')
                                ? `${versionType}:${item?.data?.split(':')[1]}`
                                : item?.data}{' '}
                            </span>
                          );
                        })}
                      </div>
                    )}

                  {cardId ===
                    CARD_CONFIG?.PORTFOLIO_FY_PROJECTION_CHART?.ChartLabel &&
                    isLabelChecked &&
                    chartData?.length > 0 && (
                      <div
                        className={`${classes['ytd-label']} ${classes['ytd-label-wrapper']}`}
                      >
                        {labelYTDRoYProjection?.map((item, index) => {
                          const color = item?.linsStyle;

                          return (
                            <span
                              key={index}
                              className={`${classes['ytd-label-text']} ${
                                classes[LABELCOLORS[color.toLowerCase()]]
                              }`}
                            >
                              {item?.data?.startsWith('T0')
                                ? `${versionType}:${item?.data?.split(':')[1]}`
                                : item?.data}{' '}
                            </span>
                          );
                        })}
                      </div>
                    )}

                  {cardId ===
                    CARD_CONFIG?.SOP_YEAR_FORECAST_CHART?.ChartLabel &&
                    isLabelChecked &&
                    forecastLabel?.length > 0 && (
                      <div
                        className={`${classes['ytd-label']} ${classes['year-forecast-view-wrapper']}`}
                      >
                        {forecastLabel?.map((item, index) => {
                          const text =
                            index == 0
                              ? `YTD S&OP vs. ${versionType} Difference`
                              : item.name;
                          return (
                            <span
                              key={index}
                              className={`${classes['ytd-label-text']}`}
                            >
                              <span
                                className={`${getClassName(
                                  item?.data ? item?.data : '-',
                                )}`}
                              >
                                {item.data}
                              </span>
                              {' ' + text}
                            </span>
                          );
                        })}
                      </div>
                    )}
                </Col>
              </Row>

              {(cardId === 'ytd-sales-v-forec' ||
                cardId === 'ytd-sales-v-py') && (
                <div>
                  <p className={classes['chart-title']}>
                    {`Variance Drivers ${
                      cardId === 'ytd-sales-v-forec'
                        ? `(${versionType})`
                        : '(PY YTD)'
                    }`}
                  </p>
                  <Chart
                    chartData={varianceChartData}
                    chartType={'bar'}
                    chartWidth={'408px'}
                    chartHeight={300}
                    style={{
                      margin:
                        cardId === CARD_CONFIG.BRAND_YOY_SALES_CHART.ChartLabel
                          ? '0px 10px'
                          : '0px',
                    }}
                  />
                </div>
              )}
              {cardId === CARD_CONFIG.BRAND_YOY_SALES_CHART.ChartLabel && (
                <div className={classes['yoy-sales-reports-container']}>
                  <Grid
                    columns={griCols}
                    dataSource={gridData?.filter((item) => {
                      return (
                        item?.title !== 'Net Sales' &&
                        item?.title !== 'Gross Sales'
                      );
                    })}
                  />
                </div>
              )}
              {cardId === 'net-sales' && (
                <div className={classes['net-sales-container']}>
                  <Grid columns={griCols} dataSource={gridData} />
                </div>
              )}
            </>
          ) : (
            <div
              className={classes['grid-wrap']}
              style={{ height: chartHeight || 300 }}
            >
              <Grid
                dataSource={gridData}
                columns={griCols}
                pagination={false}
                chartHeight={chartHeight}
                scroll={{
                  y:
                    cardId === 'short-termforecast' || cardId === 'agg-forecast'
                      ? chartHeight > 370
                        ? 400
                        : undefined
                      : chartHeight > 300
                      ? 320
                      : undefined,
                }}
              />
              {(cardId === 'ytd-sales-v-forec' ||
                cardId === 'ytd-sales-v-py') && (
                <div className={classes['grid-container']}>
                  <div className={classes['card-subtitle']}>
                    {cardId === 'ytd-sales-v-forec'
                      ? `Variance Drivers (${versionType})`
                      : 'Variance Drivers (PY YTD)'}
                  </div>
                  <Grid
                    dataSource={varianceGridData}
                    columns={ytdChartGridCols}
                    pagination={false}
                  />
                </div>
              )}
            </div>
          )}

          {actionsAllowed?.comments && (
            <div className={classes['comment-section']}>
              {cardId === 'year-forecast' && (
                <div className={classes['radio-wrap-container-sop']}>
                  <div className={classes['radio-wrap-view']}>
                    <RadioGroup
                      items={YEAR_FORECAST_COMMENT_VIEW_TYPE}
                      onChange={onSelectViewType}
                      value={viewType}
                    />
                  </div>
                </div>
              )}

              {cardId === 'year-forecast' &&
                (viewType === VIEW_TYPE_FINANCIAL_FORECAST ? (
                  <div className={classes['sop-comment-header']}>
                    S&OP vs Financial Forecast
                  </div>
                ) : (
                  <div className={classes['sop-comment-header']}>
                    Cur S&OP vs Prev S&OP
                  </div>
                ))}

              {(cardId === 'short-termforecast' &&
                selectedFilters?.SKU?.length === 1 &&
                commentsData?.length !== 0) ||
              (cardId === 'net-sales' &&
                selectedFilters?.Brand?.length === 1 &&
                commentsData?.length !== 0) ||
              (cardId === 'year-forecast' &&
                selectedFilters?.Brand?.length === 1 &&
                commentsData?.length !== 0) ||
              (cardId !== 'year-forecast' &&
                cardId !== 'short-termforecast' &&
                cardId !== 'net-sales' &&
                commentsData?.length !== 0) ? (
                <>
                  <div className={classes['comment-title']}>
                    <div>
                      {priorityComment?.UserName}
                      <span className={classes['comment-info']}>
                        {GETCOMMENTCREATEDDATE(priorityComment?.CreatedDate)}
                      </span>
                    </div>

                    {cardNumberExists && (
                      <div
                        style={{ alignItems: 'center' }}
                        className={
                          classes[
                            cardId === 'year-forecast'
                              ? 'comment-icon-align-wrap'
                              : 'comment-icon-align'
                          ]
                        }
                      >
                        {cardId === 'year-forecast' && (
                          <i
                            className={'icon icon__comments_blue'}
                            onClick={showBannerCommentsModal}
                          ></i>
                        )}
                        {priorityComment?.isPinned && (
                          <Tooltip title="Unpin">
                            <i
                              className={'icon icon__unpinned'}
                              onClick={() =>
                                onConfigurePinnedComment(
                                  ACTION_DELETE,
                                  priorityComment?.ID,
                                  priorityComment?.ChartId,
                                  cardId,
                                )
                              }
                            ></i>
                          </Tooltip>
                        )}

                        {!priorityComment?.isPinned && (
                          <Tooltip title="Pin">
                            <i
                              className={'icon icon__pinned'}
                              style={{
                                paddingRight: '5px',
                                fontSize: '14px',
                                color: '#13294b',
                              }}
                              onClick={() =>
                                onConfigurePinnedComment(
                                  ACTION_ADD,
                                  priorityComment?.ID,
                                  priorityComment?.ChartId,
                                  cardId,
                                )
                              }
                            />
                          </Tooltip>
                        )}
                        <Tooltip title="Add">
                          <i
                            className={'icon icon__add_blue'}
                            onClick={() =>
                              onConfigureComment(
                                ACTION_ADD,
                                priorityComment?.ID,
                                KPICardId?.CardId,
                              )
                            }
                          ></i>
                        </Tooltip>
                        {currentUser?.toLowerCase() ===
                          priorityComment?.UnixId?.toLowerCase() && (
                          <>
                            <Tooltip title="Edit">
                              <i
                                className={'icon icon__edit'}
                                onClick={() =>
                                  onConfigureComment(
                                    ACTION_EDIT,
                                    priorityComment?.ID,
                                    KPICardId?.CardId,
                                  )
                                }
                              ></i>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <i
                                className={'icon icon__delete_blue'}
                                onClick={() =>
                                  onConfigureComment(
                                    ACTION_DELETE,
                                    priorityComment?.ID,
                                    KPICardId?.CardId,
                                  )
                                }
                              ></i>
                            </Tooltip>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                  <div className={classes['comment-desc']}>
                    {(cardId === 'year-forecast' &&
                      priorityComment?.Comment?.length > 350) ||
                    priorityComment?.Comment?.length > 110 ? (
                      <div className={classes['comment-align']}>
                        {`${priorityComment?.Comment?.substring(
                          0,
                          cardId === 'year-forecast' ? 350 : 110,
                        )}...`}
                        <span
                          className={classes['comment-expand']}
                          onClick={onExpandCommentDesc}
                        >
                          View More
                        </span>
                      </div>
                    ) : (
                      priorityComment?.Comment
                    )}
                  </div>
                </>
              ) : (
                <div>
                  {isValidAddCondition && cardNumberExists && (
                    <div
                      className={classes['comment-action-add']}
                      style={{
                        margin:
                          cardId === 'year-forecast' ? '-15px 5px' : '0px',
                      }}
                    >
                      {priorityComment && priorityComment?.isPinned && (
                        <Tooltip title="Unpin">
                          <i
                            className={'icon icon__unpinned'}
                            onClick={() =>
                              onConfigurePinnedComment(
                                ACTION_DELETE,
                                priorityComment?.ID,
                                priorityComment?.ChartId,
                                cardId,
                              )
                            }
                          ></i>
                        </Tooltip>
                      )}

                      {priorityComment && !priorityComment?.isPinned && (
                        <Tooltip title="Pin">
                          <i
                            className={'icon icon__pinned'}
                            style={{
                              paddingRight: '5px',
                              fontSize: '14px',
                              color: '#13294b',
                            }}
                            onClick={() =>
                              onConfigurePinnedComment(
                                ACTION_ADD,
                                priorityComment?.ID,
                                priorityComment?.ChartId,
                                cardId,
                              )
                            }
                          />
                        </Tooltip>
                      )}
                      <Tooltip title="Add">
                        <i
                          className={'icon icon__add_blue'}
                          onClick={() =>
                            onConfigureComment(
                              ACTION_ADD,
                              priorityComment?.ID,
                              KPICardId?.CardId,
                            )
                          }
                        ></i>
                      </Tooltip>
                    </div>
                  )}
                  <div className={classes['comment-no-data']}>
                    {getCommentsTitle()}
                  </div>
                </div>
              )}
            </div>
          )}
          {cardId === CARD_CONFIG.BRAND_YOY_OUTS_GROWTH_CHART.ChartLabel && (
            <div className={classes['yoy-growth-reports-container']}>
              <Grid columns={griCols} dataSource={gridData} />
            </div>
          )}
        </>
      ) : (
        <div style={{ height: cardId === 'year-forecast' ? 580 : cardHeight }}>
          <div
            className={classes['catalogue-wrap']}
            style={{ height: cardId === 'year-forecast' ? 580 : cardHeight }}
          >
            <Catalogue
              data={commentsData}
              onConfigureComment={onConfigureComment}
              currentUser={currentUser}
              cardNumber={cardNumber}
              onConfigurePinnedComment={onConfigurePinnedComment}
              cardId={cardId}
              selectedFilters={selectedFilters}
            />
          </div>
        </div>
      )}

      <InfoDialog
        isVisible={isInfoModalVisible}
        onClose={handleInfoModalClose}
        message={infoMessage}
      />

      {isCardModalVisible && (
        <CardModal
          visible={isCardModalVisible}
          onClose={handleCardModalClose}
          cardTitle={cardTitleWithLeastFilter}
          key={checkboxValues + fyProjectionCheckboxValues || varianceChartData}
          chartData={
            cardId === CARD_CONFIG.BRAND_YTD_ROY_CHART?.ChartLabel
              ? ytdRoyFilteredChartData?.length === 0
                ? chartData
                : ytdRoyFilteredChartData
              : cardId === 'fy-projection'
              ? fyProjChartFilteredData?.length === 0
                ? chartData
                : fyProjChartFilteredData
              : chartData
          }
          chartType={chartType}
          gridData={gridData}
          onConfigureComment={onConfigureComment}
          cardActions={cardActions}
          actionsAllowed={actionsAllowed}
          commentsData={commentsData}
          onSelectCardAction={onSelectCardAction}
          cardId={cardId}
          category={category}
          varianceChartData={varianceChartData}
          versionType={versionType}
          scatterPlotDatatoPlot={isEventChecked ? scatterPlotDatatoPlot : []}
          labelytd={labelytd}
          ytdRoyFilteredLabel={ytdRoyFilteredLabel}
          isLabelChecked={isLabelChecked}
          isViewExpanded={isViewExpanded}
          minMax={minMax}
          currentNetSales={currentNetSales}
          labelYTDRoYProjection={labelYTDRoYProjection}
          forecastLabel={forecastLabel}
          getClassName={getClassName}
        />
      )}
      <Modal
        title="Comment Details"
        open={expandCommentModal}
        onCancel={handleClose}
        width={600}
        footer={[
          <Button key="close" onClick={handleClose} type="link">
            {CLOSE}
          </Button>,
        ]}
      >
        <div>{priorityComment?.Comment}</div>
      </Modal>
      <Modal
        title={
          <>
            <i className={'icon icon__event_black'} />
            <span className={classes['event-modal-header']}>Event Details</span>
          </>
        }
        open={isEventDetailsModalVisible}
        onCancel={handleClose}
        width={600}
        footer={[
          <Button key="close" onClick={handleClose} type="link">
            {CLOSE}
          </Button>,
        ]}
      >
        <div className={classes['modal-content']}>
          <div>{eventInfo()}</div>
        </div>
      </Modal>
      <Comment
        title={TREND_FORECASTCHART_COMMENT_MODAL_TITLE}
        visible={isBannerCommentModalVisible}
        onClose={handleCommentBannerClose}
        commentsData={commentsData}
        currentUser={currentUser}
        onConfigureComment={onConfigureComment}
        onConfigurePinnedComment={onConfigurePinnedComment}
        cardNumber={SOPCARDCOMMENT_ID.FINANCIAL}
        selectedFilters={selectedFilters}
        cardId={cardId}
      />
    </div>
  );
};

export default Card;
