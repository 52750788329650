import { CARD_CONFIG } from '@utils/cardConfig';
import { SUB_TABNAMES_BPLAN } from '../../constants';
export const BusinessPlanConstant = {
  BUSINESS_PLAN_TAB: 'Business Plan',
  DISCLAIMER: 'RESTRICTED INSIDER INFORMATION:',
  EDIT_COMMENT: 'Edit Comment',
  REMAINING_CHAR: 'Remaining characters',
  DELETE_COMMENT: 'Delete Comment',
  BUSINESS_PLAN_PERFORMANCE: 'Business Plan Performance',
  DELETE_WARMING:
    'Are you sure you want to delete this comment? This action cannot be undone.',
  DELETE: 'Delete',
};

export const getBusinessPlanViewTypes = (
  showSummary,
  showDetails,
  viewType,
  showCardIds,
) => [
  ...(showSummary &&
  [
    CARD_CONFIG.BP_NETSALES_CHART.CardId,
    CARD_CONFIG.BP_NETSALES_CHARTGRID.CardId,
  ].some((id) => showCardIds.includes(id))
    ? [
        {
          value: 'summary',
          key: (
            <>
              <i
                className={`icon icon__${
                  viewType === 'summary' ? 'chart-white' : 'chart'
                }`}
              ></i>
              {SUB_TABNAMES_BPLAN.summary}
            </>
          ),
        },
      ]
    : []),
  ...(showDetails &&
  [CARD_CONFIG.BP_UNADJUSTED_GRID.CardId].some((id) => showCardIds.includes(id))
    ? [
        {
          value: 'details',
          key: (
            <>
              <i
                className={`icon icon__${
                  viewType === 'details' ? 'table-white' : 'table'
                }`}
              ></i>
              {SUB_TABNAMES_BPLAN.details}
            </>
          ),
        },
      ]
    : []),
];
