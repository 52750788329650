import classes from './Feedback.module.scss';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { addFeedback } from '@service/feedback.service';
import { toast } from 'react-toastify';
import DialogBox from '@elements/dialogbox/DialogBox';
import { getUserUnixId } from '@utils/getUser';
import {
  CANCELTEXT,
  CHARACTER_LIMIT_REACH_ERROR_TEXT,
  COMMENTFIELDTEXT,
  COMMENTS_TEXT,
  LEAVE_FEEDBACK,
  LEAVE_FEEDBACK_ERROR_MESSAGE,
  LEAVE_FEEDBACK_TEXT,
  LIMIT_EXCEEDED_TEXT,
  PORTFOLIO_COMMENT_LIMIT,
  SUBMITTEXT,
  VALUE600,
  SUCCESS,
  FEEDBACK_ADD_MESSAGE,
} from '@constant';
import { Notification } from '@elements/notification/Notification';

const Feedback = ({ show, onHide }) => {
  const [count, setCount] = useState(0);
  const [comment, setComment] = useState('');
  const [isLimitExceeded, setIsLimitExceeded] = useState(false);
  const unixId = getUserUnixId();

  useEffect(() => {
    reset();
  }, []);

  const handleSubmit = async () => {
    Notification(SUCCESS, FEEDBACK_ADD_MESSAGE);
    const payload = {
      unixId: unixId,
      comment: comment,
    };

    try {
      await addFeedback(payload);
      onHide();
      reset();
    } catch (error) {
      toast.error(LEAVE_FEEDBACK_ERROR_MESSAGE);
      console.log('error', error);
    }
  };

  const handleCommentChanged = (e) => {
    setComment(e?.target?.value);
    setCount(e?.target?.value?.length);
    setIsLimitExceeded(e?.target?.value?.length > PORTFOLIO_COMMENT_LIMIT);
  };

  const reset = () => {
    setComment('');
    setCount(0);
    setIsLimitExceeded(false);
  };

  const handleCancel = () => {
    reset();
    onHide();
  };

  const title = (
    <>
      <p
        className="title__large title__bold d-flex align-items-center"
        title={LEAVE_FEEDBACK}
      >
        <i className="icon icon__feedback-black"></i>
        {LEAVE_FEEDBACK}
      </p>
      <p className="text text__small" title="Sub title">
        {LEAVE_FEEDBACK_TEXT}
      </p>
    </>
  );

  const content = (
    <>
      <p className="text text__x-small mt-4 mb-1" title={COMMENTS_TEXT}>
        {COMMENTS_TEXT}
      </p>
      {isLimitExceeded && (
        <p
          className="text text__small text__red animate__animated animate__headShake"
          title={LIMIT_EXCEEDED_TEXT}
        >
          {CHARACTER_LIMIT_REACH_ERROR_TEXT}
        </p>
      )}
      <textarea
        className={`w-100 ${classes.comments}`}
        value={comment}
        onChange={(e) => handleCommentChanged(e)}
        title={COMMENTFIELDTEXT}
      />
      <p className={classes['text-align-end']}>
        Remaining characters: {PORTFOLIO_COMMENT_LIMIT - count}
      </p>
    </>
  );

  const footer = (
    <div className="d-flex justify-content-end w-100">
      <Button onClick={handleCancel}> {CANCELTEXT} </Button>
      <Button
        className="ms-2"
        type="primary"
        onClick={handleSubmit}
        disabled={isLimitExceeded || !comment}
      >
        {SUBMITTEXT}
      </Button>
    </div>
  );

  return (
    <DialogBox
      title={title}
      isModalOpen={show}
      okText={SUBMITTEXT}
      handleCancel={handleCancel}
      handleOk={handleSubmit}
      width={VALUE600}
      footer={footer}
    >
      {content}
    </DialogBox>
  );
};

export default Feedback;
