import { Row, Col } from 'react-bootstrap';
import LinkButton from '@elements/button/LinkButton';
import classes from './Header.module.scss';
import { useState } from 'react';
import Feedback from '@components/elements/feedback/Feedback';
import {
  LEAVE_FEEDBACK,
  SAI_PERFORMANCE_DASHBOARD_TEXT,
  SAI_PERFORMANCE_DASHBOARD_HOVER_TEXT,
  USER_GUIDE_URL,
} from '@constant';
import { Tooltip } from 'antd';

const Header = () => {
  const [showFeedback, setShowFeedback] = useState(false);
  const handleHideFeedback = () => setShowFeedback(false);
  const handleFeedbackClick = () => {
    setShowFeedback(!showFeedback);
  };

  const handleHelpIconClick = () => {
    window.open(USER_GUIDE_URL);
  };
  return (
    <>
      <header className={classes['gne-header']}>
        <Row className="w-100">
          <Col md={6} className={classes['content-wrap']}>
            <div className="gene-logo"></div>
            <div className="divider-vertical divider__white"></div>
            <span className="title title__medium">
              <Tooltip title={SAI_PERFORMANCE_DASHBOARD_HOVER_TEXT}>
                {SAI_PERFORMANCE_DASHBOARD_TEXT}
              </Tooltip>
            </span>
          </Col>
          <Col md={6} className={classes['header-cta']}>
            <LinkButton
              className={`links ${classes.links} ${classes.cta}`}
              onClick={handleFeedbackClick}
            >
              <i className="icon icon__feedback"></i>
              {LEAVE_FEEDBACK}
            </LinkButton>
            <LinkButton
              className={`links ${classes.links} ${classes.cta}`}
              to="/"
              target="_blank"
              onClick={handleHelpIconClick}
            >
              <i className="icon icon__help"></i> Help
            </LinkButton>
          </Col>
        </Row>
      </header>
      <Feedback show={showFeedback} onHide={handleHideFeedback} />
    </>
  );
};

export default Header;
