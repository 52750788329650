import { useEffect, useState } from 'react';
import { Modal, Spin, Col, Row } from 'antd';
import Chart from '../chart/Chart';
import classes from './CardModal.module.scss';
import { CARD_CONFIG } from '@utils/cardConfig';
import {
  LABELCOLORS,
  RIGHTSIDELABELCHARTS,
  VALUE20,
  VALUE250PX,
  VALUE300PX,
  VALUE400PX,
  VALUE500PX,
} from '@constant';

const CardModal = ({
  visible,
  onClose,
  cardTitle,
  chartData,
  chartType,
  gridData,
  category,
  cardId,
  varianceChartData,
  versionType,
  key,
  scatterPlotDatatoPlot,
  isLabelChecked,
  labelytd,
  isViewExpanded,
  ytdRoyFilteredLabel,
  minMax,
  currentNetSales,
  labelYTDRoYProjection,
  forecastLabel,
  getClassName,
}) => {
  const [waiter, setWaiter] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setWaiter(false);
    }, 1000);
  }, []);

  let chartStyle = {
    height:
      cardId === CARD_CONFIG.PORTFOLIO_YTD_FORECAST_ACTUAL_CHART.ChartLabel ||
      cardId === CARD_CONFIG.PORTFOLIO_YTD_PY_FORECAST_ACTUAL_CHART.ChartLabel
        ? VALUE250PX
        : window.innerHeight > 560
        ? VALUE500PX
        : VALUE400PX,
  };

  let barChartStyle = {
    height: VALUE300PX,
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      width={'90%'}
      height={'70%'}
      styles={{
        content: {
          marginTop: '-40px',
          display: 'flex',
          flexDirection: 'column',
        },
        body: {},
      }}
    >
      <div className={classes['card-modal-wrap']}>
        <div className={classes['align-wrap']}>
          <div className={classes['card-title']}>{cardTitle}</div>
          <div className={classes['content-wrap']}>
            {waiter ? (
              <div className={classes['content-center']}>
                <Spin></Spin>
              </div>
            ) : (
              <Row
                className={`${classes['display-flex']} ${classes['overflow-hidden']}`}
                gutter={[VALUE20]}
              >
                <Col
                  span={
                    isLabelChecked
                      ? RIGHTSIDELABELCHARTS.includes(cardId)
                        ? 21
                        : 24
                      : 24
                  }
                >
                  <Chart
                    expend
                    chartType={chartType}
                    key={key}
                    chartData={chartData}
                    style={chartStyle}
                    category={category}
                    showEndLabel={isLabelChecked}
                    growthPercentage={gridData}
                    scatterPlotDatatoPlot={scatterPlotDatatoPlot}
                    isViewExpanded={isViewExpanded}
                    minMax={minMax}
                    currentNetSales={currentNetSales}
                  />
                </Col>
                <Col
                  span={
                    isLabelChecked
                      ? RIGHTSIDELABELCHARTS.includes(cardId)
                        ? 3
                        : 0
                      : 0
                  }
                >
                  {cardId ===
                    CARD_CONFIG?.PORTFOLIO_FY_PROJECTION_CHART?.ChartLabel &&
                    isLabelChecked &&
                    chartData?.length > 0 && (
                      <div className={classes['ytd-label']}>
                        {labelYTDRoYProjection?.map((item, index) => {
                          const color = item?.linsStyle;

                          return (
                            <span
                              key={index}
                              className={`${classes['ytd-label-text']} ${
                                classes[LABELCOLORS[color.toLowerCase()]]
                              }`}
                            >
                              {item?.data?.startsWith('T0')
                                ? `${versionType}:${
                                    item?.data?.includes(':')
                                      ? item?.data?.split(':')[1]
                                      : ''
                                  }`
                                : item?.data}
                            </span>
                          );
                        })}
                      </div>
                    )}

                  {cardId === CARD_CONFIG?.BRAND_YTD_ROY_CHART?.ChartLabel &&
                    isLabelChecked &&
                    chartData?.length > 0 && (
                      <div className={classes['ytd-label']}>
                        {(ytdRoyFilteredLabel?.length === 0
                          ? labelytd
                          : ytdRoyFilteredLabel
                        )?.map((item, index) => {
                          const color = item?.linsStyle;

                          return (
                            <span
                              key={index}
                              className={`${classes['ytd-label-text']} ${
                                classes[LABELCOLORS[color.toLowerCase()]]
                              }`}
                            >
                              {item?.data?.startsWith('T0')
                                ? `${versionType}:${
                                    item?.data?.includes(':')
                                      ? item?.data?.split(':')[1]
                                      : ''
                                  }`
                                : item?.data}
                            </span>
                          );
                        })}
                      </div>
                    )}

                  {cardId ===
                    CARD_CONFIG?.SOP_YEAR_FORECAST_CHART?.ChartLabel &&
                    isLabelChecked &&
                    forecastLabel?.length > 0 && (
                      <div className={`${classes['ytd-label']}`}>
                        {forecastLabel?.map((item, index) => {
                          const text =
                            index == 0
                              ? `YTD S&OP vs. ${versionType} Difference`
                              : item.name;
                          return (
                            <span
                              key={index}
                              className={`${classes['ytd-label-text']}`}
                            >
                              <span
                                className={`${getClassName(
                                  item?.data ? item?.data : '-',
                                )}`}
                              >
                                {item.data}
                              </span>
                              {' ' + text}
                            </span>
                          );
                        })}
                      </div>
                    )}
                </Col>
              </Row>
            )}
          </div>
          {(cardId === 'ytd-sales-v-forec' || cardId === 'ytd-sales-v-py') && (
            <div>
              <p className={classes['ytd-sales-label']}>
                {cardId === 'ytd-sales-v-forec'
                  ? `Variance Drivers (${versionType})`
                  : 'Variance Drivers (PY YTD)'}
              </p>

              <div className={classes['bar-chart-container']}>
                {waiter ? (
                  <div className={classes['content-center']}>
                    <Spin></Spin>
                  </div>
                ) : (
                  <Chart
                    expend
                    chartData={varianceChartData}
                    chartType={'bar'}
                    style={barChartStyle}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CardModal;
