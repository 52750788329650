import React, { useState, useEffect } from 'react';
import {
  Switch,
  Tooltip,
  Select,
  Input,
  Modal,
  Button,
  Skeleton,
  Row,
  Col,
} from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import AnchorDropdown from '@elements/dropdown/AnchorDropdown';
import Chart from '@elements/chart/Chart';
import Grid from '@elements/grid/Grid';
import RadioGroup from '@elements/radio/RadioGroup';
import SelectMenu from '@elements/selectmenu/SelectMenu';
import ViewChartActions from '@elements/chart/actions/ViewChartActions';
import {
  CARD_MENU_MYVIEW,
  YTD_ROY_DATASELECT_OPTIONS,
  DEFAULT_DEMAND_DATA_OPTIONS,
  FY_PROJECTION_DATASELECT_OPTIONS,
  FYPROJECTION_DEFAULT_DATA_OPTIONS,
  AGG_FORECAST_VIEW_TYPE,
  LEGEND_AGG_FORECAST,
  SHORT_TERM_FORECAST_VIEW_TYPE,
  LEGEND_SHORT_FORECAST,
  LEGENDDATA,
  LABEL,
  CONFIGURE,
  PERFORMANCE_CATEGORY,
  INFO,
  EXPAND,
  REMOVE,
  DUPLICATE,
  CLOSE,
  VALUE600,
  YTDRoYPROJECTIONSkpilabels,
  LABELCOLORS,
  TABNAMES_MyView,
  RIGHTSIDELABELCHARTS,
} from '@constant';
import { chartActionsAccess, ytdChartGridCols } from '@mockdata/brandChartData';
import CardModal from '@elements/Modal/CardModal';
import InfoDialog from '@elements/Modal/InfoDialog';
import classes from './ViewTile.module.scss';
import { CARD_CONFIG } from '@utils/cardConfig';
import CommentInViewTile from './CommentInViewTile';

const ViewTile = ({
  cardTitle,
  chartData,
  griCols,
  cardId,
  gridData,
  onConfigureComment,
  cardWidth,
  chartHeight,
  chartType,
  markerHigh,
  markerLow,
  infoMessage,
  showMarker,
  cardComments,
  cardHeight,
  handleChange,
  handleForecastType,
  onSelectOptionChange,
  category,
  minMax,
  chartText,
  selectedViewType,
  varianceChartData,
  varianceGridData,
  versionType,
  forecastLabel,
  dataFilterOptions,
  eventFilterOptions,
  onSelectChangesNetSales,
  selectedFilters,
  selectedDimension,
  onRemoveFromView,
  onDuplicateCard,
  currentCardID,
  handleEditCardTitle,
  onEditCard,
  tab,
  scatterPlotDatatoPlot,
  currentNetSales,
  onSelectChangesEvent,
  labelytd,
  franchise,
  businessUnit,
  brand,
  sku,
  viewname,
  eventsSelected,
  checkboxValuesEvent,
  showComment,
  comment,
  metricOptionsList,
  isLoading,
  viewType,
  onSelectViewType,
  sopPreviousOrFinanceHasComment,
}) => {
  const actionsAllowed = chartActionsAccess?.filter(
    (item) => item?.chartId === cardId,
  )[0];
  const commentsData = cardComments?.filter(
    (item) => item?.ChartId === actionsAllowed?.chartKey,
  );

  const location = useLocation();
  const [cardActions, setCardActions] = useState({
    chartView: true,
    gridView: false,
    commentView: false,
  });
  const [isLabelChecked, setIsLabelChecked] = useState(true);
  const [isEventChecked, setIsEventChecked] = useState(true);
  const [checkboxValues, setCheckBoxValues] = useState(
    DEFAULT_DEMAND_DATA_OPTIONS,
  );
  const [netSalesCheckboxValuesData, setNetSalesCheckBoxValuesData] =
    useState(dataFilterOptions);
  const [eventCheckboxValuesData, setEventCheckBoxValuesData] =
    useState(eventFilterOptions);
  const [fyProjectionCheckboxValues, setFyProjectionCheckboxValues] = useState(
    FYPROJECTION_DEFAULT_DATA_OPTIONS,
  );
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [isCardModalVisible, setIsCardModalVisible] = useState(false);
  const [ytdRoyFilteredChartData, setYtdRoyFilteredChartData] = useState(
    cardId === CARD_CONFIG.BRAND_YTD_ROY_CHART.ChartLabel ? chartData : [],
  );
  const [fyProjChartFilteredData, setFyProjChartFilteredData] = useState(
    cardId === CARD_CONFIG.PORTFOLIO_FY_PROJECTION_CHART.ChartLabel
      ? chartData
      : [],
  );
  // eslint-disable-next-line no-unused-vars
  const [expandCommentModal, setExpandCommentModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [updatedCardTitle, setUpdatedCardTitle] = useState('');
  const [initialCardTitle, setInitialCardTitle] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [chartKey, setChartKey] = useState(0);
  const [ytdRoyFilteredLabel, setYtdRoyFilteredLabel] = useState(
    cardId === CARD_CONFIG.BRAND_YTD_ROY_CHART.ChartLabel ? labelytd : [],
  );
  const [isViewExpanded, setIsViewExpanded] = useState(false);

  useEffect(() => {
    setCardActions({
      chartView: true,
      gridView: false,
      commentView: false,
    });
    getYtdRoyChartFilteredData(checkboxValues);
    getFyProjectionFilteredChartData(fyProjectionCheckboxValues);
  }, [location, selectedDimension]);

  useEffect(() => {
    getFyProjectionFilteredChartData(fyProjectionCheckboxValues);
    getYtdRoyChartFilteredData(checkboxValues);
    // setChartKey((prev) => prev + 1);
    // getNetSalesFilteredChartData(netSalesCheckboxValuesData);
  }, [
    fyProjectionCheckboxValues,
    checkboxValues,
    netSalesCheckboxValuesData,
    selectedFilters,
    selectedDimension,
    chartData,
  ]);

  const onSelectCardAction = (action) => {
    let chart = false;
    let grid = false;
    let comment = false;
    if (action === 'chart') {
      chart = true;
    } else if (action === 'grid') {
      grid = true;
    } else {
      // eslint-disable-next-line no-unused-vars
      comment = true;
    }
    setCardActions({
      chartView: chart,
      gridView: grid,
      commentView: false,
    });
  };

  useEffect(() => {
    setNetSalesCheckBoxValuesData(dataFilterOptions);
  }, [dataFilterOptions, selectedFilters?.Brand]);

  useEffect(() => {
    if (checkboxValuesEvent) {
      setEventCheckBoxValuesData(checkboxValuesEvent);
    } else {
      setEventCheckBoxValuesData(eventFilterOptions);
    }
  }, [eventFilterOptions, checkboxValuesEvent]);

  const onSelectMenu = (e) => {
    const selectedKey = e.key;
    if (selectedKey === INFO) {
      setIsInfoModalVisible(true);
    } else if (selectedKey === EXPAND) {
      setIsCardModalVisible(true);
      setIsViewExpanded(true);
    } else if (selectedKey === REMOVE) {
      onRemoveFromView();
    } else if (selectedKey === DUPLICATE) {
      onDuplicateCard(currentCardID);
    }
  };

  const handleCardModalClose = () => {
    setIsCardModalVisible(false);
    setIsViewExpanded(false);
  };

  const handleInfoModalClose = () => {
    setIsInfoModalVisible(false);
  };

  const onChangeLabelSwitch = () => {
    setIsLabelChecked(!isLabelChecked);
  };

  const onChangeEventSwitch = () => {
    setIsEventChecked(!isEventChecked);
  };

  const getYtdRoyChartFilteredData = (updatedSelection) => {
    const updatedData = chartData?.filter(
      (item) =>
        updatedSelection?.includes(item?.name) ||
        item?.name === versionType ||
        item?.name === 'High' ||
        item?.name === 'Low',
    );
    setYtdRoyFilteredChartData(updatedData);
  };

  const getFyProjectionFilteredChartData = (updatedSelection) => {
    const updatedData = chartData?.filter(
      (item) =>
        updatedSelection?.includes(item?.name) || item?.name === versionType,
    );

    setFyProjChartFilteredData(updatedData);
  };

  const getNetSalesFilteredChartData = (updatedSelection) => {
    const updatedData = chartData?.filter(
      (item) =>
        updatedSelection?.includes(item?.name) || item?.name === versionType,
    );
    setFyProjChartFilteredData(updatedData); // Update chartdata here for net sales
  };

  const onSelectChange = (key, e) => {
    const updatedSelection = e.checked
      ? [...checkboxValues, key]
      : checkboxValues?.filter((item) => item !== key);
    setCheckBoxValues(updatedSelection);
    getYtdRoyChartFilteredData(updatedSelection);
    getYtdRoyLabelFilterted(updatedSelection);
  };

  const getYtdRoyLabelFilterted = (updatedSelection) => {
    const updatedData = labelytd?.filter((item) => {
      return (
        updatedSelection?.includes(item?.name) ||
        (item?.name && item?.name.includes('High')) ||
        (item?.name && item?.name.includes('Low')) ||
        (item?.name && item?.name.includes('Squad Plan')) ||
        (item?.name && item?.name.includes('T0'))
      );
    });

    setYtdRoyFilteredLabel(updatedData);
  };

  const onSelectNetSalesDataOption = (key, e) => {
    const updatedSelection = e.checked
      ? [...netSalesCheckboxValuesData, key]
      : netSalesCheckboxValuesData?.filter((item) => item !== key);
    setNetSalesCheckBoxValuesData(updatedSelection);
    getNetSalesFilteredChartData(updatedSelection);
    onSelectChangesNetSales(updatedSelection);
  };

  const onSelectFyDataOptions = (key, e) => {
    const updatedSelection = e.checked
      ? [...fyProjectionCheckboxValues, key]
      : fyProjectionCheckboxValues?.filter((item) => item !== key);
    setFyProjectionCheckboxValues(updatedSelection);
    getFyProjectionFilteredChartData(updatedSelection);
  };

  const getClassName = (value) => {
    if (value.includes('%')) {
      const numericValue = parseFloat(value);

      if (numericValue > 0) {
        return classes.positive;
      } else {
        return classes.negative;
      }
    }
  };

  const getClassNameAggForecast = (value) => {
    if (value.includes('%')) {
      const numericValue = parseFloat(value);

      if (numericValue > LEGENDDATA.high) {
        return classes.green;
      } else if (
        numericValue >= LEGENDDATA.mediumlow &&
        numericValue < LEGENDDATA.mediumhigh
      ) {
        return classes.yellow;
      } else {
        return classes.red;
      }
    }
  };

  useEffect(() => {
    setInitialCardTitle(cardTitle);
    getCardTitle();
  }, [cardTitle]);

  // const getCardTitle = () => {
  //   if (initialCardTitle?.length > 20) {
  //     return initialCardTitle?.length > 20 ? (
  //       <Tooltip title={initialCardTitle}>
  //         {`${initialCardTitle.substring(0, 20)}...`}
  //       </Tooltip>
  //     ) : (
  //       { initialCardTitle }
  //     );
  //     // eslint-disable-next-line no-dupe-else-if
  //   } else if (initialCardTitle?.length > 20) {
  //     return initialCardTitle?.length > 20 ? (
  //       <Tooltip title={initialCardTitle}>{`${initialCardTitle.substring(
  //         0,
  //         20,
  //       )}...`}</Tooltip>
  //     ) : (
  //       initialCardTitle
  //     );
  //   } else return initialCardTitle;
  // };

  const getCardTitle = () => {
    return (
      <Tooltip title={initialCardTitle}>
        <span className={classes['txt-overflow-css']}>
          {' '}
          {`${initialCardTitle}`}{' '}
        </span>
      </Tooltip>
    );
  };

  const netSalesOptions = dataFilterOptions?.map((item) => ({
    value: item,
    label: item,
  }));
  const eventOptions = eventFilterOptions?.map((item) => ({
    value: item,
    label: item,
  }));

  const onEditCardTitle = () => {
    setIsEditMode(true);
    setUpdatedCardTitle(cardTitle);
  };

  const onChangeEditMode = () => {
    setIsEditMode(false);
    if (updatedCardTitle !== initialCardTitle) {
      setInitialCardTitle(updatedCardTitle);

      handleEditCardTitle(currentCardID, updatedCardTitle);
    }
  };

  const onChangeCardTitleText = (e) => {
    setUpdatedCardTitle(e?.target?.value);
  };

  const onSelectNetSalesEventOption = (key, e) => {
    const updatedSelection = e.checked
      ? [...eventCheckboxValuesData, key]
      : eventCheckboxValuesData?.filter((item) => item !== key);
    setEventCheckBoxValuesData(updatedSelection);
    // getNetSalesFilteredChartData(updatedSelection);
    onSelectChangesEvent(updatedSelection);
  };

  useEffect(() => {
    if (cardId === CARD_CONFIG.BRAND_YTD_ROY_CHART.ChartLabel) {
      setYtdRoyFilteredLabel(labelytd);
    }
  }, [labelytd]);

  const getTooltipContent = () => {
    const renderValueOrAll = (value) =>
      value?.length > 0 ? value?.join(', ') : 'ALL';

    const commonFields = (
      <>
        <span>• Business Unit: {businessUnit}</span> <br />
        {viewname !== 'Business Performance' && (
          <>
            <span>• Version Type: {versionType}</span>
            <br />{' '}
          </>
        )}
        <span>• Squad: {renderValueOrAll(franchise)}</span> <br />
      </>
    );

    const brandField = (
      <>
        <span>• Brand: {renderValueOrAll(brand)}</span> <br />
      </>
    );

    const skuField = (
      <>
        <span>• SKU: {renderValueOrAll(sku)}</span> <br />
      </>
    );

    if (viewname === TABNAMES_MyView.portfolio) {
      return commonFields;
    }

    if (viewname === TABNAMES_MyView.brand) {
      return (
        <>
          {commonFields}
          {brandField}
        </>
      );
    }

    if (viewname === TABNAMES_MyView.sop) {
      return (
        <>
          {commonFields}
          {brandField}
          {skuField}
        </>
      );
    }

    if (viewname === TABNAMES_MyView.bplan) {
      return (
        <>
          {commonFields}
          {brandField}
        </>
      );
    }

    return (
      <>
        {commonFields}
        {brandField}
        {skuField}
      </>
    );
  };
  if (isLoading) {
    return (
      <div className={classes['card-loader-wrap']}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    );
  }

  const labelYTDRoYProjection = [
    {
      data: `${YTDRoYPROJECTIONSkpilabels?.YTDAvg} : $${chartText?.ytdAvg}`,
      linsStyle: YTDRoYPROJECTIONSkpilabels?.YTDAvgLabelStyle,
    },
    {
      data: `${versionType} : ${chartText?.to}`,
      linsStyle: YTDRoYPROJECTIONSkpilabels?.T0LabelStyle,
    },
    {
      data: `${YTDRoYPROJECTIONSkpilabels?.Trend} : ${chartText?.trend}`,
      linsStyle: YTDRoYPROJECTIONSkpilabels?.TrendLabelStyle,
    },
    // TODO: Don't remove this comment code, We need in future.
    // {
    //   data: `${YTDRoYPROJECTIONSkpilabels?.Last12wks} : ${
    //     chartText?.twelveWeek
    //       ? `${chartText?.twelveWeek} vs ${versionType}`
    //       : '-'
    //   }`,
    //   linsStyle: YTDRoYPROJECTIONSkpilabels?.Last12wksLabelStyle,
    // },
  ];

  const handleClose = () => {
    setExpandCommentModal(false);
  };
  const onExpandCommentDesc = () => {
    setExpandCommentModal(true);
  };

  return (
    <div
      className={classes['card-wrap']}
      style={{
        width: cardWidth || '49%',
        minHeight: cardHeight,
        paddingBottom: '15px',
      }}
    >
      <div className={classes['align-wrap']}>
        <div
          className={
            isEditMode
              ? `${classes['card-title-myview']} ${classes['card-title-myview-edit']}`
              : actionsAllowed?.labels
              ? `${classes['card-title-myview']} ${classes['card-title-myview-label']}`
              : classes['card-title-myview']
          }
        >
          {tab === PERFORMANCE_CATEGORY.MYVIEW && (
            <div
              className={
                isEditMode
                  ? `${classes['card-title']} ${classes['card-title-edit']}`
                  : actionsAllowed?.labels
                  ? `${classes['card-title']} ${classes['card-title-label']}`
                  : classes['card-title']
              }
            >
              <i className={'icon icon__myView_Drag'} />
              {isEditMode ? (
                <Input
                  value={updatedCardTitle}
                  onChange={onChangeCardTitleText}
                  className={classes['input-wrap']}
                  addonAfter={
                    <span
                      style={updatedCardTitle?.trim() ? {} : { cursor: 'not-allowed' }}
                    >
                      <CheckOutlined
                        style={
                          updatedCardTitle?.trim()
                            ? {}
                            : { pointerEvents: 'none', opacity: '0.4' }
                        }
                        onClick={onChangeEditMode}
                      />
                    </span>
                  }
                />
              ) : (
                getCardTitle()
              )}
            </div>
          )}

          {chartData && (
            <div className={classes['chart-action']}>
              <ViewChartActions
                onSelectView={(view) => onSelectCardAction(view)}
                cardActions={cardActions}
                actionsAllowed={actionsAllowed}
                isEditMode={isEditMode}
                onEditCardTitle={onEditCardTitle}
                tab={tab}
              />
              {tab !== CONFIGURE && (
                <div style={{ paddingTop: 14 }}>
                  <Tooltip title="Edit Card">
                    <i
                      className={`icon icon__configureEditTile`}
                      onClick={() => onEditCard(currentCardID)}
                    ></i>
                  </Tooltip>
                  <Tooltip title={getTooltipContent()}>
                    <i className={`icon icon__filter_blue`}></i>
                  </Tooltip>
                </div>
              )}
            </div>
          )}
        </div>
        <div className={classes['dropdown-wrap']}>
          {cardId === CARD_CONFIG.BRAND_YTD_ROY_CHART.ChartLabel && (
            <>
              <div className={classes['radio-wrap']}>
                <div className={classes['radio-text']}>Data</div>
                <SelectMenu
                  menuOptions={YTD_ROY_DATASELECT_OPTIONS}
                  selectStyle={{ width: 200 }}
                  onSelectChange={onSelectChange}
                  checkboxValues={checkboxValues}
                />
              </div>
            </>
          )}

          {cardId === CARD_CONFIG.BP_NETSALES_CHART.ChartLabel && (
            <>
              <div className={classes['radio-wrap']}>
                <div className={classes['radio-text']}>Data</div>
                <div className={classes['data-select-dd']}>
                  <SelectMenu
                    menuOptions={netSalesOptions}
                    selectStyle={{ width: '100%', height: 30 }}
                    onSelectChange={onSelectNetSalesDataOption}
                    checkboxValues={netSalesCheckboxValuesData}
                  />
                </div>
              </div>
              <div className={classes['radio-wrap']}>
                <div
                  className={
                    tab == CONFIGURE
                      ? classes['radio-text']
                      : classes['radio-text-localFilter']
                  }
                >
                  Events:
                </div>
                {tab == CONFIGURE ? (
                  <SelectMenu
                    menuOptions={eventOptions}
                    selectStyle={{ width: 170, height: 30 }}
                    onSelectChange={onSelectNetSalesEventOption}
                    checkboxValues={eventCheckboxValuesData}
                  />
                ) : (
                  <Tooltip title={eventsSelected}>
                    <div
                      className={
                        classes['ellipsis-container-MyView-viewTile-netsales']
                      }
                    >
                      {eventsSelected}
                    </div>
                  </Tooltip>
                )}
              </div>
              <div className={classes['label-wrap']}>
                <div className={classes['label-text']}>Events</div>
                <Switch
                  checkedChildren={'YES'}
                  unCheckedChildren={'NO'}
                  defaultChecked
                  checked={isEventChecked}
                  onChange={onChangeEventSwitch}
                />
              </div>
            </>
          )}
          {cardId === CARD_CONFIG.PORTFOLIO_FY_PROJECTION_CHART.ChartLabel && (
            <>
              <div className={classes['radio-wrap']}>
                <div className={classes['radio-text']}>Data</div>
                <SelectMenu
                  menuOptions={FY_PROJECTION_DATASELECT_OPTIONS}
                  selectStyle={{ width: 200 }}
                  onSelectChange={onSelectFyDataOptions}
                  checkboxValues={fyProjectionCheckboxValues}
                />
              </div>
            </>
          )}
          {cardId === CARD_CONFIG.SOP_YEAR_FORECAST_CHART.ChartLabel && (
            <>
              <div
                className={
                  tab === CONFIGURE
                    ? classes['radio-wrap']
                    : classes['radio-wrap-localFilter']
                }
              >
                <div
                  className={
                    tab === CONFIGURE
                      ? classes['radio-text']
                      : classes['radio-text-localFilter']
                  }
                >
                  Metrics:{' '}
                </div>
                {tab === CONFIGURE ? (
                  <Select
                    value={currentNetSales || metricOptionsList[0]}
                    style={{ width: 200, height: 30 }}
                    onChange={onSelectOptionChange}
                    options={metricOptionsList}
                  />
                ) : (
                  <div className={classes['radio-text-localFilter']}>
                    {` ${
                      currentNetSales
                        ? currentNetSales
                        : metricOptionsList[0]?.value
                    }`}
                  </div>
                )}
              </div>
            </>
          )}

          {actionsAllowed?.labels && (
            <div className={classes['label-wrap']}>
              <div className={classes['label-text']}>{LABEL}</div>
              <Switch
                checkedChildren={'YES'}
                unCheckedChildren={'NO'}
                defaultChecked
                checked={isLabelChecked}
                onChange={onChangeLabelSwitch}
              />
            </div>
          )}
          <div className={classes['anchor-dropdown']}>
            {tab === PERFORMANCE_CATEGORY.MYVIEW && (
              <div
                onClick={onSelectMenu}
                className={classes['anchor-dropdown']}
              >
                <AnchorDropdown
                  items={CARD_MENU_MYVIEW}
                  onClick={onSelectMenu}
                  placement={'bottomLeft'}
                >
                  <i className={'icon icon__card-options'}></i>
                </AnchorDropdown>
              </div>
            )}
          </div>
        </div>
      </div>
      {cardId === CARD_CONFIG.SOP_AGGFORECAST_CHART.ChartLabel && (
        <div className={classes['view-agg-forecast']}>
          <div className={classes['view-agg-forecast-radio']}>
            <RadioGroup
              defaultValue={'aggregate'}
              items={AGG_FORECAST_VIEW_TYPE}
              onChange={handleForecastType}
              value={selectedViewType}
            />
          </div>
        </div>
      )}
      {cardId === CARD_CONFIG.SOP_AGGFORECAST_CHART.ChartLabel && (
        <div className={classes['legend-container']}>
          <div className={classes['info-label-wrap-sop']}>
            <div className={classes['info-label-sop']}>
              <div>
                <span
                  title={`${
                    forecastLabel ? forecastLabel : '-'
                  } Forecast Accuracy`}
                >
                  <span
                    className={`${
                      classes['title-weekly']
                    } ${getClassNameAggForecast(
                      forecastLabel ? forecastLabel : '-',
                    )}`}
                  >
                    {forecastLabel ? forecastLabel : '-'}
                  </span>{' '}
                  Forecast Accuracy
                </span>
              </div>
            </div>
          </div>
          <div className={classes['legend']}>
            <span className={classes.greenLegend}></span>{' '}
            <span>{LEGEND_AGG_FORECAST.GREEN}</span>
            <span className={classes.redLegend}></span>{' '}
            <span>{LEGEND_AGG_FORECAST.RED}</span>
            <span className={classes.blackLegend}></span>{' '}
            <span>{LEGEND_AGG_FORECAST.BLACK}</span>
          </div>
        </div>
      )}
      {cardId === CARD_CONFIG.SOP_SHORTTERM_BIAS_26WKS_CHART.ChartLabel && (
        <div className={classes['view-short-forecast']}>
          <div className={classes['view-short-forecast-radio']}>
            <RadioGroup
              defaultValue={'4Wk'}
              items={SHORT_TERM_FORECAST_VIEW_TYPE}
              onChange={handleChange}
              value={selectedViewType}
            />
          </div>
        </div>
      )}
      {cardId === CARD_CONFIG.SOP_SHORTTERM_BIAS_26WKS_CHART.ChartLabel && (
        <div className={classes['legend-container-square']}>
          <div className={classes['legend-square']}>
            <span className={classes.redLegendSquare}></span>{' '}
            <span>{LEGEND_SHORT_FORECAST.RED}</span>
            <span className={classes.greenLegendSquare}></span>{' '}
            <span>{LEGEND_SHORT_FORECAST.GREEN}</span>
          </div>
        </div>
      )}

      {cardActions?.chartView ? (
        <>
          <Row align="middle">
            <Col
              span={
                isLabelChecked
                  ? RIGHTSIDELABELCHARTS.includes(cardId)
                    ? 20
                    : 24
                  : 24
              }
            >
              <Chart
                chartKey={chartKey}
                chartType={chartType}
                chartData={
                  cardId === CARD_CONFIG.BRAND_YTD_ROY_CHART.ChartLabel
                    ? ytdRoyFilteredChartData?.length === 0
                      ? chartData
                      : ytdRoyFilteredChartData
                    : cardId ===
                      CARD_CONFIG.PORTFOLIO_FY_PROJECTION_CHART.ChartLabel
                    ? fyProjChartFilteredData?.length === 0
                      ? chartData
                      : fyProjChartFilteredData
                    : chartData
                }
                showEndLabel={isLabelChecked}
                markerLow={markerLow}
                markerHigh={markerHigh}
                showMarker={showMarker}
                category={category}
                minMax={minMax}
                selectedViewType={selectedViewType}
                growthPercentage={gridData}
                currentNetSales={currentNetSales}
                style={{
                  height: chartHeight || 300,
                  margin:
                    cardId === CARD_CONFIG.BRAND_YOY_SALES_CHART.ChartLabel ||
                    cardId ===
                      CARD_CONFIG.SOP_SHORTTERM_BIAS_26WKS_CHART.ChartLabel ||
                    cardId === CARD_CONFIG.SOP_AGGFORECAST_CHART.ChartLabel
                      ? '0px 25px'
                      : '0px',
                }}
                scatterPlotDatatoPlot={
                  isEventChecked ? scatterPlotDatatoPlot : []
                }
                showscatter={isEventChecked}
              />
            </Col>
            <Col
              span={
                isLabelChecked
                  ? RIGHTSIDELABELCHARTS.includes(cardId)
                    ? 4
                    : 0
                  : 0
              }
            >
              {cardId === CARD_CONFIG.BRAND_YTD_ROY_CHART.ChartLabel &&
                isLabelChecked &&
                chartData?.length > 0 && (
                  <div className={classes['ytd-label']}>
                    {(ytdRoyFilteredLabel?.length === 0
                      ? labelytd
                      : ytdRoyFilteredLabel
                    )?.map((item, index) => {
                      const color = item?.linsStyle;

                      return (
                        <span
                          key={index}
                          className={`${classes['ytd-label-text']} ${
                            classes[LABELCOLORS[color.toLowerCase()]]
                          }`}
                        >
                          {item?.data?.startsWith('T0')
                            ? `${versionType}:${
                                item?.data?.includes(':')
                                  ? item?.data?.split(':')[1]
                                  : ''
                              }`
                            : item?.data}{' '}
                        </span>
                      );
                    })}
                  </div>
                )}

              {cardId ===
                CARD_CONFIG?.PORTFOLIO_FY_PROJECTION_CHART?.ChartLabel &&
                isLabelChecked &&
                chartData?.length > 0 && (
                  <div
                    className={`${classes['ytd-label']} ${classes['label-wrapper']}`}
                  >
                    {labelYTDRoYProjection?.map((item, index) => {
                      const color = item?.linsStyle;

                      return (
                        <span
                          key={index}
                          className={`${classes['ytd-label-text']} ${
                            classes[LABELCOLORS[color.toLowerCase()]]
                          }`}
                        >
                          {item?.data?.startsWith('T0')
                            ? `${versionType}:${
                                item?.data?.includes(':')
                                  ? item?.data?.split(':')[1]
                                  : ''
                              }`
                            : item?.data}{' '}
                        </span>
                      );
                    })}
                  </div>
                )}

              {cardId === CARD_CONFIG?.SOP_YEAR_FORECAST_CHART?.ChartLabel &&
                isLabelChecked &&
                forecastLabel?.kpis?.length > 0 && (
                  <div
                    className={`${classes['ytd-label']} ${classes['label-wrapper-year-forecast']}`}
                  >
                    {forecastLabel?.kpis?.map((item, index) => {
                      const text =
                        index == 0
                          ? `YTD S&OP vs. ${versionType} Difference`
                          : item.name;
                      return (
                        <span
                          key={index}
                          className={`${classes['ytd-label-text']}`}
                        >
                          <span
                            className={`${getClassName(
                              item?.data ? item?.data : '-',
                            )}`}
                          >
                            {item.data}
                          </span>
                          {' ' + text}
                        </span>
                      );
                    })}
                  </div>
                )}
            </Col>
          </Row>

          {(cardId === CARD_CONFIG.PORTFOLIO_YTD_VARIANCE_CHART.ChartLabel ||
            cardId ===
              CARD_CONFIG.PORTFOLIO_YTD_PY_VARIANCE_CHART.ChartLabel) && (
            <div>
              <p className={classes['card-title-portfolio']}>
                {`Variance Drivers ${
                  cardId === CARD_CONFIG.PORTFOLIO_YTD_VARIANCE_CHART.ChartLabel
                    ? `(${versionType})`
                    : '(PY YTD)'
                }`}
              </p>
              <Chart
                chartData={varianceChartData}
                chartType={'bar'}
                chartWidth={'408px'}
                chartHeight={300}
                style={{
                  margin:
                    cardId === CARD_CONFIG.BRAND_YOY_SALES_CHART.ChartLabel
                      ? '0px 10px'
                      : '0px',
                }}
              />
            </div>
          )}

          {cardId === CARD_CONFIG.BRAND_YOY_SALES_CHART.ChartLabel && (
            <div className={classes['yoy-sales-reports-container']}>
              <Grid
                columns={griCols}
                dataSource={gridData?.filter((item) => {
                  return (
                    item?.title !== 'Net Sales' && item?.title !== 'Gross Sales'
                  );
                })}
              />
            </div>
          )}

          {cardId === CARD_CONFIG.BRAND_YOY_OUTS_GROWTH_CHART.ChartLabel && (
            <div className={classes['yoy-growth-reports-container']}>
              <Grid columns={griCols} dataSource={gridData} />
            </div>
          )}
        </>
      ) : (
        <div
          className={classes['grid-wrap']}
          style={{ height: chartHeight || 300 }}
        >
          <Grid
            dataSource={gridData}
            columns={griCols}
            pagination={gridData?.length > 10}
            chartHeight={chartHeight}
            scroll={{
              y:
                cardId ===
                  CARD_CONFIG.SOP_SHORTTERM_BIAS_26WKS_CHART.ChartLabel ||
                cardId === CARD_CONFIG.SOP_AGGFORECAST_CHART.ChartLabel
                  ? chartHeight > 370
                    ? 400
                    : undefined
                  : chartHeight > 300
                  ? 320
                  : undefined,
            }}
          />
          {(cardId === CARD_CONFIG.PORTFOLIO_YTD_VARIANCE_CHART.ChartLabel ||
            cardId ===
              CARD_CONFIG.PORTFOLIO_YTD_PY_VARIANCE_CHART.ChartLabel) && (
            <div className={classes['card-grid-portfolio']}>
              <div className={classes['card-subtitle']}>
                {cardId === CARD_CONFIG.PORTFOLIO_YTD_VARIANCE_CHART.ChartLabel
                  ? `Variance Drivers (${versionType})`
                  : 'Variance Drivers (PY YTD)'}
              </div>
              <Grid
                dataSource={varianceGridData}
                columns={ytdChartGridCols}
                pagination={false}
              />
            </div>
          )}
        </div>
      )}

      <CommentInViewTile
        showComment={showComment}
        comment={comment}
        cardId={cardId}
        onSelectViewType={onSelectViewType}
        viewType={viewType}
        onExpandCommentDesc={onExpandCommentDesc}
        sopPreviousOrFinanceHasComment={sopPreviousOrFinanceHasComment}
      />

      <InfoDialog
        isVisible={isInfoModalVisible}
        onClose={handleInfoModalClose}
        message={infoMessage}
      />
      {isCardModalVisible && (
        <CardModal
          visible={isCardModalVisible}
          onClose={handleCardModalClose}
          cardTitle={cardTitle}
          key={checkboxValues + fyProjectionCheckboxValues || varianceChartData}
          chartData={
            cardId === CARD_CONFIG.BRAND_YTD_ROY_CHART.ChartLabel
              ? ytdRoyFilteredChartData?.length === 0
                ? chartData
                : ytdRoyFilteredChartData
              : cardId === CARD_CONFIG.PORTFOLIO_FY_PROJECTION_CHART.ChartLabel
              ? fyProjChartFilteredData?.length === 0
                ? chartData
                : fyProjChartFilteredData
              : chartData
          }
          chartType={chartType}
          gridData={gridData}
          onConfigureComment={onConfigureComment}
          cardActions={cardActions}
          actionsAllowed={actionsAllowed}
          commentsData={commentsData}
          onSelectCardAction={onSelectCardAction}
          cardId={cardId}
          category={category}
          varianceChartData={varianceChartData}
          versionType={versionType}
          scatterPlotDatatoPlot={isEventChecked ? scatterPlotDatatoPlot : []}
          currentNetSales={currentNetSales}
          isLabelChecked={isLabelChecked}
          labelytd={labelytd}
          ytdRoyFilteredLabel={ytdRoyFilteredLabel}
          minMax={minMax}
          isViewExpanded={isViewExpanded}
          labelYTDRoYProjection={labelYTDRoYProjection}
          forecastLabel={forecastLabel?.kpis}
          getClassName={getClassName}
        />
      )}

      <Modal
        title="Comment Details"
        open={expandCommentModal}
        onCancel={handleClose}
        width={VALUE600}
        footer={[
          <Button key="close" onClick={handleClose} type="link">
            {CLOSE}
          </Button>,
        ]}
      >
        <div>{comment?.Comment}</div>
      </Modal>
    </div>
  );
};

export default React.memo(ViewTile);
