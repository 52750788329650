// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IpxAuLSG10dkQl_oNZS8{font-size:var(--font-size-x-small);font-family:var(--font-gene-sans-bold);font-weight:bold;display:flex;justify-content:space-between}.fufUA7DcazgwbKTskbbw{font-size:var(--font-size-x-small);color:var(--color-black);word-break:break-all}.VNtmbR99LGVDkVJj_koH{color:var(--color-gray);padding-left:5px;font-size:var(--font-size-normal)}`, "",{"version":3,"sources":["webpack://./src/components/elements/catalogue/Catalogue.module.scss"],"names":[],"mappings":"AAAA,sBACE,kCAAA,CACA,sCAAA,CACA,gBAAA,CACA,YAAA,CACA,6BAAA,CAGF,sBACE,kCAAA,CACA,wBAAA,CACA,oBAAA,CAGF,sBACE,uBAAA,CACA,gBAAA,CACA,iCAAA","sourcesContent":[".catalogue-title {\n  font-size: var(--font-size-x-small);\n  font-family: var(--font-gene-sans-bold);\n  font-weight: bold;\n  display: flex;\n  justify-content: space-between;\n}\n\n.catalogue-desc {\n  font-size: var(--font-size-x-small);\n  color: var(--color-black);\n  word-break: break-all;\n}\n\n.comment-info {\n  color: var(--color-gray);\n  padding-left: 5px;\n  font-size: var(--font-size-normal);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"catalogue-title": `IpxAuLSG10dkQl_oNZS8`,
	"catalogue-desc": `fufUA7DcazgwbKTskbbw`,
	"comment-info": `VNtmbR99LGVDkVJj_koH`
};
export default ___CSS_LOADER_EXPORT___;
