// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XA_KrTnelu11oPlaQlS3{position:relative}.XA_KrTnelu11oPlaQlS3 .mNcGjv3AvAh07mixfZvo{display:flex;align-items:center}`, "",{"version":3,"sources":["webpack://./src/components/layout/myviewsearchbar/SearchBar.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,4CACE,YAAA,CACA,kBAAA","sourcesContent":[".search-container {\n  position: relative;\n  .search-icon {\n    display: flex;\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search-container": `XA_KrTnelu11oPlaQlS3`,
	"search-icon": `mNcGjv3AvAh07mixfZvo`
};
export default ___CSS_LOADER_EXPORT___;
