// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vjxDcLVjRbInrIl0tUUP{padding:40px 0px 0px 40px;width:100%}.ILpBeViAu7AeiJnc1aab{font-weight:bold}.NPvjKDrk0UZvXp1YHK6z{display:flex;flex-direction:row;justify-content:space-between}`, "",{"version":3,"sources":["webpack://./src/components/myview/MyViewContainer.module.scss"],"names":[],"mappings":"AAAA,sBACE,yBAAA,CACA,UAAA,CAEF,sBACE,gBAAA,CAEF,sBAEE,YAAA,CACA,kBAAA,CACA,6BAAA","sourcesContent":[".myview-container {\n  padding: 40px 0px 0px 40px;\n  width: 100%;\n}\n.title {\n  font-weight: bold;\n}\n.container-tab\n{\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"myview-container": `vjxDcLVjRbInrIl0tUUP`,
	"title": `ILpBeViAu7AeiJnc1aab`,
	"container-tab": `NPvjKDrk0UZvXp1YHK6z`
};
export default ___CSS_LOADER_EXPORT___;
