import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Modal, Button, Tooltip, Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import AnchorDropdown from '@elements/dropdown/AnchorDropdown';
import Comment from '@elements/Modal/Comment';
import DialogBox from '@elements/dialogbox/DialogBox';
import Grid from '@elements/grid/Grid';
import InfoDialog from '@elements/Modal/InfoDialog';
import RadioButtonGroup from '@elements/radiobutton/RadioButton';
import { Notification } from '@elements/notification/Notification';
import {
  CARD_MENU_EXPORT,
  BUSINESS_PLAN_DETAILS_OPTIONS,
  COMMENT_API_NOTIFICATION,
  API_STATE_BUSINESS_PLAN,
  ERROR_MESSAGE,
  INFO,
  EXPORT,
  ACTION_ADD,
  COMMENTLIMIT,
  COMMENTLIMITERROR,
  CANCELTEXT,
  SUBMITTEXT,
  COMMNETMAXLENGTH,
  COMMENTAUTORESIZE,
  ACTION_EDIT,
  SUCCESS,
  VIEW_MORE,
  DELETE_COMMENT,
  COMMENTUPPERCASE,
  NO_COMMENT_AVAILABLE,
  SINGLE_BRAND_COMMENT,
  CLOSE,
  ALL_BRAND,
  DATA,
  VREPLACESTRING,
  SUBMIT,
  ACTION_DELETE,
  BP_DETAILS_CARDCOMMENT_ID,
  ADD_COMMENT_MODAL_HEADER,
  EDIT_COMMENT_MODAL_HEADER,
  ERROR,
  PIN_COMMENT_API_NOTIFICATION,
} from '@constant';
import {
  addComment,
  deleteComment,
  getAllComments,
  updateComment,
} from '@service/comments.service';
import SelectOption from '@elements/select/Select';
import {
  EXPORT_EXCEL_AOA,
  generateAOABusinessPlan,
  GETCOMMENTCREATEDDATE,
} from '@utils/utils';
import { getQlikSenseUnformattedData } from '@service/qlik.service';
import { getUserName, getUserUnixId } from '@utils/getUser';
import {
  setAdjustedGridData,
  setUnAdjustedGridData,
} from '@store/bplan/bplanViewSlice';
import { BusinessPlanConstant } from '../../businessPlanConstant';
import { CARD_CONFIG } from '@utils/cardConfig';
import classes from './BusinessDetails.module.scss';
import {
  addPinnedComment,
  deletePinnedComment,
} from '@service/pinnedComment.service';
import { shiftElementPlacement } from '@utils/dataFormatter';

const BusinessDetails = ({
  handleDiseaseDataType,
  bpDiseaseDataType,
  selectedFilters,
  businessComments,
  expression,
  commentCardIds,
  infoMessages,
  active,
}) => {
  const [mount, setMount] = useState(false);
  const unixId = getUserUnixId();
  const userName = getUserName();
  const bpDataOptions = useSelector((state) => state?.bpPlan?.dataOptions);
  const bussinesplanState = useSelector((state) => state?.bpPlan);
  const card_config_db = useSelector(
    (state) => state?.brandPerformance.cardConfig,
  );

  const dispatch = useDispatch();
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [error, setError] = useState('');
  const [currentCommentId, setCurrentCommentId] = useState(1);
  const [commentDesc, setCommentDesc] = useState('');
  const [newComment, setNewComment] = useState('');
  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const [refrence, setRefrence] = useState(
    bpDataOptions?.length && bpDataOptions[0],
  );
  const [uniqueYears, setUniqueYears] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [gridCommentsData, setGridCommentsData] = useState(
    businessComments?.filter((item) => {
      return (
        item?.ChartId === BP_DETAILS_CARDCOMMENT_ID &&
        item?.BrandName === selectedFilters?.Brand[0]
      );
    }),
  );
  const [expandCommentModal, setExpandCommentModal] = useState(false);
  const [currentCardId, setCurrentCardId] = useState(0);
  const [commentError, setCommentError] = useState(false);

  const getSectionsExpressionGrid = (data, CardId, ChartId, item) => {
    const expressionData = data
      .filter((e) => e.CardId === CardId && e.ChartId === ChartId)
      .map((obj) =>
        JSON.stringify({
          CardName: obj.CardName,
          id: obj.KpiId,
          name: obj.KpiName,
          expression: obj.Expression.replaceAll(
            VREPLACESTRING,
            expression.concat(`, [Version Name] ={'${item}'}`),
          ),
        }),
      );

    let dimension = [];
    const dimensionNames = new Set();

    data
      ?.filter((e) => e?.CardId === CardId && e?.ChartId === ChartId)
      ?.forEach((obj) => {
        if (obj?.DimensionName && !dimensionNames.has(obj?.DimensionName)) {
          dimensionNames.add(obj?.DimensionName);
          const dimensionName = obj?.DimensionName?.split(';');
          dimensionName?.map((item) => {
            const dim = {
              dimensionType: 'Category',
              dimensionName: item,
            };
            dimension.push(JSON.stringify(dim));
          });
        }
      });
    return {
      expression: expressionData,
      dimension: dimension,
    };
  };

  const transformGridData = (data) => {
    const brands = data?.find((item) => item?.name === '[BP Brand]')?.data;
    const diseaseAreas = data?.find(
      (item) => item?.name === 'Disease_Area',
    )?.data;
    const years = data?.find((item) => item?.name === '[BP Year]')?.data;
    const dataForYear = data?.find(
      (item) =>
        item?.name === 'Unadjusted Figures' ||
        item?.name === 'Adjusted Figures',
    )?.data;

    const polColData = data?.find((item) => item?.name === 'PoL%')?.data;
    const launchData = data?.find((item) => item?.name === 'Launch')?.data;
    const currentYear = new Date()?.getFullYear();
    const uniqueYears = [...new Set(years)];
    setUniqueYears(uniqueYears);
    const yearsToInclude = uniqueYears?.filter((year) => year >= currentYear);

    const resultMap = {};

    brands?.forEach((brand, index) => {
      const diseaseArea = diseaseAreas[index];
      const year = years[index];
      const yearlyData = dataForYear[index];
      const polValue = polColData[index];
      const launchValue = launchData[index];

      const uniqueKey = `${brand}_${diseaseArea}_${polValue}_${launchValue}`;

      // Initialize if this unique combination doesn't exist yet
      if (!resultMap[uniqueKey]) {
        resultMap[uniqueKey] = {
          brand: brand,
          diseasearea: diseaseArea,
          pol: polValue,
          launch: launchValue,
          ...yearsToInclude.reduce((acc, year) => ({ ...acc, [year]: '' }), {}),
        };
      }

      // Only add data for the years we are including
      if (yearsToInclude.includes(year)) {
        resultMap[uniqueKey][year] = yearlyData;
      }
    });
    return Object.values(resultMap)?.sort((a, b) => {
      return a?.bporder - b?.bporder;
    });
  };

  const getQlikData = useCallback(
    async (data, cardId, dim = [], type = undefined) => {
      setLoading(true);
      const payload = {
        connection_details: [
          {
            dimension: dim,
            appId: process.env.APP_ID,
            expression: data,
          },
        ],
        dataType: type,
        limit: selectedFilters?.Brand?.length === 0 ? 1665 : 600,
      };
      const postResponse = await getQlikSenseUnformattedData(payload);
      if (postResponse) {
        const dataFromResponse = postResponse[0];
        if (dataFromResponse) {
          if (cardId === API_STATE_BUSINESS_PLAN.ADJUSTEDGRIDDATA) {
            dispatch(
              setAdjustedGridData(
                transformGridData(dataFromResponse?.values, 'Adjusted'),
              ),
            );
          } else if (cardId === API_STATE_BUSINESS_PLAN.UNADJUSTEDGRIDDATA) {
            dispatch(
              setUnAdjustedGridData(
                transformGridData(dataFromResponse?.values, 'Unadjusted'),
              ),
            );
          }
        }
      }
      setLoading(false);
      setError(null);
    },
    [dispatch, selectedFilters?.Brand?.length],
  );

  const onSelectChangeReference = (value) => {
    const getBpUnAdjustedGridData = getSectionsExpressionGrid(
      brandExpressions,
      card_config_db?.BP_UNADJUSTED_GRID?.CardId,
      card_config_db?.BP_UNADJUSTED_GRID?.ChartId,
      value,
    );
    const getBpAdjustedGridData = getSectionsExpressionGrid(
      brandExpressions,
      card_config_db?.BP_ADJUSTED_GRID?.CardId,
      card_config_db?.BP_ADJUSTED_GRID?.ChartId,
      value,
    );

    getQlikData(
      getBpUnAdjustedGridData.expression,
      'UnAdjustedGridData',
      getBpUnAdjustedGridData.dimension,
      'kpi',
      value,
    );
    getQlikData(
      getBpAdjustedGridData.expression,
      'AdjustedGridData',
      getBpAdjustedGridData.dimension,
      'kpi',
      value,
    );
  };
  const fetchData = async () => {
    setLoading(true);
    try {
      if (
        brandExpressions?.length &&
        expression?.length > 0 &&
        bpDataOptions?.length
      ) {
        await getSummaryComments();
        // Assuming this function returns a promise
        const getBpUnAdjustedGridData = getSectionsExpressionGrid(
          brandExpressions,
          card_config_db?.BP_UNADJUSTED_GRID?.CardId,
          card_config_db?.BP_UNADJUSTED_GRID?.ChartId,
          bpDataOptions[0],
        );

        const getBpAdjustedGridData = getSectionsExpressionGrid(
          brandExpressions,
          card_config_db?.BP_ADJUSTED_GRID?.CardId,
          card_config_db?.BP_ADJUSTED_GRID?.ChartId,
          bpDataOptions[0],
        );
        const promises = [
          getQlikData(
            getBpUnAdjustedGridData.expression,
            'UnAdjustedGridData',
            getBpUnAdjustedGridData.dimension,
            'kpi',
          ),
          getQlikData(
            getBpAdjustedGridData.expression,
            'AdjustedGridData',
            getBpAdjustedGridData.dimension,
            'kpi',
          ),
        ];
        await Promise.all(promises);
        setLoading(false);
      }
    } catch (error) {
      toast.error(ERROR_MESSAGE);
    } finally {
      // Set loading to false once all promises resolve
    }
    return {};
  };
  const ReferenceOptionsFilter = bpDataOptions?.map((item) => ({
    value: item,
    label: item,
  }));

  const brandExpressions = useSelector(
    (state) => state?.brandPerformance?.brandExpressions,
  );
  useEffect(() => {
    if (active) {
      setMount(true);
    }
  }, [active]);

  const getSummaryComments = useCallback(async () => {
    const response = await getAllComments(unixId);
    const filteredComments = response?.filter((item) => {
      return (
        item?.ChartId === BP_DETAILS_CARDCOMMENT_ID &&
        item?.BrandName ===
          (selectedFilters?.Brand?.length && selectedFilters?.Brand[0])
      );
    });
    setGridCommentsData(filteredComments);
    return {};
  }, [selectedFilters?.Brand, unixId]);
  // eslint-disable-next-line no-unused-vars
  const { isLoading: isLoadingComment } = useQuery({
    queryFn: getSummaryComments,
    queryKey: [
      'bussinesplan-comment',
      getSummaryComments,
      selectedFilters.Brand,
    ],
    enabled: mount,
  });
  const { isLoading: isBusinessPlanLoading } = useQuery({
    queryKey: [
      'bussiness-plan',
      selectedFilters.Franchise,
      selectedFilters.Brand,
      bpDataOptions,
      brandExpressions,
      expression?.length,
      getSummaryComments,
      getSectionsExpressionGrid,
      getQlikData,
    ],
    queryFn: fetchData,
    enabled: mount,
  });

  let priorityComment = {};
  const pinnedIndex = gridCommentsData?.findIndex((x) => x?.isPinned);
  if (pinnedIndex > -1) {
    shiftElementPlacement(gridCommentsData, pinnedIndex, 0);
  }
  priorityComment = gridCommentsData?.[0] || {};

  const onSelectMenu = (e) => {
    if (e.key === INFO) {
      setIsInfoModalVisible(true);
    }
    if (e.key === EXPORT) {
      exportToExcel();
    }
  };

  const exportToExcel = async () => {
    if (bpDiseaseDataType === 'adjusted') {
      const sheetData = generateAOABusinessPlan(
        bussinesplanState.AdjustedGridData,
      );

      EXPORT_EXCEL_AOA(sheetData, 'Adjusted Details');
    } else {
      const sheetData = generateAOABusinessPlan(
        bussinesplanState.UnAdjustedGridData,
      );

      EXPORT_EXCEL_AOA(sheetData, 'Unadjusted Details');
    }
  };

  const onConfigurePinnedComment = async (action, commentId, chartId) => {
    if (action === ACTION_ADD) {
      const payload = {
        chartId: chartId,
        unixId: unixId,
        commentId: commentId,
        brandName:
          selectedFilters?.Brand?.length >= 1
            ? selectedFilters?.Brand?.[0]
            : '',
      };
      const result = await addPinnedComment(payload);
      if (result?.status === 200) {
        await getSummaryComments();
        Notification(SUCCESS, PIN_COMMENT_API_NOTIFICATION.add);
      } else {
        Notification(
          ERROR,
          result?.data?.Reason || PIN_COMMENT_API_NOTIFICATION.fail,
        );
      }
    } else if (action === ACTION_DELETE) {
      const payload = {
        chartId: chartId,
        unixId: unixId,
        commentId: commentId,
      };
      const result = await deletePinnedComment(payload);
      if (result) {
        await getSummaryComments();
        Notification(SUCCESS, PIN_COMMENT_API_NOTIFICATION.delete);
      } else {
        Notification(ERROR, PIN_COMMENT_API_NOTIFICATION.fail);
      }
    }
  };

  const onConfigureComment = (action, id, cardId) => {
    setCurrentCommentId(id);
    setCurrentCardId(cardId);
    if (action === ACTION_ADD) {
      setNewComment('');
      setIsAddModalOpen(true);
    } else if (action === ACTION_EDIT) {
      setCommentDesc(
        gridCommentsData?.filter((item) => item?.ID === id)[0]?.Comment,
      );
      setIsEditModalOpen(true);
    } else {
      setIsDeleteModalOpen(true);
    }
  };

  const handleInfoModalClose = () => {
    setIsInfoModalVisible(false);
  };

  const handleCancel = () => {
    setIsDeleteModalOpen(false);
    setIsEditModalOpen(false);
    setIsAddModalOpen(false);
    setIsCommentModalVisible(false);
    setNewComment('');
    setCommentError(false);
  };

  const handleDeleteComment = async () => {
    setIsDeleteModalOpen(false);
    setIsCommentModalVisible(false);
    const response = await deleteComment(currentCommentId, unixId);
    if (response?.message) {
      await getSummaryComments();
      Notification(SUCCESS, COMMENT_API_NOTIFICATION.delete);
    } else {
      Notification(ERROR, ERROR_MESSAGE);
    }
  };

  const handleEditComment = async () => {
    if (commentDesc.trim() === '') {
      return;
    }
    setIsEditModalOpen(false);
    setIsCommentModalVisible(false);
    const payload = {
      unixId: unixId,
      comment: commentDesc,
      id: currentCommentId,
    };
    const response = await updateComment(payload);
    if (response?.message) {
      await getSummaryComments();
      Notification(SUCCESS, COMMENT_API_NOTIFICATION.update);
    } else {
      Notification(ERROR, ERROR_MESSAGE);
    }
  };

  const handleAddComment = async () => {
    let error = false;
    if (newComment === '') {
      error = true;
    } else {
      setIsAddModalOpen(false);

      const payload = {
        buname: selectedFilters?.Business_Unit || '',
        squad:
          selectedFilters.Franchise.length === 0
            ? ''
            : selectedFilters.Franchise.join(','),
        brandName:
          selectedFilters?.Brand?.length === 0 ? '' : selectedFilters?.Brand,
        brandclassification: '',
        commenttype: '',
        level: '',
        timeperiod: '-',
        userRole: '',
        chartid: currentCardId || BP_DETAILS_CARDCOMMENT_ID,
        pageid: 1,
        isActive: 'Y',
        unixId: unixId,
        comment: newComment,
        userName: userName,
        sku: '',
      };
      const result = await addComment(payload);
      if (result) {
        await getSummaryComments();
        Notification(SUCCESS, COMMENT_API_NOTIFICATION.add);
      } else {
        Notification(ERROR, ERROR_MESSAGE);
      }
      setNewComment('');
    }
    setError(error);
  };

  const showCommentModal = () => {
    setIsCommentModalVisible(true);
  };
  const handleCommentClose = () => {
    setIsCommentModalVisible(false);
  };

  const onExpandCommentDesc = () => {
    setExpandCommentModal(true);
  };

  const handleClose = () => {
    setExpandCommentModal(false);
  };

  const DISEASE_AREA_GRID_COLUMNS = [
    {
      title: <span className="column-title">Brand</span>,
      dataIndex: 'brand',
      key: 'brand',
      align: 'left',
      fixed: 'left',
    },
    {
      title: <span className="column-title">Disease Area</span>,
      dataIndex: 'diseasearea',
      key: 'diseasearea',
      align: 'left',
      ...(uniqueYears.length > 0 ? { fixed: 'left' } : {}),
      render: (text) =>
        text?.length > 40 ? (
          <Tooltip title={text}>{`${text?.substring(0, 40)}...`}</Tooltip>
        ) : (
          text
        ),
    },
    {
      title: <span className="column-title">PoL%</span>,
      dataIndex: 'pol',
      key: 'pol',
      align: 'left',
      width: 80,
      minWidth: 100,
      render: (text) => (text ? text : '-'),
    },
    {
      title: <span className="column-title">Launch</span>,
      dataIndex: 'launch',
      key: 'launch',
      align: 'left',
      width: 80,
      render: (text) => (text ? text : '-'),
    },
    ...uniqueYears?.map((item) => ({
      title: <span className="column-title">{item}</span>,
      dataIndex: item,
      key: item,
      align: 'left',
      width: 80,
      render: (text) => (text ? text : '-'),
    })),
  ];

  const onChangeHandle = (value) => {
    if (value.length > COMMENTLIMIT) setCommentError(true);
    else setCommentError(false);
  };

  const getBrandsSelected = () => {
    return selectedFilters?.Brand?.length === 0
      ? ALL_BRAND
      : selectedFilters?.Brand?.join(', ');
  };

  return (
    <Spin spinning={isBusinessPlanLoading || isLoading}>
      <div className={classes['businessdetails--reports-container']}>
        <div className={classes['header-title']}>
          <div className={classes['header']}>
            <Tooltip
              title={`${
                CARD_CONFIG?.BP_ADJUSTED_GRID?.CardName
              } ${getBrandsSelected()}`}
            >
              <div className={classes['text-overflow-css']}>
                {CARD_CONFIG?.BP_ADJUSTED_GRID?.CardName}{' '}
                <span className={classes['brand-color']}>
                  {getBrandsSelected()}
                </span>
              </div>
            </Tooltip>
            {selectedFilters?.Brand?.length === 1 && (
              <>
                <Tooltip title="View Comments">
                  <i
                    className={`icon icon__comments_grey ${classes['icon-comment']}`}
                    onClick={showCommentModal}
                  ></i>
                </Tooltip>

                <span className={classes['comment-length']}>
                  {gridCommentsData?.length}
                </span>
              </>
            )}
          </div>
        </div>
        <div className={classes['viewtype-anchor']}>
          <AnchorDropdown items={CARD_MENU_EXPORT} onClick={onSelectMenu}>
            <i className={'icon icon__card-options'}></i>
          </AnchorDropdown>
        </div>
        <div className={classes['radio-wrap']}>
          <div className={classes['radio-button']}>
            <RadioButtonGroup
              defaultValue={'adjusted'}
              options={BUSINESS_PLAN_DETAILS_OPTIONS}
              onChange={handleDiseaseDataType}
            />
          </div>
          <div className={classes['radio-text']}>{DATA}</div>
          <SelectOption
            menuOptions={ReferenceOptionsFilter}
            selectStyle={{ width: 170, height: 30 }}
            selectedValues={refrence ? refrence : bpDataOptions[0]}
            onSelectChange={(e) => {
              onSelectChangeReference(e), setRefrence(e);
            }}
          />
        </div>
        <Grid
          columns={DISEASE_AREA_GRID_COLUMNS}
          dataSource={
            bpDiseaseDataType === 'adjusted'
              ? bussinesplanState.AdjustedGridData
              : bussinesplanState.UnAdjustedGridData
          }
          rowClassName={(record) =>
            record?.diseasearea?.includes('Total') ? 'blueHeader' : ''
          }
          scroll={{
            x: 'max-content',
            y: 550,
          }}
        />
        <div className={classes['comment-section']}>
          {selectedFilters?.Brand?.length === 1 &&
          gridCommentsData?.length !== 0 ? (
            <>
              <div className={classes['comment-title']}>
                <div>
                  {priorityComment?.UserName}
                  <span className={classes['comment-info']}>
                    {GETCOMMENTCREATEDDATE(priorityComment?.CreatedDate)}
                  </span>
                </div>
                <div className={classes['comment-icons']}>
                  {priorityComment?.isPinned && (
                    <Tooltip title="Unpin">
                      <i
                        className={'icon icon__unpinned'}
                        onClick={() =>
                          onConfigurePinnedComment(
                            ACTION_DELETE,
                            priorityComment?.ID,
                            priorityComment?.ChartId,
                          )
                        }
                      ></i>
                    </Tooltip>
                  )}

                  {!priorityComment?.isPinned && (
                    <Tooltip title="Pin">
                      <i
                        className={'icon icon__pinned'}
                        style={{
                          paddingRight: '5px',
                          fontSize: '14px',
                          color: '#13294b',
                        }}
                        onClick={() =>
                          onConfigurePinnedComment(
                            ACTION_ADD,
                            priorityComment?.ID,
                            priorityComment?.ChartId,
                          )
                        }
                      />
                    </Tooltip>
                  )}

                  {commentCardIds.includes(
                    card_config_db?.BP_DETAILS_COMMENTS?.CardId,
                  ) && (
                    <>
                      <i
                        className={'icon icon__add_blue'}
                        onClick={() =>
                          onConfigureComment(
                            ACTION_ADD,
                            priorityComment?.ID,
                            BP_DETAILS_CARDCOMMENT_ID,
                          )
                        }
                      ></i>
                      {unixId?.toLowerCase() ===
                        priorityComment?.UnixId?.toLowerCase() && (
                        <>
                          <i
                            className={'icon icon__edit'}
                            onClick={() =>
                              onConfigureComment(
                                ACTION_EDIT,
                                priorityComment?.ID,
                                BP_DETAILS_CARDCOMMENT_ID,
                              )
                            }
                          ></i>
                          <i
                            className={'icon icon__delete_blue'}
                            onClick={() =>
                              onConfigureComment(
                                ACTION_DELETE,
                                priorityComment?.ID,
                                BP_DETAILS_CARDCOMMENT_ID,
                              )
                            }
                          ></i>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className={classes['comment-desc']}>
                {priorityComment?.Comment?.length > 110 ? (
                  <div className={classes['comment-align']}>
                    {`${priorityComment?.Comment.substring(0, 110)}...`}
                    <span
                      className={classes['comment-expand']}
                      onClick={onExpandCommentDesc}
                    >
                      {VIEW_MORE}
                    </span>
                  </div>
                ) : (
                  priorityComment?.Comment
                )}
              </div>
            </>
          ) : (
            <div>
              {selectedFilters?.Brand?.length === 1 && (
                <div className={classes['comment-icon-add']}>
                  <i
                    className={'icon icon__add_blue'}
                    onClick={() =>
                      onConfigureComment(
                        ACTION_ADD,
                        priorityComment?.ID,
                        BP_DETAILS_CARDCOMMENT_ID,
                      )
                    }
                  ></i>
                </div>
              )}
              <div className={classes['comment-no-data']}>
                {selectedFilters?.Brand?.length === 1
                  ? NO_COMMENT_AVAILABLE
                  : SINGLE_BRAND_COMMENT}
              </div>
            </div>
          )}
        </div>
        <InfoDialog
          isVisible={isInfoModalVisible}
          onClose={handleInfoModalClose}
          message={
            infoMessages[card_config_db?.BP_ADJUSTED_GRID?.CardId]
              ? infoMessages[card_config_db?.BP_ADJUSTED_GRID?.CardId]
              : `${CARD_CONFIG?.BP_ADJUSTED_GRID?.CardName} `
          }
        />
        <DialogBox
          title={
            <span className={classes['comment-modal-header']}>
              <i className={'icon icon__delete_black'}></i>
              {DELETE_COMMENT}
            </span>
          }
          isModalOpen={isDeleteModalOpen}
          okText={BusinessPlanConstant.DELETE}
          handleCancel={handleCancel}
          handleOk={handleDeleteComment}
          width={370}
        >
          <div className={classes['modal-content']}>
            {BusinessPlanConstant.DELETE_WARMING}
          </div>
        </DialogBox>
        <DialogBox
          title={
            <span className={classes['comment-modal-header']}>
              <i className={'icon icon__add_black'}></i>
              {ADD_COMMENT_MODAL_HEADER}
            </span>
          }
          isModalOpen={isAddModalOpen}
          okText={SUBMIT}
          handleCancel={handleCancel}
          handleOk={handleAddComment}
          width={600}
          footer={[
            <Button key="{CANCELTEXT}" onClick={handleCancel}>
              {CANCELTEXT}
            </Button>,
            <Button
              key="{SUBMITTEXT}"
              disabled={newComment?.length > COMMENTLIMIT}
              type="primary"
              onClick={handleAddComment}
            >
              {SUBMITTEXT}
            </Button>,
          ]}
        >
          <div className={classes['modal-content']}>
            <p className="modal-title">{COMMENTUPPERCASE}</p>
            <TextArea
              value={newComment}
              onChange={(e) => {
                setNewComment(e?.target?.value);
                onChangeHandle(e?.target?.value);
                setError(false);
              }}
              rows={4}
              className={classes['text-area']}
              maxLength={COMMNETMAXLENGTH}
              autoSize={COMMENTAUTORESIZE}
            />
            <p className={classes['text-align-end']}>
              Remaining characters: {COMMENTLIMIT - newComment?.length}
            </p>
            {error && <div className={classes['error-message']}>{error}</div>}
            {commentError ? (
              <span className={classes['error-message']}>
                {COMMENTLIMITERROR}
              </span>
            ) : (
              ''
            )}
          </div>
        </DialogBox>
        <DialogBox
          title={
            <span className={classes['comment-modal-header']}>
              <i className={'icon icon__edit_black'}></i>
              {EDIT_COMMENT_MODAL_HEADER}
            </span>
          }
          isModalOpen={isEditModalOpen}
          okText={SUBMIT}
          handleCancel={handleCancel}
          handleOk={handleEditComment}
          width={600}
          footer={[
            <Button key="{CANCELTEXT}" onClick={handleCancel}>
              {CANCELTEXT}
            </Button>,
            <Button
              key="{SUBMITTEXT}"
              disabled={commentDesc?.length > COMMENTLIMIT}
              type="primary"
              onClick={handleEditComment}
            >
              {SUBMITTEXT}
            </Button>,
          ]}
        >
          <div className={classes['modal-content']}>
            <p className={classes['modal-title']}>{COMMENTUPPERCASE}</p>
            <TextArea
              value={commentDesc}
              onChange={(e) => {
                setCommentDesc(e?.target?.value);
                onChangeHandle(e?.target?.value);
              }}
              rows={4}
              className={classes['text-area']}
              maxLength={COMMNETMAXLENGTH}
              autoSize={COMMENTAUTORESIZE}
            />
            <p className={classes['text-align-end']}>
              Remaining characters: {COMMENTLIMIT - commentDesc?.length}
            </p>
            {error && <div className={classes['error-message']}>{error}</div>}
            {commentError ? (
              <span className={classes['error-message']}>
                {COMMENTLIMITERROR}
              </span>
            ) : (
              ''
            )}
          </div>
        </DialogBox>
        <Comment
          visible={isCommentModalVisible}
          onClose={handleCommentClose}
          commentsData={gridCommentsData}
          currentUser={unixId}
          onConfigurePinnedComment={onConfigurePinnedComment}
          onConfigureComment={onConfigureComment}
          title={`${CARD_CONFIG?.BP_ADJUSTED_GRID?.CardName} ${selectedFilters?.Brand}`}
          cardNumber={card_config_db?.BP_DETAILS_COMMENTS?.CardId}
          selectedFilters={selectedFilters}
          cardId={card_config_db?.BP_DETAILS_COMMENTS?.CardId}
        />
        <Modal
          title={COMMENTUPPERCASE}
          open={expandCommentModal}
          onCancel={handleClose}
          width={600}
          footer={[
            <Button key="close" onClick={handleClose} type="link">
              {CLOSE}
            </Button>,
          ]}
        >
          <div className={classes['modal-content']}>
            {priorityComment?.Comment}
          </div>
        </Modal>
      </div>
    </Spin>
  );
};

export default BusinessDetails;
