import { colorLabelSpan } from './colorLabelSpan';

export const portfolioYTDActualsForeacastOptions = ({
  chartData,

  growthPercentage,

  expand,
}) => {
  return {
    tooltip: {
      show: true,
      formatter: (params) => {
        let tooltipContent = ' ';
        const value =
          params?.value === null || params?.value === undefined
            ? '-'
            : params?.value;
        const data =
          colorLabelSpan({ color: params?.color }) +
          ' ' +
          params?.name +
          ' : ' +
          (value === '-'
            ? '-'
            : value?.toLocaleString() < 0
            ? `-$${Math.abs(value)?.toLocaleString()}M`
            : `$${value?.toLocaleString()}M`) +
          '<br/>';
        tooltipContent += data;
        return tooltipContent;
      },
    },
    xAxis: {
      type: 'value',
      show: false,
      splitLine: { show: false },
      axisLabel: {
        color: '#000000',
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: 'category',
      data: chartData?.map((item) => {
        return item?.name;
      }),
      axisLabel: {
        color: '#000000',
        show: true,
        width: 70,
        overflow: 'breakAll',
        ellipsis: true,
      },
      axisTick: {
        show: false,
      },
    },
    grid: expand
      ? { left: '2%', right: '10%', containLabel: true }
      : {
          left: '18%', // Adjust this value to give more space to the yAxis labels
          right: '15%',
        },
    series: [
      {
        data: chartData?.map((dataItem, index) => ({
          ...dataItem,
          itemStyle: {
            color: index === 0 ? '#4B80B1' : '#13294B',
          },
        })),
        type: 'bar',
        barWidth: '80%',
        label: {
          color: 'white',
          show: true,
          position: 'inside',
          formatter: (params) => {
            return params?.data?.value?.toLocaleString() == 0
              ? ''
              : params?.data?.value?.toLocaleString() < 0
              ? `-$${Math.abs(params?.data?.value)?.toLocaleString()}M`
              : `$${params?.data?.value?.toLocaleString()}M`;
          },
        },
      },
      {
        type: 'scatter',
        data: expand
          ? [
              {
                value: [
                  chartData &&
                    growthPercentage &&
                    Number(growthPercentage[0]?.data),
                  'Actuals',
                ],
                labelLayout: {
                  // hideOverlap: true,
                  moveOverlap: 'shiftX',
                },
                label: {
                  show: true,
                  formatter: () => {
                    return growthPercentage && growthPercentage[0]?.growth;
                  },
                  color:
                    growthPercentage &&
                    Number(growthPercentage[0]?.growth?.split('%')[0]) > 0
                      ? '#56A944'
                      : '#C23934',
                  position: 'center',
                  fontWeight: 'bold',
                  offset:
                    chartData &&
                    chartData?.length > 0 &&
                    chartData.find((item) => item.name === 'Actuals')?.value >=
                      0
                      ? [13, 0]
                      : [0, -34],

                  fontSize: 13,
                },
              },
            ]
          : [
              {
                value: [
                  chartData &&
                    growthPercentage &&
                    Number(growthPercentage[0]?.data),
                  'Actuals',
                ],
                labelLayout: {
                  moveOverlap: 'shiftX',
                },
                label: {
                  show: true,
                  formatter: () => {
                    return growthPercentage && growthPercentage[0]?.growth;
                  },
                  color:
                    growthPercentage &&
                    Number(growthPercentage[0]?.growth?.split('%')[0]) > 0
                      ? '#56A944'
                      : '#C23934',
                  position: 'center',
                  fontWeight: 'bold',
                  offset:
                    chartData &&
                    chartData?.length > 0 &&
                    chartData.find((item) => item.name === 'Actuals')?.value >=
                      0
                      ? [13, 0]
                      : [-0, -27],

                  fontSize: 13,
                },
              },
            ],
        tooltip: {
          show: false,
        },
        itemStyle: {
          color: 'transparent',
        },
      },
    ],
  };
};
