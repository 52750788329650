import { CARD_CONFIG } from '@utils/cardConfig';

export const BANNER_CARD_ID = {
  PORTFOLIO: 1000,
  BRAND: 1001,
  BUSINESS_PLAN: 1002,
};

export const SOPCARDCOMMENT_ID = {
  PREVIOUS: 2001,
  FINANCIAL: 23,
};

export const BP_DETAILS_CARDCOMMENT_ID = 3001;

export const CARD_MENU = [
  {
    label: (
      <span>
        <i className={'icon icon__info'}></i>Info
      </span>
    ),
    key: 'info',
  },
  {
    label: (
      <div>
        <i className={'icon icon__expand'}></i>Expand View
      </div>
    ),
    key: 'expand',
  },
  {
    label: (
      <span>
        <i className={'icon icon__myview_blue'}></i>Add to My View
      </span>
    ),
    key: 'addtoview',
  },
];

export const CARD_MENU_EXPORT = [
  {
    label: (
      <span>
        <i className={'icon icon__info'}></i>Info
      </span>
    ),
    key: 'info',
  },
  {
    label: (
      <span>
        <i className={'icon icon__excel'}></i> <span>Export To Excel</span>
      </span>
    ),
    key: 'export',
  },
];

export const CARD_MENU_BP = [
  {
    label: (
      <span>
        <i className={'icon icon__info'}></i>Info
      </span>
    ),
    key: 'info',
  },
  {
    label: (
      <span>
        <i className={'icon icon__event_blue'}></i>Event Details
      </span>
    ),
    key: 'event',
  },
  {
    label: (
      <div>
        <i className={'icon icon__expand'}></i>Expand View
      </div>
    ),
    key: 'expand',
  },
  {
    label: (
      <span>
        <i className={'icon icon__myview_blue'}></i>Add to My View
      </span>
    ),
    key: 'addtoview',
  },
];

export const CARD_MENU_MYVIEW = [
  {
    label: (
      <span>
        <i className={'icon icon__info'}></i>Info
      </span>
    ),
    key: 'info',
  },
  {
    label: (
      <div>
        <i className={'icon icon__expand'}></i>Expand View
      </div>
    ),
    key: 'expand',
  },
  {
    label: (
      <span>
        <i className={'icon icon__myView_Duplicate'}></i>Duplicate
      </span>
    ),
    key: 'duplicate',
  },
  {
    label: (
      <span>
        <i className={'icon icon__delete_blue'}></i>Remove from My View
      </span>
    ),
    key: 'remove',
  },
];

export const APP_URL = '/analyticshub/saif';
export const REPORT_CATEGORY = 'Archived Reports';
export const BUSINESS_PLAN = {
  PORTFOLIO: 'portfolio',
  BRAND: 'brand',
  SOP: 'sop',
};
//CURRENT_TAB_CATEGORY

export const BUSINESS_PLAN_CATEGORY = {
  BUSINESSPLAN: 'businessplan',
};
export const PERFORMANCE_CATEGORY = {
  PORTFOLIO: 'portfolio',
  BRAND: 'brand',
  SOP: 'sop',
  MYVIEW: 'myview',
};

export const COMMENT_API_NOTIFICATION = {
  delete: 'Comment deleted successfully!',
  add: 'Comment Added successfully!',
  update: 'Comment updated successfully!',
};

export const PIN_COMMENT_API_NOTIFICATION = {
  delete: 'Comment unpinned successfully!',
  add: 'Comment pinned successfully!',
  fail: 'Error occured while performing action',
};

export const MYVIEW_NOTIFICATION = {
  delete: 'Card deleted successfully!',
  add: 'Card added successfully!',
  update: 'Card updated successfully!',
  limitExceed: 'Cannot add more than 20 cards to My View!',
};
export const VIEW_TYPE_CURRENT = 'current';
export const VIEW_TYPE_CURRENT_YEAR = 'current';
export const VIEW_TYPE_PREVIOUS = 'previous';
export const VIEW_TYPE_YEARLY = 'yearly';
export const VIEW_TYPE_FINANCIAL_FORECAST = 'financialForecast';
export const VIEW_TYPE_PREVIOUS_MONTH = 'previousMonth';

export const AGG_FORECAST_VIEW_TYPE = [
  {
    value: 'aggregate',
    key: 'Agg. Forecast',
  },
  {
    value: 'accuracy',
    key: '% Accuracy',
  },
];

export const SHORT_TERM_FORECAST_VIEW_TYPE = [
  {
    key: '4 Weeks MA',
    value: '4Wk',
  },
  {
    key: '26 Weeks MA',
    value: '26Wk',
  },
];

export const VIEW_TYPES_PORTFOLIO = [
  {
    value: 'current',
    key: <>YTD</>,
  },
  {
    value: 'previous',
    key: <>QTD</>,
  },
];

export const VIEW_TYPES_SOP_FORECAST = [
  {
    value: 'previousMonth',
    key: <>Previous Month</>,
  },
  {
    value: 'financialForecast',
    key: <>Financial Forecast</>,
  },
];

export const YTD_ROY_DATASELECT_OPTIONS = [
  { value: 'Actual', label: 'Actual' },

  { value: '12 wk MA', label: '12 Week MA' },
  { value: 'Trend', label: 'Trend' },
];

export const DEFAULT_DEMAND_DATA_OPTIONS = ['Actual', '12 wk MA', 'Trend'];

export const NET_SALES_EVNETS_OPTIONS = [
  { value: 'Competitor Launch', label: 'Competitor Launch' },
  { value: 'LOE Date', label: 'LOE Date' },
  { value: 'Lorem Ipsum', label: 'Lorem Ipsum' },
];

export const NET_SALES_DATA_OPTIONS = [
  { value: 'Current Forecast', label: 'Current Forecast' },
  { value: 'Business Plan 2023', label: 'Business Plan 2023' },
  { value: 'Buisness Plan 2022', label: 'Buisness Plan 2022' },
];

export const DEFAULT_NET_SALES_DATA_OPTIONS = [
  'Current Forecast',
  'Business Plan 2023',
  'Buisness Plan 2022',
];

export const FY_PROJECTION_DATASELECT_OPTIONS = [
  { value: 'Actuals', label: 'Actuals' },
  { value: '12 wk MA', label: '12 Week MA' },
  { value: 'Trend', label: 'Trend' },
];

export const FYPROJECTION_DEFAULT_DATA_OPTIONS = [
  'Actuals',
  '12 wk MA',
  'Trend',
];

export const DEFAULT_YEAR_FORECAST_OPTIONS = [
  { value: 'Total Grams', label: 'Total Grams' },
  { value: 'Commercial Grams', label: 'Commercial Grams' },
  { value: 'Packs', label: 'Packs' },
  { value: 'NFS Total Packs', label: 'NFS Total Packs' },
  { value: 'Net Sales', label: 'Net Sales' },
];

export const DEFAULT_AGG_FORECAST_OPTIONS = [
  { value: '6 Months', label: '6 Months' },
  { value: '3 Months', label: '3 Months' },
];

export const LEGEND = {
  RED: '<=97.5%',
  BLUE: '>97.5% to <=102.5%',
  GREEN: '>102.5%',
};
export const SOP_FORECAST_LEGEND = {
  GREEN: '<=20%',
  RED: '10%',
  BLUE: '>20% to <=80%',
  GREEN_COLOR: '#56A944',
  RED_COLOR: '#C23934',
  BLUE_COLOR: '#041633',
  MAX_LENGTH: 30,
  LEGEND_VALUE_1: 20,
  LEGEND_VALUE_2: 10,
  GREATERTHAN: '>',
};

export const LEGEND_AGG_FORECAST = {
  GREEN: '>85%',
  RED: '>40% to <=85%',
  BLACK: '<=40%',
};

export const LEGEND_SHORT_FORECAST = {
  GREEN: 'Overforecast',
  RED: 'Underforecast',
};

const currentYear = new Date().getFullYear();
export const COLUMNS_KPI = (data, selectedFilters) => {
  if (!data || data.length === 0) return [];

  // Extract all unique keys from data objects
  const allKeys = Array.from(new Set(data.flatMap(Object.keys)));

  // Separate static columns and dynamic quarter columns
  const staticColumns = ['Indication', 'KPI Name', 'Units'];
  const quarterColumns = allKeys.filter((key) => /Q\d+'\d{2}/.test(key));

  // Sort quarters: previous year first, then current year
  quarterColumns.sort((a, b) => {
    const [qA, yA] = a
      .match(/Q(\d+)'(\d{2})/)
      .slice(1)
      .map(Number);
    const [qB, yB] = b
      .match(/Q(\d+)'(\d{2})/)
      .slice(1)
      .map(Number);
    return yA === yB ? qA - qB : yA - yB;
  });

  // Combine static and dynamic columns
  const orderedKeys = [...staticColumns, ...quarterColumns];

  return orderedKeys.map((key) => {
    const modifiedName = key.includes('T0')
      ? key.replace('T0', selectedFilters?.VersionType || 'T0')
      : key;

    return {
      title: <span className="column-title">{modifiedName}</span>,
      dataIndex: key,
      key: key,
      width:
        key.length < 10 ? (key === 'KPI Name' ? '140px' : '140px') : '140px',
      ...(staticColumns.includes(key) ? { fixed: 'left' } : {}),
    };
  });
};

export const COLUMNS_MONTHLY = (viewType, monthlyColumns) => {
  if (monthlyColumns) {
    let ytd = monthlyColumns?.filter((month) => month?.includes('YTD'));
    // Filter out the rest of the months (excluding 'YTD')
    let otherMonths = monthlyColumns?.filter(
      (month) => !month?.includes('YTD'),
    );
    // Concatenate the other months with 'YTD' at the end
    let sortedMonths = [...otherMonths, ...ytd];

    return [
      {
        title: <span className="column-title">Metric</span>,
        dataIndex: 'title',
        key: 'title',
        fixed: 'left',
      },
      ...sortedMonths?.map((item) => ({
        title: <span className="column-title">{item}</span>,
        dataIndex: item,
        key: item,
        width: 100,
        render: (text, record) => renderCell(text, record),
      })),
    ];
  }
};

export const renderCell = (text, record) => {
  const varianceKpi = ['Price', 'Reserves', 'Inventory', 'Demand'];
  let color = '';
  if (record?.title === 'Variances') {
    return ' ';
  }

  const displayText = text === '-0' ? Math.abs(parseInt(text)) : text || '-';
  if (typeof text === 'string' && text?.includes('%')) {
    const value = Math.abs(
      parseFloat(
        text.includes(',')
          ? text.split(',').join('').replace('%', '')
          : text?.replace('%', ''),
      ),
    );
    if (!isNaN(value)) {
      if (value <= 97.5) {
        color = '#C23934';
      }
      if (value > 97.5 && value <= 102.5) {
        color = '#13294b';
      }
      if (value > 102.5) {
        color = '#56A944';
      }
    }
  }

  if (varianceKpi?.includes(record?.title) && record?.key !== 4) {
    return text ? (text > 0 ? `+${text}` : text) : '-';
  }

  return <span style={{ color }}>{displayText}</span>;
};

export const GET_ROWS = ['Variances', 'Net Sales ($M)'];
export const GET_ROW_DEMAND = 'Demand';
export const VIEW_TYPE_DEMAND = 'demand';
export const DEFAULT_FORECASTVIEW = 'aggregate';
export const SHORT_TERM_VIEW_TYPE_WEEK = '4Wk';

const renderFunction = (text, record) => RENDER_CELL_GRID(text, record);

export const CHART_GRID_COLUMNS_VIEW = [
  {
    title: '',
    dataIndex: 'title',
    key: 'metric',
    width: 200,
  },
  {
    title: '2021',
    dataIndex: '2021',
    key: '2021',
    minWidth: 120,
  },
  {
    title: '2022',
    dataIndex: '2022',
    key: '2022',
    minWidth: 120,
  },
  {
    title: '2023',
    dataIndex: '2023',
    key: '2023',
    minWidth: 120,
  },
  {
    title: 'YTD 2024',
    dataIndex: 'YTD2024',
    key: 'YTD2024',
    minWidth: 120,
  },
]?.map((column) => {
  if (column.dataIndex !== 'title') {
    column.render = (text, record) => renderFunction(text, record);
  }
  return column;
});

export const CHART_GRID_COLUMNS = (years) => {
  let uniqueYears;
  if (years) {
    // Create a new array by spreading the original and sort it
    uniqueYears = [...years]?.sort(
      (a, b) =>
        parseInt(a?.replace('YTD', '')?.trim()) -
        parseInt(b?.replace('YTD', '')?.trim()),
    );
  }

  return [
    {
      title: '',
      dataIndex: 'title',
      key: 'metric',
      width: 200,
    },
    ...uniqueYears?.map((item) => ({
      title: (
        <span className="column-title">
          {Number(item) === currentYear ? `YTD ${item}` : item}
        </span>
      ),
      dataIndex: Number(item) === currentYear ? `YTD ${item}` : item,
      key: Number(item) === currentYear ? `YTD ${item}` : item,
      minWidth: 120,
      render: (text, record) => renderFunction(text, record),
    })),
  ];
};

export const RENDER_CELL_GRID = (text, record) => {
  let color = '';
  let fontWeight = '';
  const displayText = text || '-';

  if (
    record.title === 'YTD Avg. OUTs (Eq. Vials)' ||
    record.title === 'Net Sales' ||
    record.title === 'Gross Sales'
  ) {
    fontWeight = 'bold';
  }

  if (typeof text === 'string' && text?.includes('%')) {
    const value = parseFloat(text?.replace('%', ''));

    if (!isNaN(value)) {
      if (value < 0) {
        color = '#C23934';
        fontWeight = 'bold';
      } else {
        color = '#56A944';
        fontWeight = 'bold';
      }
    }
  }

  return <span style={{ color, fontWeight }}>{displayText}</span>;
};

export const X_AXIS_YOY = [
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  'H1',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  'H2',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
];

const renderFunctionNetSales = (text, record) =>
  NET_SALES_RENDER_CELL_GRID(text, record);

export const NET_SALES_CHART_GRID_COLUMNS = (years) =>
  [
    {
      title: <span className="column-title">Metric</span>,
      dataIndex: 'metric',
      key: 'metric',
      align: 'left',
    },
    ...years.map((item) => ({
      title: <span className="column-title">{item}</span>,
      dataIndex: item,
      key: item,
      align: 'left',
      render: (text) => (text ? text : '-'),
    })),
  ]?.map((column) => {
    if (column.dataIndex !== 'metric') {
      column.render = (text, record) => renderFunctionNetSales(text, record);
    }
    return column;
  });

export const NET_SALES_RENDER_CELL_GRID = (text) => {
  let color = '';

  const displayText = text || '-';

  if (typeof text === 'string' && text.includes('%')) {
    const value = parseFloat(text.replace('%', ''));

    if (!isNaN(value)) {
      if (value < 0) {
        color = '#C23934';
      } else {
        color = '#56A944';
      }
    }
  }

  return <span style={{ color }}>{displayText}</span>;
};

export const BUSINESS_PLAN_DETAILS_OPTIONS = [
  { value: 'unadjusted', label: 'Unadjusted Figures' },
  { value: 'adjusted', label: 'Adjusted Figures' },
];

const StaticUrl =
  'https://qlikprd.gene.com/QvAJAXZfc/opendoc.htm?document=ecosystem%20business%20insights.qvw&lang=en-US&host=QVS%40PROD';

const navigateNewTab = (url) => {
  // eslint-disable-next-line no-undef
  window.open(url, '_blank');
};

export const PORTFOLIO_COLUMN_MENU = [
  {
    label: (
      <span>
        <i className={'icon icon__prescription'}></i>Brand Page
      </span>
    ),
    key: 'brand',
  },
  {
    label: (
      <div onClick={() => navigateNewTab(StaticUrl)}>
        <i className={'icon icon__chartbar'}></i>EA Dashboard
      </div>
    ),
    key: 'eadashboard',
  },
  {
    label: (
      <span>
        <i className={'icon icon__file'}></i>Weekly Report
      </span>
    ),
    key: 'weeklyreport',
  },
];

export const LEGENDDATA = {
  high: '85',
  mediumlow: '40',
  mediumhigh: '85',
};

export const INFO_YTD_VAR_DRIVES =
  'This is Info Section for YTD Variance Drives (T0 , $M)';

export const INFO_YTD_VAR_DRIVERS =
  'This is Info Section for YTD YoY Variance Drivers (PY YTD, $M)';

export const INFO_YTD_ROY = 'This is Info Section for YTD & RoY';

export const INFO_SALES_DEMAND =
  'This is Info Section for Sales and Demand Variance';

export const INFO_YOY_SALES =
  'This is Info Section for Year Over Year Sales Growth';

export const INFO_YOY_OUTS =
  'This is Info Section for Year Over Year OUTs Growth';

export const ACTION_ADD = 'add';
export const ACTION_EDIT = 'edit';
export const ACTION_DELETE = 'delete';
export const LABELCOLOR = {
  T0: '#AF1685',
  'Squad Plan': '#AF1685',
  '12 Wk Avg.': '#DB930B',
  '4 Wk Avg.': '#4B80B1',
  Trend: '#56A944',
};

export const PORTFOLIO_BANNERGRID_COLUMNS = [
  {
    title: '',
    dataIndex: 'name',
    key: 'name',
    minWidth: 110,
    render: (text) => (
      <span style={{ fontSize: '13px' }}>{text ? text : '-'}</span>
    ),
  },
  {
    title: (
      <span style={{ fontSize: '13px', fontWeight: '400' }}>FY Projection</span>
    ),
    dataIndex: 'FY Projection',
    key: 'FY Projection',
    render: (text) => (
      <span style={{ fontWeight: 'bold', fontSize: '14px' }}>
        {text ? text : '-'}
      </span>
    ),
  },
  {
    title: (
      <span style={{ fontSize: '13px', fontWeight: '400' }}>
        RoY Weekly Sales
      </span>
    ),
    dataIndex: 'ROY Weekly Sales',
    key: 'ROY Weekly Sales',
    render: (text) => (
      <span style={{ fontWeight: 'bold', fontSize: '14px' }}>
        {text ? text : '-'}
      </span>
    ),
  },
];

export const MY_VIEW_CATEGORY = {
  MYVIEW: 'myview',
};

// need to update moew cards chart label that is full width
export const MY_VIEW_FULLWIDTH = [
  'fy-projection',
  'ytd-roy',
  'sales-demand',
  'year-forecast',
  'net-sales',
];

export const MY_VIEW_SINGLECHART = [
  'ytd-variance',
  'ytd-py',
  'yoy-sales',
  'agg-forecast',
  'short-termforecast',
  'yoy-growth',
];

export const MY_VIEW_CHART_With_GRID = [''];

export const TABNAMES = {
  portfolio: 'Portfolio Performance',
  brand: 'Brand Performance',
  sop: 'S&OP',
  bplan: 'Business Plan',
};
export const TABNAMES_MyView = {
  portfolio: 'Portfolio Performance',
  brand: 'Brand Performance',
  sop: 'S&OP',
  bplan: 'Business Performance',
};
export const SUB_TABNAMES_PORTFOLIO = {
  summary: 'Summary',
  portfolio: 'Portfolio',
  eqouts: 'Eq. OUTs',
};

export const SUB_TABNAMES_BRAND = {
  summary: 'Summary',
  quaterly: 'Quaterly',
  monthly: 'Monthly',
};

export const SUB_TABNAMES_SOP = {
  summary: 'Summary',
  forecast: 'Forecast % Change',
};

export const SUB_TABNAMES_BPLAN = {
  summary: 'Summary',
  details: 'Details',
};
export const AppRoute = '/analyticshub/saif/';
export const BRANDURL = '/analyticshub/saif/brand';

export const LEGAL_COMPLIANCE_TEXT = `The following includes confidential, material non-public information that, if disclosed, may have a material impact on the share price of Roche. Such information may be provided ONLY to employees on the company insider list, and may NOT be disclosed to any other person. You must keep this information confidential, and you may not use the information to purchase, sell or otherwise trade securities or to make trading recommendations to others. Failure to abide by these obligations violates Genentech’s Code of Conduct, the Roche Group Insider Directive, and your confidentiality obligations, and may constitute a criminal offense under U.S. and Swiss laws. Violations may result in serious consequences including termination of employment, criminal penalties and imprisonment.
Note: For YTD Sales vs. PY YTD the Demand bucket includes sales variance due to revenue days as well (i.e. Demand + ERD).`;

export const CARDNAME = 'cardName';
export const CARDNAMECCAPS = 'CardName';
export const CONFIGWARNINGMSG = 'Chart title may need to be updated.';

export const YEAR_FORECAST_COMMENT_VIEW_TYPE = [
  {
    key: 'Curr S&OP vs. Prev S&OP',
    value: 'previous',
  },
  {
    key: 'S&OP vs. Financial Forecast',
    value: 'financialForecast',
  },
];

export const GETKPIDATACOMMENT = (comments, brand, chartID) => {
  const kpicomments = comments?.filter((item) => item?.ChartId === chartID);
  const kpi = kpicomments?.filter((item) => item?.BrandName === brand);
  return kpi?.length ? kpi[0]?.Comment : '';
};

export const COMMENTLIMIT = 1000;
export const PORTFOLIO_COMMENT_LIMIT = 5000;
export const PORTFOLIO_COMMENT_ERROR = `Comment length should be under ${PORTFOLIO_COMMENT_LIMIT} characters.`;
export const COMMENTLIMITERROR = `Comment length should be under ${COMMENTLIMIT} characters.`;
export const COMMENTLIMIT_CARD = 1000;
export const COMMENTLIMITERROR_CARD = `Comment length should be under ${COMMENTLIMIT_CARD} characters.`;
export const PORTFOLIO_BANNER_COLORPALLETE = [
  '#AF1685',
  '#56A944',
  '#4B80B1',
  '#DB930B',
];
// Filters
export const FILTERS = {
  BU_FILTER: '[BU Filter]',
  VERSION_TYPE: 'Version_Type',
  FRANCHISE: 'Franchise',
  SKU: 'SKU',
  BRAND: 'Brand',
  MEASURE_SOP: '[Measure S&OP]',
  FACT_FLAG: 'Fact_Flag',
  VARIANCE_VERSION_TYPE: 'Variance_Version_Type',
  FRANCHISE_T: 'Franchise_T',
  BP_BRAND: '[BP Brand]',
  BP_BUSINESS_UNIT: '[BP Business Unit]',
  EVENT_TYPE: 'EventType',
  VERSION_NAME: '[Version Name]',
};
export const PRIMARY_CONDITION_FILTER = {
  FRANCHISE: 'Franchise=',
  SKU: 'SKU=',
  BRAND: 'Brand=',
  FRANCHISE_T: 'Franchise_T=',
};

//portfoliocontainer

export const PORTFOLIO_COMMENT_TITLE = 'Portfolio Narratives';
export const MODAL_COMMENT_TITLE = 'Portfolio Narratives';
export const WEEK_PORFOLIO = 'Week';
export const FY_PROJECTION = 'FY Projection';
export const ROY_WEEKLY_SALES = 'ROY Weekly Sales';
export const BANNER = 'banner';
export const BANNERTABLE = 'bannerTable';

//Common
export const REMOVE = 'remove';
export const DUPLICATE = 'duplicate';
export const CONFIGURE = 'configure';
export const SUCCESS = 'success';
export const ERROR = 'error';
export const ALL = 'ALL';
export const UNIXID = 'unixId';
export const USERNAME = 'userName';
export const SINGLE = 'single';
export const KPI = 'kpi';
export const EXPAND = 'expand';
export const EVENT = 'event';
export const INFO = 'info';
export const EXPORT = 'export';
export const NA = 'N/A';
export const GROWTH = 'Growth';
export const T0 = 'T0';
export const PIN_SUCCESS = 'pinSuccess';

export const CATEGORYLOWERCASE = 'category';
export const CATEGORYUPPERCASE = 'Category';
export const VREPLACESTRING = 'vReplaceString';
export const LINECHART = 'line';
export const BARCHART = 'bar';
export const PIE = 'pie';
export const TOTAL = 'Total';
export const NONE = 'none';
export const EXCEL_NOTIFICATION = 'The file download has started.';
export const BOTTOMLEFT = 'bottomLeft';
export const UNDERLINE = 'underline';
export const POINTER = 'pointer';
export const RED = 'red';
export const GREEN = 'green';
export const ERROR_MESSAGE = 'Error fetching data:';
export const ERROR_MESSAGE_CATEGORIES = 'Categories are undefined or empty.';
export const SKU = 'SKU';
export const YEAR = 'Year';
export const BRANDLOWERCASE = 'brand';
export const BRANDUPPERCASE = 'Brand';
export const BRANDSUPPERCASE = 'Brands';
export const WEEKLYREPORT = 'weeklyreport';
export const CHART = 'chart';
export const GRID = 'grid';
export const TABLE = 'table';
export const TABLE_ICON_WHITE = 'table-white';
export const CHART_ICON_WHITE = 'chart-white';
export const SUBMIT = 'Submit';
export const PRIMARY = 'primary';
export const NO_COMMENT = 'No comments added';
export const NO_NARRATIVE_AVAIALABLE = 'Narrative not available';
export const VIEW_MORE = 'View More';
export const DISEASE_AREA_DATA = 'Disease Area Data For Erivedge';
export const DELETE_COMMENT = 'Delete Comment';
export const NO_COMMENT_AVAILABLE = 'No Comments Available';
export const SINGLE_BRAND_COMMENT =
  'Please select a single Brand to enable comments.';
export const CLOSE = 'Close';
export const ALL_BRAND = 'All Brands';
export const DATA = 'Data';
export const ALL_SQUADS = 'All Squads';
export const MY_VIEW = 'My View';
export const LABEL = 'Label';
export const EDIT_COMMENT_MODAL_HEADER = 'Edit Comment';
export const ADD_COMMENT_MODAL_HEADER = 'Add Comment';
export const DELETE_BUTTON_TEXT = 'Delete';

export const YES = 'YES';
export const NO = 'NO';
export const DELETE_CONFIRMATION_TEXT =
  'Are you sure you want to delete this comment? This action cannot be undone.';
export const TOAST_ERROR_MESSAGE =
  'Something went Wrong. Please try again later.';
export const DISCLAIMER = 'RESTRICTED INSIDER INFORMATION:';
export const BRANDSUMMARY = 'BrandSummary';
//portfolio summary

export const ACTUALS = 'Actuals';
export const PYYTD = 'PY YTD';
export const PORTFOLIO_BANNERGRID_SEPARATOR = '&##&';

export const STATE_PORTFOLIO_SUMMARY = {
  YTDVARIANCEACTUAL: 'ytdVarianceActual',
  YTDVARIANCE: 'ytdVariance',
  YTDPYACTUAL: 'ytdPyActual',
  YTDPYVARIANCE: 'ytdPyVariance',
  YTDAVGLABEL: 'ytdAvgLabel',
  YTD12WEEKLABEL: 'ytd12weekLabel',
  YTDTRENDLABEL: 'ytdTrendLabel',
  YTDT0LABEL: 'ytdTOLabel',
  FYPROJECTION: 'fyProjection',
};
export const CHART_DATA = 'chartData';

//PortfolioTab

export const PORTFOLIO_TABVIEW = {
  QTD: 'QTD Portfolio Sales by Brand through week #',
  YTD: 'YTD Portfolio Sales by Brand through week #',
};
export const QTD_YEAR = 'QtdYear';
export const PORTFOLIO_QTD_TEXT = 'portfolioQtdText';
export const PORTFOLIO_TABHEADER =
  'All metrics in millons. Select brand to open the Ecosystem Analytics Dashboard.';

export const PORTFOLIO_COLUMN_QTD = {
  PRICE: 'Price',
  RES: 'Reserves',
  INV: 'Inventory',
  DEMAND: 'Demand',
  PYQTD: 'PY QTD',
  SQUAD: 'Squad',
  BRANDS: 'Brands',
  WEEKLYLINK: 'WeeklyReportLinks',
};

export const PORTFOLIO_COLUMN_YTD = {
  YTD_ACT: 'YTD Act',
  VSPY: 'vs. PY',
  T0: 'T0',
  DELTA: 'Delta',
  VST0: 'vs. T0',
  WEEKLYAVGTOGO: 'Weekly Avg. To Go',
  PRICE: 'Price',
  RES: 'Reserves',
  INV: 'Inventory',
  DEMAND: 'Demand',
  WEEKLYAVG: 'Weekly Avg.',
  WEEKAVG4: '4-Wk Avg.',
  WEEKAVG12: '12-Week Avg.',
  T0FULL: 'T0full',
  TREND: 'Trend',
  SQUAD: 'Squad',
  BRANDS: 'Brands',
  WEEKLYLINK: 'WeeklyReportLinks',
};

export const PORTFOLIO_COLUMN_YTD_EXPORT = {
  ytdact: 'YTD Act',
  vspy: 'vs. PY',
  T0: '{selectedFilters?.VersionType}',
  Delta: 'Delta',
  vsT0: 'vs. {selectedFilters?.VersionType}',
  weeklyavgtogo: 'Weekly Avg. To Go',
  Price: 'Price',
  Res: 'Reserves',
  Inv: 'Inventory',
  Demand: 'Demand',
  weeklyavg: 'Weekly Avg.',
  weekavg4: '4-Wk Avg.',
  weekavg12: '12-Week Avg.',
  T0full: '{selectedFilters?.VersionType} Full Year',
  Trend: 'Trend Full Year',
  title: 'Squad',
  Brands: 'Brand',
  ytdComment: 'YTD vs. {selectedFilters?.VersionType}',
  ytdPrevComment: 'YTD vs. PY',
  weeklyLink: 'WeeklyReportLinks',
};
//Eqouts tabs

export const PORTFOLIO_EQOUTS_COLUMNS = {
  LAST_WEEK_ACTUALS: 'Last Week Actuals',
  WEEK_4_AVG: '1 Month (4 wk) Avg.',
  WEEK_12_AVG: '3 Month (12 wk) Avg.',
  WEEK_26_AVG: '6 Month (26 wk) Avg.',
  WEEK_52_AVG: '12 Month (52 wk) Avg.',
  YTD_OUTS_WK: 'YTD OUTs/wk',
  VS_PY_YTD: 'Vs. PY YTD',
  VS_SQUAD_PLAN_YTD: 'Vs. T0 YTD',
  ROY_OUTS_WK_TO_SQUAD_PLAN: 'ROY OUTs/wk to Squad Plan',
  ROY_TO: 'ROY OUTs/wk to T0',
  SQUAD: 'Squad',
  BRAND: 'Brand',
  WEEKLYLINK: 'WeeklyReportLinks',
};
export const COMMENTUPPERCASE = 'Comment';
export const COMMENTLOWERCASE = 'comment';
export const STRING = 'string';
export const FRANCHISE = 'Franchise';
export const WEEKEND_OUTS = '[Weekend OUTs]';
export const WEEK_13_OUT = '13-week OUTs';
export const BRAND_COLOR = '#004677';
export const SQUADS = 'Squads';
export const B_ORDER = 'B_Order';
export const YTDCOMMENT = 'ytdComment';
export const YTDPREVCOMMENT = 'ytdPrevComment';
export const EXCEL_DOWNLOAD_EQOUTS = 'Eq Out.xlsx';

//SOP CONTAINER

export const SOP = 'sop';
export const SOP_BANNER_TITLE = 'S&OP Performance (YTD)';
export const SOP_FORECASTYEAR_DROPDOWN_COLUMN = 'Measure S&OP';

export const API_STATE_SOP_CONTAINER = {
  WEEKLYAVG: ' WeeklyAvg',
  PREVIOUSMONTHGRIDDATA: 'previousMonthGridData',
  FINANCIALFORECASTGRIDDATA: 'financialForecastGridData',
};
export const PREVIOUSMONTH = 'Previous Month';
export const FINANCIALFORECAST = 'Financial Forecast';

export const PACKS = 'Packs';
export const SALES = 'Sales';
export const GRAMS = 'Grams';

//SOP SUMMARY
export const SOPINSIGHTS = 'S&OP Insights';

export const SOP_VIEWNAME = 'S&OP Performance';

export const API_STATE_SOP_SUMMARY = {
  FORECASTTRENDCHART: 'forecastTrendChartData',
  FORECASTTRENDCHARTLABEL: 'forecastTrendChartLabel',
  AGGFORECASTLABEL: 'AggForecastLabel',
  FORECASTTRENDMONTH: 'forecastTrendMonth',
  AGGREGATECHARTDATA: 'aggregateChartData',
  ACCURACYCHARTDATA: 'accuracyChartData',
  BIASFORECAST4WKCHARTDATA: 'biasForecast4WksChartData',
  BIASFORECAST26WKCHARTDATA: 'biasForecast26WksChartData',
};

export const SOP_INFO_MESSAGE =
  'This is info section for Short Term Forecast Bias Chart';

//SOP FORECAST CHANGE
export const SOP_EXPORT_FILE_NAME = {
  PREVIOUS: 'Forecast Change- Previous month.xlsx',
  FINANCIAL: 'Forecast Change- Financial forecast.xlsx',
};

export const SOP_TABLE_HEADERS = {
  PREVIOUS: 'Forecast % Change (Curr vs. Prev S&OP Forecast)',
  FINANCIAL: 'Forecast % Difference (Current S&OP vs. Financial Forecast)',
};

export const FORECAST_INFOMODAL_HEADER = 'Forecast % change';

//Brand Container

export const EQUILIZED_OUTS = 'Equilized OUTs';
export const FORECASTED_OUTS = 'Forecasted OUTs';
export const PATIENTS = 'Patients';
export const NET_SALES = 'Net Sales';
export const FORECASTED_SALES = 'Forecasted Sales';
export const ESTIMATED = 'Estimated';
export const STATE_BRAND_CONTAINER = {
  BANNER: 'banner',
  MONTHLY: 'monthly',
  QUATERLY: 'quarterly',
  MONTHLYPREVIOUS: 'monthlyPrev',
  QUATERLYPREVIOUS: 'quarterlyPrev',
};

//Brand Sumary

export const ACTUAL_SALES = 'Actual Sales';
export const ACTUAL = 'Actual';

export const STATE_BRAND_SUMMARY = {
  YOYSALES: 'yoySales',
  YOYOUTSGRID: 'yoyOutsGrid',
  YTDVARIANCE: 'ytdVariance',
  YOYVARIANCE: 'yoyVariance',
  YTDROYDEMAND: 'ytdRoyDemand',
  YTDROYDEMANDTEXT: 'ytdRoyDemandText',
  SALESDEMAND: 'salesDemand',
  YOYOUTS: 'yoyOuts',

  YOYSALESGRID: 'yoySalesGrid',
  YTDROYDEMANDYTD: 'ytdRoyDemandYtd',
  YTDROYDEMAND4WEEK: 'ytdRoyDemand4Week',
  YTDROYSALESYTD: 'ytdRoySalesYtd',
  YTDROYSALES4WEEK: 'ytdRoySales4Week',
};

export const BRAND_SECTION_EXPRESSION_NAME = {
  CHARTDATA1: 'YTD Variance Drivers (T0, $M)',
  CHARTDATA2: 'YTD YoY Variance Drivers (PY YTD, $M)',
  CHARTDATA3DEMAND: 'Demand YTD & RoY',
  CHARTDATA3DEMANDTEXT: 'Demand YTD & RoY Text',
  CHARTDATASALESDEMAND: 'Sales & Demand',
  CHARTDATAYOYOUTS: 'Year over Year OUTs Growth',
  CHARTDATAYOYSALES: 'Year Over Year Sales Growth',
  CHARTDATA3SALESYTD: 'Sales YTD & RoY',
  CHARTDEMANDLABEL: 'Demand YTD & RoY Label',
};

//Brand Monthly
export const CURRENT = 'current';
export const PREVIOUS = 'previous';

//Business Plan

export const API_STATE_BUSINESS_PLAN = {
  UNADJUSTEDGRIDDATA: 'UnAdjustedGridData',
  ADJUSTEDGRIDDATA: 'AdjustedGridData',
};

export const ADJUSTED = 'Adjusted';
export const UNADJUSTED = 'Unadjusted';
export const UNADJUSTED_FIGURE = 'Unadjusted Figures';
export const ADJUSTED_FIGURE = 'Adjusted Figures';
export const DISEASE_AREA = 'Disease_Area';

//Business Summary

export const VIEW_NAME = 'Business Performance';

export const API_STATE_BUSINESS_SUMMARY = {
  SUMMARYDATA: 'summaryData',
  EVENTDETAILS: 'eventDetails',
  NETSALESGRIDATA: 'netSalesGridData',
};

//VIEWNAME
export const SUB_VIEWNAMES = {
  SUMMARY: 'summary',
  PORTFOLIO: 'portfolio',
  EQOUTS: 'Eqouts',
  FORECAST: 'forecast',
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
  DETAILS: 'details',
  KPIS: 'kpis',
};

export const CARD_TITLE = {
  YTD_ROY_PROJECTIONS: 'YTD & RoY Projections',
  YTD_SALES_VS_PY: 'YTD Sales vs. PY YTD ($M)',
  YTD_SALES_VS_FORECAST: 'YTD Sales vs. Forecast ($M)',
  YEAR_FORECAST: '3 Year Forecast Trend View',
  AGG_FORECAST: 'Aggregated Forecast Accuracy',
  SHORT_TERMFORECAST: 'Short-term Forecast BIAS',
  YTD_ROY_PROJECTION: 'YTD & RoY Projections',
  YTD_ROY: 'YTD & RoY',
};

export const CARD_ID = {
  YTD_ROY_PROJECTIONS: 'fy-projection',
  YTD_SALES_VS_PY: 'ytd-sales-v-py',
  YTD_SALES_VS_FORECAST: 'ytd-sales-v-forec',
  PORTFOLIO_YTD: 'portfolioYtd',
  PORTFOLIO_YTD_TOTAL: 'portfolioYtdTotal',
  PORTFOLIO_QTD: 'portfolioQtd',
  PORTFOLIO_QTD_TOTAL: 'portfolioQtdTotal',
  EQOUT: 'eqout',
  EQOUTLINE: 'eqoutLine',
  YEAR_FORECAST: 'year-forecast',
  AGG_FORECAST: 'agg-forecast',
  SHORT_TERMFORECAST: 'short-termforecast',
  YOY_GROWTH: 'yoy-growth',
  YOY_SALES: 'yoy-sales',
  DEMAND: 'sales-demand',
  YTD_ROY: 'ytd-roy',
  YTD_PY: 'ytd-py',
  YTD_VARIANCE: 'ytd-variance',
  PORTFOLIO_QTD_TEXT: 'portfolioQtdText',
  NET_SALES: 'net-sales',
};

export const CHART_TYPE = {
  YTD_ROY_PROJECTIONS: 'fyprojectionlineChart',
  YTD_SALES_VS_PY: 'SalespySidebar',
  YTD_SALES_VS_FORECAST: 'Sidebar',
  YEAR_FORECAST: 'yearforecastlinechart',
  AGG_FORECAST: 'aggforecastlinechart',
  AGG_FORECAST_ACCURACY: 'accuracyforecastlinechart',
  SHORT_TERMFORECAST: 'barChartOptionsSop',

  NET_SALES: 'BusinessplanNetSalesLineChart',
  PORTFOLIO_TIMELINE: 'portfolioTimeline',
  PIE: 'pie',
};

export const QUERY_ERROR = {
  PORTFOLIO_SUMMARY_COMMENT:
    'Error occured in  [Portfolio Summary comment] - Please try after sometime.',
  PORTFOLIO_COMMENT:
    'Error occured in  [Portfolio comment] - Please try after sometime.',
  PORTFOLIO_WEEK:
    'Error occured in  [Portfolio week] - Please try after sometime.',
  PORTFOLIO_BANNER_TABLE:
    'Error occured in  [Portfolio Banner Table Data] - Please try after sometime.',

  PORTFOLIO_BANNER_CHART:
    'Error occured in  [Portfolio Banner chart Data] - Please try after sometime.',

  PORTFOLIO_YEAR:
    'Error occured in  [Portfolio year] - Please try after sometime.',
};

export const QUERY_KEY = {
  PORTFOLIO_SUMMARY_COMMENT: 'Portfolio Summary comment',
  PORTFOLIO_COMMENT: 'Portfolio comment',
  PORTFOLIO_WEEK: 'Portfolio week',
  PORTFOLIO_BANNER_TABLE: 'Portfolio Banner Table Data',
  PORTFOLIO_BANNER_CHART: 'Portfolio  Banner chart Data',
  PORTFOLIO_YEAR: 'Portfolio Year',
  SUMMARY_DETAILS: 'Summary details',
  PORTFOLIO_TAB: 'Porfolio tab',
  SOP_BANNER: 'Fetch Sop Banner',
  SOP_FORECASTCHANGE: 'SOPForecastPercentageChangeTabData',
  SOP_YEARFORECAST_CHART: 'yearForecastChart',
  SOP_FORECASTTABLE: 'SOPSummaryTabData',
};
export const NARRATION_VIEW = {
  BUSSINESS_PLAN: 'Business Plan',
  BRAND: 'Brand',
  PORTFOLIO: 'Portfolio',
};
export const SOPbannerContent = (
  isCollapsed,
  sopState,
  getClassName,
  classes,
) => {
  return (
    <div>
      {!isCollapsed && (
        <div className={classes['banner-data']}>
          {sopState?.sopSummaryData?.map((item, index) => {
            return (
              <div
                className={`${classes['title-weekly']} ${
                  item?.name === '% Change From Last Forecast'
                    ? getClassName(item?.data ? item?.data : '-')
                    : ''
                }`}
                key={index}
              >
                <span className={classes['title-data']}>
                  {item?.data ? item?.data : '-'}
                </span>
                <p className={classes['title']}>{item?.name}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const sopSummary_CardIds = [
  CARD_CONFIG.SOP_YEAR_FORECAST_CHART.CardId,
  CARD_CONFIG.SOP_AGGFORECAST_CHART.CardId,
  CARD_CONFIG.SOP_AGG_ACCURACY_FORECAST_CHART.CardId,
  CARD_CONFIG.SOP_SHORTTERM_BIAS_4WKS_CHART.CardId,
  CARD_CONFIG.SOP_SHORTTERM_BIAS_26WKS_CHART.CardId,
];

export const getSopViewTypes = (
  showSummary,
  showForecastChange,
  viewType,
  showCardIds,
) => {
  return [
    ...(showSummary && sopSummary_CardIds.some((id) => showCardIds.includes(id))
      ? [
          {
            value: 'summary',
            key: (
              <>
                <i
                  className={`icon icon__${
                    viewType === 'summary' ? 'chart-white' : 'chart'
                  }`}
                ></i>
                Summary
              </>
            ),
          },
        ]
      : []),
    ...(showForecastChange &&
    [CARD_CONFIG.SOP_FORECASTCHANGE_PREVIOUS_GRID.CardId].some((id) =>
      showCardIds.includes(id),
    )
      ? [
          {
            value: 'forecast',
            key: (
              <>
                <i
                  className={`icon icon__${
                    viewType === 'forecast' ? 'table-white' : 'table'
                  }`}
                ></i>
                Forecast Comparison
              </>
            ),
          },
        ]
      : []),
  ];
};

export const factFlagType = (metric) => {
  if (metric?.includes('Packs')) {
    return 'Packs';
  } else if (metric?.includes('Sales')) {
    return 'Sales';
  } else return 'Grams';
};

//Business Details

export const CANCELTEXT = 'Cancel';
export const SUBMITTEXT = 'Submit';
export const COMMENTTEXT = 'Comment';
export const COMMNETMAXLENGTH = COMMENTLIMIT + 1;
export const COMMENTAUTORESIZE = true;
export const ISACTIVEFLAG = 'Y';
export const COMMENTS_MESSAGE = {
  NO_COMMENT: 'No comments added',
  SKU_ENABLE: 'Please select a single SKU to enable comments.',
  BRAND_ENABLE: 'Please select a single Brand to enable comments.',
};

export const DELETE_COMMENT_MESSAGE =
  'Are you sure you want to delete this comment? This action cannot be undone.';

//card
export const LINE_NAME = {
  HIGH: 'High',
  LOW: 'Low',
  SQUAD_PLAN: 'Squad Plan',
  T0: 'T0',
};

//performance container

export const TAB = {
  BRAND: 'Brand',
  PORTFOLIO: 'Portfolio',
  SOP: 'S&OP',
};

export const YTDANDROYCOMMENT =
  'This chart will only display data when a single squad is selected.';
export const WIDTH85VW = '85vw';
export const VALUE400PX = '440px';
export const VALUE500PX = '500px';
export const VALUE250PX = '250px';
export const COMMENTEDCHARTS = [
  'ytd-variance',
  'ytd-py',
  'year-forecast',
  'short-termforecast',
  'net-sales',
];
export const RIGHTSIDELABELCHARTS = [
  'fy-projection',
  'ytd-roy',
  'year-forecast',
];
export const FLOAT_FILTERS = 'Filters';
export const BUSSINESS_UNIT = 'Business Unit';
export const SQUAD = 'Squad';
export const Ioptions = {
  root: null,
  rootMargin: '0px',
  threshold: 1.0,
};
export const VALUE600 = 600;
export const VALUE350 = 350;
export const VALUE110 = 110;
export const SOPVSFINANCIALFORECAST = 'S&OP vs Financial Forecast';
export const CURSOPVSPREVSOP = 'Cur S&OP vs Prev S&OP';
export const BUSINESSUPPERCASE = 'Business';
export const BUSINESSLOWERCASE = 'business';
export const SOPUPPERCASE = 'S&OP';
export const NETSALESKIPICARDID = 30;
export const YEARFORECASTFINANCIALFORECASTKPICARDID = 23;
export const PREVIOUSMONTHFORECASTKPICARDID = 2001;
export const YTDVARIANCEKPICARDID = 1;

export const TREND_FORECASTCHART_COMMENT_MODAL_TITLE =
  'Trend Forecast Comments';
export const YTDRoYPROJECTIONSkpilabels = {
  YTDAvg: 'YTD Avg',
  YTDAvgLabelStyle: '#4b80b1',
  T0: 'T0',
  T0LabelStyle: '#af1685',
  Trend: 'Trend',
  TrendLabelStyle: '#56a944',
  Last12wks: 'Last 12wks',
  Last12wksLabelStyle: '#db930b',
};
export const WIDTH65VW = '65vw';
export const VALUE20 = 20;
export const VALUE80PERCENT = '80%';
export const VALUE20PX = '20px';
export const VALUE2 = 2;
export const VALUE20PERCENT = '20%';
export const VALUE300PX = '300px';
export const VALUE100PERCENT = '100%';
export const VALUE80PX = '80px';
export const VALUE50PX = '50px';
export const LABELCOLORS = {
  '#4b80b1': 'color-4b80b1',
  '#af1685': 'color-af1685',
  '#56a944': 'color-56a944',
  '#db930b': 'color-db930b',
  '#919698': 'color-919698',
  '#13294b': 'color-13294b',
  '#cbcbcb': 'color-cbcbcb',
};

export const COMMENT_VISIBILITY_LABEL = {
  SHOW: 'Show',
  HIDE: 'Hide',
};
export const SDLINECHART_YAXIS_LABEL = 'Variance ($M)';
export const NarrationConstant = {
  businessPlanNarrative: 'Business Plan Narrative',
  businessPlanNarratives: 'Business Plan Narratives',
  brandPerformanceNarratives: 'Brand Performance Narratives',
  brandPerformanceNarrative: 'Brand Performance Narrative',
  portfolioMessage: 'Please select one or no squad to view narratives',
  bpMessage: 'Please select one or no squad/brand to view narratives',
};
export const LEAVE_FEEDBACK_ERROR_MESSAGE = 'Error while submitting feedback.';
export const VALUE10 = 10;
export const VALUE1 = 1;
export const LEAVE_FEEDBACK = 'Leave Feedback';
export const LEAVE_FEEDBACK_TEXT =
  'Compliment or critique, we are just happy to hear from you.';
export const COMMENTS_TEXT = 'Comments';
export const CHARACTER_LIMIT_REACH_ERROR_TEXT = `oops! It looks like you have reached the maximum characters allowed
          for comments.`;
export const LIMIT_EXCEEDED_TEXT = 'Limit exceeded';
export const COMMENTFIELDTEXT = 'Comment field';
export const CHARACTERS_TEXT = 'Characters';
export const SAI_PERFORMANCE_DASHBOARD_TEXT = 'SPIDER';
export const SAI_PERFORMANCE_DASHBOARD_HOVER_TEXT =
  'Sales Performance Insights for Decision Enablement and Risk Assessment';
export const VALUE100VW = '100vw';
export const VALUE90PERCENT = '90%';
export const FEEDBACK_ADD_MESSAGE = 'Adding feedback';
export const VALUE95VW = '95vw';
export const VALUE99VW = '99vw';
export const USER_GUIDE_URL =
  'https://docs.google.com/presentation/d/11LZNuw9J9RVEO1uHCn4D8I1nmq9yjBG7cn9lBhyElx0/edit#slide=id.g394b2b580f391844_451';
export const NO_INFO = 'No Info Available';
